import {combineReducers} from "redux"

const active = (state = {section: ``, field: ``, option: ``}, action) => {
  switch (action.type) {
  case 'SET_ACTIVE':
    return {...state, [action.name]: action.value}
  case 'CLEAR_FORM':
    return {section: ``, field: ``, option: ``}
  default:
    return state
  }
}

const form = (state = {base: {}, sections: {}, fields: {}, formSettings: {}}, action) => {
  switch (action.type) {
  case 'SET_RESOURCES':
    return {...state, [action.name]: action.value}
  case 'SET_FIELD':
    // const category = state[action.category]
    // category[action.id] = action.value
    // return { ...state, [action.category]: category }
    return {...state, [action.category]: {...state[action.category], [action.id]: action.value}}
  case 'MERGE_FIELD':
    // const c = state[action.category]
    // c[action.id] = { ...c[action.id], [action.key]: action.value }
    // return { ...state, [action.category]: c }
    return {
      ...state,
      [action.category]: {
        ...state[action.category],
        [action.id]: {
          ...state[action.category][action.id],
          [action.key]: action.value
        }
      }
    }
  case 'DELETE_RESOURCE':
    const s = {...state}
    delete s[action.category][action.id]
    return {...s}
  case 'ADD_RESOURCE':
    return {...state, [action.category]: {...state[action.category], [action.id]: action.body}}
  case 'CLEAR_FORM':
    return {base: {}, sections: {}, fields: {}, formSettings: {}}
  default:
    return state
  }
}

const status = (state = {section: false, field: false, option: false}, action) => {
  switch (action.type) {
  case 'STATUS_UPDATE':
    return {...state, [action.category]: action.bool}
  case 'CLEAR_FORM':
    return {section: false, field: false, option: false}
  default:
    return state
  }
}

const meta = (state = {lock: false}, action) => {
  switch (action.type) {
  case 'LOCK':
    return Object.assign({}, state, {lock: action.bool})
  case 'CLEAR_FORM':
    return Object.assign({}, state, {lock: false})
  default:
    return state
  }
}

const rootReducer = combineReducers({
  active,
  form,
  status,
  meta
})

export default rootReducer
