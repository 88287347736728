// These will be stripped out during the build step when deployed to production
// https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables

let firebaseConfig
let FULL_TEXT_SEARCH_URL
let FUNCTIONS_URL
let BUCKET

let ENVIRONMENT = process.env.NODE_ENV

switch (process.env.REACT_APP_ENV) {
case 'development': 
  ENVIRONMENT = 'development'
  break
case 'production':
  ENVIRONMENT = 'production'
  break
case 'prod-uat':
  ENVIRONMENT = 'prod-uat'
  break
default: break
}

if (ENVIRONMENT === 'prod-uat') {
  console.log('uat')
  firebaseConfig = {
    apiKey: "AIzaSyCETEHRnxMhbdB9x_Wdx2CzNYFWUb27ASY",
    authDomain: "fit-qm-uatenv.firebaseapp.com",
    databaseURL: "https://fit-qm-uatenv.firebaseio.com",
    projectId: "fit-qm-uatenv",
    storageBucket: "fit-qm-uatenv.appspot.com",
    messagingSenderId: "23445169986",
    appId: "1:23445169986:web:7dc1e915dc6820bf1fcadb",
    measurementId: "G-GR63EBS5KF"
  }

  FULL_TEXT_SEARCH_URL = 'https://us-central1-fit-qm-uatenv.cloudfunctions.net/utilities'
  FUNCTIONS_URL = `https://us-central1-fit-qm-uatenv.cloudfunctions.net`
  BUCKET = "fit-qm-uatenv.appspot.com"
}

if (ENVIRONMENT === `development`) {
  console.log('development')
  firebaseConfig = {
    apiKey: `AIzaSyA7Czt3RMjZ0qEFEVUbSzZMfAfvCFIXoKk`,
    authDomain: `sunkaizen-9f140.firebaseapp.com`,
    databaseURL: `https://sunkaizen-9f140.firebaseio.com`,
    projectId: `sunkaizen-9f140`,
    storageBucket: `sunkaizen-9f140.appspot.com`,
    messagingSenderId: `712328998852`
  }

  FULL_TEXT_SEARCH_URL = `https://us-central1-sunkaizen-9f140.cloudfunctions.net/utilities`
  FUNCTIONS_URL = `https://us-central1-sunkaizen-9f140.cloudfunctions.net`
  BUCKET = `sunkaizen-9f140.appspot.com`
}

if (ENVIRONMENT === `production`) {
  console.log('production')
  firebaseConfig = {
    apiKey: `AIzaSyALM_4ol-7Q_djWiTTn4Qx5HCUIGGY1MMQ`,
    authDomain: `solarfit-6d9b8.firebaseapp.com`,
    databaseURL: `https://solarfit-6d9b8.firebaseio.com`,
    projectId: `solarfit-6d9b8`,
    storageBucket: `solarfit-6d9b8.appspot.com`,
    messagingSenderId: `545874751425`
  }

  FULL_TEXT_SEARCH_URL = `https://us-central1-solarfit-6d9b8.cloudfunctions.net/utilities`
  FUNCTIONS_URL = `https://us-central1-solarfit-6d9b8.cloudfunctions.net`
  BUCKET = `solarfit-6d9b8.appspot.com`
}

const staticMapKey = `AIzaSyA7Czt3RMjZ0qEFEVUbSzZMfAfvCFIXoKk`

export {firebaseConfig, FULL_TEXT_SEARCH_URL, FUNCTIONS_URL, ENVIRONMENT, BUCKET, staticMapKey}

export const googleAPIKey = `AIzaSyA7Czt3RMjZ0qEFEVUbSzZMfAfvCFIXoKk`

export const colors = {
  primary: '#234091',
  secondary: '#F4F6F8',
  tertiary: '#BD943F',
  quaternary: 'lightgrey'
}