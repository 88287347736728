/* eslint-disable */
import _ from "lodash"
import React, {useState} from "react"
import {List, Button} from "antd"

import {subscribe} from "./Firebase/SubscribeHOC"
import {create} from "./Firebase/CreateHOC"
import {read} from "./Firebase/ReadHOC"
import {update} from "./Firebase/UpdateHOC"
import {destroy} from "./Firebase/DestroyHOC"
import {pipe} from "../utilities"

function ProjectLister({
  subscribeResources: {projects, users, reports, tests, todos} = {},
  creationFunctions: {newTodo, createTest},
  updateFunctions: {updateTodo},
  deletionFunctions: {deleteTodo, deleteTest},
  makeSubscription,
  removeSubscription,
  subscriptions,
  readDB,
  readResults
}) {
  const lastTest = _.maxBy(tests, x => x.number) || {}

  const [editTodoNames, setEditTodoNames] = useState({})

  function readTest () {
    readDB(
      {
        paths: [{collection: `projects`}],
        options: { where: [{field: `client.name`, test: `==`, value: `AutomatedClient`}] }
      },
      'readTest'
    )
  }

  return (
    <div>
      <Button onClick={readTest} >Read Test</Button>
      {console.log(readResults)}
      <div className='padding-2' >{readResults.readTest && readResults.readTest[0].id}</div>
      <Button onClick={() => createTest({number: _.isNumber(lastTest.number) ? lastTest.number + 1 : 0})}>
        Add Test
      </Button>
      <List
        bordered
        dataSource={pipe(tests, [_.filter, x => x.type !== `Todo`], [_.sortBy, x => x.number]) || []}
        rowKey={x => x.id}
        renderItem={item => (
          <div style={{padding: `1rem`, display: `flex`, alignItems: `center`, justifyContent: `center`}}>
            <div>Test #{item.number}</div>
            <Button onClick={() => deleteTest(item)}>Delete</Button>
            {/* {isLoading[] && <div>Loading...</div>} */}
          </div>
        )}
      />
      <Button onClick={() => newTodo({type: `Todo`})}>Add Todo</Button>
      <List
        bordered
        dataSource={todos || []}
        rowKey={x => x.id}
        renderItem={item => (
          <div style={{padding: `1rem`, display: `flex`, alignItems: `center`, justifyContent: `center`}}>
            <div>{item.name || item.type}</div>
            <Button onClick={() => deleteTodo(item)}>Delete</Button>
            <input
              defaultValue={item.name || item.type}
              onChange={e => setEditTodoNames({...editTodoNames, [item.id]: e.target.value})}
            ></input>
            <Button onClick={() => updateTodo(item.id, {name: editTodoNames[item.id]})}>Update Name</Button>
          </div>
        )}
      />
      <Button onClick={() => makeSubscription({paths: [{collection: `reports`}]}, `reports`)}>Add Reports</Button>
      <List
        bordered
        dataSource={reports || []}
        renderItem={item => (
          <div style={{padding: `1rem`}}>
            <div>{item.name}</div>
          </div>
        )}
      />
      <List
        bordered
        dataSource={projects || []}
        renderItem={item => (
          <div style={{padding: `1rem`}}>
            <div style={{fontWeight: `bold`}}>{item.site.geocode_results.formatted_address}</div>
            <div>{item.client.name}</div>
          </div>
        )}
      />
      <List
        bordered
        dataSource={users || []}
        renderItem={item => (
          <div style={{padding: `1rem`}}>
            <div style={{fontWeight: `bold`}}>{item.name}</div>
            <div>{item.address}</div>
          </div>
        )}
      />
    </div>
  )
}

const WrappedProjectLister = pipe(ProjectLister, subscribe(), create(), read(), update(), destroy())

export default function TestHOC() {
  const subscriptions = {
    projects: {
      paths: [{collection: `projects`}],
      options: {
        test: true,
        ing: false,
        where: [{field: `client.name`, test: `==`, value: `AutomatedClient`}]
      }
    },
    users: {
      paths: [{collection: `users`}],
      options: {
        test: true,
        ing: false,
        order: `name`,
        where: [{field: `name`, test: `<`, value: `D`}]
      }
    },
    // todos: {
    //   paths: [{collection: `test`}],
    //   options: {
    //     where: [{field: `type`, test: `==`, value: `Todo`}]
    //   }
    // }
  }

  const mapCreationFunctionsToPaths = {
    createTest: [{collection: `test`}],
    newTodo: [{collection: `test`}]
  }

  const mapUpdateFunctionsToPaths = {
    updateTodo: [{collection: `test`}]
  }

  const mapDeletionFunctionsToPaths = {
    deleteTest: [{collection: `test`}],
    deleteTodo: [{collection: `test`}]
  }

  return (
    <div>
      <h1>Test HOC</h1>
      <WrappedProjectLister
        subscriptions={subscriptions}
        mapCreationFunctionsToPaths={mapCreationFunctionsToPaths}
        mapUpdateFunctionsToPaths={mapUpdateFunctionsToPaths}
        mapDeletionFunctionsToPaths={mapDeletionFunctionsToPaths}
      />
    </div>
  )
}
