import React, {useReducer} from "react"

const filterTypes = {
  type: {type: "type", name: "Project Type", options: [], instances: ["all"]},
  status: {type: "status", name: "Status", options: [], instances: ["all"]},
  started: {type: "started", name: "Date Created", options: [], instances: ["all"]},
  completed: {type: "completed", name: "Date Completed", options: [], instances: ["all"]},
  client: {type: "client", name: "Client", options: [], instances: ["all"]},
  "quality-manager": {type: "quality-manager", name: "Quality Manager", options: [], instances: ["all"]},
  installer: {type: "installer", name: "Installer", options: [], instances: ["all"]},
  outcome: {type: "outcome", name: "Outcome", options: ["Pass", "Fail"], instances: ["all"]}
}

const initialState = {users: {}, forms: {}, statuses: {}, filters: {}, subscriptions: [], filterTypes, presetFilters: {}}
function reducer (state, action) {
  switch (action.type) {
  case 'users': return {...state, users: action.value}
  case 'forms': return {...state, forms: action.value}
  case 'statuses': 
    const statuses = {...state.statuses}
    statuses.instances = action.value
    return {...state, statuses}
  case 'filters': return {...state, filters: action.value}
  case 'subscriptions': return {...state, subscriptions: action.value}
  case 'filterTypes': return {...state, filterTypes: action.value}
  case 'includeIntakeData': return {...state, includeIntakeData: action.value}
  case 'clearState': 
    const ft = state.filterTypes
    for (let f in ft) { ft[f].instances = ['all'] }
    return {...state, filterTypes: ft}
  default: return state
  }
}

export const ReducerContext = React.createContext(null)
export const wrap = C => props => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <ReducerContext.Provider value={{state, dispatch}}><C {...props} /></ReducerContext.Provider>
  )
}