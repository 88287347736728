import React from "react"
import PropTypes from "prop-types"

import {connect} from "react-redux"
import {Draggable} from "react-beautiful-dnd" //	DragDropContext, Droppable,
import {Button} from "antd"
import {setField, mergeField, setResources, deleteResource, setActive, save} from "./action_creators" //	, setField
import FormItem from "./FormItem"
import NoContent from "./NoContent"
import {db} from "../utilities"

const createNewField = async (section, formId, type = `single-input`, field = {}) => {
  const sectionRef = db
    .collection(`forms`)
    .doc(formId)
    .collection(`sections`)
    .doc(section.id)
  const fieldRef = sectionRef.collection(`fields`).doc()
  await fieldRef.set({type, id: fieldRef.id})
  const order = [].concat(section.order, fieldRef.id).filter(i => i)

  return [
    {id: fieldRef.id},
    {category: `sections`, id: section.id, key: `order`, value: order}
  ]
}

/** Duplicates a field by ID and inserts it immediately after the copied field
 * @param section A copy of the entire section doc on which the field being duplicated lives
 * @param formId The ID of the Form on which the field being copied lives
 * @param fieldId  The ID of the Field to be Copied
 */
const duplicateField = async (section, formId, fieldId = {}) => {
  const sectionRef = db
    .collection(`forms`)
    .doc(formId)
    .collection(`sections`)
    .doc(section.id)

  const fieldToBeCopied = await sectionRef
    .collection(`fields`)
    .doc(fieldId)
    .get()
  const fieldRef = sectionRef.collection(`fields`).doc()

  //Add the newly created field immediately after the one just made
  let fieldIndex
  if (section.order.includes(fieldId)) fieldIndex = section.order.indexOf(fieldId)
  else throw new Error(`section deos not contain expected ID`, fieldId)
  
  const order = [...section.order]
  // console.log("order before splice", order)
  order.splice(fieldIndex + 1, 0, fieldRef.id)
  // console.log("order after splice", order)

  await fieldRef.set({...fieldToBeCopied.data(), id: fieldRef.id}, {merge: true})
  await sectionRef.set({category: `sections`, id: section.id, order}, {merge: true})

  return fieldRef.id
}

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: `none`,
  padding: `0.2rem`,
  margin: `0 0 2rem 0`,

  // styles needed for draggables
  ...draggableStyle
})

const mapStateToProps = state => ({
  field: state.active.field,
  fields: state.form.fields,
  section: state.form.sections[state.active.section] || null,
  order: (state.form.sections[state.active.section] || {}).order,
  sectionId: state.active.section,
  formId: state.form.base.id
})

const mapDispatchToProps = dispatch => ({
  setResources: values => dispatch(setResources({type: `fields`, values})),
  duplicate: formId => section => async field => {
    await save()
    return duplicateField(section, formId, field.id)
  },
  del: async id => {
    dispatch(await deleteResource({id, category: `fields`}))
  },
  active: id => dispatch(setActive({type: `field`, id}))
})

class Fields extends React.Component {
  static propTypes = {
    section: PropTypes.object,
    fields: PropTypes.object,
    setResource: PropTypes.func,
    provided: PropTypes.object,
    snapshot: PropTypes.object,
    del: PropTypes.func,
    duplicate: PropTypes.func,
    formId: PropTypes.string
  }

  state = {loading: null}

  componentDidMount = () => {
    if (this.props.section) this.getFields()
  }
  componentDidUpdate = pprops => {
    if (this.props.section.id !== pprops.section.id) this.getFields()
  }

  componentWillUnmount = () => {
    console.log(`save the form when the user navigates away from the page?`)
  }

  getFields = () => {
    console.log(`getting fields from the database`)
    db.collection(`forms`)
      .doc(this.props.formId)
      .collection(`sections`)
      .doc(this.props.section.id)
      .collection(`fields`)
      .onSnapshot(snapshot => {
        const fields = {}
        snapshot.forEach(field => (fields[field.id] = {...field.data(), id: field.id}))
        console.log(`got new fields from DB, reloading`)
        this.props.setResources(fields)
        if (this.props.section.order && !this.props.field) this.props.active(this.props.section.order[0])
      })
  }

  render() {
    const {section, fields, provided, active, del, duplicate} = this.props //	snapshot,

    return (
      <div ref={provided.innerRef}>
        {Object.keys(fields).length > 0 ? (
          <div ref={provided.innerRef}>
            {(section.order || []).map((id, i) => {
              const f = fields[id]
              if (f)
                return (
                  <Draggable key={id} draggableId={id} index={i}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <FormItem
                          id={f.id}
                          type={f.type}
                          disabled={this.state.loading === f.id}
                          activate={active}
                          del={del}
                          duplicate={async id => {
                            this.setState({loading: f.id})
                            await duplicate(this.props.formId)(this.props.section)(this.props.fields[id])
                            this.setState({loading: false})
                          }}
                          selected={this.props.field}
                        />
                      </div>
                    )}
                  </Draggable>
                )
              // eslint-disable-next-line
              else return
            })}
            {provided.placeholder}
          </div>
        ) : (
          <NoContent parent="section" child="field" childs="fields" />
        )}
      </div>
    )
  }
}

const WrappedFields = connect(mapStateToProps, mapDispatchToProps)(Fields)

export default WrappedFields

const NakedAdd = ({add}) => (
  <Button onClick={add} className="thick" type="primary" size="large" style={{marginLeft: `1rem`}}>
    Add Field
  </Button>
)

const buttonProps = state => ({
  section: state.form.sections[state.active.section],
  formId: state.form.base.id,
  fieldId: state.active.field
})

const buttonDispatch = (dispatch, ownProps) => ({
  active: id => dispatch(setActive({type: `field`, id})),
  addResource: async (section, formId, fieldId) => {
    await save()
    if (fieldId) await dispatch(setActive({type: `field`, fieldId}))
    const [newField, sectionUpdate] = await createNewField(section, formId)
    // console.log(newField, sectionUpdate)

    // dispatch(setField(newField))
    dispatch(mergeField(sectionUpdate))
    // dispatch(setActive({type: "field", id: newField.id}))
    return newField.id
  }
})

const buttonMergeProps = (pState, pDispatch) => ({
  add: async () => pDispatch.active(await pDispatch.addResource(pState.section, pState.formId, pState.fieldId))
})

NakedAdd.propTypes = {add: PropTypes.func}

const Add = connect(buttonProps, buttonDispatch, buttonMergeProps)(NakedAdd)

export {Add}
