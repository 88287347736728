import React from "react"
import {Icon} from "antd"
import {db, storage, firebase} from "../utilities"
import GeocodeWrapper from "../GeocodeWrapper"
import {staticMapKey} from "../config"
import rootReducer from "./reducer"
import {createStore, applyMiddleware} from "redux"
// import {createLogger} from "redux-logger"
import thunkMiddleware from "redux-thunk"

export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware)) // , loggerMiddleware

export const wrappedGeocode = new GeocodeWrapper()

// base[type] is either an array or a map of {id: bool} 
//  that describes the permissions a user (base) has for a category (type)
// test is an instance of the category
// this function checks whether 'base' has permission to use 'test'
export const checkForPermission = (base, test, type) => {  
  if (base[`${type}All`]) return true
  else if (!base[type]) return false
  else {
    return Array.isArray(base[type]) ? base[type].includes(test.id) : Object.keys(base[type] || {}).includes(test.id)
  }
}

export const typeMap = {
  client: [
    `clients`,
    {
      type: `projectType`,
      accessor: (client, form) => checkForPermission(client, form, `qualifications`)
    },
    {
      type: `inspector`,
      accessor: (client, fu) => checkForPermission(fu, client, `clients`)
    }
  ],
  form: [
    `forms`,
    {
      type: `client`,
      accessor: (form, client) => checkForPermission(client, form, `qualifications`)
    },
    {
      type: `inspector`,
      accessor: (form, fu) => checkForPermission(fu, form, `qualifications`)
    }
  ],
  "field-user": [
    `fus`,
    {
      type: `client`,
      accessor: (fu, client) => checkForPermission(fu, client, `qualifications`)
    },
    {
      type: `projectType`,
      accessor: (fu, form) => checkForPermission(fu, form, `qualifications`)
    }
  ]
}

export const mapDBToFields = project => {
  const fields = {
    client: project.client,
    projectType: project.intake,
    rubric: project.rubric,
    installer: project.installer,
    inspector: project.inspection && project.inspection.inspector ? project.inspection.inspector : {},
    nabcep_ids: project.nabcep_ids,
    siteOwnerName: project.site.siteOwnerName,
    siteAddress: project.site.siteAddress,
    sameRep: project.site.sameRep,
    sameAddress: project.site.sameAddress,
    siteRepName: project.site.siteRepName,
    siteRepEmail: project.site.siteRepEmail,
    siteRepPhone: project.site.siteRepPhone,
    ignoreAvailability: project.ignoreAvailability
  }

  return fields
}

//Gets a thumnail image via api and adds it to the "site" on a project.
//TODO: Move this over to use the SubmitFiles function
export const getStaticMapThumbnail = async site => {
  const {lat, lng} = site.geocode_results.geometry.location
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=13&size=500x200&maptype=roadmap&markers=color:green%7C${lat},${lng}&key=${staticMapKey}`

  const res = await fetch(mapUrl)
  const b = await res.blob()

  const imageRef = storage.ref().child(`siteThumbnails/${site.id}`)
  await imageRef.put(b)

  const {bucket, fullPath, name} = imageRef
  site.thumbnail = {bucket, fullPath, name}
  return site
}

export const generateProjectShell = async projectType => {
  try {
    let formRef = db.collection(`forms`).doc(projectType.id)
    // let formSnap = await formRef.get()
    let sectionsSnap = await formRef.collection(`sections`).get()

    const intake = projectType
    // { ...formSnap.data(), id: formSnap.id }

    const sections = {}
    await sectionsSnap.forEach(async section => (sections[section.id] = {...section.data(), id: section.id}))

    const sectionsArray = Object.entries(sections)
    const promises = sectionsArray.map(([k, v]) =>
      formRef
        .collection(`sections`)
        .doc(k)
        .collection(`fields`)
        .get()
    )

    const results = await Promise.all(promises)
    const fieldsArray = results.map(ff => ff.docs.map(field => ({...field.data(), id: field.id})))
    fieldsArray.forEach((fields, i) => (sections[sectionsArray[i][0]].fields = fields))

    intake.sections = sections
    return intake
  } catch (e) {
    console.log(`error in generateProjectShell`, e)
  }
}

const makeSiteAddress = async ({project = {site: {}}, site, dummyAddress, latitude, longitude, siteAddress}) => {
  try {
    if (dummyAddress) site.dummyAddress = dummyAddress
    if (latitude && longitude && (latitude !== project.site.latitude || longitude !== project.site.longitude)) {
      site.geocode_results = JSON.parse(
        JSON.stringify(await wrappedGeocode.geocode({lat: latitude, lng: longitude}, `location`))
      )
      site.siteAddress = site.geocode_results.formatted_address
      site = await getStaticMapThumbnail(site)
    } else if (siteAddress !== project.site.siteAddress) {
      // wrappedGeocode.geocode({ siteAddress }, (results, status) => results)
      site.geocode_results = JSON.parse(JSON.stringify(await wrappedGeocode.geocode(siteAddress)))
      site = await getStaticMapThumbnail(site)
    } else {
      site.geocode_results = project.site.geocode_results
      site.thumbnail = project.site.thumbnail
    }

    return site
  } catch (e) {
    console.log(`error in makeSiteAddress`, e)
    if (e.message === `ADDRESS_FAILED_GEOCODING`) throw e
  }
}

const assembleProject = async ({
  client,
  installer = null,
  site,
  projectType,
  inspector = null,
  rubric = null,
  projRef,
  project = {intake: {}, inspection: {}},
  ignoreAvailability = null
}) => {
  let returns = []

  let pp = {
    client: {id: ``, qualifications: {}, ...client},
    installer,
    rubric,
    site,
    ignoreAvailability,
    updated: firebase.firestore.Timestamp.now()
  }

  if (!project.id) {
    pp = {
      ...pp,
      ...{
        id: projRef.id,
        assigned: false,
        created: firebase.firestore.Timestamp.now()
      }
    }
  }

  try {
    if (projectType.title !== project.intake.title) {
      pp.intake = await generateProjectShell(projectType)
      pp.status = pp.intake.noSchedule ? `In Progress` : `To Be Scheduled`
    }

    // check whether the user has changed the inspector; if they have, replace the calendar entry
    const {inspection = {}} = project

    // if the provided inspector is different from the one on the project, then replace the calendar entry with the new user
    if (inspector && inspector.id && !inspection.inspector || (inspection.inspector && inspection.inspector.id !== inspector.id)) {
      if ((inspection.inspector || {}).id) {
        const ref = db
          .collection(`users`)
          .doc(inspection.inspector.id)
          .collection(`calendar`)
          .where(`id`, `==`, project.id)
        if (ref.exists) await ref.delete()
      }

      console.log(inspector)

      const userRef = db.collection(`users`).doc(inspector.id)

      returns.push([userRef.collection(`calendar`).doc(), {...project}])

      const snap = await userRef.get()
      const newInspector = snap.data()
      const {name, id, email, phone = null} = newInspector
      project.inspection = {inspector: {name, id, email, phone}}
    }
    // if this is a new project, it may or may not include an inspector; if it does, then the inspector needs to be added to the calendar
    else if (!project.id && inspector && inspector.id) {
      const userRef = db.collection(`users`).doc(inspector.id)
      returns.push([userRef.collection(`calendar`).doc(), {...project}])

      project.inspection = {inspector}
    }

    returns.push([projRef, {...project, ...pp}])

    return returns
  } catch (e) {
    console.log(`error in assembleProject`, e)
    throw e
  }
}

export const projectModify = async (project, fields, uid) => {
  const projRef = db.collection(`projects`).doc(project.id)
  const siteRef = db.collection(`sites`).doc(project.site.id)

  try {
    const {
      // geocode_results = {},
      latitude,
      longitude,
      siteOwnerName = ``,
      siteAddress = ``,
      dummyAddress = ``,
      sameRep = false,
      sameAddress = false,
      siteRepName = ``,
      siteRepEmail = ``,
      siteRepPhone = ``,
      client = {id: ``, name: ``, qualifications: {}},
      projectType,
      rubric,
      inspector,
      installer,
      ignoreAvailability
    } = fields

    let site = {
      siteOwnerName,
      siteAddress,
      sameRep,
      sameAddress,
      siteRepName,
      siteRepEmail,
      siteRepPhone,
      id: siteRef.id
    }

    site = await makeSiteAddress({
      project,
      site,
      dummyAddress,
      latitude,
      longitude,
      siteAddress
    })

    const batch = db.batch()
    batch.set(siteRef, site)

    const batchEntries = await assembleProject({
      client,
      installer,
      site,
      projectType,
      inspector,
      rubric,
      project,
      projRef,
      ignoreAvailability
    })

    console.log(batchEntries)

    batchEntries.map(entry => batch.set(...entry))
    await batch.commit()
  } catch (e) {
    console.log(`an error occurred while updating a project: `, e)
    throw e
  }
}

export const projectsOne = async (fields, uid) => {
  const projRef = db.collection(`projects`).doc()
  const siteRef = db.collection(`sites`).doc()

  try {
    const {
      // id = "",
      geocode_results = {},
      latitude,
      longitude,
      siteOwnerName = ``,
      siteAddress = ``,
      dummyAddress = ``,
      sameRep = false,
      sameAddress = false,
      siteRepName = ``,
      siteRepEmail = ``,
      siteRepPhone = ``,
      client,
      projectType,
      inspector,
      installer = {id: ``, name: ``},
      rubric = null,
      ignoreAvailability
    } = fields

    let site = {
      geocode_results,
      siteOwnerName,
      siteAddress,
      sameRep,
      sameAddress,
      siteRepName,
      siteRepEmail,
      siteRepPhone,
      id: siteRef.id
    }

    site = await makeSiteAddress({
      site,
      dummyAddress,
      latitude,
      longitude,
      siteAddress
    })

    const batch = db.batch()
    batch.set(siteRef, site)

    const batchEntries = await assembleProject({
      client,
      installer,
      site,
      projectType,
      inspector,
      rubric,
      projRef,
      ignoreAvailability
    })

    batchEntries.map(entry => batch.set(...entry))

    await batch.commit()
    return projRef.id
  } catch (e) {
    console.log(`an error occurred while saving a single project to the database: `, e)
    throw e
  }
}

export const projectVisit = async (id) => {
  console.info('projectVisit - ', id)
  const projRef = db.collection(`projects`).doc(id)
  await projRef.update({
    visited: firebase.firestore.Timestamp.now()
  }) 
}

export const Required = () => (
  <div
    style={{
      display: `flex`,
      flexFlow: `row nowrap`,
      alignItems: `center`,
      color: `red`
    }}
  >
    <Icon type="info-circle" />
    <div style={{padding: `0 0 0 0.5rem`}}>Required</div>
  </div>
)
