import React, {useRef, useEffect, useContext} from "react"
import {ReducerContext} from './Provider'
import Statuses from './Statuses'
import base from "./patterns/base"
import './localStyles.css'

export default function FileUploader (props) {
  const {submit, helperMessage: Message, parentID} = props
  const {disabled, buttonClassName, buttonStyle, boxStyle, boxClassName} = props
  const {pre = [], post = []} = props
  const {state, dispatch} = useContext(ReducerContext)

  const dndRef = useRef(null)

  useEffect(function () {
    // invariable checking            
    if (!props.pattern && !submit) throw new Error('no pattern or submit prop supplied; uploading files will do nothing')
    if (!parentID) console.log('file uploader does not know where to upload to')

    dispatch({type: 'pattern', value: props.pattern})
    return () => dispatch({type: 'clear'})
  }, [])

  useEffect(function () { 
    if (Object.keys(state.photos).length && submit) { submit(state.photos) } 
  }, [state.photos])

  useEffect(function () {
    if (!state.files.length) return
    if (!state.pattern && !submit) return

    (async function () {
      try {            
        if (state.pattern) {
          await base(state, dispatch, state.pattern(parentID, state, dispatch))
          dispatch({type: 'uploading', value: false})
          return
        }
    
        await submit(state.files, statuses => dispatch({type: 'statuses', value: statuses}))
      }
      catch (e) { console.log(e) }
      finally { 
        if (post.length) for (let f of post) { f() }
        dispatch({type: 'loading', value: false}) 
      }
    })()
  }, [state.files])

  function dragover (e) {
    e.preventDefault()
    dispatch({type: 'hovering', value: true})
  }
  
  function dragleave (e) {
    e.preventDefault()
    dispatch({type: 'hovering', value: false})
  }

  async function drop (e) {
    e.preventDefault()
    dispatch({type: 'uploading', value: true})

    let files = []
    if (e.dataTransfer) {
      files = e.dataTransfer.items
        ? Array.from(e.dataTransfer.items)
          .filter((i) => i.kind === `file`)
          .map((f) => f.getAsFile())
        : Array.from(e.dataTransfer.files)
    } 
    else {
      files = Array.from(e.target.files)
      dndRef.current.value = null
    }

    if (e.dataTransfer) {
      if (e.dataTransfer.items) e.dataTransfer.items.clear()
      else e.dataTransfer.clearData()
    }        

    if (pre.length) for (let f of pre) { f(files) }
    dispatch({type: 'files', value: files})
  }

  return (
    <ReducerContext.Provider value={{state, dispatch}} >
      <div
        className={`flex-column full-height full-width align-center justify-center file-uploader ${state.hovering && `dragover`}`}
        style={(disabled || state.uploading) ? {pointerEvents: 'none', opacity: '0.4'} : {}}
      >
        <div
          className={`files full-height full-width align-center justify-center flex-column margin-1 ${boxClassName}`}
          style={{flex: 1, backgroundColor: '#E9ECEF', color: 'black', padding: `5rem`, ...boxStyle}}
          onDrop={drop}
          onDragOver={dragover}
          onDragLeave={dragleave}
        >
          {!state.uploading && <div style={{fontSize: `150%`, width: `100%`}}><Message /></div>}
          {state.uploading && <Statuses statuses={state.statuses} />}
        </div>
        <div className="upload-button" >
          <p>Or...</p>
          <input 
            style={buttonStyle} 
            className={`${buttonClassName}`} 
            type="file" 
            onChange={(e) => drop(e)} 
            multiple 
            ref={dndRef} 
          />
        </div>
      </div>
    </ReducerContext.Provider>
  )    
}