import React from "react"
import PropTypes from "prop-types"
import {Spin} from "antd" //	, Button
import Details from "./Details"
import PhotoPool from "./PhotoPool"
import {PhotoViewer} from "./PhotoViewer"
import {DragDropContext} from "react-beautiful-dnd"
import Sections from "./Sections"
import {setProject, setField, onDragStart, photoViewer} from "./action_creators"
import {dropHandle} from './utilities'
import {connect} from "react-redux"

const mapStateToProps = state => ({
  project: state.project,
  photos: (state.fields[state.project.id] || {}).images,
  loading: state.status.loading,
  open: state.meta.photoViewer,
  fields: state.fields
})

const mapDispatchToProps = dispatch => ({
  setProject: project => dispatch(setProject(project)),
  onDragStart: () => dispatch(onDragStart(true)),
  close: () => dispatch(photoViewer(false)),
  setField: (id, value) => dispatch(setField(id, value))
})

function DnDWrapper ({project, fields, setField, photos = {}, loading, open, close, onDragStart}) {
  const done = photos => {
    for (let k in photos) {
      const photo = photos[k]
      if (photo.delete) {
        delete photos[k]
      }
    }
    setField(project.id, {images: photos})
    close()
  }

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={dropHandle}>
      <div className="flex-row" >
        <div className="padding-2 background-white flex-row" style={{flex: 3, width: '65%'}}>
          {project.intake && project.intake.order && (
            <div className="full-width" >
              {loading && <Spin />}
              {!loading && <Sections />}
            </div>
          )}
        </div>
        <div className="flex-column padding-left-1" style={{flex: 2}}>
          <Details />
          <PhotoPool />
        </div>
        {open && <PhotoViewer {...{photos, done}} />}
      </div>
    </DragDropContext>
  )
}

DnDWrapper.propTypes = {
  project: PropTypes.object,
  photos: PropTypes.object,
  loading: PropTypes.bool
}

const WrappedDnDWrapper = connect(mapStateToProps, mapDispatchToProps)(DnDWrapper)
export default WrappedDnDWrapper
