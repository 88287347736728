import {auth} from '../../Auth'

export const statusLogs = {
  "In Progress": (logger, siteAddress) =>
    logger.event(`${auth.currentUser.displayName} submitted (${siteAddress}) for review`, null, 3),
  Review: (logger, siteAddress, corrections) =>
    corrections > 0
      ? logger.event(
        `${auth.currentUser.displayName} reviewed (${siteAddress}) and sent it back to the Field User for more work`,
        null,
        3
      )
      : logger.event(`${auth.currentUser.displayName} finalized (${siteAddress})`, null, 3),
  "Needs Corrections": (logger, siteAddress) =>
    logger.event(`${auth.currentUser.displayName} submitted (${siteAddress}) for review`, null, 3)
}