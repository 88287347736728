import React from "react"
import PropTypes from "prop-types"
import {Form, Input, Select, Checkbox} from "antd" //	Button, Row, Col, Divider
import {connect} from "react-redux"
import {setResources, setField} from "./action_creators"
import {db} from "../utilities" //	, firebase

const Option = Select.Option

const mapStateToProps = state => ({
  form: state.form.base,
  title: state.form.base.title,
  noSchedule: state.form.base.noSchedule,
  authorizedClients: state.form.base.authorizedClients,
  authorizedRegions: state.form.base.authorizedRegions,
  created: state.form.base.created,
  updated: state.form.base.updated
})

const mapDispatchToProps = dispatch => ({
  setField: ({id, value}) => dispatch(setField({category: "base", id, value})),
  setResources: values => dispatch(setResources({type: "base", values}))
})

class FormSettings extends React.Component {
  static propTypes = {
    form: PropTypes.object,
    setField: PropTypes.func
  }

  componentDidMount = () => {
    const queries = [
      // { type: "qualifications", filter: null, query: db.collection("forms") },
      {type: "clients", filter: ["type", "==", "client"], query: db.collection("users")}
    ]

    const subscriptions = queries.map(({filter, query, type}) => {
      if (filter) query = query.where(...filter)

      return query.onSnapshot(snapshot => {
        this.setState({[type]: snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))})
      })
    })

    const regionsSubscription = db
      .collection("meta")
      .doc("regions")
      .onSnapshot(regionSnap => {
        const regions = Object.keys(regionSnap.data())
        this.setState({regions})
      })

    this.setState({
      subscriptions: [].concat(this.state.subsciptions, subscriptions, regionsSubscription).filter(i => i)
    })
  }

  componentWillUnmount = () => {
    if (this.state.subscriptions.length > 0) this.state.subscriptions.map(s => s())
  }

  state = {
    regions: [],
    qualifications: [],
    clients: [],
    subscriptions: []
  }

  saveBase = async () => {
    await db
      .collection("forms")
      .doc(this.props.form.id)
      .set(this.props.form, {merge: true})
  }

  render = () => (
    <div style={{background: "rgb(244, 246, 248)", padding: "1rem"}} onBlur={this.saveBase}>
      <Form.Item label="Title">
        <Input
          style={{marginBottom: "1rem"}}
          size="large"
          onChange={e => this.props.setField({id: "title", value: e.target.value})}
          value={this.props.title}
        />
      </Form.Item>
      <Checkbox
        style={{padding: "1rem"}}
        checked={this.props.noSchedule}
        onChange={e => this.props.setField({id: "noSchedule", value: e.target.checked})}
      >
        IBTS will not Schedule Intakes for Projects Using this Form
      </Checkbox>
      <Form.Item label="Authorized Clients">
        <Select
          style={{marginBottom: "1rem", width: "100%"}}
          size="large"
          mode="multiple"
          onChange={e => this.props.setField({id: "authorizedClients", value: e})}
          value={this.props.authorizedClients}
        >
          {this.state.clients.sort((a, b) => a.name > b.name ? 1 : -1).map((client, i) => (
            <Option key={client.email || 1}>{client.name || client.email || ""}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Authorized Regions">
        <Select
          style={{marginBottom: "1rem", width: "100%"}}
          size="large"
          mode="multiple"
          onChange={e => this.props.setField({id: "authorizedRegions", value: e})}
          value={this.props.authorizedRegions}
        >
          {this.state.regions.sort().map((region, i) => (
            <Option key={region}>{region}</Option>
          ))}
        </Select>
      </Form.Item>
      {/*
				<Form.Item label="Authorized Qualifications" >
					<Select
						style={ { marginBottom: "1rem" } }
						size="large"
						mode="multiple"
						onChange={ e => this.props.setResources({ ...this.props.form, authorizedQualifications: e }) }
						value={ this.props.form.authorizedQualifications }
						style={ { width: "100%" } }
					>
						{ this.state.qualifications.map((qualification = { title: "Untitled Form" }, i) => (<Option key={ qualification.title } >{ qualification.title }</Option>) ) }
					</Select>
				</Form.Item>
			*/}
      {this.props.created && (
        <div style={{padding: "2rem 0 0 0", display: "flex", flexFlow: "row nowrap", justifyContent: "space-between"}}>
          <div style={{fontWeight: "bold"}}>Created</div>
          <div>{this.props.created.toDate().toLocaleString()}</div>
        </div>
      )}
      {this.props.updated && (
        <div style={{display: "flex", flexFlow: "row nowrap", justifyContent: "space-between"}}>
          <div style={{fontWeight: "bold"}}>Updated</div>
          <div>{this.props.updated.toDate().toLocaleString()}</div>
        </div>
      )}
    </div>
  )
}

const WrappedFormSettings = connect(mapStateToProps, mapDispatchToProps)(FormSettings)

export default WrappedFormSettings
