import {headerMap, headers} from './headerOrder'

export default function flattenProject (project) {
  const projectArray = []

  const hm = headerMap(project)
  for (let header of headers) { 
    projectArray.push(hm[header]) 
  }

  return projectArray
}