import React, {useEffect} from "react"
import {Spin} from "antd"
import TopLevelState from './TopLevelState'
import {subscribe} from '../HOC/Firebase/SubscribeHOC'

function ResourceGetter ({match, location, history, makeSubscription, subscribeResources}) {
  const {id: doc} = match.params
  useEffect(function () { return makeSubscription({paths: [{collection: 'projects', doc}]}, 'projects') }, [doc])

  const [resource] = subscribeResources['projects'] || []

  return resource 
    ? <TopLevelState {...{resource, match, location, history}} /> 
    : <div className="padding-3" ><Spin size="large" /></div>
}

const WrappedResourceGetter = subscribe()(ResourceGetter)
export default WrappedResourceGetter 