import React, {useState} from "react"
import CancelProjectModal from '../CancelProjectModal'
import {auth} from '../../Auth'
import {Modal} from 'antd'

export default function CancelProjectButton ({id}) {
  const [show, setShow] = useState(false)

  const invalidUserType = !['client', 'admin', 'super-admin'].includes(auth.sunkaizenUser.type)
  const buttonClasses = `
      flex-row justify-center padding-0-5 margin-1 full-width color-white background-grey pointer cancel-button 
      ${invalidUserType ? 'background-secondary color-black' : ''}
    `

  return (
    <div className="flex-row justify-end" >
      <div
        className={buttonClasses}
        onClick={e => {
          e.preventDefault()
          setShow(true)
        }}
        disabled={invalidUserType}
      >
            Cancel Project
      </div>
      <Modal
        title="Select a Reason for Cancelling this Project"
        visible={show}
        onOk={() => setShow(false)}
        onCancel={() => setShow(false)}
        footer={null}
      >
        <CancelProjectModal id={id} done={() => setShow(false)} />
      </Modal>
    </div>        
  )
}