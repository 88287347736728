import React from "react"

import Content from "../Content"
import ScoringSystemsList from "./ScoringSystemsList"

export default function ScoringSystemsPage() {
  return (
    <Content title="Scoring Systems">
      <ScoringSystemsList />
    </Content>
  )
}
