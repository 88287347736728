import React, {useState, useEffect} from "react"
import {storage} from "../../utilities"
import {subscribe} from '../../HOC/Firebase/SubscribeHOC'
import {StaticMap, Header, TypeRubric, MiscInfo, IntakeAndSnapshots, SaveReport} from '.'
import {DownloadImages, CancelProjectButton, CustomPDFReport, InstallerViewable} from '.' // , AddTicket
import "../DetailedProjectView.css"

function ProjectDetails ({history, project, installerReadable, makeSubscription, subscribeResources}) {
  const [snapshots, setSnapshots] = useState([])
  // const [reportURL, setReportURL] = useState(null)

  const {created, updated, status, id, lock, rubric = {name: ''}, client = {name: '', id: null}, report = {}} = project
  const {intake = {title: ''}, site = {geocode_results: {geometry: {location: {}}}}} = project

  useEffect(function () {
    return makeSubscription(
      {
        paths: [{collection: 'snapshots'}],
        options: {where: [{field: 'projectId', test: '==', value: id}]}
      },
      'snapshots'
    )
  }, [])

  useEffect(function () {
    (async function () {
      const {snapshots: ss = []} = subscribeResources
      const pp = snapshots.map(snapshot => storage.ref(snapshot.fullPath).getDownloadURL()
        .then(url => snapshot.url = url)
      )

      await Promise.all(pp)
      setSnapshots(ss)
    })()
  }, [subscribeResources])
  
  // useEffect(function () {
  //     (async function () {
  //         if (!report.fullPath) return
  //         const url = await storage.ref(report.fullPath).getDownloadURL()
  //         setReportURL(url)
  //     })()
  // }, [report.fullPath])

  return (
    <div className="flex-column background-white margin-left-2" data-cy="projectDetails">
      <Header status={status} />
      <StaticMap site={site} />
      <TypeRubric {...{id, intake, status, rubric}} />
      <MiscInfo {...{client, created, updated}} />
      <IntakeAndSnapshots {...{id, lock, site, status, client, snapshots}} />
      <SaveReport {...{id, status, report, site, intake}} />
      <DownloadImages id={id} />
      <InstallerViewable {...{project, installerReadable}} />
      <CustomPDFReport {...{status, intake, site, id}} />
      {/* <AddTicket {...{project, history, status, rubric}} /> */}
      <CancelProjectButton id={id} />          
    </div>
  )
}

const WrappedProjectDetails = subscribe()(ProjectDetails)
export default WrappedProjectDetails