import React from "react"
import {Table as AntTable} from "antd"

import Layout from "./Layout"

export default function Table({buttonText, onButtonClick, layoutProps, tableProps}) {
  return (
    <Layout buttonText={buttonText} onButtonClick={onButtonClick} {...layoutProps}>
      <AntTable {...tableProps} />
    </Layout>
  )
}
