// This is three multi-select fields

import React from "react"
import PropTypes from "prop-types"

import {Form, Select, Checkbox} from "antd" //	Input,
import {db, capitalizeFirst} from "../utilities"

const SelectField = ({mode, set, field, placeholder, options}) => (
  <Select
    onChange={e => set(e)}
    mode={mode}
    value={field}
    size="large"
    placeholder={placeholder}
    style={{border: "none", width: "100%"}}
  >
    {options.sort()}
  </Select>
)

const PermissionsBlock = ({type, fieldAll, options, check, set, fieldsObject, placeholder}) => (
  <div style={{width: "100%"}}>
    <div style={{display: "flex", alignItems: "center", width: "100%"}}>
      <div style={{fontWeight: "bold", flex: 1, display: "flex", justifySelf: "flex-start"}}>{type}</div>
      <Form.Item style={{flex: 1, display: "flex", justifyContent: "flex-end"}}>
        <Checkbox checked={fieldAll} onChange={e => check(e.target.checked)}>
          Grant all access
        </Checkbox>
      </Form.Item>
    </div>
    <Form.Item style={{width: "100%"}}>
      <SelectField
        {...{
          mode: "multiple",
          field: Object.keys(fieldsObject),
          set,
          placeholder,
          options: Object.values(options).map((c, i) => (
            <Select.Option key={c.id}>{capitalizeFirst(c.name || "")}</Select.Option>
          ))
        }}
      />
    </Form.Item>
  </div>
)

export default class Permissions extends React.Component {
  static propTypes = {
    setField: PropTypes.func,
    fields: PropTypes.object
  }

  state = {unsubscribeClients: null, unsubscribeQualifications: null, clients: {}, regions: {}, qualifications: {}}

  componentDidMount = () => {
    this.getPermissions()
  }

  componentWillUnmount = () => {
    const {unsubscribeClients, unsubscribeQualifications} = this.state
    Object.entries({unsubscribeClients, unsubscribeQualifications}).forEach(([k, v]) => {
      if (k) v()
    })
  }

  getPermissions = () => {
    ["regions"].map(type => this.getMeta(type))

    const unsubscribeQualifications = db.collection("forms").onSnapshot(snapshot => {
      if (!snapshot.empty) {
        const qualifications = {}
        snapshot.forEach(form => {
          qualifications[form.id] = {
            name: form.data().title || "",
            id: form.id
          }
        })

        this.setState({qualifications})
      }
    })

    const unsubscribeClients = db
      .collection("users")
      .where("type", "==", "client")
      .onSnapshot(snapshot => {
        if (!snapshot.empty) {
          const clients = {}
          snapshot.forEach(client => {
            clients[client.id] = {
              name: client.data().name,
              id: client.id
            }
          })
          this.setState({clients})
        }
      })

    this.setState({unsubscribeClients, unsubscribeQualifications})
  }

  getMeta = type => {
    const unsubscriber = db
      .collection("meta")
      .doc(type)
      .onSnapshot(docSnap => {
        if (docSnap.exists) {
          const resources = {}
          Object.keys(docSnap.data()).forEach(r => (resources[r] = {id: r, name: r}))
          this.setState({[type]: resources})
        }
      })

    this.setState({["unsubscribe" + capitalizeFirst(type)]: unsubscriber})
  }

  setPermissions = type => permissions => {
    const p = {}

    let name = "name"
    // this line was causing qualifications to be entered into state with an 'undefined' value, because they had no title
    // const name = type === "qualifications" ? "title" : "name"

    permissions.forEach(pp => (p[pp] = this.state[type][pp][name]))
    this.props.setField(type)(p)
  }

  render = () => {
    const {
      fields: {clients = {}, qualifications = {}, regions = {}} = {clients: {}, qualifications: {}, regions: {}}
    } = this.props
    return (
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "flex-start",
          background: "#F4F6F8",
          padding: "2rem",
          margin: "0 0 1rem 0"
        }}
      >
        <h3>Permissions</h3>
        {this.props.fields.type !== "client" && (
          <PermissionsBlock
            style={{width: "100%"}}
            {...{
              type: "Clients",
              fieldAll: this.props.fields.clientsAll,
              check: this.props.setField("clientsAll"),
              fieldsObject: clients,
              options: this.state.clients,
              set: this.setPermissions("clients"),
              placeholder: "Select Clients"
            }}
            data-cy="userPermissions"
          />
        )}
        <PermissionsBlock
          style={{width: "100%"}}
          {...{
            type: "Regions",
            fieldAll: this.props.fields.regionsAll,
            check: this.props.setField("regionsAll"),
            fieldsObject: regions,
            options: this.state.regions,
            set: this.setPermissions("regions"),
            placeholder: "Select Regions"
          }}
        />
        <PermissionsBlock
          style={{width: "100%"}}
          {...{
            type: "Qualifications",
            fieldAll: this.props.fields.qualificationsAll,
            check: this.props.setField("qualificationsAll"),
            fieldsObject: qualifications,
            options: this.state.qualifications,
            set: this.setPermissions("qualifications"),
            placeholder: "Select Qualifications"
          }}
        />
      </div>
    )
  }
}
