import React, {useState, useEffect} from 'react'
import {Button, Checkbox, Modal} from 'antd'
import SystemLogger from "../../../SystemLogger"
import {auth} from '../../../Auth'
import AutocompleteDatabase from '../../../AutocompleteDatabase'
import DB from '../../../DAL/DB'

const authorizations = new DB.Authorizations()

// when both users agree to be connected, the connection becomes active
//  if only one side has agreed, the connection is pending
//  if an active connection gets broken by either side, it is reset to inactive on both sides

//	authorization
// {
//     id: string
//     organizations: [orgA: string, orgB: string],
//     status: string,
//     permissions: {
//         orgA: {client: enum[inactive, pending, active], field-user: enum[inactive, pending, active], installer: enum[inactive, pending, active]},
//         orgB: {client: enum[inactive, pending, active], field-user: enum[inactive, pending, active], installer: enum[inactive, pending, active]}
//     },
//     details: {
//         orgA: {name: string, phone: string, email: string}, 
//         orgB: {name: string, phone: string, email: string}
//     }
// }


// this component's name is misleading; it can't 'add' an authorization, only request that one be added
export default function AddAuthorization () {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [logger, setLogger] = useState(null)
  const [selected, setSelected] = useState(null)
  const [client, setClient] = useState(false)
  const [fieldUser, setFieldUser] = useState(false)

  async function create () {
    let authorization
    const {ok, data} = await authorizations.get(
      null, 
      {
        filters: [
          [`organizations`, 'array-contains', [selected.id, auth.sunkaizenUser.id]]
        ]
      }
    )

    if (ok) authorization = data[0]
    else authorization = {
      id: authorizations.makeId(), 
      permissions: {
        [auth.sunkaizenUser.id]: {client: 'inactive', 'field-user': 'inactive'}, 
        [selected.id]: {client: 'inactive', 'field-user': 'inactive'}
      }, 
      details: {}
    }

    authorization = {
      id: authorization.id,
      organizations: [selected.id, auth.sunkaizenUser.id],
      permissions: {
        ...authorization.permissions, 
        [selected.id]: {
          client: client ? 'pending' : 'inactive', 
          'field-user':  fieldUser ? 'pending' : 'inactive'
        }
      },
      details: {
        [auth.sunkaizenUser.id]: {name: auth.sunkaizenUser.name, phone: auth.sunkaizenUser.phone, email: auth.sunkaizenUser.email},
        [selected.id]: {name: selected.name, phone: selected.phone, email: selected.email}
      }
    }

    console.log(authorization)
    await authorizations.update(authorization.id, null, authorization)
  }

  useEffect(function () {
    auth.sunkaizenUserSubscribe(user => {
      setLogger(new SystemLogger({path: '/authorizations', uid: auth.sunkaizenUser.uid}))
    })
  }, [])

  function setValue (v) {
    setSelected(v)
    setClient(false)
    setFieldUser(false)
  }

  async function request () {
    try {
      setLoading(true)
      await create()
      setVisible(false)
    }
    catch (e) {
      logger.error(`failed to request authorization`)
      console.log(e.message)
      throw e
    }
    finally { 
      setLoading(false)
    }
  }

  return (
    <div>
      <Button 
        style={{flex: 1, height: '4rem', background: 'purple', color: 'white', margin: '0 0.3rem 0 0.3rem'}} 
        onClick={() => setVisible(true)} 
      >
                Add New Authorization
      </Button>
      <Modal 
        title="Add New Authorization"
        visible={visible}
        width="60%"
        onCancel={() => setVisible(false)}
        footer={<div><Button onClick={() => setVisible(false)} >Cancel</Button></div>}
      >
        <div className="flex-column" >
          <div className="bold" >Search Companies</div>
          <AutocompleteDatabase type="organizations" sort="name" setValue={setValue} />
          {selected && 
                        <div className="padding-1 flex-row align-center justify-space-between" >
                          <div className="flex-column" >
                            <div className="bold" >Company</div>
                            <div>{selected.name}</div>
                          </div>
                          <div className="flex-column" >
                            <div className="bold" >Email</div>
                            <div>{selected.email}</div>
                          </div>
                          <div className="flex-column" >
                            <div className="bold" >Type</div>
                            <div className="flex-column" >
                              <Checkbox 
                                style={{margin: 0}} 
                                onClick={e => setClient(e.target.checked)} 
                              >
                                        Client
                              </Checkbox>
                              <Checkbox 
                                style={{margin: 0}} 
                                onClick={e => setFieldUser(e.target.checked)} 
                              >
                                        Field User
                              </Checkbox>
                            </div>
                          </div>
                          <Button disabled={loading} type="primary" onClick={request} >Request</Button>
                        </div>
          }
        </div>
      </Modal>
    </div>
  )
}