import React from "react"
import {Select} from "antd"

import {connect} from "react-redux"

const dropdownStateToProps = (state, ownProps) => ({option: state.form.fields[ownProps.id].options[ownProps.i] || {}})
const NakedDropdownItem = ({id, i, option}) => (
  <Select.Option value={option.label.toLowerCase()}>{option.label}</Select.Option>
)
const DropdownItem = connect(dropdownStateToProps)(NakedDropdownItem)

const mapStateToProps = (state, ownProps) => ({field: state.form.fields[ownProps.id] || {}})
const NakedDropdown = ({field, id}) => (
  <Select style={{width: `20rem`}}>
    {field.options && field.options.sort().map((option, i) => <DropdownItem id={id} key={i} i={i} />)}
  </Select>
)
const Dropdown = connect(mapStateToProps)(NakedDropdown)

export default Dropdown
