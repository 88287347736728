import React, {useState, useEffect} from 'react'
import {Button} from "antd"
import {loggerEffect} from '../effects'
import {auth} from '../Auth'
import {LoadingOutlined} from "@ant-design/icons"
import {FUNCTIONS_URL} from "../config"

export default function ResetElastic () {
  const [loading, setLoading] = useState(false)
  const [logger, setLogger] = useState(null)
  useEffect(loggerEffect(setLogger), [])

  async function reset () {
    setLoading(true)

    try {
      const authorization = await auth.getIdToken()
      const url = `${FUNCTIONS_URL}/utilities/reset_elastic_index`
    
      const res = await ( 
        await fetch(
          url, 
          {method: `GET`, mode: `cors`, headers: {authorization}}) 
      ).text()
    
      if (res !== 'success') {
        console.log(res)
        throw new Error('failed to reset elastic')
      }

      logger.log('elastic reset')
    }
    catch (e) { 
      console.log(e)
      logger.log('failed to update elastic index; see console') 
    }
    finally { setLoading(false) }
  }

  return (
    <Button onClick={reset} >Reset Elastic {loading && <LoadingOutlined />}</Button>
  )
}