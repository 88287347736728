export const pushData = ({state, dispatch}) => (args) => {
  const {date = null, time = null, duration = null, project = null, inspector = null} = args
  const argsKeys = {}
  for (let k in args) { argsKeys[k] = true }

  dispatch({
    type: 'pushData',
    value: {
      date: argsKeys.date ? date : state.date,
      time: argsKeys.time ? time : state.time,
      duration: argsKeys.duration ? duration : state.duration,
      project: project || state.project,
      inspector: inspector || state.inspector
    }
  })
}

export default pushData