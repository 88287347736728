import React from 'react'
import './localStyles.css'

export default function Statuses ({statuses}) {
  return (
    <div className="slow-fade-in" >
      {Object.entries(statuses).map(([k, v]) => (
        <div key={k} >
          <label className="bold padding-right-1" >{k}</label>
          <span>{Math.round(v.progress)}%</span>
        </div>
      ))}
    </div>
  )
}