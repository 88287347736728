import React from 'react'
import {Button} from "antd"
import {connect} from "react-redux"
import {reviewMode} from "../action_creators"

const mapStateToProps = state => ({rm: state.meta.reviewMode})
const mapDispatchToProps = {reviewMode}

export function ReviewOptions ({reviewMode, rm, corrections, status, finalize}) {
  return (
    <div className="flex-row justify-space-around" style={{flex: 1}}>
      <Button
        type={rm ? "primary" : "default"}
        style={{margin: "0.5rem"}}
        onClick={() => reviewMode(!rm)}
      >
                Toggle Review Mode
      </Button>
      <Button
        style={{margin: "0.5rem"}}
        onClick={() => finalize(corrections < 1, status)}
        type="primary"
        data-cy="returnToFuOrFinalizeBtn"
      >
        {corrections > 0 ? "Return to Field User for Corrections" : "Finalize"}
      </Button>
    </div>
  )
}

const WrappedReviewOptions = connect(mapStateToProps, mapDispatchToProps)(ReviewOptions)
export default WrappedReviewOptions