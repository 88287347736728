import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import {Button, Icon, Input, Select, Modal, Checkbox} from "antd"
import {db, storage, history, emailRx} from "../utilities"
import Permissions from "./Permissions"

const PermissionsBlock = ({permissionsProps, user}) => {
  const [qualifications, setQualifications] = useState({})
  const [regions, setRegions] = useState({})
  const [clients, setClients] = useState({})

  useEffect(() => {
    const subscriptions = []

    const unsubscribeQualifications = db.collection("forms").onSnapshot(snapshot => {
      if (!snapshot.empty) {
        const qualifications = {}
        snapshot.forEach(form => {
          qualifications[form.id] = {
            name: form.data().title || "",
            id: form.id
          }
        })
        setQualifications(qualifications)
      }
    })
    subscriptions.push(unsubscribeQualifications)

    const unsubscribeClients = db
      .collection("users")
      .where("type", "==", "client")
      .onSnapshot(snapshot => {
        if (!snapshot.empty) {
          const clients = {}
          snapshot.forEach(client => {
            clients[client.id] = {
              name: client.data().name,
              id: client.id
            }
          })
          setClients(clients)
        }
      })
    subscriptions.push(unsubscribeClients)

    const unsubscribeRegions = db
      .collection("meta")
      .doc("regions")
      .onSnapshot(regionSnap => {
        if (regionSnap.exists) {
          const regions = {}
          Object.keys(regionSnap.data()).forEach(r => (regions[r] = {name: r, id: r}))
          setRegions(regions)
        }
      })
    subscriptions.push(unsubscribeRegions)

    return () => subscriptions.map(s => s())
  }, [])

  return (
    <div>
      <div className="flex-column flex-align-start">
        <div style={{fontWeight: "bold", padding: "2rem 0 1rem 0"}}>Qualifications</div>
        <Permissions
          {...{
            ...permissionsProps,
            permissions: qualifications,
            userPermissions: user.qualifications,
            userPermissionsAll: user.qualificationsAll,
            type: "qualifications",
            sort: (a, b) => a.name > b.name ? 1 : -1
          }}
        />
      </div>
      <div className="flex-column flex-align-start">
        <div style={{fontWeight: "bold", padding: "2rem 0 1rem 0"}}>Clients</div>
        <Permissions
          {...{
            ...permissionsProps,
            permissions: clients,
            userPermissions: user.clients,
            userPermissionsAll: user.clientsAll,
            type: "clients",
            sort: (a, b) => a.name > b.name ? 1 : -1
          }}
        />
      </div>
      <div className="flex-column flex-align-start">
        <div style={{fontWeight: "bold", padding: "2rem 0 1rem 0"}}>Regions</div>
        <Permissions
          {...{
            ...permissionsProps,
            permissions: regions,
            userPermissions: user.regions,
            userPermissionsAll: user.regionsAll,
            type: "regions",
            sort: (a, b) => a.name > b.name ? 1 : -1
          }}
        />
      </div>
    </div>
  )
}

PermissionsBlock.propTypes = {
  permissionsProps: PropTypes.object,
  user: PropTypes.object
}

export default PermissionsBlock
