import React from "react"
import PropTypes from "prop-types"

import {db} from "./utilities" //	, capitalizeFirst, singular
import {Link} from "react-router-dom"
import {Icon} from "antd" //	Table, , Modal

export default class ProjectNotifications extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    auth: PropTypes.object
  }

  componentDidMount = () => {
    this.getNotifications()
  }
  componentWillUnmount = () => {
    if (this.state.unsubscribe) this.state.unsubscribe()
  }

  state = {notifications: []}

  getNotifications = () => {
    const unsubscribe = this.makeQuery()
      .orderBy("created", "desc")
      .onSnapshot(notificationsSnapshot => {
        const notifications = notificationsSnapshot.docs
          .filter(notification => !notification.seen)
          .map(notification => ({...notification.data(), id: notification.id}))

        const notificationDates = {}
        notifications.forEach(notification => {
          notificationDates[notification.created] = notification
        })
        this.setState({notifications: Object.values(notificationDates)})

        // this.setState({ notifications })
      })

    this.setState({unsubscribe})
  }

  markRead = e => id => {
    e.stopPropagation()
    e.preventDefault()
    console.log(id)
    db.collection("projectTransactionLog")
      .doc(id)
      .set({seen: true}, {merge: true})
  }

  makeQuery = () => {
    const {sunkaizenUser} = this.props.auth
    // const { filters } = this.state
    const filterArray = [
      sunkaizenUser.type === "admin" || sunkaizenUser.type === "super-admin"
        ? null
        : ["recipient", "==", sunkaizenUser.id],
      ["seen", "==", false]
    ].filter(i => i)
    return filterArray.reduce((a, c) => a.where(...c), db.collection("projectTransactionLog"))
  }

  render = () => (
    <div style={{width: "100%", display: "flex"}}>
      <div style={{flex: 1, margin: "3rem", display: "flex", flexFlow: "column"}}>
        <h1 className="page-title">Your Notifications</h1>
        {this.state.notifications.map((notification, i) => (
          <Link
            to={`/notifications/${notification.id}`}
            key={i}
            style={{
              color: "black",
              textDecoration: "none",
              borderRadius: "0.5rem",
              backgroundColor: "white",
              margin: "0 0 0.5rem 0",
              padding: "0.5rem 1rem 0.5rem 1rem",
              display: "flex",
              flexFlow: "row nowrap",
              alignItems: "center",
              justifyContent: "space-between",
              border: "0.1rem solid lightgrey"
            }}
          >
            <div style={{padding: "0.5rem 1rem 0.5rem 1rem"}}>
              {`Project (${(notification.site || {}).siteOwnerName}, ${(notification.site || {}).siteAddress}) ${
                notification.message
              }`}
            </div>
            <Icon
              style={{zIndex: 10, padding: "1rem"}}
              type="close"
              onClickCapture={e => this.markRead(e)(notification.id)}
            />
          </Link>
        ))}
      </div>
    </div>
  )
}
