import React from "react"
// import PropTypes from 'prop-types'

import DeleteButton from "../DeleteButton"
import {history, db} from "../utilities" //	, capitalizeFirst, singular
// import { Link } from 'react-router-dom'
import {Icon, Popconfirm} from "antd"
import {FUNCTIONS_URL} from "../config"
import {auth} from "../Auth"
import {textFilter} from "./TableFilters" //	textSorter, numberSorter, dateFilter
import SystemLogger from "../SystemLogger"

const del = prune => async (id, name, type) => {
  const logger = new SystemLogger({path: `/users/${auth.currentUser.uid}`, uid: auth.currentUser.uid})
  if (['admin', 'super-admin'].includes(type) && auth.sunkaizenUser.type !== 'super-admin') {
    logger.log('You do not have permission to delete this user')
    return
  }

  prune(id)

  const body = {user: auth.currentUser.uid, delTarget: id}

  try {
    const res = await (
      await fetch(`${FUNCTIONS_URL}/utilities/delete_user`, {
        method: `POST`,
        headers: {"Content-Type": `application/json`},
        body: JSON.stringify(body)
      })
    ).text()

    switch (res) {
    case 'success': break
    case `auth/user-not-found`: 
      logger.event(
        `User ${name} (${id}) does not exist in the authentication system; attempting to clean up this issue`
      )
      break
    default:
      throw new Error(`unable to delete user: ${res}`)
    }

    const batch = db.batch()
    const calDates = await db
      .collection(`calendars`)
      .where(`owner`, `==`, id)
      .get()
    calDates.forEach(date => batch.delete(date.ref))
    await batch.commit()
    await db
      .collection(`users`)
      .doc(id)
      .delete()
    logger.event(`Successfully deleted user ${name} (${id})`)
  } catch (e) {
    logger.event(`Failed to delete user ${name} (${id}), contact your system administrator`)
    console.log(e)
  }
}

// <Link style={ { padding: "0.5rem" } } to={ `/users/${record.id}/manage` } >
// 	<Icon style={ { color: "grey", fontSize: 24 } } type="edit" />
// </Link>
const actions = prune => ({
  title: `Actions`,
  key: `action`,
  render: (text, record) => (
    <DeleteButton
      customRender
      confirmationDisplay="Delete?"
      onConfirm={e => {
        del(prune)(record.id, record.name, record.type)
        e.stopPropagation()
      }}
      onCancel={e => e.stopPropagation()}
      collection="users"
      documentId={record.id}
      okText="Yes"
      cancelText="No"
    >
      <Icon type="delete" style={{padding: `0.5rem`, fontSize: 24}} onClick={e => e.stopPropagation()} />
    </DeleteButton>
  )
})

const columnsMap = (activeFilters, prune) => {
  const users = [
    // {
    // 	title: "Id",
    // 	dataIndex: "id",
    // 	key: "id",
    // 	// sorter: (a, b) => textSorter(a.id, b.id),
    // 	...textFilter(activeFilters)("id")
    // },
    {
      title: `Name`,
      dataIndex: `name`,
      key: `name`,
      // sorter: (a, b) => textSorter(a.name, b.name),
      ...textFilter(activeFilters)(`name`)
    },
    {
      title: `Email`,
      dataIndex: `email`,
      key: `email`,
      // sorter: (a, b) => textSorter(a.email, b.email),
      ...textFilter(activeFilters)(`email`)
    },
    {
      title: `Type`,
      dataIndex: `type`,
      key: `type`,
      // sorter: (a, b) => textSorter(a.type, b.type),
      ...textFilter(activeFilters)(`type`)
    },
    {
      title: `Phone`,
      dataIndex: `phone`,
      key: `phone`,
      // sorter: (a, b) => textSorter(a.phone, b.phone),
      ...textFilter(activeFilters)(`phone`)
    },
    {
      title: `Zip`,
      dataIndex: `postal_code`,
      key: `zip`
      // sorter: (a, b) => numberSorter(a.zip, b.zip)
    },
    {
      title: `State`,
      dataIndex: `administrative_area_level_1`,
      key: `state`
      // sorter: (a, b) => textSorter(a.state, b.state)
    }
  ]

  return users.concat(actions(prune))
}

export const makeTableProps = ({resources, loading, refresh, activeFilters = {}, prune}) => {
  const columns = columnsMap(activeFilters, prune)
  const dataSource = Object.values(resources).map((r, i) => ({...r, key: i}))

  const rowSelection = {
    onChange: (keys, rows) => {},
    getCheckboxProps: record => ({
      disabled: record.disabled,
      name: record.name
    })
  }

  const onRow = record => ({
    style: {cursor: `pointer`},
    onClick: event => {
      history.push(`/users/${record.id}/manage`)
    }
  })

  return {
    columns,
    dataSource,
    loading,
    rowSelection,
    onRow,
    onChange: refresh,
    rowKey: record => record.id
  }
}
