import React, {useContext, useEffect, useRef} from "react"
import {ReducerContext} from "./reducer"
import {Icon} from "antd"

export function MagnifiedImage ({current, ...rest}) {
  const {state, dispatch} = useContext(ReducerContext)

  const picture = state.originals[(state.pictures[current] || {}).name] || {}
  const [selected, classIfViewing, classIfSelected, classIfMarked] = [
    state.selected[current],
    picture.marked,
    state.viewing === current ? ` viewing` : ``,
    state.selected[current] ? ` selected` : ``,
    picture.marked ? ` marked` : ``
  ]

  const picRef = useRef(null)
  useEffect(() => {
    dispatch({type: `ref`, id: current, value: picRef})
    return () => { dispatch({type: `ref`, id: current, value: null}) }
  }, [])

  return (
    <div
      className={`image-thumbnail-wrapper` + classIfSelected + classIfViewing + classIfMarked}
      ref={picRef}
      id={current}
      onClick={() => dispatch({type: `viewImage`, id: current})}
      {...rest}
    >
      <img
        className="image-thumbnail"
        onDoubleClick={() => dispatch({type: `select`, id: current, value: !selected})}
        src={picture.url}
      />
      <div className="image-actions">
        <Icon
          className={`image-action-icon selected-icon` + classIfSelected}
          onClick={e => {
            e.stopPropagation()
            dispatch({type: `select`, id: current, value: !selected})
          }}
          type="check"
          title="Select Image"
        />
        <Icon
          className={`image-action-icon marked-icon` + classIfMarked}
          onClick={e => {
            e.stopPropagation()
            dispatch({
              type: `changeStatus`,
              pictures: {[picture.name]: {...picture, ...{marked: !picture.marked}}}
            })
          }}
          type="tag"
          title="Mark Image"
        />
      </div>
    </div>
  )
}
