import React from "react" //  , { useState, useEffect }

const PdfTest = () => {
  // you need to put your code here that creates a PDF blob
  // the page will then render the file as a link that opens up a new page
  const pdf = null

  return (
    <div>
      <div style={{padding: `3rem`, border: `0.1rem solid white`}}>
        <h4>Click the link that appears below to open your PDF</h4>
        {pdf && (
          <a href={URL.createObjectURL(pdf)} target="_blank" rel="noopener noreferrer">
            PDF Link
          </a>
        )}
      </div>
    </div>
  )
}

export default PdfTest
