import React from "react"
import {Link} from "react-router-dom"

export default function IntakeCalendarEvent ({event}) {
  return (
    <div>
      <label>Client</label>
      <div>{event.data.client.name}</div>
      {   event.data && 
                <div className="padding-top-bottom-1" >
                  <label>Inspector</label>
                  <div>{event.data.inspection.inspector.name}</div>
                </div>
      }
      <label>Project:</label>
      <Link to={`/projects/${event.data.id}/manage`}>View Project</Link>
    </div>        
  )
}