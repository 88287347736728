import DB from '../../../../DAL/DB'

const rubrics = new DB.Rubrics()

export default async function convertRubrics (oId, id, fieldIdDict) {
  try {
    const {ok, data: rr} = await rubrics.get(null, {filters: [['formId', '==', oId]]} )
    if (!ok) throw new Error(`no rubrics for ${id}`)
        

    console.log(fieldIdDict)

    const pp = []
    for (let rubric of rr) {
      const newRubricId = rubrics.makeId()
      rubric.id = newRubricId
      rubric.formId = id
    
      const newQuestionIds = {}
      for (let i in rubric.questions) {
        newQuestionIds[fieldIdDict[i]] = rubric.questions[i]
      }
      rubric.questions = newQuestionIds

      console.log(rubric.id)

      pp.push(rubrics.update(rubric.id, null, rubric))
    }

    await Promise.all(pp)
  }
  catch (e) {
    console.log(`could not convert rubrics for form ${id}`)
    console.log(e)
  }
}