import React from "react"
import PropTypes from "prop-types"

// import { db, capitalizeFirst, singular } from '../utilities'
// import { bulk, bulkComponents, one, oneComponents } from './utilities'

import ResourceCreate from "./ResourceCreate"
import TabFilters from "./TabFilters"

import {Input} from "antd" //	Modal, , Icon, Button, DatePicker

export default class FilterMeta extends React.Component {
  static propTypes = {
    resources: PropTypes.object,
    activeFilters: PropTypes.object,
    get: PropTypes.func,
    select: PropTypes.func,
    fullTextSearch: PropTypes.func,
    setFullTextQuery: PropTypes.func,
    loading: PropTypes.bool
  }

  state = {
    show: false,
    selected: "All",
    searchString: null
  }

  select = selected => {
    this.setState({selected})
    this.props.select(selected)
  }

  render = () => (
    <div style={{display: "flex", flexFlow: "column"}}>
      <TabFilters
        {...{
          selected: this.state.selected,
          select: this.select,
          loading: this.props.loading
        }}
      />
      <div
        style={{
          padding: "1rem 1rem 1rem 0",
          display: "flex",
          flexFlow: "row nowrap",
          alignItems: "center",
          justifyContent: "flex-end"
        }}
      >
        <form
          style={{flex: 5, padding: "1.5rem", marginRight: "1rem"}}
          onSubmit={e => {
            e.preventDefault()
            this.props.get()
          }}
        >
          <Input onChange={e => this.props.setFullTextQuery(e)} placeholder="Start typing to search" />
        </form>
        <ResourceCreate get={this.props.get} style={{flex: 1}} />
      </div>
    </div>
  )
}
