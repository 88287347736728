import moment from 'moment'

export default function makeCalendarMap (calendar) {
  const calendarMap = {}

  for (let appointment in calendar) {
    const d = moment(calendar[appointment].date.toDate()).format('MM/DD')
    const old = calendarMap[d] || []
    calendarMap[d] = [...old, calendar[appointment]].filter(a => a)
  }

  return calendarMap
}