import React, {useState, useEffect} from "react"
import {auth} from '../../Auth'
import {Link} from "react-router-dom"
import JSZip from "jszip"
import {saveAs} from "file-saver"
import {Icon, Button} from "antd"
import {firebaseConfig} from '../../config'
import {checkLock, checkDev} from '../../effects'
import breakLock from './breakLock'


const allowedUsers = [`admin`, `super-admin`, `field-user`, `quality-manager`, `client`]

export default function IntakeAndSnapshots ({id, site, status, client, snapshots = []}) {
  const showIntakeButtonTests = []
  const [lock, setLock] = useState(true)
  const [dev, setDev] = useState(false)

  checkDev(setDev)
  checkLock(id, setLock)
  showIntakeButtonTests.push(!!auth.sunkaizenUser)
  showIntakeButtonTests.push(!['To Be Scheduled', 'Canceled'].includes(status))
  showIntakeButtonTests.push(allowedUsers.includes((auth.sunkaizenUser || {}).type))
  showIntakeButtonTests.push(!lock)

  if (auth.sunkaizenUser && auth.sunkaizenUser.type === 'client') {
    if (
      auth.sunkaizenUser.id === client.id && 
            status === 'Completed'
    ) showIntakeButtonTests.push(true)
    else showIntakeButtonTests.push(false)
  }
    
  let showIntakeButton = showIntakeButtonTests.every(v => v)

  async function downloadSnapshots () {
    let pp = snapshots.map(({fullPath}) => {
      const corrected = fullPath.replace(/\//g, '%2F').replace(/,/g, '%2C')
      return fetch(`https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/${corrected}?alt=media`)
    })
    const results = await Promise.all(pp)
    pp = results.map((result) => result.blob())
    const blobs = await Promise.all(pp)    
        
    const {siteOwnerName, siteAddress} = site
    const zip = new JSZip()
    blobs.forEach((blob, i) => zip.file(snapshots[i].name, blob, {binary: true}))
    saveAs(await zip.generateAsync({type: `blob`}), `Snapshots for ${siteOwnerName}-${siteAddress}-${id}`)        
  }

  return (
    <div className="padding-top-1" >
      {dev && lock && <Button onClick={() => breakLock(id)} >Break Lock</Button>}
      {showIntakeButton &&
                <div className="full-width" >
                  <Link to={`/intake/${id}`} className="intake-link">
                    <Icon className="padding-right-1" type="file-sync" />Intake Form
                  </Link>
                  {snapshots.length > 0 && (
                    <div className="snapshot-box">
                      <h4>Intake Snapshots</h4>
                      <div className="flex-column">
                        {snapshots.slice(0, 5).map((snapshot) => {
                          const {fullPath} = snapshot
                          const corrected = fullPath.replace(/\//g, '%2F').replace(/,/g, '%2C')

                          return (
                            <a 
                              href={`https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/${corrected}?alt=media`}
                              target="_blank" 
                              rel="noopener noreferrer"
                            >
                              {snapshot.created.toDate().toISOString()}
                            </a>
                          )
                        } )}
                        {snapshots.length > 5 && <div>and {snapshots.length - 5} more...</div>}
                      </div>
                      <Button className="margin-1" onClick={downloadSnapshots}>Download All</Button>
                    </div>
                  )}
                </div>
      }
    </div>
  )
}