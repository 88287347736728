import {store} from './store'
import {checkRequired} from './checkRequired'
import {finalize} from './finalize'
import {firebase} from "../../utilities"
import DB from '../../DAL/DB'
import {setLoading, setProject} from '../action_creators'
const projects = new DB.Projects()

class ValidationError {
  constructor(errors) {
    this.errors = errors
    this.name = `Validation Error`
  }
}
  
export async function saveIntake (finish = false) {

  console.log('saveIntake')

  const state = store.getState()
  
  // the loading property is only modified by this function
  //  so if it's true, another invocation is active
  //  this conditional block basically serves as a lock
  if (state.status.loading) {
    const unsubscribe = store.subscribe(async () => {
      const state = store.getState()
      if (!state.status.loading) {
        unsubscribe()
        await saveIntake(finish)
      }
    })

    return
  }
  
  store.dispatch(setLoading(true))
  const {project, sections, fields} = state

  // console.log(Object.keys(project.intake.sections).length)

  for (let i in sections) {
    const section = sections[i]
    section.fields = (section.order || []).map(fId => fields[fId]).filter(j => j)
  }

  project.intake.sections = sections
  
  if (finish) {
    console.log(`attempting to finalize this project; error checking`)

    const errors = checkRequired(project.intake)
    if (Object.keys(errors).length > 0) {
      store.dispatch(setLoading(false))
      throw new ValidationError(errors)
    }
  }

  project.photos = fields[project.id].images || {}

  try {
    if (finish) {
      // validate project
      await finalize(project)

      switch (project.status) {
      case `Review`:
        project.status = `Completed`
        break
      case `In Progress`:
        project.status = `Review`
        break
      case `Needs Corrections`:
        project.status = `Review`
        break
      default:
        break
      }
    }

    store.dispatch(setProject(project))
    await projects.update(project.id, {noMerge: true}, project)
    store.dispatch(setLoading(false))
  
    // if (!finish) store.dispatch(setProject(project))
  } 
  catch (e) { 
    console.log(e) 
    throw e
  }
}