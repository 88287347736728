import React from 'react'
import {Droppable} from "react-beautiful-dnd"
import {connect} from "react-redux"
import Field from './Field'

const sToP = (state, op) => ({ 
  dragging: state.active.dragging,
  // field: state.fields[op.fieldId]
})

const getStyle = snapshot => {
  return snapshot.isDraggingOver
    ? {
      border: "0.2rem dashed lightblue",
      borderRadius: "0.3rem"
    }
    : {}
}

const baseStyle = {borderBottom: "0.1rem solid lightgrey", zIndex: 10}

export function DnDWrapper ({fieldId, key, dragging}) { // field, 
  const style = snapshot => dragging ? {...baseStyle, ...getStyle(snapshot)} : baseStyle

  return (
    <div key={key}>
      {fieldId && (
        <Droppable droppableId={fieldId}>
          {(provided, snapshot) => (
            <div className="flex-row padding-2" style={style(snapshot)} ref={provided.innerRef}>
              <Field {...{fieldId, provided, snapshot}} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </div>
  )
}

const WrappedDnDWrapper = connect(sToP)(DnDWrapper)
export default WrappedDnDWrapper