import DB from '../../DAL/DB'
import {auth} from '../../Auth'

const subscriptions = new DB.Subscriptions()

export default function saveToDb (f) {
  return async function (frequency) {
    const {filter, name: title, includeIntakeData} = f
    const subscription = {
      title,
      frequency,
      filter,
      includeIntakeData: !!includeIntakeData,
      email: auth.sunkaizenUser.email,
      id: auth.sunkaizenUser.id
    }
      
    try {
      const id = subscriptions.makeId()
      await subscriptions.update(id, null, subscription)
    } 
    catch (e) { console.log(`error subscribing to filter: `, e) }
  }
}