export default function sorter (sortValue) {
  let sort = []

  if (sortValue.field && sortValue.order) {
    const direction = sortValue.order === `ascend` ? `asc` : `desc`
    sort = [
      {
        [`${sortValue.field}${sortValue.field === `created` ? `._seconds` : `.sortable`}`]: direction
      }
    ]
  }

  return sort
}