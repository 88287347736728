import React, {useEffect, useState} from "react"
import {Table} from "antd"
import {storage} from "../../utilities"
import FileUploader, {patterns} from "../../NewFileUploader"
import {helperMessage} from './helperMessage'
import {makeColumns} from './columns'
import {subscribe} from '../../HOC/Firebase/SubscribeHOC'
import StatusDisplay from './StatusDisplay'

export function TopLevelState ({project}) {
  const [files, setFiles] = useState({})
  const [statuses, setStatuses] = useState({})

  // useEffect(function () { 
  //     return makeSubscription({paths: [{collection: 'projects', doc: project.id}]}, 'project') 
  // }, [])
  useEffect(function () {
    (async function () {
      const pp = (project.intake.files || []).map(file => {
        if (!file.fullPath) return null
        return storage.ref().child(file.fullPath)
          .getDownloadURL()
          .then(url => {
            file.url = url
            return file
          })
      })
      const ff = await Promise.all(pp)
      setFiles(ff)
    })()
  }, [project.intake.files])

  function updateStatus (newStatus) { 
    setStatuses({...statuses, ...newStatus}) 
  }

  let k = 0
  const dataSource = Object.entries(files).map(([id, file]) => ({...file, key: ++k, id}))
  const columns = makeColumns(project.id)

  return (
    <div className="background-white margin-top-2" >
      <StatusDisplay statuses={statuses} />
      <h3 className="padding-2 margin-0" style={{borderBottom: "0.1rem solid #DFE3E8"}} >
                Uploaded Documents
      </h3>
      <div className="padding-1" ><Table {...{dataSource, columns}} /></div>
      <div className="padding-2" >
        <FileUploader
          parentID={project.id}
          pattern={patterns.file}
          helperMessage={helperMessage}
        />
      </div>
    </div>
  )
}

const WrappedTopLevelState = subscribe()(TopLevelState)
export default WrappedTopLevelState


{/* <FileUploader
collection="projects"
id={project.id}
type="file"
updateStatus={updateStatus}
helperMessage={helperMessage}
submit={ submit(updateStatus, project.id) }
/> */}