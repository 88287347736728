import {OPEN_TICKET_MODAL, CLOSE_TICKET_MODAL} from "../action_creators"

export const ui = (state = {modalVisible: false}, action) => {
  switch (action.type) {
  case OPEN_TICKET_MODAL:
    return {...state, modalVisible: true}
  case CLOSE_TICKET_MODAL:
    return {...state, modalVisible: false}
  default:
    return state
  }
}
