export default function dispatchSubscribeResources (dispatch, subscribeResources) {
  return function () {
    for (let k in subscribeResources) { 
      const subscribeMap = {}
      for (let r of subscribeResources[k]) { subscribeMap[r.id] = r }

      if (k === 'availability') {
        console.log(subscribeResources[k])
        console.log(k, subscribeMap)    
      }

      dispatch({type: k, value: subscribeMap})
    }
  }   
}