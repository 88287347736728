import React from 'react'
import InspectorBrief from './InspectorBrief'
import {Icon} from "antd"

export default function InspectionDate ({project}) {
  return (
    <div
      className="full-width flex-column padding-bottom-1 margin-bottom-1"
      style={{flex: 3, height: "40%", border: "0.1rem 0 0.1rem 0 solid #C4CDD5"}}
    >
      {project.inspection.date && project.inspection.date.toDate && (
        <div>
          <h4 style={{padding: "1.5rem", fontWeight: "bold"}}>
            <Icon className="padding-right-1" type="calendar" />
            {project.inspection.date.toDate().toDateString()}
          </h4>
          <h5 style={{padding: "1.5rem"}}>
            <Icon className="padding-right-1" type="clock-circle" />
            {project.inspection.date.toDate().toTimeString()}
          </h5>
        </div>
      )}
      <div className="flex-column full-width padding-top-1" style={{flex: 2, height: "40%"}} >
        <h3 style={{flex: 1}}>Assigned Inspector</h3>
        <InspectorBrief {...project.inspection.inspector} />
      </div>
    </div>        
  )
}