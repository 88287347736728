import React from "react"
// import PropTypes from 'prop-types'

// import { db, capitalizeFirst, singular } from '../utilities'

import {Table} from "antd"

// import Auth from '../Auth'
import {makeTableProps} from "./TableProps"
import FilterMeta from "./FilterMeta"
import {FULL_TEXT_SEARCH_URL} from "../config"
import localStyles from "./localStyles"
import {isFilterSame, makeQueryString} from "./utilities"

class TopLevelState extends React.Component {
  // // this is in here because we use the same route /:route to render stuff with the same component
  // //	the route changes without calling any lifecycle methods on the components, and PropBuilder then attempts to render the component
  // // 	with a new route but the same props, which throws an error in some cases
  // const unlisten = this.props.history.listen(async (location, action) => await this.setState({ resources: {} }) )
  // this.setState({ unlisten })
  componentDidMount = () => this.get()
  componentWillUnmount = () => {
    if (this.state.unsubscribe.length > 0) this.state.unsubscribe.map(subscription => subscription())
    if (this.state.unlisten) this.state.unlisten()
  }

  async componentDidUpdate (pprops) {
    if (this.state.refresh) {
      this.setState({refresh: false})
      await this.get()
    }
  }

  state = {
    usertype: "All",
    resources: {},
    unsubscribe: [],
    activeFilters: {filters: {}, sorter: {order: null, field: null}},
    sorter: null,
    // filters: {},
    filterCount: null,
    loading: true,
    size: 10,
    from: 0,
    total: 0,
    status: "All",
    query_strings: [],
    filters: [],
    fullTextQuery: ""
  }

  get = async () => {
    const body = this.makeQuery()

    console.log(body)

    const res = await (
      await fetch(`${FULL_TEXT_SEARCH_URL}/full_search`, {
        method: "POST",
        mode: "cors",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body)
      })
    ).json()

    console.log(res)

    const resources = {}
    if (res.hits) {
      const rr = res.hits.hits.map(r => r._source)
      rr.forEach(resource => (resources[resource.id] = resource))

      this.setState({from: body.size + body.from, resources, loading: false, total: res.hits.total.value})
    } else {
      this.setState({loading: false})
    }
  }

  makeQuery = () => {
    const {size, from, usertype, query_strings, filters, fullTextQuery} = this.state

    let ff
    if (usertype !== "All") ff = filters.concat({term: {"type.sortable": {value: usertype}}})

    const sort = [{'name.sortable': 'asc'}]

    return {
      type: "users",
      query: fullTextQuery,
      size,
      from: fullTextQuery.length > 0 ? 0 : from,
      query_strings,
      filters: ff,
      sort
    }
  }

  select = async usertype => {
    if (usertype !== this.state.usertype) {
      await this.setState({usertype, from: 0})
      this.get()
    }
  }

  refresh = async (pagination = {current: 1}, filters = {}, sorter = {}) => {
    this.setState({loading: true})
    const ff = {}
    Object.entries(filters).forEach(([k, v]) => {
      if (v.length > 0) ff[k] = v
    })

    let queryParams = {}
    if (isFilterSame(ff, this.state.filters)) {
      console.log(pagination.current)
      queryParams.from = (pagination.current - 1) * this.state.size
    } else {
      queryParams = {...queryParams, ...makeQueryString(filters)}
    }

    this.setState({
      refresh: true,
      loading: false,
      activeFilters: {sorter, pagination, filters: ff},
      ...queryParams
    })

    // await this.get()
  }

  prune = id => {
    const {resources} = this.state
    delete resources[id]
    this.setState({resources})
  }

  render = () => (
    <div style={localStyles.container}>
      <div style={localStyles.titleContainer}>
        <h1 className="page-title">Users</h1>
      </div>
      <div style={{...localStyles.fieldset, padding: "3rem"}}>
        <FilterMeta
          {...{
            resources: this.state.resources,
            activeFilters: this.state.activeFilters,
            select: this.select,
            setFullTextQuery: e => this.setState({fullTextQuery: e.target.value}),
            fullTextSearch: this.fullTextSearch,
            get: this.get,
            loading: this.state.loading
          }}
        />
        <Table
          pagination={{total: this.state.total, current: this.state.from/10}}
          {...makeTableProps({
            resources: this.state.resources,
            loading: this.state.loading,
            refresh: (...args) => this.refresh(...args),
            prune: this.prune
          })}
        />
      </div>
    </div>
  )
}

export default TopLevelState
