import {commentHeaderMap, commentHeaders} from "./commentHeaderOrder"

export default function flattenIntakeComment(project, intake_field) {
  const headerAry = []

  const hm = commentHeaderMap(project, intake_field)
  for (let header of commentHeaders) {
    headerAry.push(hm[header])
  }

  return headerAry
}
