export default function organizeResources(subscribeResources, readResults, state, dispatch) {
  return function () {
    for (let k in subscribeResources) {
      switch (k) {
        case "clients":
          const cc = subscribeResources[k]
          const fff = {...state.filterTypes}
          for (let u of cc) {
            fff.client.options.push({
              id: u.id,
              name: u.name,
            })
          }
          fff.client.options = Array.from(new Set(fff.client.options)).sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          })
          dispatch({type: "filterTypes", value: fff})
          break
        case "users":
          const uu = subscribeResources[k]
          const filterTypes = {...state.filterTypes}
          for (let user of uu) {
            if (!["installer", "quality-manager"].includes(user.type)) {
              // console.log(user.type)
              continue
            }

            filterTypes[user.type].options.push(user.name)
          }
          for (let user of ["quality-manager", "installer"]) {
            filterTypes[user].options = Array.from(new Set(filterTypes[user].options)).sort()
          }
          dispatch({type: "filterTypes", value: filterTypes})
          break
        case "forms":
          const fo = subscribeResources[k]
          const ft = {...state.filterTypes}
          ft.type.options = fo.map((form) => form.title).filter((i) => i)
          dispatch({type: "filterTypes", value: ft})
          break
        case "filters":
          const ff = subscribeResources[k]
          const filters = {}
          for (let f of ff) {
            filters[f.id] = f
          }
          dispatch({type: "presetFilters", value: filters})
          break
        case "subscriptions":
          dispatch({type: "subscriptions", value: subscribeResources[k]})
          break
      }
      delete subscribeResources[k]
    }

    if (readResults.projectStatuses) {
      const [statuses] = readResults.projectStatuses
      const filterTypes = {...state.filterTypes}

      console.log(Object.keys(statuses))

      filterTypes.status.options = Object.keys(statuses)
      dispatch({type: "filterTypes", value: filterTypes})
    }
  }
}
