import React from "react"

export const styles = {
  button: {
    padding: `0.5em`,
    cursor: `pointer`,
    margin: `0.2em`,
    borderRadius: `0.2em`,
    // backgroundColor: "#2A77B2",
    background: `#2A77B2`,
    color: `#A2C4DD`,
    textDecoration: `none`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`
    // fontSize: "75%"
  },
  line: {
    borderBottom: `1px solid rgba(211, 211, 211, .4)`
  },
  del: {
    paddingLeft: `0.3em`,
    cursor: `pointer`,
    width: `5%`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`
  },
  // field: v => ({
  // 	flex: v,
  // 	display: "inline-block"
  // }),
  item: {
    padding: `0.5em`,
    display: `flex`,
    alignItems: `flex-end`
  },
  fieldset: {
    display: `flex`,
    justifyContent: `flex-start`,
    flexDirection: `column`,
    backgroundColor: `white`,
    borderRadius: `0.3em`,
    padding: `1em`,
    boxShadow: `1px 1px lightgrey`
    // margin: "0 2em 2em 2em"
  },
  container: {
    //	very similar to a fieldset except for the padding/margin
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignContent: `flex-start`,
    borderRadius: `0.2em`,
    // margin: "0 1em 0 1em",
    padding: `1em`,
    fontSize: `75%`
  },
  rowContainer: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignContent: `flex-start`,
    borderRadius: `0.2em`,
    // margin: "0 1em 0 1em",
    padding: `1em`
  },
  header: {
    margin: 0,
    padding: `0.3em`,
    // width: "80%",
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    alignSelf: `flex-start`
  },
  pane: {
    height: `100%`,
    width: `50%`
  },
  outer: {
    display: `flex`,
    justifyContent: `flex-start`,
    flexDirection: `column`,
    padding: `0.5em`,
    width: `100%`,
    alignItems: `flex-start`
  },
  titleContainer: {
    width: `100%`,
    display: `flex`,
    flexFlow: `row nowrap`,
    padding: `2em 1em 1em 1em`
  },
  label: {padding: `0 1em 0 0`},
  settingsField: {padding: `1em`}
}

const Settings = () => (
  <form style={{display: `flex`, flexDirection: `column`, padding: `2em`}}>
    <div style={styles.settingsField}>
      <label style={styles.label}>Setting 1</label>
      <input type="text" />
    </div>
    <div style={{display: `flex`, alignItems: `center`, justifyContent: `center`, flexDirection: `column`}}>
      <legend>Setting 2</legend>
      <div>
        <label style={styles.label}>option 1</label>
        <input id="option1" type="radio" name="setting2" value="one" />
      </div>
      <div>
        <label style={styles.label}>option 2</label>
        <input id="option2" type="radio" name="setting2" value="two" />
      </div>
      <div>
        <label style={styles.label}>option 3</label>
        <input id="option3" type="radio" name="setting2" value="three" />
      </div>
      <div>
        <label style={styles.label}>option 4</label>
        <input id="option4" type="radio" name="setting2" value="four" />
      </div>
    </div>
    <hr />
    <div style={styles.settingsField}>
      <label style={styles.label}>Setting 3 </label>
      <input type="checkbox" />
    </div>
    <div style={styles.settingsField}>
      <label style={styles.label}>Setting 4 </label>
      <input type="date" />
    </div>
    <div style={styles.settingsField}>
      <label style={styles.label}>Setting 5 </label>
      <input type="color" />
    </div>
  </form>
)

const SystemSettings = () => {
  return (
    <div style={styles.container}>
      <div style={styles.titleContainer}>
        <h3 style={{...styles.header, flex: `4 1 auto`}}>System Settings</h3>
      </div>

      <div style={{...styles.fieldset, padding: `3em`}}>
        <Settings />
      </div>
    </div>
  )
}

export default SystemSettings
