import React, {useEffect, useState} from "react"
import {history} from "../../history"
import {auth} from "../../Auth"
import {Button} from "antd"
import {connect} from "react-redux"
import {highlightErrors, setStatus, setLoading, setSave} from "../action_creators"
import {saveIntake} from '../utilities'
import {loggerEffect} from '../../effects'

import IntakeStatus from './IntakeStatus'
import ReviewOptions from './ReviewOptions'
import ActiveSectionDisplay from './ActiveSectionDisplay'
import {statusLogs} from './statusLogs'

const mapStateToProps = state => ({
  project: state.project,
  corrections: Object.values(state.project.intake.corrections || {}).filter(i => i !== undefined).length,
  disabled: state.meta.saveButton
})

const mapDispatchToProps = {highlightErrors, setStatus, setLoading, setSave}

export function Details ({project, corrections, highlightErrors, setStatus, setLoading, setSave, disabled}) {
  const [logger, setLogger] = useState(null)

  useEffect(loggerEffect(setLogger, `/projects/${project.id}`), [])

  async function finalize (done, status) {
    setSave(true)
    logger.categoryId = project.id

    try {
      if (!done && status === "Review") { setStatus("Needs Corrections") }
      await saveIntake(done)

      statusLogs[status](logger, project.site.siteAddress, corrections)
      history.push("/projects")
    } 
    catch (e) {
      console.log(e)
      highlightErrors(e.errors)
      logger.event(
        `You must fill out all required fields to submit this project; missing fields have been highlighted`,
        null,
        2,
        {[auth.sunkaizenUser.id]: auth.sunkaizenUser.type}
      )
      return      
    }
    finally { 
      setLoading(false)
      setTimeout(() => setSave(false), 10000)
    }
  }

  return (
    <div className="padding-bottom-1" data-cy="intakeDetails">
      <div className="background-white" >
        <IntakeStatus status={project.status} />
        <ActiveSectionDisplay />
        <div
          className="flex-row justify-space-between"
          style={{borderBottom: "0.1rem solid #DFE3E8", padding: "1.5rem"}}
        >
          <div className="bold" >Started</div>
          <div>{project.startedAt && project.startedAt.toDate().toDateString()}</div>
        </div>
        <div className="flex-row align-center justify-center padding-2" >
          {(project.status === "In Progress" || project.status === "Needs Corrections") && (
            <Button disabled={disabled} onClick={() => finalize(true, project.status)} type="primary">
                            Submit for Review
            </Button>
          )}
          {project.status === "Review" &&
                        ["super-admin", "admin", "quality-manager"].includes(auth.sunkaizenUser.type) && (
            <ReviewOptions {...{corrections, status: project.status, finalize}} />
          )
          }
        </div>
      </div>
    </div>
  )
}

const WrappedDetails = connect(mapStateToProps, mapDispatchToProps)(Details)
export default WrappedDetails
