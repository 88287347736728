import React from "react"
import PropTypes from "prop-types"

import {Card, Icon} from "antd" //	, Popconfirm,  Divider, Input, Button, Row, Col, Select, Affix, Tabs

import {capitalizeFirst} from "../utilities"

const NoContent = ({parent, child, childs}) => (
  <Card style={{textAlign: `center`}}>
    <div style={{background: `#FBFBFB`, border: `0.1rem dashed #C4CDD5`, padding: `5rem`}}>
      <Icon
        type="form"
        style={{
          fontSize: `2.5rem`,
          background: `#C4CDD5`,
          color: `#FFF`,
          padding: `2rem`,
          borderRadius: `5rem`,
          margin: `3rem`
        }}
      />
      <h3>
        This {capitalizeFirst(parent)} Does Not Have Any {capitalizeFirst(childs)}
      </h3>
      <p>Add a {capitalizeFirst(child)} to get started. Use the side panel to change properties</p>
    </div>
  </Card>
)

NoContent.propTypes = {
  parent: PropTypes.string,
  children: PropTypes.string
}

export default NoContent
