import React, {useEffect, useContext} from "react"
import {ReducerContext} from "./reducer"
import ParetoChart from '../ParetoChart'

var paretoData = {}

export default function Chart () {
  const {state} = useContext(ReducerContext)

  if( state.data ){
    paretoData = {}
    state.data.questions.forEach( question_data => {
      paretoData[question_data.question] = question_data.num_fail
    })

    console.log(paretoData)
  }
  return (
    <div><ParetoChart data={paretoData} /></div>
  )
}