import React, {useState, useEffect} from "react"
import Header from "./Header"
import HistoryLog from "./HistoryLog"
import InstallerInformation from "./InstallerInformation"
import InspectionInformation from "./InspectionInformation"
import SiteOwnerInformation from "./SiteOwnerInformation"
import SiteRepresentative from "./SiteRepresentative"
import SiteNotes from "./SiteNotes"
import UploadedDocuments from "./UploadedDocuments"
import ProjectDetails from "./ProjectDetails"
import ProjectCancelled from "./ProjectCancelled"
import {auth} from '../Auth'
import './DetailedProjectView.css'
import {loggerEffect} from '../effects'
import {FUNCTIONS_URL} from '../config'

export default function DetailedProjectView ({resource, match, history}) {

  const forceSync = async () => {
    try {
      const res = await fetch(`${FUNCTIONS_URL}/utilities/index_project`, {
        method: 'POST',
        body: JSON.stringify({
          projectId: resource.id
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await res.json()
      console.info('Project Synced:', data)
    } catch (e) {
      console.error(e)
    }
        
  }
  useEffect(() => { // register project visit - so it can be force synced
    forceSync()
  }, [])

  const [logger, setLogger] = useState(null) // eslint-disable-line
  useEffect(loggerEffect(setLogger), [])
  const projectDetailsProps = {project: resource, history, installerReadable: resource.installerReadable}

  return (
    <div className="flex-column" style={{padding: `3rem`}}>
      <Header id={resource.id} status={resource.status} />
      <div className="flex-row" style={{background: `rgba(249, 250, 251, 0.7)`, textAlign: `left`}}>
        <div className="flex-column full-width" style={{flex: 2}}>
          {resource.status === `Canceled` && <ProjectCancelled cancellations={resource.cancellations} />}
          {/* <ProjectOwner owner={(resource.organization || {}).name} /> */}
          <InspectionInformation project={resource} />
          <div className="background-white margin-bottom-2" data-cy="siteInfoProjectDetail">
            <h3 className="padding-2" style={{borderBottom: `0.1rem solid #DFE3E8`}}>
                            Site Info
            </h3>
            <SiteOwnerInformation {...resource.site} />
            <SiteRepresentative {...resource.site} />
            {auth.sunkaizenUser.type !== `installer` && <SiteNotes resource={resource} />}
          </div>
          <InstallerInformation installer={resource.installer} nabcep_ids={resource.nabcep_ids} />
          <HistoryLog projectId={match.params.id} />
          <UploadedDocuments project={resource} />
        </div>
        <div className="margin-left-1" style={{flex: 1}}>
          <ProjectDetails {...projectDetailsProps} />
        </div>
      </div>
    </div>
  )
}