import {store} from "../utilities"
import {setQueryParams, setTableControls, setResources} from '../action_creators'
import {firebase} from '../../utilities'

export default function tablePostProcessor (res) {
  const state = store.getState()
  const {total: {value: total} = {value: 0}} = res.hits || {}

  store.dispatch(setTableControls({pagination: {current: state.tableControls.pagination.current, total}}))
  store.dispatch(setQueryParams({from: state.query.from + state.query.size}))
    
  const resources = {}
  const rr = res.hits ? res.hits.hits.map(r => r._source) : []
  rr.forEach(resource => {
    if (resource.created) {
      resource.created = new firebase.firestore.Timestamp(resource.created._seconds, resource.created._nanoseconds)
    }
    if (resource.updated) {
      resource.updated = new firebase.firestore.Timestamp(resource.updated._seconds, resource.updated._nanoseconds)
    }
    resources[resource.id] = resource
  })

  // console.log(resources)
  store.dispatch(setResources(resources))
}