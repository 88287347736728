import _ from "lodash"

import * as Model from "./model"

export const INITIALIZE_TICKET_MODAL = `INITIALIZE_TICKET_MODAL`
/**
 * Before creating tickets you select the intake failures you want to create
 * tickets for. That selection is mediated by this action.
 */
export const SET_INTAKE_FAILURE_SELECTION = `SET_INTAKE_FAILURE_SELECTION`
export const OPEN_TICKET_MODAL = `OPEN_TICKET_MODAL`
export const CLOSE_TICKET_MODAL = `CLOSE_TICKET_MODAL`
export const CHANGE_TICKET_INFORMATION = `CHANGE_TICKET_INFORMATION`

export const initializeTicketModal = ({intakeFailuresById}) => ({type: INITIALIZE_TICKET_MODAL, intakeFailuresById})
export const setIntakeFailureSelection = ({id, selected}) => ({type: SET_INTAKE_FAILURE_SELECTION, id, selected})
export const openTicketModal = () => ({type: OPEN_TICKET_MODAL})
export const closeTicketModal = () => ({type: CLOSE_TICKET_MODAL})
export const changeTicketInformation = ({id, field, value}) => ({type: CHANGE_TICKET_INFORMATION, id, field, value})

/*
CREATE_TICKET implies that we'll move onto the next modal view, so there's some UI transition code that should trigger
*/
