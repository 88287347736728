import columns from './columns'
import onRow from './onRow'
import permissions from './permissions'
import sorter from './sorter'
import rowSelection from './rowSelection'

import SingleUserBuilder from "../../../UserBuilder/SingleUserBuilder"
import BulkUserBuilder from "../../../UserBuilder/BulkUserBuilder"

import {organizationFilter} from '../utilities'

const config = {
  columns, 
  onRow, 
  permissions, 
  sorter,
  rowSelection, 
  type: 'users', 
  searchbar: true, 
  savedFilters: false,
  tabs: 'type',
  bulk: () => BulkUserBuilder,
  create: () => SingleUserBuilder,
  preProcess: [organizationFilter]
}

export default config