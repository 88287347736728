import React from 'react'
import SchedulingMap from './SchedulingMap'
import SchedulingMapWrapper from './SchedulingMapWrapper'

export default function Map (props) {
  return (
    <SchedulingMapWrapper>
      <SchedulingMap {...props} />
    </SchedulingMapWrapper>
  )
}