import projectsConfig from './projects'
import projectsInspector from './projectsInspector'
import projectsRequestor from './projectsRequestor'
import usersConfig from './users'
import formsConfig from './forms'
import authorizationsConfig from './authorizations'
import ticketsConfig from './tickets'
import reportsConfig from './reports'

const strategiesMap = {
  [projectsConfig.type]: projectsConfig,
  [ticketsConfig.type]: ticketsConfig,
  [projectsInspector.type]: projectsInspector,
  [projectsRequestor.type]: projectsRequestor,
  [usersConfig.type]: usersConfig,
  [formsConfig.type]: formsConfig,
  [authorizationsConfig.type]: authorizationsConfig,
  [reportsConfig.type]: reportsConfig,
  none: {}
}

export default strategiesMap