import React from 'react'
import {Checkbox} from "antd"

export default function IgnoreAvailability ({change, ignoreAvailability}) {

  console.log(ignoreAvailability)

  return (
    <Checkbox 
      onClick={e => change('ignoreAvailability')(e.target.checked)} 
      checked={ignoreAvailability} 
    >
            Ignore Inspector Availability for Scheduling
    </Checkbox>
  )
}