import _ from "lodash"
import React, {useEffect} from "react"
import {Popconfirm, Button, Icon} from "antd"

import Auth from "./Auth"
import SystemLogger from "./SystemLogger"

const auth = new Auth()

/**
 * This is a button modified to have a PopConfirm trigger on click, and to
 * support logging the resources that were removed. It is intended to be used
 * only in places where items are being removed or deleted. As such, it defaults
 * to the "danger" button type.
 *
 * You can pass any extra props (those not specified for logging or the
 * PopConfirm) directly to this component and they'll be passed on to the Button
 * being rendered; for example, use `type` to set the button type to something
 * other than "danger". Additionally `popconfirmProps` can be used to pass any
 * settings that aren't covered to that component (but note that PopConfirm's
 * `title` is instead `confirmationDisplay` here, for clarity and to
 * disambiguate from Button's `title`).
 *
 * Lastly, if you wish to render anything other than the default antd Button
 * component, use the `customRender` prop flag to stop the default button from
 * rendering. Not that no extra properties (as explained above) will be passed
 * on to the children.
 *
 * OPT `customRender`: Render children directly inside of a popconfirm without
 * placing them inside of a Button component.
 *
 * OPT `collection`: The firebase collection the value comes from. When combined
 * with `documentId`, the component will store the deletion even in logs.
 *
 * OPT `documentId`: The id of the document being deleted.
 *
 * OPT `confirmationDisplay`: The confirmation message displayed when the user
 * clicks the button; any ReactNode is valid (so JSX or strings). Defaults to
 * "Are you sure you want to delete this?".
 *
 * REQ `onConfirm`: Callback for handling deletion confirmation.
 *
 * OPT `onCancel`: Callback for handling deletion cancellation.
 *
 * OPT `children`: As with a normal Button, you can nest children that you want
 * to render inside the button directly inside the JSX.
 *
 * OPT `okText`: Text for proceeding with deletion
 *
 * OPT `cancelText`: Text for cancelling deletion.
 *
 * OPT `popconfirmProps`: Give props directly to the popconfirm if needed.
 */
export default function DeleteButton({
  collection,
  documentId,
  confirmationDisplay,
  onConfirm,
  onCancel,
  children,
  okText,
  cancelText,
  popconfirmProps = {},
  customRender = false,
  ...additionalProps
}) {
  const onConfirmWrapped = (...args) => {
    if (_.isString(collection) && _.isString(documentId)) {
      const logger = new SystemLogger({
        uid: _.get(auth, `sunkaizenUser.id`, null),
        path: `/${collection}/${documentId}`
      })

      // logger.log(`Resource Deleted`)
    }
    onConfirm(...args)
  }

  return (
    <Popconfirm
      title={confirmationDisplay || `Are you sure you want to delete this?`}
      onConfirm={onConfirmWrapped}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
      {...popconfirmProps}
    >
      {customRender ? (
        children
      ) : (
        <Button type="danger" {...additionalProps}>
          {children}
        </Button>
      )}
    </Popconfirm>
  )
}
