import DB from '../../../../DAL/DB'
import {auth} from '../../../../Auth'

const rubrics = new DB.Rubrics()

export default async function addRubrics (res) {
  try {
    const {data: rr, ok} = await rubrics.get(
      null, 
      // {filters: [['organization.id', '==', auth.sunkaizenUser.organization.id]]}
    )
    if (!ok) return

    const rubricsMap = {}
    for (let rubric of rr) { 
      rubricsMap[rubric.formId] = rubricsMap[rubric.formId] 
        ? [...rubricsMap[rubric.formId], rubric]
        : [rubric] 
    }

    if (res.hits && Array.isArray(res.hits.hits)) {
      for (let hit of res.hits.hits) {
        if (rubricsMap[hit._source.id]) { hit._source.rubrics = rubricsMap[hit._source.id] }
      }
    }

    return res        
  }
  catch (e) { console.log('addRubrics function failed', e) }
}