import React from "react"
import PropTypes from "prop-types"
import {Checkbox} from "antd"
import Auth from "../Auth"

import Multiselect from "./Multiselect"

const auth = new Auth()

const Permissions = ({
  type,
  edit,
  permissions = {},
  userPermissions,
  userPermissionsAll,
  change,
  sort
}) => (
  <div className="flex-row flex-align-center full-width">
    {['admin', 'super-admin'].includes(auth.sunkaizenUser.type) && edit 
      ? (
        <Multiselect
          options={Object.values(permissions).sort(sort)}
          selected={Object.keys(userPermissions || {}).filter(p => permissions[p])}
          select={(v = []) => {
            const pp = {}
            v.forEach(p => pp[p] = permissions[p].name)
            change(type)(pp)
          }}
        />
      ) 
      : (
        <div className="flex-row" style={{flexFlow: 'row wrap'}} >
          {userPermissions &&
            Object.values(userPermissions).sort(sort).map((name, i) => (
              <div
                key={i}
                style={{
                  background: "#DFE3E8",
                  padding: "1rem 1.5rem 1rem 1.5rem",
                  margin: "0 0.5rem 0 0"
                }}
              >
                {name}
              </div>
            ))
          }
        </div>
      )
    }
    <Checkbox
      disabled={ !edit || !['admin', 'super-admin'].includes(auth.sunkaizenUser.type)}
      onChange={e => change(`${type}All`)(e.target.checked)}
      checked={userPermissionsAll}
    >
      Grant All Access
    </Checkbox>
  </div>
)

Permissions.propTypes = {
  type: PropTypes.string,
  edit: PropTypes.func,
  permissions: PropTypes.object,
  userPermissions: PropTypes.object,
  userPermissionsAll: PropTypes.bool,
  change: PropTypes.func
}

export default Permissions
