import React from "react"

export default function InstallerInformation ({installer, nabcep_ids}) {
  return (
    <div className="flex-column align-start background-white padding-2 margin-bottom-1" >
      <h3>Installer</h3>
      <h4
        className="padding-1"
        style={{
          borderRadius: "0.5rem",
          width: "75%",
          margin: "1rem 0 1rem 0",
          background: "rgba(249, 250, 251, 0.7)"
        }}
      >
        {installer && installer.id ? installer.name : ""}
      </h4>
      {
        <div>
          <h5 className="padding-1" >Installer&apos;s NABCEP IDs used on this project</h5>
          <div className="margin-left-1 flex-row" >
            {nabcep_ids ? (
              (nabcep_ids || []).map((id, i) => (
                <div ley={i} style={{padding: "0 1rem 1rem 1rem"}}>
                  {id}
                </div>
              ))
            ) : (
              <div style={{padding: "0 1rem 1rem 1rem"}}>No NABCEP Ids selected</div>
            )}
          </div>
        </div>
      }
    </div>
  )
}