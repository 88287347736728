import React from 'react'
import NewTable from '../UnifiedList/Provider'
import {Provider, connect} from 'react-redux'
import {createStore} from 'redux'

import './styles.css'

/*
    the reducer is here to control the style of selected rows without adding special code to the UnifiedList
    it holds the ids of selected table components; and it passes them to the UnifiedList to force a re-render
    whenever the user selects/unselects a new report.

    different parts of the reports strategy import the store and use it directly to make the process of 
    'downloading selected reports' work

        -- Chris 5/12/2021
*/ 


function reducer (state = {}, action) {
  switch (action.type) {
  case 'set': return {...state, [action.id]: action.value}
  case 'delete': 
    const s = {...state}
    delete s[action.id]
    return s
  case 'clear': return {}
  default: return state
  }
}

export const store = createStore(reducer)

function stp (s) { return {selected: s} }

export function ReportsList ({selected, ...rest}) { 
  return (
    <div className="flex-column padding-2 align-start" >
      <NewTable {...rest} selected={selected} strategy="reports" />
    </div>
  )
}

const WrappedReportsList = connect(stp)(ReportsList)

export default function P () {
  return (
    <Provider store={store} >
      <WrappedReportsList />
    </Provider>
  )
}