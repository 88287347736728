import {combineReducers} from "redux"

const fields = (state = {}, action) => {
  switch (action.type) {
  case `field`:
    return {...state, [action.field]: action.value}
  case `fields`:
    return {...state, ...action.fields}
  case `blankFields`:
    return {}
  default:
    return state
  }
}

const installers = (state = {}, action) => {
  if (action.type === `installers`) return action.value
  else return state
}

const projectBase = (state = {errors: {}}, action) => {
  switch (action.type) {
  case `initialProject`:
    return {...state, ...action.project}
  default:
    return state
  }
}

const permissionsCategories = (state = {fus: {}, clients: {}, forms: {}}, action) => {
  switch (action.type) {
  case `formsPopulate`:
    return {...state, forms: action.forms}
  case `clientsPopulate`:
    return {...state, clients: action.clients}
  case `fusPopulate`:
    return {...state, fus: action.fus}
  default:
    return state
  }
}

const permissions = (state = {fus: {}, clients: {}, forms: {}}, action) => {
  switch (action.type) {
  case `forms`:
    return {...state, forms: action.forms}
  case `clients`:
    return {...state, clients: action.clients}
  case `fus`:
    return {...state, fus: action.fus}
  default:
    return state
  }
}

const projectId = (state = null, action) => {
  switch (action.type) {
  case `projectId`:
    return action.projectId
  default:
    return state
  }
}

const rootReducer = combineReducers({
  fields,
  installers,
  projectBase,
  permissionsCategories,
  permissions,
  projectId
})

export default rootReducer
