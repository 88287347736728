import React from "react"

import {connect} from "react-redux"

import SingleInput from "./SingleInput"
import NumericInput from "./NumericInput"
import TextAreaField from "./TextAreaField"
import RadioButton from "./RadioButton"
import Dropdown from "./Dropdown"
import Checkboxes from "./Checkboxes"
import Multiselect from "./Multiselect"
import DateInput from "./DateInput"
import TimeInput from "./TimeInput"
// import FileUpload from "./FileUpload"
// import PhotoRequired from './PhotoRequired'

import {fieldHasErrors} from "../../utilities"

const components = [
  SingleInput,
  NumericInput,
  TextAreaField,
  RadioButton,
  Dropdown,
  Checkboxes,
  Multiselect,
  DateInput,
  TimeInput,
  // FileUpload
]

const componentNameMapping = {
  "single-input": SingleInput,
  "numeric-input": NumericInput,
  "text-area-field": TextAreaField,
  "radio-button": RadioButton,
  dropdown: Dropdown,
  checkboxes: Checkboxes,
  multiselect: Multiselect,
  "date-input": DateInput,
  "time-input": TimeInput,
  // "file-upload": FileUpload,
  templated: RadioButton
}

const mapStateToProps = (state) => ({
  errors: state.project.intake.errors,
  fields: state.fields
})

const NakedFieldType = ({component}) => { // errors = {}
  const C = componentNameMapping[component.type] || SingleInput

  // the error val is unused as of now; errors are computed from field contents using the same function that calculate the errors value
  // and they disappear as soon as someone fixes them -- Chris 1/14/2020
  // const error = errors[component.id]

  return (
    <div
      style={
        fieldHasErrors(component)
          ? {backgroundColor: `rgba(255, 0, 0, 0.2)`, padding: `0.5rem`, borderRadius: `0.3rem`}
          : {backgroundColor: `initial`, padding: `0.5rem`}
      }
    >
      <C {...component} />
    </div>
  )
}

const FieldType = connect(mapStateToProps)(NakedFieldType)

export {components, FieldType}
