/**
 * In the category of: Header, Footer. This is intended to be used to wrap page
 * content within, providing a consistent layout.
 */

import React from "react"

import "./Content.scss"

export default function Content({className, children, title}) {
  return (
    <div className={`content-component pageContainer ${className}`}>
      <h1 className="page-title">{title}</h1>
      {children}
    </div>
  )
}
