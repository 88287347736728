import {store} from "./utilities"
import {firebase, db} from "../utilities"
import Auth from "../Auth"
import {resourceType, validateResource, fieldNameConverter} from "../validators"
import {checkForPermission, typeMap} from "./utilities"

const auth = new Auth()

const validators = {...resourceType[`project`], ...resourceType[`site`]}
export const setField = (field, value) => async dispatch => {
  if (validators[field]) {
    const [errors] = validateResource({[field]: value})({[field]: validators[field]})
    dispatch({type: `project`, value: {errors}})
  }

  dispatch({type: `field`, field, value})
}

export const setInitialFields = fields => ({type: `fields`, fields})
export const blankFields = () => ({type: `blankFields`})

const singular = {clients: 'client', forms: 'form', fus: 'field-user'}
export const populatePermissions = (type, permissions) => {
  store.dispatch({type: `${type}Populate`, [type]: permissions})
  return makePermissions(singular[type])
}
export const setPermissionGroup = (type, permissions) => ({type, [type]: permissions})
export const setProject = project => ({type: `initialProject`, project})
export const setInstallers = value => ({type: `installers`, value})

const typeEnum = {client: true, form: true, "field-user": true}

export const makePermissions = type => {
  if (!typeEnum[type]) throw new Error(`provided an invalid type: `, type)

  const state = store.getState()

  // one is the plural name of the singular argument 'type' 
  // two is a two-item array, where each item is a group of tools to check the current user permissions for the parameter 'type'
  const [one, ...two] = typeMap[type]

  // if the user is a client, and the type under consideration is 'client', 
  //  allow them to only select themselves for the 'client' field, and short circuit
  if (type === `client` && auth.sunkaizenUser.type === `client`) {
    return setPermissionGroup(one, {
      [auth.sunkaizenUser.id]: state.permissionsCategories[one][auth.sunkaizenUser.id] || {}
    })
  }

  let permissionedResourceGroup = state.permissionsCategories[one]

  // if user is a client, they are only allowed to use certain forms; winnow the initial form list to only include those forms
  if (auth.sunkaizenUser.type === `client` && type === `form` && !auth.sunkaizenUser.qualificationsAll) {
    if (auth.sunkaizenUser.qualifications) {
      permissionedResourceGroup = Object.values(permissionedResourceGroup)
        .filter(form => auth.sunkaizenUser.qualifications[form.id])
    }
  }

  const allowedPermissions = {}

  // clients are subject to permissions; admins can do whatever they want
  if (auth.sunkaizenUser.type === `client`) {
    Object.values(permissionedResourceGroup).filter(res => {
      // test the current resource against each other permission type
      return two.every(({type: t, accessor}) => {
        return state.fields[t] ? accessor(res, state.fields[t]) : true
      })
    })
      .forEach(res => (allowedPermissions[res.id] = res))
  } else if (auth.sunkaizenUser.type == `admin` || auth.sunkaizenUser.type == `super-admin`) {
    Object.values(permissionedResourceGroup).forEach(res => (allowedPermissions[res.id] = res))
  }

  return setPermissionGroup(one, allowedPermissions)
}
