export const cancelProjectReasons = {
  "Homeowner request to cancel": {
    reason: "Homeowner request to cancel",
    options: {required: ["comment"]}
  },
  "Client request to cancel": {
    reason: "Client request to cancel",
    options: {required: ["comment"]}
  },
  "Customer is not reachable": {
    reason: "Customer is not reachable",
    options: {required: ["comment"]}
  },
  "Inspector request to cancel": {
    reason: "Inspector request to cancel",
    options: {required: ["comment"]}
  }
}