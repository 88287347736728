import React from "react"
import PropTypes from "prop-types"

import Plotly from "plotly.js-basic-dist"
import moment from "moment"

export default class ProjectAgeAtTimeOfCancellation extends React.Component {
  static propTypes = {
    options: PropTypes.object,
    layout: PropTypes.object,
    projects: PropTypes.array
  }

  chartRef = React.createRef()

  componentDidUpdate = () => this.updateChart()

  updateChart = () => {
    const ageData = []

    this.props.projects.forEach(project => {
      if (project.cancellations)
        project.cancellations.forEach(cancellation => {
          if (!cancellation.canceledAt.toDate) return
          ageData.push({
            created: project.created,
            canceled: cancellation.canceledAt,
            delta: moment
              .duration(moment(cancellation.canceledAt.toDate()).diff(moment(project.created.toDate())))
              .asDays(),
            site: project.site.siteAddress
          })
        })
    })

    const dataMap = {}
    ageData.map(point => point.delta.toFixed(2)).forEach(d => (dataMap[d] = dataMap[d] ? dataMap[d] + 1 : 1))

    const xData = [],
      yData = []
    Object.entries(dataMap).forEach(([value, count]) => {
      xData.push(value)
      yData.push(count)
    })
    const trace = {
      x: xData,
      y: yData,
      type: "bar"
    }

    const layout = {
      margin: {l: 20, t: 20, r: 20, b: 60, pad: 0},
      title: {x: 1},
      xaxis: {
        title: {
          text: "Days"
        }
      }
    }

    const data = [trace]
    Plotly.newPlot(this.chartRef.current, data, layout)
  }

  render = () => (
    <div
      style={{
        padding: "2rem 0 1rem 0",
        margin: "2rem 0 0 0",
        border: "0.1rem solid lightgrey",
        flex: 1,
        flexFlow: "column",
        background: "white"
      }}
    >
      <h3>Project Age at Time of Cancellation</h3>
      <div ref={this.chartRef}></div>
    </div>
  )
}
