import React from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {Form, Checkbox, Input, Icon, Button, Row, Col} from "antd" //	Popconfirm, Card, Divider, Select, Affix, Tabs

import DeleteButton from "../../DeleteButton"
import {setField} from "../action_creators"

const mapStateToProps = (state, ownProps) => ({
  field: state.form.fields[state.active.field],
  option: state.form.fields[state.active.field].options[ownProps.index]
})

const mapDispatchToProps = dispatch => ({
  setField: field => ({id, value}) => {
    field.options[id] = value
    dispatch(setField({category: "fields", id: field.id, value: field}))
  }
})

class Option extends React.Component {
  static propTypes = {
    option: PropTypes.object,
    field: PropTypes.object,
    del: PropTypes.func,
    selected: PropTypes.string,
    index: PropTypes.number
  }

  render = () => {
    const setField = this.props.setField(this.props.field)
    const {index, option, del, errors} = this.props

    console.log(errors)

    return (
      <div style={{marginBottom: "1.5rem"}} key={index}>
        {errors && (
          <div
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              alignItems: "center",
              justifyContent: "center",
              background: "red",
              color: "white",
              padding: "0.5rem",
              margin: "0 0 0.5rem 0",
              borderRadius: "0.5rem"
            }}
          >
            <Icon type="exclamation-circle" />
            <div style={{padding: "0 0 0 0.5rem"}}>Duplicate</div>
          </div>
        )}
        <Row>
          <Col span={20}>
            <Form.Item style={{margin: 0}}>
              <Input
                addonAfter={<Icon type="drag" />}
                placeholder="Please provide a name for this option"
                onChange={e => setField({id: this.props.index, value: {...option, label: e.target.value}})}
                value={option.label}
              />
            </Form.Item>
          </Col>
          <Col span={2} offset={1}>
            <DeleteButton
              confirmationDisplay="Remove this option?"
              type="secondary"
              onConfirm={() => del(index)}
              icon="minus-circle"
            />
          </Col>
        </Row>

        <Row>
          {/*					<Col span={ 20 } offset={2}>
						<div className="followupContainer">
							<Form.Item style={ { margin: 0 } } >
								<Checkbox
									onChange={ e => setField({ id: this.props.index, value: { ...option, requireFollowup: e.target.checked } }) }
									checked={ option.requireFollowup }
								>
									Require followup
								</Checkbox>
							</Form.Item>
						</div>
					</Col>
*/}
          <Col span={16}>
            <div className="followupContainer">
              <Row>
                <Form.Item style={{margin: 0}}>
                  <Checkbox
                    onChange={e =>
                      setField({id: this.props.index, value: {...option, requireFollowupComments: e.target.checked}})
                    }
                    checked={option.requireFollowupComments}
                  >
                    Require Comments
                  </Checkbox>
                </Form.Item>
              </Row>
              <Row>
                <Form.Item style={{margin: 0}}>
                  <Checkbox
                    onChange={e =>
                      setField({id: this.props.index, value: {...option, requireFollowupSignature: e.target.checked}})
                    }
                    checked={option.requireFollowupSignature}
                  >
                    Require Signature
                  </Checkbox>
                </Form.Item>
              </Row>
              <Row>
                <Form.Item style={{margin: 0}}>
                  <Checkbox
                    onChange={e =>
                      setField({id: this.props.index, value: {...option, requireFollowupPhoto: e.target.checked}})
                    }
                    checked={option.requireFollowupPhoto}
                  >
                    Require Photo
                  </Checkbox>
                </Form.Item>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const WrappedOption = connect(mapStateToProps, mapDispatchToProps)(Option)

// Option.propTypes = {
// 	form: PropTypes.object,
// 	del: PropTypes.func,
// 	selected: PropTypes.bool,
// 	index: PropTypes.number
// }

// const WrappedOption = Form.create({ onValuesChange: save })(Option)
export default WrappedOption
