import React from "react"
import PropTypes from "prop-types"
import {Input, Select} from "antd"
import {connect} from "react-redux"

import {changeTicketInformation} from "../action_creators"

const TextArea = Input.TextArea

const mapStateToProps = state => ({ticket: state.ticketsById[`NEW_TICKET`]})

const mapDispatchToProps = {changeTicketInformation}

export let CreateTicket = ({ticket, changeTicketInformation}) => {
  const onChange = ({field, getValue}) => event =>
    changeTicketInformation({id: `NEW_TICKET`, field, value: getValue(event)})

  return (
    <div data-test="create-ticket-component">
      <Input
        value={ticket.title}
        placeholder="Title of ticket"
        onChange={onChange({field: `title`, getValue: e => e.target.value})}
      />
      <TextArea
        value={ticket.description}
        placeholder="Describe work required"
        onChange={onChange({field: `description`, getValue: e => e.target.value})}
      />
      <Select placeholder="Select Assignee" style={{width: `100%`}} />
    </div>
  )
}

CreateTicket.propTypes = {
  ticket: PropTypes.object,
  changeTicketInformation: PropTypes.func
}

CreateTicket = connect(mapStateToProps, mapDispatchToProps)(CreateTicket)
