export default function sorter (sortValue) {
  let sort = []

  if (sortValue.field && sortValue.order) {
    const field = `${sortValue.field}${(sortValue.field === `created` || sortValue.field === 'updated') ? `._seconds` : `.sortable`}`
    const direction = sortValue.order === `ascend` ? `asc` : `desc`
    sort = [{[field]: direction}]
  }

  return sort
}