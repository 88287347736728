import React from "react"
import {Card, Input, Icon, Button, Row, Col} from "antd"

export default function Layout({children, loading, searchInputProps, buttonText, onButtonClick}) {
  return (
    <Card>
      <Row style={{marginBottom: `1.5rem`}}>
        <Col span={18}>
          {searchInputProps && (
            <Input
              placeholder="Start typing to search"
              prefix={<Icon type="search" style={{color: `rgba(0,0,0,.25)`}} />}
              {...searchInputProps}
            />
          )}
        </Col>
        <Col span={6} style={{textAlign: `right`}}>
          <Button type="primary" onClick={onButtonClick} loading={loading}>
            {buttonText}
          </Button>
        </Col>
      </Row>
      <Row>{children}</Row>
    </Card>
  )
}
