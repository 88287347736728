import makeCurrentDayAvailability from './makeCurrentDayAvailability'
import appointmentFilter from './appointmentFilter'

export default function getDisabled (date, calendar, availability, ignoreAvailability) {
  const currentDayAvailability = makeCurrentDayAvailability(availability, date, ignoreAvailability) 
  const [disabledHours, disabledMinutes] = appointmentFilter(currentDayAvailability, Object.values(calendar), date)

  return [
    () => disabledHours, 
    disabledMinutes
  ]
}