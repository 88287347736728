import React, {useState, useEffect} from "react"
import {prepareProject, saveImages} from "./utilities"
import DnDWrapper from "./DnDWrapper"
import {Spin} from "antd"
import {setProject, setFields, setLoading, setActive, resetState, setSave} from "./action_creators"
import {connect} from "react-redux"
import {subscribe} from '../HOC/Firebase/SubscribeHOC'
import {loggerEffect, getLock} from '../effects'

const mapStateToProps = state => ({
  loading: state.status.loading,
  active: state.active.section
})

const mapDispatchToProps = dispatch => ({
  setProject: project => dispatch(setProject(project)),
  setFields: fields => dispatch(setFields(fields)),
  setLoading: loading => dispatch(setLoading(loading)),
  setActive: e => dispatch(setActive({name: "section", value: e})),
  resetState: () => dispatch(resetState()),
  setSave: b => setSave(b)
})

function DoIntake ({match, ...props}) {
  // redux props
  const {loading, active, setActive, setProject, setFields, setLoading, setSave} = props
  // SubscribeHOC methods
  const {makeSubscription, subscribeResources} = props

  const [logger, setLogger] = useState(null)
  const [readonly, setReadonly] = useState(false)
  const [first, setFirst] = useState(true)

  getLock(match.params.id)
  useEffect(loggerEffect(setLogger, `/projects/${match.params.id}`), [])
  useEffect(function () {
    resetState()
    makeSubscription({paths: [{collection: 'projects', doc: match.params.id}]}, 'project')
    window.addEventListener('beforeunload', saveImages)
    return () => {
      window.removeEventListener('beforeunload', saveImages)
      saveImages()
      // resetState()
    }
  }, [])
  useEffect(function () {
    (async function () {
      setLoading(true)
      setSave(true)
      const {project: [project] = []} = subscribeResources

      try {
        if (project) {
          const {fields, userIsFieldUser} = await prepareProject(project, first, logger)

          setFirst(false)
          setProject(project)
          setFields(fields)
          if (!active) setActive(project.intake.order[0])
          if (userIsFieldUser) setReadonly(true)
        }
      }
      catch (e) { console.log("error transforming project", e) }
      finally { 
        setLoading(false) 
        setSave(false)
      }
    })()

    return () => setSave(false)
  }, [subscribeResources])

  return (
    <div>
      {loading && <Spin size="large" />}
      <div className="padding-2" >
        {readonly && <h1 className="page-header">This project is in review. You cannot modify it.</h1> }
        {!readonly && 
                    <div className="flex-column" style={{textAlign: "left"}}>
                      <h1 className="page-header bold padding-bottom-2" style={{flex: 1}}>Intake</h1>
                      <DnDWrapper />
                    </div>                
        }
      </div>
    </div>
  )
}

const WrappedDoIntake = subscribe()(connect(mapStateToProps, mapDispatchToProps)(DoIntake))
export default WrappedDoIntake