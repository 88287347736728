import React from 'react'
import {Draggable} from "react-beautiful-dnd"

export function Images ({images, project}) {
  return (
    <div>
      <div style={{flexFlow: 'row wrap'}} className="flex-row" >
        {images.map((image, i) => (
          <div style={{width: `25%`, padding: `0.3rem`}} key={i}>
            <Draggable key={i} draggableId={`${project.id}prefix${image.name}`} index={i}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  <img
                    className="full-width"
                    style={{border: image.marked ? `2px solid yellow` : `none`}}
                    src={image.url}
                    alt="intake"
                  />
                </div>
              )}
            </Draggable>
          </div>
        ))}
      </div>
    </div>
  )
}