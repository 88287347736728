import DB from '../../../../DAL/DB'

const forms = new DB.Forms()

async function getForm (id) {
  const {data: [form]} = await forms.get(id)
  if (!form) throw new Error('couldn not get form')

  const formSections = new DB.FormSection(id)
  const {data: sections = [], ok} = await formSections.get()
    
  const fields = {}
  const pp = sections.map(s => {
    const formFields = new DB.FormField(id, s.id)
    return formFields.get().then(({data: ff = []}) => { fields[s.id] = ff })
  })
  await Promise.all(pp)

  return [form, sections, fields]
}

async function transformForm (form, sections) {
  const formSections = new DB.FormSection(form.id)

  const newFormId = forms.makeId()
  const newForm = {...form, id: newFormId, title: `${form.title} (duplicate)`}

  const newSections = []
  for (let section of sections) {
    const newSectionId = formSections.makeId()
    const i = newForm.order.indexOf(section.id)
    newForm.order.splice(i, 1, newSectionId)

    const newSection = {...section, id: newSectionId}
    newSections.push(newSection)
  }

  await forms.update(newFormId, null, newForm)
  return [newForm, newSections]
}

async function transformSections (sections, newSections, fields, newFormId) {
  const fieldIdDict = {}

  for (let sId in sections) {
    const oSection = sections[sId]
    const section = newSections[sId]
    const formFields = new DB.FormField(newFormId, section.id)

    const pp = []
    for (let field of fields[oSection.id]) {
      if (!field) continue

      const newFieldId = formFields.makeId()
      fieldIdDict[field.id] = newFieldId
      pp.push(formFields.update(newFieldId, null, {...field, id: newFieldId}))

      const i = section.order.indexOf(field.id)
      section.order.splice(i, 1, newFieldId)
    }

    const formSections = new DB.FormSection(newFormId)
    await formSections.update(section.id, null, section)

    await Promise.all(pp)
  }

  return fieldIdDict
}

export default async function convertSectionsAndFields (id) {
  try {
    const [form, sections, fields] = await getForm(id)

    const [newForm, newSections] = await transformForm(form, sections)
    const fieldIdDict = await transformSections(sections, newSections, fields, newForm.id)
            
    return [newForm.id, fieldIdDict]
  }
  catch (e) {
    console.log(`could not convert from ${id}`)
    console.log(e)
  }
}