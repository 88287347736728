import React, {useState, useEffect} from 'react'
import {subscribe} from './HOC/Firebase/SubscribeHOC'
import {List, Select} from 'antd'

export function CalendarIntakesByUser ({makeSubscription, subscribeResources}) {
  const [data, setData] = useState([])
  const [users, setUsers] = useState([])

  useEffect(function () {
    makeSubscription(
      {
        paths: [{collection: 'users'}],
        options: {where: [{field: 'type', test: '==', value: 'field-user'}]}
      }, 
      'users'
    )
  }, [])

  useEffect(function () {
    const {intakes, users: uu} = subscribeResources || {}
    if (uu) {
      uu.sort((a, b) => a.name > b.name ? 1 : -1)
      setUsers(uu)
    }
    if (!intakes || !Array.isArray(intakes)) return

    setData(intakes)
  }, [subscribeResources])

  function getIntakes (id) {
    makeSubscription(
      {
        paths: [{collection: 'calendars'}], 
        options: {where: [{field: 'data.inspection.inspector.id', test: '==', value: id}]}
      },
      'intakes'
    )
  }

  return (
    <div>
      <Select style={{width: '100rem', margin: '2rem'}} onChange={e => getIntakes(e)} >
        {
          users.map(user => <Select.Option value={user.id} >{user.name}</Select.Option>)
        }
      </Select>
      <List 
        dataSource={data}
        renderItem={ item => 
          <div className="flex-row justify-space-between" >
            <div>{item.data.site.siteAddress}</div>
            <div>{item.data.id}</div> 
            <div>{item.date.toDate().toDateString()}</div>
            <div>{item.end.toDate().toDateString()}</div>
          </div>
        }
      />
    </div>
  )
}

const WrappedCalendIntakesByUser = subscribe()(CalendarIntakesByUser)
export default WrappedCalendIntakesByUser