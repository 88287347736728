import {contentString} from '../../utilities'
import {inspectorOther, shape} from '../../SchedulingMap/mapIcons'

export default function makePins (todayIntakes, map) {
  const p = {}
  for (let e of todayIntakes) {
    const {id, site: { 
      siteRepEmail: email, siteRepName: contact, siteRepPhone: phone, geocode_results 
    } = {}} = e.data
    const {
      formatted_address, geometry: {location: position}
    } = geocode_results || {formatted_address: 'Assigned Project', geometry: {location: {}}}

    const marker = new window.google.maps.Marker({ 
      position, title: formatted_address, map, shape, icon: inspectorOther() 
    })
    const infoWindow = new window.google.maps.InfoWindow({
      content: contentString({name: formatted_address, contact, phone, email})
    }) 

    marker.addListener("click", () => { infoWindow.open(map, marker) })
    marker.addListener('mouseout', () => { infoWindow.close() })

    p[id] = marker
  }

  return p
}