import columns from './columns'
import onRow from './onRow'
import permissions from './permissions'
import sorter from './sorter'
import rowSelection from './rowSelection'
import CreateAuthorization from './CreateAuthorization'
import {auth} from '../../../Auth'

function postQueryTransform (authorization) {
  let other
  for (let id of authorization.organizations) {
    if (id !== auth.sunkaizenUser.id) other = id
  }

  authorization.oID = other

  authorization.name = authorization.details[other].name
  authorization.phone = authorization.details[other].phone
  authorization.email = authorization.details[other].email
}

function transformWrapper (queryResult) { 
  queryResult.hits ? queryResult.hits.hits.forEach(r => postQueryTransform(r._source)) : []    
}

const config = {
  columns, 
  onRow, 
  permissions, 
  sorter,
  rowSelection, 
  type: 'authorizations', 
  searchbar: true,
  tabs: 'status',
  alternate: CreateAuthorization,
  postProcess: [transformWrapper]
}

export default config