import {store} from "../utilities"
import {getLock, unlock} from './locking'
import {lockQueue, setLoading} from '../action_creators'
import get from './get'
import makeQuery from './makeQuery'
import preProcess from './preProcess'
import postProcess from './postProcess'
import {FULL_TEXT_SEARCH_URL} from "../../config"

export default async function query () {
  const state = store.getState()
  const lock = getLock()
    
  if (!Object.keys(state.resources).length) { store.dispatch(setLoading(true)) }

  try {
    const query = makeQuery()
    preProcess(query)

    console.log(query)
    const url = `${FULL_TEXT_SEARCH_URL}/full_search`
    const res = await get(url, query)
    console.log(res)

    await postProcess(res, lock)
  }
  catch (e) { console.log('error getting resources from elastic', e) }
  finally {
    if (state.loading) store.dispatch(setLoading(false))
    if (unlock(lock)) {
      store.dispatch(lockQueue(false))
      // queue()
    }
  }
}