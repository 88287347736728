import React from 'react'
import {Icon, List} from "antd"
import {connect} from "react-redux"

const sToP = state => ({
  active: state.active.section,
  project: state.project,
  sections: state.sections
})

export function ActiveSectionDisplay ({project = {intake: {order: []}}, sections, active}) {
  const orderedSections = project.intake.order.map(id => sections[id])

  return (
    <div style={{borderBottom: "0.1rem solid #DFE3E8", padding: "1.5rem"}}>
      <List
        itemLayout="vertical"
        dataSource={orderedSections}
        renderItem={item => (
          <List.Item style={{border: "none", margin: 0, padding: 0}}>
            <div className="full-width flex-row align-center" >
              <div className="flex-row align-center justify-center" style={{flex: 1}}>
                {item.id === active 
                  ? <Icon theme="filled" type="check-circle" />
                  : <div style={{padding: ".75rem", borderRadius: "1rem", background: "#C4CDD5"}}></div>
                }
              </div>
              <div className="bold" style={{flex: 5}}>{item ? item.title : "[no section]"}</div>
              <div style={{flex: 2}}>{item ? item.status : "Not Started"}</div>
            </div>
          </List.Item>
        )}
      />
    </div>
  )
}

const WrappedActiveSectionDisplay = connect(sToP)(ActiveSectionDisplay)
export default WrappedActiveSectionDisplay