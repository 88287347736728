import React, {useEffect, useContext} from "react"
import FieldUserBrief from './FieldUserBrief'
import SelectedFieldUser from './SelectedFieldUser'
import {pushData, dispatchSubscribeResources} from '../utilities'
import {List} from 'antd'
import {ReducerContext} from '../reducer'
import {subscribe} from '../../HOC/Firebase/SubscribeHOC'
import {auth} from '../../Auth'

export function FieldUsers ({makeSubscription, subscribeResources}) {
  const {state, dispatch} = useContext(ReducerContext)
  const {mapping: {filteredInspectors = {}}, inspector} = state
  const push = pushData({state, dispatch})

  useEffect(function () {
    auth.sunkaizenUserSubscribe(user => {
      if (!user) return

      if (user.type === `field-user`) { 
        dispatch({type: 'inspectors', value: {[user.id]: user}}) 
        return
      }
        
      return makeSubscription(
        {
          paths: [{collection: `users`}], 
          options: {
            where: [{field: `type`, test: `==`, value: `field-user`}],
            order: `name`
          }
        }, 
        `inspectors`
      )
    })        
  }, [])

  useEffect(dispatchSubscribeResources(dispatch, subscribeResources), [subscribeResources])

  return (
    <div className="padding-top-bottom-2" >
      <div className="flex-row justify-space-between align-center" >
        <div className="bold padding-0-5" >Field Users</div>
        { inspector.id &&
                <a className="padding-right-2" href="#" onClick={() => push({inspector: {}})} >Clear Selected Inspector</a>
        }
      </div>
      {inspector.id
        ? <SelectedFieldUser inspector={inspector} />
        : (
          <div>
            <List 
              dataSource={Object.values(filteredInspectors)}
              loading={!Object.keys(filteredInspectors)}
              renderItem={inspector => <FieldUserBrief inspector={inspector} />}
            />
          </div>
        )
      }
    </div>
  )
}

const WrappedFieldUsers = subscribe()(FieldUsers)
export default WrappedFieldUsers