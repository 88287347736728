import React, {useEffect, useContext} from "react"
import DB from '../DAL/DB'
import {ReducerContext, wrap} from "./reducer"
import Dropdowns from './Dropdowns'
import Tables from './Tables'
import Chart from './Chart'

import "./Analytics.scss"

const analytics = new DB.Analytics()

export function Analytics() {
  const {dispatch} = useContext(ReducerContext)

  // get forms when page loads
  useEffect(function () {
    (async function () {
      const {ok, data} = await analytics.get()

      if (ok) {
        const uniqueForms = {}
        for (let resource of data) {
          uniqueForms[resource.id] = {id: resource.form_id, title: resource.form_title}
        }

        dispatch({type: 'forms', value: Object.values(uniqueForms)})
      }
    })()
  }, [])

  return (
    <div className="analytics-page">
      <h1 className="page-title">Analytics</h1>
      <Dropdowns />
      <Chart />            
      <Tables />
    </div>        
  )
}

const WrappedAnalytics = wrap(Analytics)
export default WrappedAnalytics