import React, {useEffect, useState, useContext} from "react"
import {ReducerContext} from '../reducer'
import {TimeChooser, disabledDate, dateCellRender} from '.'
import {getCalendar, dispatchSubscribeResources, availabilityLimitInspectors, pushData, getAvailability} from '../utilities'
import {subscribe} from '../../HOC/Firebase/SubscribeHOC'
import {Calendar} from 'antd'
import moment from 'moment'

export function DateTimePicker ({makeSubscription, subscribeResources}) {
  const {state, dispatch} = useContext(ReducerContext)
  const push = pushData({state, dispatch})

  const {inspectorAvailability: inspector, date, calendar, inspector: ii, project: {ignoreAvailability}} = state

  function dd () {
    if (ignoreAvailability) return currentDate => { if (currentDate.startOf('day') < moment().startOf('day')) return true }
    return disabledDate(inspector, date || moment())
  }
  const dayDisabled = dd()

  useEffect(function () {
    if (!inspector) return
    if (date) return

    const now = moment()
    if (!dayDisabled(now)) push({time: null, date: now})
  }, [inspector])

  useEffect(function () {
    const ii = availabilityLimitInspectors(state.inspectors, state.availability) || {}
    dispatch({type: 'inspectorAvailability', value: ii[state.inspector.id]})
  }, [state.inspectors, ii.id, state.availability])

  useEffect(getAvailability(makeSubscription), [])
  useEffect(dispatchSubscribeResources(dispatch, subscribeResources), [subscribeResources])

  // get the calendar for the selected field user
  useEffect(function () {
    const unsubscribe = getCalendar(makeSubscription, ii.id)
    return () => {
      unsubscribe()
      dispatch({type: 'calendar', value: []})
    }
  }, [ii.id])


  return (
    <div>
      {state.inspector.id && (
        <div className="calendar-wrapper" >
          <Calendar
            defaultValue={date}
            disabledDate={dayDisabled}
            onChange={e => push({time: null, date: e})}
            dateCellRender={dateCellRender(calendar)}
            fullscreen={false}
          />
        </div>
      )}
      {state.inspector.id && !inspector && 
                <div className="padding-1" >Selected Field User has no availability this month</div>
      }
      {date && <TimeChooser {...{inspector, ignoreAvailability}} />}
    </div>
  )
}

const WrappedDateTimePicker = subscribe()(DateTimePicker)
export default WrappedDateTimePicker