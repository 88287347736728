import React from "react"
import AvailabilityCalendarEvent from './AvailabilityCalendarEvent'
import IntakeCalendarEvent from './IntakeCalendarEvent'
import {Modal, Button} from "antd"
import {auth} from '../Auth'
import DeleteButton from "../DeleteButton"
import {formatTime} from "./utilities"
import DB from '../DAL/DB'

const calendars = new DB.Calendars()

export default function CalendarEvent ({toggle, visible, event = {}}) {
  const footer = [
    <Button key="back" onClick={toggle}>Cancel</Button>,
    <Button key="submit" type="primary" onClick={toggle}>Ok</Button>      
  ]
  if (auth.sunkaizenUser.type === 'field-user' && event.type === 'Availability') footer.push(
    <DeleteButton
      key="delete-event"
      onConfirm={async () => {
        await calendars.delete(event.id)
        toggle()
      }}
      collection="calendars"
      documentId={event.id}
      confirmationDisplay="Are you sure you want to delete this event?"
    >
            Delete Event
    </DeleteButton>
  )

  return (
    <Modal
      width="500px"
      visible={visible}
      footer={footer}
    >
      <div className="calendar calendar-event">
        <h2>{event.type}</h2>
        { event.type === 'Availability' && <AvailabilityCalendarEvent event={event} /> }
        { event.type === 'Intake' && <IntakeCalendarEvent event={event} /> }
        <div>
          <label>Start Date/Time:</label>
          <p>{formatTime(event.date && event.date.toDate())}</p>
        </div>
        <div>
          <label>End Date/Time:</label>
          <p>{formatTime(event.endDate && event.endDate.toDate())}</p>
        </div>                
      </div>
    </Modal>        
  )
}