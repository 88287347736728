import React from "react"
import {Radio, Icon, Tooltip} from "antd" //	Input,
import PhotoRequired from "./PhotoRequired"
import Followup from "./Followup"
import {connect} from "react-redux"
import {setField} from "../../action_creators"

const stop = (state, ownProps) => ({
  field: state.fields[ownProps.id],
  value: state.fields[ownProps.id].value
})
const dtop = dispatch => ({setField: ({id, value}) => dispatch(setField(id, value))})

const oo = [{label: `Pass`}, {label: `Fail`}, {label: `Missing`}, {label: `N/A`}]

export function RadioButton ({setField, field, id}) {
  let {question, label, options, required, tipText, value, photoRequired} = field
  let {values = {}, followup = {}} = value || {}

  const ff = {}
  options = options ? options : oo
  options.forEach(option => { ff[option.label] = {} })
  followup = {...ff, ...followup}

  function set (v) {
    field.value = {values: {[v.target.value]: true}, followup}
    setField({id, value: field})
  }

  const [currentValue] = Object.keys(values)
  const [selectedOption] = (options || oo).filter(option => option.label === currentValue)

  return (
    <div>
      {required && (
        <div className="bold flex-row" >
          <Tooltip title="Required">
            <Icon type="exclamation-circle" style={{fontSize: `2rem`, padding: `0 1rem 0 0`}} />
          </Tooltip>
          <div>REQUIRED</div>
        </div>
      )}
      <div className="bold padding-bottom-1" >{question}</div>
      <div className="flex-column full-width align-start" >
        <div className="padding-right-1" style={{fontFamily: `Arial, serif`, fontSize: `85%`}}>
          {(label || ``).toUpperCase()}
        </div>
        <div>{tipText && `(${tipText})`}</div>
        <div className="full-width" >
          <Radio.Group style={{display: `flex`, flexFlow: `column`}} onChange={set} value={currentValue}>
            {(options || oo).map((option, i) => (
              <Radio value={option.label} key={i}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
          <Followup id={id} option={selectedOption} />
        </div>
      </div>
      {photoRequired && (!field.images || Object.keys(field.images).length < 1) && <PhotoRequired />}
    </div>
  )
}

const WrappedRadioButton = connect(stop, dtop)(RadioButton)
export default WrappedRadioButton