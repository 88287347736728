import React from "react"
import {Icon} from "antd"
import {dateFilter} from "../../tableFilters" //	textSorter, numberSorter,

export default function columnsMap (activeFilters = {sorter: {order: null, field: null}}, setModalVisible, setCancelId) {
  return [
    {
      title: `Site Owner`,
      dataIndex: `site.siteOwnerName`,
      key: `site.siteOwnerName`
    },
    {
      title: `Site`,
      dataIndex: `site.geocode_results.formatted_address`,
      key: `site`
    },
    {
      title: `Client`,
      dataIndex: `client.name`,
      key: `client.name`,
      sorter: true
    },
    {
      title: `Type`,
      dataIndex: `intake.title`,
      key: `intake.title`,
      sorter: true
    },
    {
      title: `Created`,
      dataIndex: `created`,
      key: `created`,
      sorter: true,
      render: t => <div>{t ? t.toDate().toDateString() : `N/A`}</div>,
      ...dateFilter(activeFilters)(`created`)
    },
    {
      title: `Status`,
      dataIndex: `status`,
      key: `status`
    },
    {
      title: `Actions`,
      key: `action`,
      render: (text, record) => (
        <div
          onClick={e => {
            e.stopPropagation()
            if (record.status === "Canceled") return
            setCancelId(record.id)
            setModalVisible(true)
          }}
        >
          <Icon
            style={{padding: "0.5rem", fontSize: 24, color: record.status === "Canceled" ? "transparent" : "initial"}}
            type="delete"
          />
        </div>
      )
    }
  ]
}
