import React, {useState, useEffect} from "react"
import DB from './DAL/DB'

const pp = new DB.Projects()

const ids = [
  '1OoOu4bzw9NKRzYDRmui',
  '1SttxImWAAfspLUtF8p8',
  '20i1CZbvC19loWv9TnHz',
  '8jDHOCYEFcAIauDvabNv',
  'AVKGa1mHlWti8OMhVDde',
  'aVLv6dDWhHPcJD9XcRYc',
  'avyCoPyNxWn5g56PtNty',
  'b8krJnAHORM8BBdYpmNp',
  'bvpPzk7zsZDeAq8tA3TD',
  'CIXGsltPTsiZTznREcIn',
  'D9Izz06uDsb8KPIr8jO5',
  'DB7Ie674eHyRkRS0dPoV',
  'dpyCniPOhAAqN4inFnwz',
  'DSJeI2y2eJkwbSxtnDSY',
  'ElOc3Iju5dfK8n8U3qZZ',
  'fqajgRQR2ziwjvSu64JE',
  'fsK62WYVJ8yPOfoUklk2',
  'hYm0XxB0iREj8UY04qvx',
  'JGbuhIwyFdLENUZIA5b7',
  'jsf2htj4zvUhoUs3ZVmC',
  'KVjzJXzjJ5tgqizNFPNU',
  'My2lawITptMtkARNEfi3',
  'nnGYJM5p2uM4OtaseyXP',
  'oDjUmLhDc2MS0ZCaiFGx',
  'ok7Jf7HfRVoI2XBcBfNq',
  'pHZK5a287ps01xUqiucI',
  'Ptb70FFGXZ7h6FHhZkgl',
  'pxLtCxQZRozKVF2o2Jcb',
  'qIkqHu1n4naTHijPPsgh',
  'Qznaz7y5btgx6RhHxgB6',
  'SyEzmdhjI8WWILLGcWRE',
  'XI3NSzobV782khSG9jmm',
  'zIaeqXZEmtfNWdefyRAT',
  'ZuMCfUyjQ81p8HTng3G7'
]


export default function Testing () {
  const [projects, setProjects] = useState([])

  useEffect(function () {
    (async function () {
      const promises = []
      for (let id of ids) { promises.push(pp.get(id)) }

      const snaps = await Promise.all(promises)
      const projectArray = snaps.map(({ok, data}) => data[0])
      setProjects(projectArray)
    })()
  }, [])

  console.log(projects.map(p => [p.site.siteOwnerName, p.inspection.inspector.name]))

  return (
    <div className="padding-2 flex-column align-center justify-center" >
      <div className="flex-row full-width align-center" >
        <div style={{flex: 1}} className="padding-1 bold" >Site Owner Name</div>
        <div style={{flex: 1}} className="bold" >Inspector Name</div>          
      </div>
      {projects.map(project => {
        return (
          <div className="padding-1 flex-row align-center full-width" >
            <div style={{flex: 1}} className="padding-1" >{project.site.siteOwnerName}</div>
            <div style={{flex: 1}} >{project.inspection.inspector.name}</div>
          </div>
        )
      })}
    </div>
  )

}