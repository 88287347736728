import React, {useContext, useEffect} from 'react'
import {Table} from "antd"
import {TableDescription} from './TableDescription'
import {generateColumns} from './generateColumns'
import {transformData} from './transformData'
import {ReducerContext} from "./reducer"

export default function Tables () {
  const {state, dispatch} = useContext(ReducerContext)

  // convert data into table-ready format
  useEffect(function () {
    if (state.data) { dispatch({type: `final`, value: transformData(state.data)}) }
    console.log(state.data)

  }, [state.data])

  return (
    <div>
      <TableDescription betterOrWorse="better" />
      <Table
        columns={generateColumns({
          aggregationType: state.final.aggregationType,
          betterOrWorse: `better`
        })}
        dataSource={state.final.better}
        rowKey={state.final.aggregationType}
      />
      <TableDescription betterOrWorse="worse" />
      <Table
        columns={generateColumns({
          aggregationType: state.final.aggregationType,
          betterOrWorse: `worse`
        })}
        dataSource={state.final.worse}
        rowKey={state.final.aggregationType}
      />
    </div>
  )
}