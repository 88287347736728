import React, {useEffect} from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import {Provider, connect} from "react-redux"
import {Modal} from "antd"

import * as Model from "./model"
import * as API from "./api"
import {initializeTicketModal, openTicketModal, closeTicketModal} from "./action_creators"
import {FailuresBySection} from "./TicketModal/FailuresBySection"
import {CreateTicket} from "./TicketModal/CreateTicket"
import {store} from "./store"

import "./styles.scss"

const mapSideEffectsToProps = {getProject: API.getProject}

/**
 * This Higher-Order Component provides a more clear sense of separation between
 * side-effectful (impure) functions vs. other component properties. 
 *
 * It expects the caller to provide a `mapSideEffectsToProps` argument, which
 * should be an object map from the property names we want to give to our
 * side-effectful functions functions, to the side-effectful functions
 * themselves. 
 *
 *    const mapSideEffectsToProps = {getProject: API.getProject}
 *
 *  Once the side-effects are provided, like
 *
 *    TicketModal = provideSideEffects(mapSideEffectsToProps)(TicketModal)
 *
 * They will then be provided to the component as any other prop would:
 *
 *    let TicketModal = ({...,  getProject}) => { ... }
 *
 * Though using default arguments would work similarly, like:
 *
 *    let TicketModal = ({getProject: API.getProject}) => { ... }
 *
 * ## Rationale for this HOC
 *
 * Running all side-effects through this HOC gives us more freedom to extend how
 * we handle side-effects the future. One example in use right now is if the
 * `enforceMocks` property is passed to the component as `true`, the properties
 * will be checked for functions corresponding to the mocked side-effects, and
 * an error will be thrown if there isn't a replacement for each one. This can
 * make it easier to write tests that never accidentally trigger side-effects.
 *
 * There may be other ways in which we can provide common solution patterns to
 * classes of problems, like the firestore subscribe function, and the
 * difficulty it introduces with mocking.
 *
 * Using an HOC also has the effect of making it easier to exercise discipline
 * in where side-effects are used. Its existence is a reminder that separating
 * the side-effect from the business logic itself ensures ease of testability.
 * It also promotes thinking about side-effects as dependencies, rather than
 * things you just do inside of your code sometimes.
 *
 * ## What are Side-Effects?
 *
 * From a UI testing perspective, side-effects would include anything for which
 * either or both of the following are true:
 *
 * (1) The function is asynchronous (2) The function is non-deterministic
 *
 * A function that returns different values with successive invocations is still
 * deterministic within the context of UI testing, as every run of the test
 * would still yield the same results.
 */
function provideSideEffects(mapSideEffectsToProps) {
  return Component => props => {
    let sideEffectFunctions

    if (props.enforceMocks) {
      const sideEffectFunctionNames = Object.keys(mapSideEffectsToProps)

      const sideEffectReplacementsInProps = _.filter(props, (propValue, propName) => _.includes(sideEffectFunctionNames, propName))

      

      const allSideEffectsAreReplaced = _.isEqual(sideEffectReplacementsInProps, sideEffectFunctionNames)

      if (!allSideEffectsAreReplaced) {
        throw new Error(
          `You are attempting to enforce mocking of all a component's side-effects, but have not mocked all of the side effects provided to the component`
        )
      }

      sideEffectFunctions = props.sideEffectMocks
    } else {
      sideEffectFunctions = mapSideEffectsToProps
    }

    // Instead of passing the functions directly, we place them in the
    // sideEffects property for clarity.
    return <Component {...{...props, sideEffects: sideEffectFunctions}} />
  }
}

const mapStateToProps = state => {
  const intakeFailures = _.values(state.intakeFailuresById)

  return {
    failuresBySection: _.groupBy(intakeFailures, failure => failure.sectionTitle),
    modalVisible: state.ui.modalVisible
  }
}

const mapDispatchToProps = {initializeTicketModal, openTicketModal, closeTicketModal}

// Modal modes.
const CREATE_TICKET = `CreateTicket`
const UPDATE_TICKET = `UpdateTicket`
const SELECT_FAILURES = `SelectFailures`

/**
 * The ticket modal has a few different usage cases (select failures, create
 * ticket, update ticket). Check the source file for "modal models" to see all
 * the possible modes.
 */
let TicketModal = ({
  mode = SELECT_FAILURES,
  modalVisible,
  failuresBySection,
  initializeTicketModal,
  openTicketModal,
  closeTicketModal,
  getProject
}) => {
  let view

  if (mode === SELECT_FAILURES) {
    view = _.map(failuresBySection, (failures, sectionTitle) => (
      <FailuresBySection key={sectionTitle} {...{sectionTitle, failures}} />
    ))
  } else if (mode === CREATE_TICKET) {
    view = <CreateTicket />
  }

  useEffect(() => {
    const run = async () => {
      const project = await getProject(`8hXok72bD0RZEULwmZSP`)
      const intakeFailuresById = Model.intakeFailuresById(project)
      initializeTicketModal({intakeFailuresById})
    }
    run()
  }, [])

  return (
    <div className="ticket-modal">
      <button onClick={openTicketModal}>Toggle Modal</button>
      <Modal className="modal" visible={modalVisible} onOk={closeTicketModal} onCancel={closeTicketModal}>
        {view}
      </Modal>
    </div>
  )
}

TicketModal.propTypes = {
  mode: PropTypes.string,
  failuresBySection: PropTypes.object,
  initializeTicketModal: PropTypes.func,
  openTicketModal: PropTypes.func,
  closeTicketModal: PropTypes.func,
  modalVisible: PropTypes.bool,
  getProject: PropTypes.func
}

//
// Connect Store
//

TicketModal = connect(mapStateToProps, mapDispatchToProps)(TicketModal)
TicketModal = provideSideEffects(mapSideEffectsToProps)(TicketModal)

const TicketModalEntryPoint = (...props) => (
  <Provider store={store}>
    <TicketModal {...props} />
  </Provider>
)

export default TicketModalEntryPoint
