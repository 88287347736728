import React from "react"
// import PropTypes from 'prop-types'

import {store} from "./utilities"
import {Provider} from "react-redux"
import FormEdit from "./FormEdit"

import "./Forms.scss"

const EntryPoint = props => (
  <Provider store={store}>
    <div className="forms">
      <FormEdit {...props} />
    </div>
  </Provider>
)

export default EntryPoint
