import React from "react"
import PropTypes from "prop-types"

import {connect} from "react-redux"
import {DragDropContext, Droppable} from "react-beautiful-dnd" //	, Draggable
import {Card, Divider} from "antd"

import Auth from "../Auth"
import SystemLogger from "../SystemLogger"

import {setActive, setField} from "./action_creators"

import Fields, {Add} from "./Fields"
import NoContent from "./NoContent"

const auth = new Auth()

const mapStateToProps = state => ({
  section: state.form.sections[state.active.section]
})

const mapDispatchToProps = dispatch => ({
  active: ({id, type}) => dispatch(setActive({type, id})),
  setField: ({id, value, category}) => dispatch(setField({category, id, value}))
})

class FieldsWrapper extends React.Component {
  static propTypes = {
    setField: PropTypes.func,
    active: PropTypes.func,
    section: PropTypes.object
  }

  componentDidMount = () => {
    this.logger = new SystemLogger(window.location.pathname, auth.currentUser.uid)
  }

  onDragEnd = result => {
    if (!result.destination) this.logger.log("You dropped an item outside the droppable area!", "please try again", 5)
    else {
      const {order} = this.props.section

      const [element] = order.splice(result.source.index, 1)
      order.splice(result.destination.index, 0, element)

      this.props.setField({
        id: this.props.section.id,
        value: Object.assign({}, this.props.section, {order}),
        category: "sections"
      })
    }
  }

  onDragStart = target => this.props.active({id: target.draggableId, type: "field"})

  render = () => {
    if (this.props.section) {
      return (
        <Card>
          <h3>{this.props.section.title}</h3>
          <Divider />
          <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
            <Droppable droppableId="sectionsdroppable">
              {(provided, snapshot) => <Fields {...{provided, snapshot}} />}
            </Droppable>
          </DragDropContext>
          <hr />
          <Add />
        </Card>
      )
    } else {
      return <NoContent parent="Form" child="Section" childs="Sections" />
    }
  }
}

const WrappedFieldsWrapper = connect(mapStateToProps, mapDispatchToProps)(FieldsWrapper)

export default WrappedFieldsWrapper
