const localStyles = {
  filterField: selected => ({
    background: selected ? `white` : `lightgrey`,
    borderTop: selected ? `0.3rem solid #234091` : `0.3rem solid lightgrey`,
    padding: `0.5rem 2rem 0.75rem 2rem`,
    margin: `0 0.3rem 0 0`,
    fontSize: `115%`
  }),
  titleContainer: {
    width: `100%`,
    display: `flex`,
    flexFlow: `row nowrap`,
    padding: `2rem 0 2rem 0`,
    fontWeight: `bolder`
  },
  container: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignContent: `flex-start`,
    borderRadius: `0.2em`,
    padding: `3rem`
  },
  fieldset: {
    display: `flex`,
    justifyContent: `flex-start`,
    flexDirection: `column`,
    backgroundColor: `white`,
    borderRadius: `0.3em`,
    padding: `1em`,
    boxShadow: `1px 1px lightgrey`
  },
  button: {
    padding: `0.5rem`,
    cursor: `pointer`,
    borderRadius: `0.2em`,
    background: `#2A77B2`,
    color: `white`,
    textDecoration: `none`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`
  },
  dropdown: {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    border: `1px solid rgba(211, 211, 211, .6)`,
    borderRadius: `0.2em 0 0 0.2em`,
    flex: 15,
    backgroundColor: `white`,
    padding: `0.5em`
  }
}

export default localStyles
