import React from "react"

// import PropTypes from 'prop-types'

const styles = {
  centered: {
    alignSelf: `center`,
    justifySelf: `center`,
    fontSize: `200%`,
    fontWeight: `bold`
  }
}

const AccountCreationPending = () => (
  <div style={styles.centered}>
    Your account creation request is currently pending; please check with the FIT-QM administrators for more details
  </div>
)

export default AccountCreationPending