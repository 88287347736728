import SystemLogger from '../../SystemLogger'
import {auth} from '../../Auth'
import moment from 'moment'

export default class ValidateScheduling {
  constructor () {
    this.logger = new SystemLogger({uid: auth.currentUser.uid})
    // toggle this to get debugging messages for why a schedule didn't validate
    this.log = false
  }

  validate ({time, date, duration, project, inspector}, final) {
    let logger = final ? this.logger.event : (log) => console.log(log)
    if (!this.log) logger = () => null

    if (!time) {
      logger('You must provide a time for this inspection')
      return false
    }

    if (!date) {
      logger('You must provide a date for this inspection')
      return false
    }
    if (date < moment().startOf('day')) {
      logger('You have scheduled this inspection for a date in the past')
      return false
    }
    if (!duration) {
      logger('You must provide a duration for this inspection')
      return false
    }
    if (duration && duration.hours < 1 && duration.minutes < 30) {
      logger('This inspection is scheduled for less than an half an hour')
      return false
    }
    if (!project.id) {
      logger('You have not chosen a project for inspection')
      return false
    }
    if (!inspector.id) {
      logger('You have not chosen an inspector for this inspection')
      return false
    }
    if (
      !inspector.qualificationsAll && 
            (
              inspector.qualifications && 
                !Object.values(inspector.qualifications).includes(project.intake.title)
            )
    ) {
      logger('You have scheduled an inspector that is not qualified to perform this inspection type')
      // ['client', 'admin', 'super-admin'].includes(auth.sunkaizenUser.type)
      return false
    }
    else {
      console.log(0)
      return true
    }
  }
}