import * as React from "react"
import _ from "lodash"
import {Button} from "antd"

import "./TitleBar.scss"

export function TitleBar(props) {
  const {form, history, rubrics, sections} = props

  return (
    <div
      className="title-bar"
      style={{
        display: `flex`,
        justifyContent: `space-between`,
        padding: `2rem`,
        position: `sticky`,
        top: `0`,
        zIndex: `1`,
        backgroundColor: `rgb(244, 246, 248)`,
        boxShadow: `5px -4px 8px 0px`
      }}
    >
      <h1 className="page-title" style={{padding: `0`, margin: `0`, marginTop: `3px`}}>
        <strong>Rubric: </strong>
        {_.get(form, `title`)}
      </h1>
      <div className="button-container">
        <Button
          size="large"
          onClick={() => history.replace(`/forms`)}
          style={{marginRight: `2.5rem`, ...buttonPadding}}
        >
          Cancel
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={async () => {
            await props.saveRubrics({rubrics, sections})
            history.replace(`/forms`)
          }}
          style={{...buttonPadding, marginRight: `1.5rem`}}
        >
          Save and Exit
        </Button>
      </div>
    </div>
  )
}

const buttonPadding = {paddingLeft: `5rem`, paddingRight: `5rem`}
