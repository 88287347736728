import React from "react"
import {Input, Icon, Tooltip} from "antd"
import PhotoRequired from "./PhotoRequired"
import {connect} from "react-redux"
import {setField} from "../../action_creators"

const stop = (state, ownProps) => ({field: state.fields[ownProps.id]})
const dtop = dispatch => ({setField: ({id, value}) => dispatch(setField(id, value))})

const TextAreaField = ({setField, field, id, question, label = ``, required, tipText, value = ``, photoRequired}) => {
  const set = e => setField({id, value: {...field, value: e.target.value}})

  return (
    <div>
      {required && (
        <div className="bold flex-row" >
          <Tooltip title="Required">
            <Icon type="exclamation-circle" style={{fontSize: "2rem", padding: "0 1rem 0 0"}} />
          </Tooltip>
          <div>REQUIRED</div>
        </div>
      )}
      <div className="bold padding-bottom-1">{question}</div>
      <div className="flex-column full-width align-start" >
        <div className="padding-right-1" style={{fontFamily: `Arial, serif`, fontSize: `85%`}}>
          {(label || ``).toUpperCase()}
        </div>
        <div className="full-width" >
          <Input.TextArea size="large" onChange={set} value={value} placeholder={tipText} />
        </div>
      </div>
      {photoRequired && (!field.images || Object.keys(field.images).length < 1) && <PhotoRequired />}
    </div>
  )
}

const WrappedTextAreaField = connect(stop, dtop)(TextAreaField)
export default WrappedTextAreaField