import React, {useState} from "react"
import PropTypes from "prop-types"
import {Icon, Modal, Checkbox} from "antd"
import FileUploader from "../FileHandling/FileUploader"

import {helperMessage, logoMessage} from "./utilities"

const AvatarPane = ({avatar, change, deleteAvatar, edit, user, done}) => {
  const [show, setShow] = useState(false)
  return (
    <div className="avatar-pane-container flex-row flex-align-center">
      <div className="flex-column">
        <img className="avatar full-width" src={avatar} alt="profile avatar" />
        <div className="flex-row">
          <div className="avatar-action-button flex-row flex-align-center" onClick={() => setShow(true)}>
            <Icon type="edit" />
            <div style={{padding: "0 0 0 1rem"}}>Change Photo</div>
          </div>
          <div className="avatar-action-button flex-row flex-align-center" onClick={deleteAvatar}>
            <Icon type="delete" />
            <div style={{padding: "0 0 0 1rem"}}>Delete Photo</div>
          </div>
        </div>
        <div className="flex-column">
          <h4 className="notify-button">Notify Me By</h4>
          {[
            ["notifyEmail", "Email"],
            ["notifyPhone", "Phone"]
          ].map(([item, label]) => (
            <Checkbox
              disabled={!edit}
              onChange={e => change(item)({target: {value: e.target.checked}})}
              checked={user[item]}
            >
              {label}
            </Checkbox>
          ))}
        </div>
        {user.type === "client" && (
          <FileUploader
            className="full-width"
            {...{helperMessage: logoMessage(user.logo), id: user.id, collection: "users", type: "logo"}}
          />
        )}
      </div>
      <Modal
        title="Upload a new Profile Photo"
        visible={show}
        onOk={() => setShow(false)}
        onCancel={() => setShow(false)}
      >
        <FileUploader {...{helperMessage, id: user.id, collection: "users", type: "avatar"}} />
      </Modal>
    </div>
  )
}

AvatarPane.propTypes = {
  avatar: PropTypes.object,
  addPhoto: PropTypes.func,
  deleteAvatar: PropTypes.func,
  edit: PropTypes.bool,
  user: PropTypes.object,
  show: PropTypes.func,
  done: PropTypes.func
}

export default AvatarPane
