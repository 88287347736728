import {message} from "antd"

import {firebase, db} from "./utilities"

export default class SystemLogger {
  constructor({path, uid}) {
    this.uid = uid
    this.category = ""
    this.categoryId = ""

    this._parsePath(path)
  }

  static subscriptions = {}

  static subscribe = uid => f => {
    if (!this.subscriptions[uid]) this.subscriptions[uid] = []
    this.subscriptions[uid].push(f)
  }
  static unsubscribe = uid => i => {
    this.subscriptions[uid].splice(i, 0)
  }

  /**
   * Severity is between 1 and 5, with 5 being the *least* severe.
   * By default, log severity will be least severe (5).
   * Provide a second parameter if you need a higher severity-level.
   */
  log = (message, extended = "", severity = 5, user_index = null) =>
    this._log({type: "log", message, extended, severity, user_index})
  /** See docs for the `log` method. */
  error = (message, extended = "", severity = 5, user_index = null) =>
    this._log({type: "error", message, extended, severity, user_index})
  /** See docs for the `log` method. */
  event = (message, extended = "", severity = 5, user_index = null) =>
    this._log({type: "event", message, extended, severity, user_index})

  projectLog = project => (message, extended = "", severity) => {
    this._log({
      type: "log",
      message,
      extended,
      severity,
      user_index: this.getProjectUsers(project)
    })
  }

  getProjectUsers = project => {
    const {
      installer,
      client,
      inspection: {
        inspector: {id: inspectorId = null},
        reviewer: {id: reviewerId = null}
      }
    } = project
    return {installer, client, inspector: inspectorId, reviewer: reviewerId}
  }

  _parsePath = path => {
    // I pull the blank and ...rest vars out of the path, just in case, but I never use them
    // 	the linter throws a warning if I don't exclude this line
    // eslint-disable-next-line
    const [blank, category, categoryId = "", ...rest] = path ? path.split("/") : ["", "", "", [""]]

    this.category = category
    this.categoryId = categoryId
  }

  _log = async resource => {
    // Temporary hack for allieviating issue where messages aren't showing when
    // a `uid` isn't present.
    if (!this.uid) {
      // console.log('no UID present')

      message.destroy() // Clear existing messages.
      if (resource.type === "error") {
        message.error(resource.message)
      } else {
        message.info(resource.message)
      }
      return // Exit the function; the lack of a `uid` will prevent things from working correctly.
    }

    if (resource.user_index) {
      Object.entries(this.constructor.subscriptions).forEach(([id, ff]) => {
        if (resource.user_index[id]) ff.map(f => f(resource.message))
      })
    } else {
      Object.values(this.constructor.subscriptions).forEach(ff => ff.forEach(f => f(resource.message)))
    }

    const docRef = db.collection(this.category === "projects" ? "projectTransactionLog" : "transactionLog").doc()
    const {uid, category, categoryId} = this
    const data = {
      ...resource,
      id: docRef.id,
      creator: uid,
      created: firebase.firestore.Timestamp.now(),
      category,
      categoryId,
      seen: false
    }

    try {
      if (data.category === "projects" && categoryId) {
        try {
          const projSnap = await db
            .collection("projects")
            .doc(categoryId)
            .get()
          const project = projSnap.data()
          const {siteAddress, siteOwnerName} = project.site
          data.site = {siteAddress, siteOwnerName}
        }
        catch (e) {
          console.log('failed to get project site information in SystemLogger', e)
        }
        finally {
          await db
            .collection("projects")
            .doc(data.categoryId)
            .collection("history")
            .doc(data.id)
            .set(data, {merge: true})
            
          await docRef.set(data, {merge: true})
        }
      }
    } catch (e) {
      console.log("there was an error creating a log entry: ", e, "while attempting to create: ", data)
    }
  }
}
