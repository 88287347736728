import React from "react"
import {Button, Icon, Input, Select, Modal, Checkbox} from "antd"

export const capitalizationMap = {
  name: "Full Name",
  type: "User Type",
  email: "Email",
  phone: "Phone",
  address: "Address",
  city: "City",
  state: "State",
  zip: "Zip",
  nabcep_ids: "NABCEP Ids"
}

export const helperMessage = () => (
  <div style={{display: "flex", flexFlow: "row nowrap", alignItems: "center"}}>
    <Icon type="arrow-up" />
    <div style={{padding: "0 0 0 1rem"}}>Drop an image here to make it your profile pic</div>
  </div>
)

export const logoMessage = (logo = {}) => () => (
  <div style={{width: "100%"}}>
    <div>Drop a PNG image here to replace your current company logo</div>
    <div style={{fontSize: 14}}>Your logo should be landscape-oriented, and will work best with a ratio of 3:2</div>
    <div style={{display: "flex", flexFlow: "row nowrap", alignItems: "center", justifyContent: "center"}}>
      <img style={{width: "100%"}} src={logo.url} />
    </div>
  </div>
)
