import React from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {Form, Popconfirm, Divider, Input, Button, Row, Col} from "antd" //	Card, Icon, Select

import DeleteButton from "../DeleteButton"
import {deleteResource, setField, setResources} from "./action_creators"

const mapStateToProps = (state, ownProps) => ({
  section: state.form.sections[ownProps.id],
  form: state.form.base
})

const mapDispatchToProps = dispatch => ({
  del: form => id => {
    const order = form.order
    order.splice(id, 1)
    dispatch(setResources({category: `base`, values: Object.assign({}, form, {order})}))
    dispatch(deleteResource({id, category: `sections`}))
  },
  setField: ({id, value}) => dispatch(setField({category: `sections`, id, value}))
})

const EditingSection = ({edit, form, section, del, setField}) => {
  return (
    <div>
      <b>Title</b>
      <Form.Item>
        <Input
          autoFocus
          style={{marginBottom: `2rem`}}
          size="large"
          onChange={e => setField({id: section.id, value: Object.assign({}, section, {title: e.target.value})})}
          value={section ? section.title : `Untitled Section`}
        />
      </Form.Item>
      <Row>
        <Col span={20}>
          <Button onClick={() => edit(false)}>Done</Button>
        </Col>
        <Col span={4} style={{textAlign: `right`}}>
          <DeleteButton
            onConfirm={() => del(form)(section.id)}
            popconfirmProps={{placement: `left`}}
            confirmationDisplay="Are you sure you want to delete this section and all of its fields?"
          >
            Delete
          </DeleteButton>
        </Col>
      </Row>
      <Divider />
    </div>
  )
}

EditingSection.propTypes = {
  del: PropTypes.func,
  edit: PropTypes.func,
  section: PropTypes.object,
  setField: PropTypes.func
}

const WrappedEditingSection = connect(mapStateToProps, mapDispatchToProps)(EditingSection)

export default WrappedEditingSection
