import React from "react"
// import {Icon} from "antd"
// import {textFilter, dateFilter} from "../../tableFilters" //	textSorter, numberSorter,

function renderStatus(status) {
  const color = {
    Submitted: `#6DBE44`,
    Rejected: `#912240`,
    Incomplete: `#212B36`,
  }[status]

  return <div style={{color}}>{status}</div>
}

function renderPriority(priority) {
  if (priority === `Urgent`) return <span style={{color: `red`}}>Urgent</span>
  else if (priority === `Normal`) return `—`
}


export default function columnsMap () {
  return [
    {dataIndex: `title`, title: `Title`, sorter: (a, b) => (a.title || ``).localeCompare(b.title || ``)},
    {
      dataIndex: `site.geocode_results.formatted_address`, 
      title: `Address`, 
      sorter: (a, b) => 
        (a.site.geocode_results.formatted_address || ``).localeCompare(b.site.geocode_results.formatted_address || ``)
    },
    {
      dataIndex: `assignee`,
      title: `Assignee`,
      render: (text, resource) => {
        if (!text && resource.otherName) return resource.otherName
        if (text) { return (resource.watcherMap[text] || {}).name }
        return 'No Assignee'
      },
      sorter: (a, b) => (a.assignee || ``).localeCompare(b.assignee || ``),
      // filters: createAssigneeFilters(data, usersById),
      onFilter: (value, record) => {
        if (record.assignee === value) { return true } 
        else if (!record.assignee && value === `none`) { return true }
      },
    },
    {
      dataIndex: `status`,
      title: `Status`,
      render: (text) => renderStatus(text),
      sorter: (a, b) => (a.status || ``).localeCompare(b.status || ``),
    },
    {
      dataIndex: `created`,
      title: `Created`,
      render: (text) => {
        return text ? text.toDate().toDateString() : null
      },
      sorter: (a, b) => a.created.toDate().getTime() - b.created.toDate().getTime(),
    },
    {
      dataIndex: `priority`,
      title: `Urgent`,
      render: (text) => renderPriority(text),
      sorter: (a, b) => (a.priority || ``).localeCompare(b.priority || ``),
      defaultSortOrder: `descend`,
    },
  ]
}
