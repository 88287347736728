import React, {useState, useEffect} from "react"
import {Icon, Button} from "antd"
import {firebaseConfig, FUNCTIONS_URL} from "../../config"
import {auth} from "../../Auth"
import {checkDev} from "../../effects"

export default function SaveReport({id, status, report, site, intake}) {
  const fullPath = report?.fullPath
  const className = `project-report ${fullPath ? "color-primary" : "color-quaternary"}`
  const [disabled, setDisabled] = useState(false)
  const [dev, setDev] = useState(false)

  checkDev(setDev)

  let url
  if (fullPath) {
    const corrected = fullPath.replace(/\//g, "%2F").replace(/,/g, "%2C").replace(/#/g, "%23")
    url = `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/${corrected}?alt=media`
  }

  async function generateReport() {
    setDisabled(true)

    const r = await (await fetch(`${FUNCTIONS_URL}/utilities/reportmaker/${id}`)).text()
    if (r !== "success") {
      console.log(r)
      return
    }

    setTimeout(() => setDisabled(false), 10000)
  }

  return (
    <div>
      {(dev ||
        (status === "Completed" &&
          ["admin", "super-admin"].includes(auth.sunkaizenUser.type) &&
          !Object.keys(report || {}).length)) && (
        <Button disabled={disabled} onClick={generateReport}>
          {dev ? "Regenerate Report" : "Generate a Missing Report"}
        </Button>
      )}
      <a
        className={className}
        style={{pointerEvents: report?.fullPath ? `auto` : `none`}}
        href={url}
        download={`${site.siteOwnerName} ${intake.title} Report.pdf`}
      >
        <Icon className="padding-right-1" type="project" />
        Project Report
      </a>
    </div>
  )
}
