import React from "react"

export default function StatusDisplay ({statuses}) {
  return (
    <div className="background-white padding-1 flex-column align-center justify-center" >
      {!!Object.keys(statuses).length && (
        <div>
          {Object.entries(statuses).map(([k, v], i) => 
            v.progress < 100 
              ?   (
                <div key={i} className="color-white padding-1 flex-row" style={{background: "#BD943F"}} >
                  <div className="padding-right-1" >{k}</div>
                  <div>{Math.round(v.progress)}</div>
                </div>
              )
              : null
          )}
        </div>
      )}
    </div>        
  )
}