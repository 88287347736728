import React from 'react'

export default function NotScheduledFormattingWrapper ({children}) {
  return (
    <div className="flex-row justify-center align-center padding-2" style={{flex: 1, border: "0.1rem dashed #C4CDD5"}} >
      <div className="flex-column align-center" >
        {children}
      </div>
    </div>
  )
}