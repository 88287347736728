import React, {useReducer, useEffect} from "react"
import {convertPaths} from './utilities'

function reducer (state, action) {
  switch (action.type) {
  case 'results': return {...state, results: {...state.results, [action.name]: action.value}}
  case 'readCall': return {...state, readCall: action.value}
  case 'clear':
    const results = {...state.results}
    delete results[action.value]
    return {...state, results}
  default: return state
  }
}

// wrapped component calls readDB with a DBQuery (see utilities in this folder) and watches readResults[name] for the return
export const read = () => Component => {
  function ReadHOC ({childRef, ...props}) { 
    const [state, dispatch] = useReducer(reducer, {results: {}, readCall: [{}, null]})

    useEffect(function () { for (let read of (props.initialDbReads || [])) {readDB(read)} }, [props.initialDbReads])
    useEffect(function () {
      (async function () {
        const [{paths, options}, name] = state.readCall
        if (!name) return

        const {resource, doc} = convertPaths(paths)
        if (options) options.filters = (options.where || []).map(({field, test, value}) => [field, test, value])
        
        const {ok, data: value} = await resource.get(doc, options)
        if (ok) dispatch({type: 'results', name, value})
        // else systemLog.notify(`Error reading db resource: ${[name, paths, options]}`)
      })()
    }, [state.readCall])

    function readDB (...readCall) { dispatch({type: 'readCall', value: readCall}) }

    return <Component ref={childRef} {...props} readDB={readDB} readResults={state.results} />
  }

  return React.forwardRef((props, ref) => <ReadHOC {...props} childRef={ref} />)
}