import React, {useState, useEffect} from "react"
import {auth} from '../Auth'
import {Button} from "antd"
import DB from '../DAL/DB'
import {loggerEffect} from '../effects'
import {firebase} from "../utilities"
import SiteNote from './SiteNote'

const projects = new DB.Projects()

// deleteSiteNote, addSiteNote, changeSiteNote, 
export default function SiteNotes ({resource = {}}) {
  const [logger, setLogger] = useState(null)
  const [notes, setNotes] = useState([])

  useEffect(() => { 
    if (!Array.isArray(resource.siteNotes || [])) throw new Error(`project ${resource.id} has an invalid site notes format`)
    if (resource.siteNotes) setNotes(resource.siteNotes) 
  }, [resource.siteNotes])
  useEffect(loggerEffect(setLogger), [])

  async function addSiteNote () {
    const {currentUser: {displayName: author, uid: authorId}} = auth
    const newSiteNote = {created: firebase.firestore.Timestamp.now(), note: ``, author, authorId}

    try {
      await projects.update(resource.id, null, {siteNotes: [...(resource.siteNotes || []), newSiteNote]})
      logger.event(`${author} added a site note`)
    }
    catch (e) { console.log(`error adding a site note`, e) }
  }

  function deleteSiteNote (i) {
    const siteNotes = [...resource.siteNotes]
    siteNotes.splice(i, 1)
    projects.update(resource.id, null, {siteNotes})
  }

  async function changeSiteNote () { await projects.update(resource.id, null, {siteNotes: notes}) }

  console.log(notes)

  return (
    <div className="flex-column align-start background-white padding-2 margin-bottom-1" style={{fontAlign: `left`}} >
      <h4>Notes</h4>
      <div className="full-width flex-column align-start padding-1" style={{background: `rgba(249, 250, 251, 0.7)`}} >
        <div className="full-width" style={{padding: `0 0 0 0.5rem`}}>
          {console.log(notes)}
          {notes.map((note, i) => <SiteNote {...{logger, i, deleteSiteNote, changeSiteNote, setNotes, notes, ...note}} />)}
        </div>
        <Button onClick={addSiteNote}>Add Note</Button>
      </div>
    </div>
  )
}