import {storage, db} from "../utilities"

/**
 * Takes in the index of the file to be deleted, removes that index from the corresponding file arrays on the respective docs
 * and moves the file from its current location to a 'deletedFiles' folder.
 */
export const deleteFile = (collection, id, fileIndex) => {
  db.collection(collection)
    .doc(id)
    .get()
    .then(docSnap => {
      //Remove the current index from the collection
      let docFiles = docSnap.data().intake.files
      let removedFile = docFiles.splice(fileIndex, 1)[0]

      console.log(`removedFile`, removedFile)
      //Move the file from it's corresponding location to the "deleted" directory
      // To do this we have to actually re-upload the file.
      moveFirebaseFile(removedFile.fullPath, `deleted/${removedFile.fullPath}`)

      let oldRef = storage.ref().child(removedFile.fullPath)
      oldRef.getDownloadURL().then(url => {
        fetch(url).then(htmlReturn => {
          let fileArray = new Uint8Array()
          const reader = htmlReturn.body.getReader()

          //get the reader that reads the readable stream of data
          reader
            .read()
            .then(function appendStreamChunk({done, value}) {
              //If the reader doesn't return "done = true" append the chunk that was returned to us
              // rinse and repeat until it is done.
              if (value) {
                fileArray = mergeTypedArrays(fileArray, value)
              }
              if (done) {
                console.log(fileArray)
                return fileArray
              } else {
                // "Readout not complete, reading next chunk"
                return reader.read().then(appendStreamChunk)
              }
            })
            .then(file => {
              //Write the file to the new storage place
              storage
                .ref()
                .child(`deleted/` + removedFile.fullPath)
                .put(file)
              //Remove the old reference
              oldRef.delete()
            })
        })
      })

      let updateDoc = {files: docFiles}

      if (collection == `projects`) {
        let project = docSnap.data()
        const {intake} = project

        //Remove the file reference from the project's intake object in all cases
        intake.files.splice(fileIndex, 1)

        // If the file we're removing has a section and field specified, then remove the reference
        //  from the corresponding section of the intake as well
        if (removedFile.section && removedFile.field) {
          const {fields} = intake.sections[removedFile.section]

          const [{fieldIndex}] = fields
            .map((field, i) => (field.id === removedFile.field ? {fieldIndex: i} : false))
            .filter(i => i)

          const {fieldFileIndex} = fields[fieldIndex].files
            .map((f, i) => (f.id === removedFile.id ? {fieldFileIndex: i} : false))
            .filter(i => i)

          intake.sections[removedFile.section].fields[fieldIndex].files.splice(fieldFileIndex, 1)
        }

        updateDoc.intake = intake
      }

      db.collection(collection)
        .doc(id)
        .set(updateDoc, {merge: true})
    })
}

/** Takes two typed arrays and concatenates them.
 *  A nice man wrote this on the internet
 *  https://stackoverflow.com/questions/14071463/how-can-i-merge-typedarrays-in-javascript/35633935#35633935
 */
function mergeTypedArrays(a, b) {
  // Checks for truthy values on both arrays
  if (!a && !b) throw `Please specify valid arguments for parameters a and b.`
  // Checks for truthy values or empty arrays on each argument
  // to avoid the unnecessary construction of a new array and
  // the type comparison
  if (!b || b.length === 0) return a
  if (!a || a.length === 0) return b
  // Make sure that both typed arrays are of the same type
  if (Object.prototype.toString.call(a) !== Object.prototype.toString.call(b))
    throw `The types of the two arguments passed for parameters a and b do not match.`
  var c = new a.constructor(a.length + b.length)
  c.set(a)
  c.set(b, a.length)
  return c
}

/**
 * Moves a file in firebase storage from its current location to the destination
 * returns the status object for the moved file.
 * @param {String} currentPath The path to the existing file from storage root
 * @param {String} destinationPath The desired pathe for the existing file after storage
 */
function moveFirebaseFile(currentPath, destinationPath) {
  let oldRef = storage.ref().child(currentPath)

  oldRef.getDownloadURL().then(url => {
    fetch(url).then(htmlReturn => {
      let fileArray = new Uint8Array()
      const reader = htmlReturn.body.getReader()

      //get the reader that reads the readable stream of data
      reader
        .read()
        .then(function appendStreamChunk({done, value}) {
          //If the reader doesn't return "done = true" append the chunk that was returned to us
          // rinse and repeat until it is done.
          if (value) {
            fileArray = mergeTypedArrays(fileArray, value)
          }
          if (done) {
            console.log(fileArray)
            return fileArray
          } else {
            // "Readout not complete, reading next chunk"
            return reader.read().then(appendStreamChunk)
          }
        })
        .then(file => {
          //Write the file to the new storage place
          let status = storage
            .ref()
            .child(destinationPath)
            .put(file)
          //Remove the old reference
          oldRef.delete()

          return status
        })
    })
  })
}
