import React from 'react'
import {Droppable} from "react-beautiful-dnd"
import {Images} from './Images'

export function DropTarget ({project, fields, sortedImages, fullsize}) {
  return (
    <div>
      <Droppable droppableId={project.id || `pool`}>
        {(provided) => (
          <div ref={provided.innerRef} className="padding-1 flex-row justify-start" >
            {project.id && fields && (
              <Images images={sortedImages} project={project} fullsize={fullsize} />
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {fields &&
                fields[project.id] &&
                fields[project.id].images &&
                fields[project.id].images.length < 1 && (
        <div className="padding-2" >This intake has no images</div>
      )}
    </div>        
  )
}