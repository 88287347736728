import {auth} from "../../../Auth"
import {FUNCTIONS_URL} from '../../../config'

export default async function emailReport (report, emails, closeModal) {
  //split emails (string) into an array
  let reportEmails = emails
    .toLowerCase()
    .replace(/\s+/g, "")
    .split(/[,;]/)

  let invalidEmails = []
  reportEmails.forEach(email => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) { invalidEmails.push(email) }
  })

  if (invalidEmails.length > 0) {
    throw new Error(`The following emails are invalid: ${invalidEmails.toString()}`)
  }

  if (reportEmails.length > 10) {
    throw new Error(`A report can only be sent to 10 recipents at a time.  You supplied ${reportEmails.length}`)
  }

  return fetch(`${FUNCTIONS_URL}/utilities/send_report`, {
    method: "POST",
    mode: "cors",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify({
      emails: reportEmails,
      report,
      uid: auth.sunkaizenUser.id
    })
  })
    .then(response => response.text())
    .then(text => {
      closeModal()
      console.log("Send Report return", text)
    })
}