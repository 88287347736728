export const del = async id => {
  console.log(id)
}

// try {
//   const sections = await db
//     .collection("forms")
//     .doc(id)
//     .collection("sections")
//     .get()
//   if (!sections.empty) {
//     const pp = [].concat(
//       sections.docs.map(section => {
//           return section.data().order
//             ? section.data().order.map(id =>
//                 section.ref.collection("fields").doc(id).delete()
//               )
//             : null
//         })
//         .filter(i => i)
//     )
//     pp.concat(sections.docs.map(section => section.ref.delete()))
//     await Promise.all(pp)
//   }
//   await db
//     .collection("forms")
//     .doc(id)
//     .delete()

//   store.dispatch(pruneResource(id))
// }
// catch (e) {
//   console.log('failed to delete a form', e)
// }
// finally {
//   console.log('successfully deleted form', id)
// }