import ProjectDetails from './ProjectDetails'

import StaticMap from './StaticMap'
import Header from './Header'
import TypeRubric from './TypeRubric'
import MiscInfo from './MiscInfo'
import IntakeAndSnapshots from './IntakeAndSnapshots'
import SaveReport from './SaveReport'
import DownloadImages from './DownloadImages'
import CancelProjectButton from './CancelProjectButton'
import CustomPDFReport from './CustomPDFReport'
import InstallerViewable from './InstallerViewable'
import AddTicket from './AddTicket'

export {
  StaticMap,
  Header,
  TypeRubric,
  MiscInfo,
  IntakeAndSnapshots,
  SaveReport,
  DownloadImages,
  CancelProjectButton,
  CustomPDFReport,
  InstallerViewable,
  AddTicket
}
export default ProjectDetails