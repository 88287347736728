import React from "react"
import {db} from "../utilities" //	capitalizeFirst, , firebase
import {generateProjectShell} from "./utilities"
import {resourceType, validateResource} from "../validators"
import SystemLogger from "../SystemLogger"
import FileUploader from "../FileHandling/FileUploader"
import ProjectType from "./ProjectType"
import ClientInformation from "./ClientInformation"
import Auth from "../Auth"

import {
  addTipText,
  geocodeSites,
  makeBatches,
  makeCSVTemplate,
  makeResources,
  readUploadedText,
  SiteInformation,
  validateHeaders
} from "./bulkUtilities"

import {connect} from "react-redux"
import {blankFields, setInitialFields, populatePermissions} from "./action_creators"

const auth = new Auth()

const sToP = store => ({
  fields: store.fields
})

const dToP = dispatch => ({
  blankFields: () => dispatch(blankFields()),
  setInitialFields: fields => dispatch(setInitialFields(fields)),
  populatePermissions: type => permissions => dispatch(populatePermissions(type, permissions))
})

class BulkProjectImport extends React.Component {
  state = {
    sites: [],
    fields: {},
    forms: {},
    clients: {},
    // permissionFilters: { forms: {}, clients: {} },
    // answers: null,
    csvTemplate: null,
    csvOrder: null,
    loading: false
  }

  componentDidMount = async () => {
    this.logger = new SystemLogger({uid: auth.currentUser.uid})

    this.getUsers()
    this.getForms()
    await this.loaded()
  }

  componentDidUpdate = async pprops => {
    console.log(this.props.fields)
    if (
      this.props.fields.projectType && 
      (
        (!pprops.fields.projectType && this.props.fields.projectType) ||
        pprops.fields.projectType !== this.props.fields.projectType
      )
    ) {
      await this.setState({loading: true})

      try {
        const formSnap = await db
          .collection("meta")
          .doc("form_csv")
          .collection("forms")
          .doc(this.props.fields.projectType.id)
          .get()
        const {order: csvOrder, template: csvTemplate} = formSnap.data()
        await this.setState({csvOrder, csvTemplate, loading: false})
      } catch (e) {
        console.log("problem getting CSV order and template ", e)
        await this.setState({loading: false})
      }
    }
  }

  componentWillUnmount = () => {
    // if (this.state.subscriptions.length > 0) this.state.subscriptions.map(subscription => subscription())
    this.props.blankFields()
  }

  getUsers = () => {
    const subscription = db
      .collection("users")
      .where("type", "==", "client")
      .onSnapshot(snapshot => {
        const clients = {}
        snapshot.docs.forEach(doc => {
          clients[doc.id] = doc.data()
        })
        this.props.populatePermissions("clients")(clients)
      })
    this.setState({
      subscriptions: [].concat(this.state.subscriptions, subscription)
    })
  }

  getForms = () => {
    const subscription = db
      .collection("forms")
      .where("active", "==", true)
      .onSnapshot(snapshot => {
        const forms = {}
        snapshot.docs.forEach(doc => (forms[doc.id] = {...doc.data(), id: doc.id}))
        this.props.populatePermissions("forms")(forms)
      })
    this.setState({
      subscriptions: [].concat(this.state.subscriptions, subscription)
    })
  }

  upload = async files => {
    if (!files) return

    let promises = files.map(f => readUploadedText(f))
    let csvArray = await Promise.all(promises)

    let [file] = csvArray
    if (!file) return

    let header = file.shift()
    file = file.filter(f => f.some(l => l.length > 0))

    if (!validateHeaders(header, this.state.csvTemplate)) {
      console.log("there's something wrong with the header; canceling", header)
      return
    }

    try {
      const sites = await geocodeSites(file)
      // console.log(pp)
      // const sites = await Promise.all(pp)

      sites.forEach((site, i) => {
        site.tipText = file[i]
      })
      this.setState({sites})
    } catch (e) {
      console.log("could not geocode all the sites", e)
      this.logger.log(e.message)
    }
  }

  setLoaded = async e => {
    if (e.key === "googleMapsLoaded") {
      if (e.newValue === "true") {
        console.log("google maps is loaded")
        this.setState({loaded: true})
        this.geocoder = new window.google.maps.Geocoder()
      }
    }
  }

  async loaded() {
    if (window.google && window.google.maps) {
      console.log("google maps is already loaded")
      window.removeEventListener("storage", this.setLoaded)
      await this.setState({loaded: true})
      this.geocoder = new window.google.maps.Geocoder()
    } else {
      console.log("google maps is not yet loaded, listening")
      window.addEventListener("storage", this.setLoaded)
    }
  }

  finalize = async () => {
    const {sites} = this.state
    const {fields} = this.props
    const {client, projectType, rubric = {}} = fields

    const intake = await generateProjectShell(projectType)
    // const addAnswers = applyAnswers(answers)

    let dbOps = sites.map(async ss => {
      const projRef = db.collection("projects").doc()
      const siteRef = db.collection("sites").doc()

      return makeResources(ss)(siteRef)(projRef)
        .then(([site, project]) => {
          project.projectType = intake.title
          const ii = {...intake, sections: JSON.parse(JSON.stringify(intake.sections))}
          project = {...project, intake: ii, client, rubric}
          project.errors = validateResource(project)(resourceType["project"])

          project = addTipText(project, ss, this.state.csvOrder)

          return [
            {ref: projRef, data: project},
            {ref: siteRef, data: {...site}}
          ]
        })
    })

    const r = await Promise.all(dbOps)
    dbOps = [].concat(...r)
    const batchJobs = makeBatches(dbOps)

    try {
      const results = await Promise.all(batchJobs.map(batch => batch.commit()))

      if (results.every(r => !r)) {
        this.logger.event(
          "successfully created bulk projects",
          `inserted ${dbOps.length} resources into the database`,
          3
        )
        this.setState({sites: []})
        this.props.blankFields()
      } else {
        throw new Error("failed to commit all the db objects")
      }
    } catch (e) {
      this.logger.error("failed to commit all sites and projects to the database", JSON.stringify(e, null, 2), 5)
      console.log(e)
    }
  }

  render = () => {
    const {sites, csvTemplate} = this.state
    const {setField, upload} = this
    const {fields} = this.props

    return (
      <div style={{height: "100%"}}>
        <ProjectType />
        <ClientInformation />
        <FileUploader
          submit={upload}
          collection = "none"
          id = "none"
          type = "csvUpload"
          helperMessage={
            fields.projectType && csvTemplate
              ? () =>
                makeCSVTemplate({
                  type: fields.projectType,
                  template: csvTemplate
                })
              : () => <div>Please Select a Project Type</div>
          }
        />
        {sites.length > 0 && <SiteInformation sites={sites} />}
        {/* <AnswerUploader setAnswers={ this.setAnswers } /> */}
      </div>
    )
  }
}

const WrappedBulkProjectImport = connect(sToP, dToP, null, {
  forwardRef: true
})(BulkProjectImport)

export default WrappedBulkProjectImport
