import React from "react"
// import PropTypes from 'prop-types'

import {Table} from "antd"
// import Plotly from 'plotly.js-basic-dist'
import Plotly from "plotly.js-geo-dist"

export default class InspectionQualityByRegion extends React.Component {
  // propTypes = {
  // 	options: PropTypes.object,
  // 	layout: PropTypes.object
  // }

  componentDidMount = async () => {
    this.chartRef = React.createRef()

    // the table has a region,
    // 	each region has a quality score,
    // 	which is an average of the quality scores of the installation counts
    // 	the object keys need to be states, the values need to be an array of installations
    const states = {}
    this.props.projects.forEach(project => {
      const {address_components} = project.site.geocode_results
      const [state] = address_components
        .map(b => (b.types.includes("administrative_area_level_1") ? b.short_name : false))
        .filter(i => i)

      states[state] = [].concat(states[state], project).filter(i => i)
    })

    const data = Object.entries(states).map(([state, projects]) => {
      const count = projects.length
      const quality = projects.reduce((a, q) => (a += q), 0) / count

      return {state, count, quality}
    })

    data.sort((a, b) => {
      if (a.quality === b.quality) return 0
      if (a.quality > b.quality) return 1
      else return -1
    })

    const columns = [
      {
        title: "Region",
        dataIndex: "state",
        key: "state"
      },
      {
        title: "Quality Score",
        dataIndex: "quality",
        key: "quality"
      },
      {
        title: "Installation Count",
        dataIndex: "count",
        key: "count"
      }
    ]

    await this.setState({data, columns})

    const locations = {}
    this.props.projects.forEach(project => {
      const {address_components} = project.site.geocode_results
      const [state] = address_components
        .map(b => (b.types.includes("administrative_area_level_1") ? b.short_name : false))
        .filter(i => i)

      console.log(state)
      locations[state] = locations[state] + 1 || 1
    })

    const dd = [
      {
        type: "choropleth",
        locationmode: "USA-states",
        locations: Object.keys(locations),
        z: Object.values(locations),
        text: Object.keys(locations),
        autocolorscale: true
      }
    ]

    const layout = {
      title: "Inspection Quality by Region",
      height: 400,
      width: 400,
      margin: {l: 0, t: 100, r: 0, b: 50, pad: 0},
      geo: {
        scope: "usa",
        countrycolor: "rgb(255, 255, 255)",
        showland: true,
        landcolor: "rgb(217, 217, 217)",
        showlakes: true,
        lakecolor: "rgb(255, 255, 255)",
        subunitcolor: "rgb(255, 255, 255)",
        lonaxis: {},
        lataxis: {}
      }
    }

    Plotly.newPlot(this.chartRef.current, dd, layout)
  }

  state = {columns: [], data: []}

  render = () => (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        background: "white",
        border: "0.1rem solid lightgrey",
        width: "100%",
        height: "100%",
        flex: 1
      }}
    >
      <div ref={this.chartRef}></div>
      <div>
        <h4>Highest Regions</h4>
        <Table columns={this.state.columns} data={this.state.data.slice(0, 3)} />
      </div>
      {this.state.data.length > 5 && (
        <div>
          <h4>Lowest Regions</h4>
          <Table columns={this.state.columns} data={this.state.data.slice(this.state.data.length - 3)} />
        </div>
      )}
    </div>
  )
}
