import React from 'react'
import {Link} from "react-router-dom"
import {db} from "../../utilities"
import Scheduled from "../Scheduled"
import {Button} from "antd"

// document on the different project statuses
// https://docs.google.com/spreadsheets/d/1fcQtpHxSCnuYuueP7NXcQGJ9Vep_XFVW1Cp9J_-joDU/edit#gid=0
const statusMap = {
  "To Be Scheduled": () => <div></div>,
  Scheduled: Scheduled,
  "In Progress": ({
    project: {
      intake: {id}
    }
  }) => <Link to={`/intake/${id}`}></Link>,
  Review: ({
    project: {
      intake: {id}
    }
  }) => <Link to={`/review/${id}`}></Link>,
  Corrections: ({
    project: {
      intake: {id}
    }
  }) => <Link to={`/correct/${id}`}></Link>,
  Completed: () => <div>Completed</div>,
  Canceled: ({project: {id}}) => {
    const reactivate = id =>
      db
        .collection("projects")
        .doc(id)
        .set({status: "To Be Scheduled"}, {merge: true})
    return <Button onClick={() => reactivate(id)}>Reactivate Project</Button>
  }
}

export default function ButtonRenderer ({project}) {
  const C = statusMap[project.status] || statusMap["To Be Scheduled"]
  return C ? <C {...{project}} /> : <div></div>
}