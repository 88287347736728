import React from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {Button, Popconfirm, Input, Icon, Row, Col} from "antd"

import DeleteButton from "../DeleteButton"
import EditingSection from "./EditingSection"
import {db} from "../utilities"
import {addResource, setResources, setActive, statusUpdate, setField, mergeField} from "./action_creators" //	deleteResource,

const mapStateToProps = state => ({
  all: state.form,
  form: state.form.base,
  sections: state.form.sections,
  section: state.active.section,
  status: state.status.section
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  add: id => {
    const resource = {id, body: {}, category: "sections"}
    dispatch(addResource(resource))
  },
  setResources: values => dispatch(setResources({type: "sections", values})),
  // del: id => dispatch(deleteResource({ id, category: "sections" })),
  active: async ({section}) => {
    const fieldId = section.order ? section.order[0] : ""
    await dispatch(setActive({type: "section", id: section.id}))
    dispatch(setActive({type: "field", id: fieldId}))
  },
  statusUpdate: bool => dispatch(statusUpdate({category: "section", bool})),
  setField: value => dispatch(setField({category: "sections", id: ownProps.id, value})),
  mergeField: (key, value) => dispatch(mergeField({category: "sections", id: ownProps.id, key, value}))
})

class SectionItem extends React.Component {
  static propTypes = {
    del: PropTypes.func,
    active: PropTypes.func,
    section: PropTypes.string,
    id: PropTypes.string,
    setField: PropTypes.func,
    mergeField: PropTypes.func
  }

  state = {hovering: false, editing: false, editingSectionTitle: false}

  hovering = hovering => this.setState({hovering})

  saveTitle = async () => {
    try {
      await db
        .collection("forms")
        .doc(this.props.form.id)
        .collection("sections")
        .doc(this.props.section)
        .set(this.props.sections[this.props.section], {merge: true})      
    }
    catch (e) { 
      console.log(e) 
      throw e
    }
  }

  toggleEditingSectionTitle = () => {
    this.setState(state => ({editingSectionTitle: !state.editingSectionTitle}))
  }

  render = () => (
    <div>
      {this.props.sections[this.props.id] && Object.keys(this.props.sections[this.props.id]).length > 0 && (
        <div
          className={this.props.section === this.props.id ? "sectionItem sectionItemSelected" : "sectionItem"}
          onMouseEnter={() => this.hovering(true)}
          onMouseLeave={() => this.hovering(false)}
          onClick={() => this.props.active({section: this.props.sections[this.props.id]})}
        >
          <Row style={{display: "flex", alignItems: "center", flexFlow: "row nowrap"}}>
            <Col
              span={18}
              style={{display: "flex", flexFlow: "row nowrap", alignItems: "center", padding: "0 1rem 0 0"}}
            >
              {this.props.section === this.props.id && <Icon type="right-circle" style={{padding: "0 1rem 0 0"}} />}
              <Input
                disabled={!this.state.editingSectionTitle}
                autoFocus={this.props.section === this.props.id}
                style={{
                  backgroundColor: "rgba(248, 248, 248, 1)",
                  padding: "0 1rem 0 1rem",
                  color: this.state.editingSectionTitle ? "inherit" : "rgba(0, 0, 0, 0.55)",
                  cursor: "pointer"
                }}
                value={this.props.sections[this.props.id] ? this.props.sections[this.props.id].title : null}
                onChange={e => this.props.mergeField("title", e.target.value)}
                size="large"
                onBlur={this.saveTitle}
              />
            </Col>
            <Col span={6} style={{textAlign: "right", padding: "0 1rem 0 0"}}>
              {this.state.hovering && (
                <Row>
                  {!this.state.editingSectionTitle && <Button onClick={this.toggleEditingSectionTitle}>Edit</Button>}
                  {this.state.editingSectionTitle && <Button onClick={this.toggleEditingSectionTitle}>Save</Button>}
                  <DeleteButton
                    type="secondary"
                    onConfirm={() => this.props.del(this.props.id)}
                    confirmationDisplay="Are you sure you want to delete this section and all of its fields?"
                    popconfirmProps={{placement: "left"}}
                    style={{marginLeft: "0.5rem"}}
                  >
                    Delete
                  </DeleteButton>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      )}
      {this.state.editing && <EditingSection edit={this.edit} id={this.props.id} />}
    </div>
  )
}

const WrappedSectionItem = connect(mapStateToProps, mapDispatchToProps)(SectionItem)

export default WrappedSectionItem
