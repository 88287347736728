import React from "react"
import PropTypes from "prop-types"

import SingleInput from "./SingleInput"
import TextAreaField from "./TextAreaField"
import RadioButton from "./RadioButton"
import Dropdown from "./Dropdown"
import Checkboxes from "./Checkboxes"
import Multiselect from "./Multiselect"
import DateInput from "./DateInput"
import TimeInput from "./TimeInput"
import NumericInput from "./NumericInput"
import FileUpload from "./FileUpload"
import Templated from "./Templated"
import {Icon, Tooltip} from "antd" //	Input, Checkbox,
import PhotoRequired from "./PhotoRequired"
import {connect} from "react-redux"

const components = [
  SingleInput,
  NumericInput,
  TextAreaField,
  RadioButton,
  Dropdown,
  Checkboxes,
  Multiselect,
  DateInput,
  TimeInput,
  FileUpload,
  Templated
]

const componentNameMapping = {
  "single-input": SingleInput,
  "numeric-input": NumericInput,
  "text-area-field": TextAreaField,
  "radio-button": RadioButton,
  dropdown: Dropdown,
  checkboxes: Checkboxes,
  multiselect: Multiselect,
  "date-input": DateInput,
  "time-input": TimeInput,
  "file-upload": FileUpload,
  templated: Templated
}

const componentDisplayNames = {
  "Single Input": `single-input`,
  "Numeric Input": `numeric-input`,
  "Text Area": `text-area-field`,
  "Radio Button": `radio-button`,
  Dropdown: `dropdown`,
  Checkboxes: `checkboxes`,
  Multiselect: `multiselect`,
  Date: `date-input`,
  Time: `time-input`,
  // "File Upload": "file-upload",
  Templated: `templated`
}

const mapStateToProps = (state, ownProps) => ({
  field: state.form.fields[ownProps.id] || {}
})

const SingleInputFieldBase = ({id, field}) => {
  // if (!field.type) field.type = "single-input"
  if (!field.type) return <div></div>
  const C = componentNameMapping[field.type]
  return (
    <div>
      <b>
        {field.required && (
          <Tooltip title="Required">
            <Icon type="info-circle" style={{padding: `0 0.5rem 0 0`}} />
          </Tooltip>
        )}
        {field.label}
      </b>

      <div>{field.question}</div>
      <div style={{pointerEvents: `none`}}>
        <C />
      </div>
      <small>{field.tipText}</small>
      {field.photoRequired && <PhotoRequired />}
      {field.requiredForFieldIntake && (
        <div style={{padding: `1rem 0 0 0`, display: `flex`, flexFlow: `row nowrap`, alignItems: `center`}}>
          <Icon type="exclamation" style={{fontSize: 24}} /> Required for Field Intake
        </div>
      )}
    </div>
  )
}

const Single = connect(mapStateToProps)(SingleInputFieldBase)

const OptionFieldBase = ({id, field, options}) => {
  const C = componentNameMapping[field.type]

  return (
    <div>
      <div style={{fontWeight: `bold`}}>
        <span style={{padding: `0 0.5rem 0 0`}}>
          {field.required && (
            <Tooltip title="Required">
              <Icon type="info-circle" />
            </Tooltip>
          )}
        </span>
        {field.label}
      </div>
      <div>{field.question}</div>
      <div style={{marginTop: `2rem`, marginBottom: `2rem`, pointerEvents: `none`}}>
        <C id={field.id} />
      </div>

      <small>{field.tipText}</small>
      {field.photoRequired && <PhotoRequired />}
      {field.requiredForFieldIntake && (
        <div style={{padding: `1rem 0 0 0`, display: `flex`, flexFlow: `row nowrap`, alignItems: `center`}}>
          <Icon type="exclamation" style={{fontSize: 24}} /> Required for Field Intake
        </div>
      )}
    </div>
  )
}

OptionFieldBase.propTypes = {
  id: PropTypes.string,
  field: PropTypes.object,
  options: PropTypes.array
}

const Multiple = connect(mapStateToProps)(OptionFieldBase)

const componentTypeMapping = {
  "single-input": Single,
  "numeric-input": Single,
  "text-area-field": Single,
  "radio-button": Multiple,
  dropdown: Multiple,
  checkboxes: Multiple,
  multiselect: Multiple,
  "date-input": Single,
  "time-input": Single,
  "file-upload": Single,
  templated: Multiple
}

const mapFieldTypeState = (state, ownProps) => ({field: state.form.fields[ownProps.id]})

const NakedFieldType = ({id, type, field = {}}) => {
  const C = componentTypeMapping[field.type ? field.type : type] || Single
  return <C id={id} />
}

const FieldType = connect(mapFieldTypeState)(NakedFieldType)

export {components, componentDisplayNames, FieldType}
