export default function availabilityLimitInspectors(inspectors, availability) {  
  const ii = {...inspectors}  
  const availabilityByDate = {}
  for (let i in availability) {
    const a = availability[i]
    availabilityByDate[a.data.id] = availabilityByDate[a.data.id] 
      ? [...availabilityByDate[a.data.id], a] 
      : [a]
  }
  
  let jj = {}
  for (let id in ii) {
    jj[id] = {...ii[id], availability: availabilityByDate[id]}
  }

  console.log(jj)

  return jj
}