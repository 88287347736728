import DB from '../../DAL/DB'
import moment from 'moment'
import clearData from './clearData'
import SystemLogger from "../../SystemLogger"
import {auth} from '../../Auth'

const projects = new DB.Projects()
const calendars = new DB.Calendars()

export default async function finalize ({project, inspector, time, date, duration}, dispatch, state) {
  project.inspection = {}
  project.inspection.inspector = {name: inspector.name, id: inspector.id, email: inspector.email}

  date.hours(time.hours())
  date.minutes(time.minutes())
  project.inspection.date = date.toDate()
  project.inspection.duration = duration.toDate()
  project.inspection.endDate = moment(date)
    .set({
      hour: date.hours() + duration.hours(), 
      minute: date.minutes() + duration.minutes()
    }).toDate()

  try {
    project.status = 'Scheduled'
    await projects.update(project.id, null, project)

    delete project.intake
    await calendars.update(project.id, null, {
      data: project, 
      date: project.inspection.date,
      duration: project.inspection.duration,
      endDate: project.inspection.endDate,
      start: project.inspection.date,
      end: project.inspection.endDate,
      title: `Intake - ${project.client.name}`,
      id: project.id,
      type: 'Intake'
    })

    clearData(dispatch)
    const pp = {...state.projects}
    delete pp[project.id]
    dispatch({type: 'projects', value: pp})

    const logger = new SystemLogger({uid: auth.sunkaizenUser.id})
    logger.log(
      `Scheduled ${project.site.siteOwnerName} ` + 
            `${project.site.geocode_results.formatted_address} ` + 
            `for inspection on ${project.inspection.date.toISOString()}`
    )
  }
  catch (e) {
    console.log('error scheduling project')
    throw e
  }
}