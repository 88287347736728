import React, {useState, useEffect} from "react"
import {Input} from "antd" // Button,

/**
 *
 * @param {boolean} full If true then write out the entire formatted address into the autocomplete line on a match, if false just write the street address
 * @param {boolean} edit If true then you can edit the field, disabled otherwise
 */
const AutocompleteField = ({full, edit, style = {}, submit = () => null, placeholder = null, old_address = ``, autocomplete: ac = 'new-password'}) => {
  const [address, setAddress] = useState(undefined)
  // const [geocoded, setGeocoded] = useState(null)
  // const [addressAutocompleted, setAddressAutocompleted] = useState(null)
  const [autocompleteRef, setAutocompleteRef] = useState(null)
  const [autocomplete, setAutocomplete] = useState(null)

  if (old_address && old_address.length > 0 && address === undefined) setAddress(old_address)

  useEffect(() => {
    window.addEventListener(`bounds_changed`, e => {
      console.log(e)
      autocomplete.getBounds()
    })

    const getAutocomplete = async () => {
      const autocompleteRef = await React.createRef()
      setAutocompleteRef(autocompleteRef)

      const autocomplete = await new window.google.maps.places.Autocomplete(autocompleteRef.current.input, {
        types: [`geocode`]
      })

      setAutocomplete(autocomplete)

      autocomplete.addListener(`place_changed`, fillAddress(autocomplete))
      autocomplete.addListener(`place_changed`, () => console.log(`address changed`))
    }

    getAutocomplete()
  }, [])

  const fillAddress = autocomplete => async () => {
    let place = await autocomplete.getPlace()
    const geocoder = new window.google.maps.Geocoder()

    if (place && place.formatted_address) {
      geocoder.geocode({address: place.formatted_address}, (results, status) => {
        const gg = JSON.parse(JSON.stringify(results))
        console.log(`gg`, gg)

        //Default to display the formatted address in the field
        let showingAddress = gg[0].formatted_address

        //If full is not set or false, then only show the street and route
        if (!full) {
          const types = {
            street_number: true,
            route: true
          }

          //Filter down just to the address components we want from the lookup
          const addressComponents = gg[0].address_components.filter(c => types[c.types[0]])

          let streetNumber = addressComponents.filter(c => c.types.includes(`street_number`))[0].short_name
          let route = addressComponents.filter(c => c.types.includes(`route`))[0].short_name

          showingAddress = `${streetNumber} ${route}`
        }

        setAddress(showingAddress)
        // setGeocoded(gg)
        // setAddressAutocompleted(true)

        submit(gg, true)
        return true
      })
    }
  }

  return (
    <Input
      disabled={!edit}
      style={style}
      type="text"
      // onBlur={fillAddress}
      ref={autocompleteRef}
      size="large"
      placeholder={placeholder || `Address`}
      value={address}
      onBlur={() => submit(address)}
      onChange={e => setAddress(e.target.value)}
      autocomplete="new-password"
    />
  )
}

export default AutocompleteField
