import React from "react"
// import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'

// import { styles } from './utilities/resourceBuilder'

const localStyles = {
  searchbar: {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    flex: 85,
    border: `1px solid rgba(211, 211, 211, .6)`,
    borderLeft: `0px`,
    borderRadius: `0 0.2em 0.2em 0`,
    padding: `1.5rem`
  },
  dropdown: {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    border: `1px solid rgba(211, 211, 211, .6)`,
    borderRadius: `0.2em 0 0 0.2em`,
    flex: 15,
    backgroundColor: `white`,
    padding: `0.5em`
  },
  form: {
    padding: `0.2rem`,
    borderRadius: `0.1em`,
    display: `flex`,
    flexDirection: `row`,
    fontSize: `75%`
  }
}

const capitalizeFirst = name => name.charAt(0).toUpperCase() + name.slice(1)

const Searchbar = ({getResources, type, resourceProperties}) => {
  const search = e => {
    e.preventDefault()

    let {constraint, field} = e.target
    getResources({constraint, field})
  }

  return (
    <form style={localStyles.form} onSubmit={search}>
      <select style={localStyles.dropdown}>
        {resourceProperties.map((property, key) => (
          <option key={key} value={property}>
            {capitalizeFirst(property)}
          </option>
        ))}
      </select>
      <input style={localStyles.searchbar} type="text" placeholder="Start typing to search" />
    </form>
  )
}

export default Searchbar
