import React, {useEffect, useState} from 'react'
import {List} from 'antd'
import {firebase} from '../utilities'

function getBrokePhotos (projects) {
  const pp = []

  for (let project of projects) {
    if (!project.photos) continue

    const broke = []
    for (let i in project.photos) {
      const photo = project.photos[i]
      if (photo.fullPath.includes('undefined/undefined/undefined')) broke.push(photo)
    }

    const brief = {id: project.id, name: project.site.siteOwnerName, address: project.site.siteAddress}
    pp.push({project: brief, photos: broke})
  }

  return pp.filter(p => p.photos.length)
}

function getDuplicates (broke) {
  const duplicates = {}

  for (let project of broke) {
    for (let photo of project.photos) {
      duplicates[photo.fullPath] = duplicates[photo.fullPath] ? duplicates[photo.fullPath]++ : 1
    }
  }

  return duplicates
}

// db.collection('projects').orderBy('photos').startAfter(null).get().then(snap => e = snap.docs.map(d => d.data()))
export default function ProjectPhotoDuplicates ({readDB, readResults}) {
  const [projects, setProjects] = useState([])
  useEffect(function () {
    (async function () {
      const getAbbreviatedProjects = firebase.functions().httpsCallable('getAbbreviatedProjects')
      const {data: projects} = await getAbbreviatedProjects()

      console.log(projects)
      setProjects(projects)
    })()
  }, [])

  const broke = getBrokePhotos(projects)
  const photoNameTable = getDuplicates(broke)

  console.log(photoNameTable)

  let present = []

  for (let project of broke) {
    let duplicates = []

    for (let photo of project.photos) { if (photoNameTable[photo.name] > 1) duplicates.push(photo) }
    project.photos = duplicates

    if (project.photos.length) present.push(project)
  }

  return (
    <List 
      loading={!projects.length}
      dataSource={present}
      renderItem={({project: {id, name, address}, photos}) => (
        <List.Item>
          <div>{name} {address} {id}</div>
          <div className="flex-column" >{ photos.map(photo => <div>photo.name</div>) }</div>
        </List.Item>
      )}
    />
  )
}