import React, {useState, useCallback} from "react"
import {Modal, Input} from "antd"

export function useModal({content, props}) {
  const [visible, setVisible] = useState(false)
  const showModal = () => setVisible(true)
  const hideModal = () => setVisible(false)

  const onCancel = useCallback(() => {
    props.onCancel()
    hideModal()
  }, [props.onCancel])

  const modal = (
    <Modal width="985px" {...props} visible={visible} onCancel={onCancel}>
      {content}
    </Modal>
  )

  return {showModal, hideModal, modal}
}

export function ModalInput({componentType, ...rest}) {
  let Component = componentType || Input
  return <Component {...rest} size="large" />
}
