import {auth} from '../../Auth'
import moment from 'moment'

export default function getAvailability (makeSubscription) {
  return function () {
    const date = moment()

    const where = [{field: 'type', test: '==', value: 'Availability'}]
    if (auth.sunkaizenUser.type === `field-user`) where.push({field: 'data.id', test: '==', value: auth.sunkaizenUser.id})

    // the code only cares about recent and near future (6 months) availability
    date.month(date.month() - 6)
    where.push({field: 'date', test: '>', value: date.toDate()})
    
    date.month(date.month() + 12)
    where.push({field: 'date', test: '<', value: date.toDate()})
    
    console.log(where)

    return makeSubscription({paths: [{collection: 'calendars'}], options: {where}}, 'availability')
  }
}