import React from "react"
import PropTypes from "prop-types"

import {connect} from "react-redux"

import {Radio} from "antd" //	, Input, Checkbox, Icon, Tooltip

const radioItemStateToProps = (state, ownProps) => ({
  option: state.form.fields[ownProps.fieldId].options[ownProps.i] || {}
})
const NakedRadioItem = ({fieldId, i, option}) => (
  <div>
    {option && (
      <Radio value={option.label} key={i}>
        {option.label}
      </Radio>
    )}
  </div>
)
const RadioItem = connect(radioItemStateToProps)(NakedRadioItem)

const mapStateToProps = (state, ownProps) => ({options: state.form.fields[ownProps.id].options || []})
const RadioButton = ({id, options}) => (
  <Radio.Group style={{pointerEvents: `none`}}>
    {options.map((option, i) => (
      <RadioItem fieldId={id} key={i} i={i} />
    ))}
  </Radio.Group>
)

RadioButton.propTypes = {
  options: PropTypes.array,
  id: PropTypes.string
}

const WrappedRadioButton = connect(mapStateToProps)(RadioButton)

export default WrappedRadioButton
