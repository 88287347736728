import React, {useState, useEffect} from "react"
import {auth} from '../../Auth'
import {loggerEffect} from '../../effects'
import DB from '../../DAL/DB'
import {Checkbox} from 'antd'

const projects = new DB.Projects()

// make sure to distinguish between project-owner-clients and project-requester-clients on this component
export default function InstallerViewable ({project, installerReadable}) {
  const [logger, setLogger] = useState(null)
  useEffect(loggerEffect(setLogger), [])

  async function toggleInstallerPermissions () {
    const {installerViewable, id: pId, installer: {id} = {id: null}} = project

    if (!id || id.length < 1) {
      logger.event(`This project ${id} has no installer; please add one before using this option`, null, 5)
      return
    }

    try {
      await projects.update(pId, null, {...project, installerViewable: !installerViewable})
      console.log(`set new user_index`)
    } 
    catch (e) {console.log(`could not toggle installer permissions`, e)}
  }

  return (
    <div>
      {['client', 'admin', 'super-admin'].includes(auth.sunkaizenUser.type) && (
        <Checkbox
          className="toggle-installer-viewable"
          onChange={toggleInstallerPermissions}
          checked={!!installerReadable}
        >
                    Installer Viewable
        </Checkbox>
      )}
    </div>
  )
}