import {initializeProject} from './initializeProject'
import {auth} from '../../Auth'
import checkFormIntegrity from './checkFormIntegrity'

export async function prepareProject (project, first, logger) {
  if (!project.startedAt) {
    await initializeProject(project)
    logger.categoryId = project.id
    logger.event(
      `${auth.currentUser.displayName} started an intake`,
      JSON.stringify(project, null, 2),
      3
    )
    return
  }
  
  if (!['In Progress', 'Review', 'Needs Corrections', 'Completed'].includes(project.status)) project.status = 'In Progress'

  let userIsFieldUser = false
  if (project.status === "Review") {
    switch (auth.sunkaizenUser.type) {
    case "field-user":
      userIsFieldUser = true
      break
    case 'quality-manager':
    case 'admin':
    case 'super-admin':
      if (first) {
        logger.categoryId = project.id
        logger.event(`${auth.currentUser.displayName} started reviewing an intake`, null, 5)
        project.reviewer = {
          name: auth.sunkaizenUser.name,
          id: auth.sunkaizenUser.id,
          email: auth.sunkaizenUser.email,
          type: auth.sunkaizenUser.type
        }
      }
      break
    default:
      console.log('unexpected user accessed this project in review', auth.sunkaizenUser)
    }
  }
  
  let fields = {[project.id]: {images: project.photos}}
  Object.values(project.intake.sections).forEach(section => fields = {...fields, ...section.fields})
  
  await checkFormIntegrity(project)

  return {project, fields, userIsFieldUser}
}