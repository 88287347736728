import React from "react"
import PropTypes from "prop-types"
import {Input} from "antd"

import "./DetailedUserView.css"

import {capitalizationMap} from "./utilities"

const Field = ({edit, type, style, user, change}) => (
  <div className="flex-column field-wrapper" style={style}>
    <div className="info-text">{capitalizationMap[type]} </div>
    <Input
      disabled={!edit}
      type={type === "email" ? "email" : "text"}
      style={{border: "none"}}
      size="large"
      placeholder={capitalizationMap[type]}
      value={user[type]}
      onChange={e => change(e.target.value)}
    />
  </div>
)

Field.propTypes = {
  edit: PropTypes.func,
  type: PropTypes.string,
  style: PropTypes.object,
  user: PropTypes.object,
  change: PropTypes.func
}

export default Field
