import filterInspectors from './filterInspectors'
import {inspectorSelected, inspectorImage, shape} from './mapIcons'
import {contentString} from '../utilities'

const getLocation = inspector => {
  if (inspector.geocode_results) return inspector.geocode_results.geometry
  else if (inspector.geometry) return inspector.geometry
  else return null
}

export default function addInspectors (state, pins, onClick) {
  const {inspector, inspectors, mapping: {map}} = state

  for (let i in pins) { pins[i].setMap(null) }
    
  const pp = {}

  Object.values(inspectors).forEach(({id, name, phone, email, geocode_results, geometry}) => {
    const g = getLocation({geometry, geocode_results})
    if (!g) return
    const {location: position} = g
    const icon = id === inspector.id ? inspectorSelected() : inspectorImage()
        
    const marker = new window.google.maps.Marker({position, title: name, map, shape, icon})
    const infoWindow = new window.google.maps.InfoWindow({
      content: contentString({name, contact: name, phone, email})
    })

    marker.addListener("click", () => { onClick(inspectors[id]) })
    marker.addListener('mouseout', () => { infoWindow.close() })
    if (id === inspector.id) infoWindow.open(map, marker)

    pp[id] = marker
  })

  return [filterInspectors(map, inspectors), pp]
}