import columns from './columns'
import onRow from './onRow'
import permissions from './permissions'
import sorter from './sorter'
import rowSelection from './rowSelection'
import {organizationFilter} from '../utilities'

const config = {
  columns, 
  onRow, 
  permissions, 
  sorter,
  rowSelection, 
  type: 'inspections', 
  searchbar: true,
  savedFilters: true,
  tabs: 'status',
  // bulk: BulkProjectImport,
  // create: ProjectCreator,
  // postProcess: [hideCanceled]
  preProcess: [organizationFilter]
}

export default config