import React from "react"
import {Button, Icon} from "antd"

const FileUpload = () => (
  <Button>
    <Icon style={{padding: `0 0.5rem 0 0`}} type="upload" />
    Click to Upload
  </Button>
)
export default FileUpload
