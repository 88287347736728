import React from 'react'
import CancellationPhoto from "../CancellationPhoto"
import LabeledSection from './LabeledSection'

export default function CancelledIntakes ({project}) {
  return (
    <div className="flex-column full-width" >
      <h4>Cancelled Intakes</h4>
      <div>
        {project.intakeCancellations.map(({canceledAt, reason, canceledDate, inspector, comment, photo}, i) => (
          <div
            key={i}
            className="padding-1 background-white margin-bottom-1"
            style={{borderRadius: "1rem", border: "0.1rem solid lightgrey"}}
          >
            <LabeledSection label="Canceled on" >{canceledAt.toDate && canceledAt.toDate().toLocaleString()}</LabeledSection>
            <LabeledSection label="Reason" >{reason}</LabeledSection>
            {comment && (
              <LabeledSection label="Cancellation Note" >{comment}</LabeledSection>
            )}
            {photo && (
              <div className="flex-column" >
                <div className="padding-right-1 bold" >Cancellation Photo</div>
                <CancellationPhoto className="full-width margin-1" style={{height: "10rem"}} photo={photo} />
              </div>
            )}
            <hr style={{border: '1px solid rgba(244, 244, 244)'}} />
            <LabeledSection label="Was Scheduled For" >
              {canceledDate && canceledDate.toDate && canceledDate.toDate().toLocaleString()}
            </LabeledSection>
            <LabeledSection label="Field User" >{inspector && inspector.name}</LabeledSection>
          </div>
        ))}
      </div>
    </div>        
  )
}