import React from "react"
import PropTypes from "prop-types"

import Plotly from "plotly.js-basic-dist"

import {db} from "../../utilities"

import {Button, Dropdown, Icon, Menu, Table} from "antd"

const menu = (data = [], chooseOption) => (
  <Menu onClick={chooseOption}>
    {data.map(name => (
      <Menu.Item key={name}>
        <Icon type="user" />
        {name}
      </Menu.Item>
    ))}
  </Menu>
)

const TypeChooser = ({menu}) => (
  <Dropdown overlay={menu}>
    <Button>
      Select an Intake Type
      <Icon type="down" />
    </Button>
  </Dropdown>
)

export default class FailuresOfPriorityMeasures extends React.Component {
  static propTypes = {projects: PropTypes.array}

  componentDidMount = async () => {
    this.chartRef = React.createRef()
    await this.getProjects()

    const data = {}
    if (this.state.project) {
      this.props.projects[this.state.project].forEach(project => {
        Object.values(project.intake.sections).forEach(section => {
          section.fields.forEach(field => {
            data[field.question] = [].concat(field, data[field.question])
          })
        })
      })
    }

    const tableData = Object.entries(data).map(([question, fields]) => {
      const fails = fields.map(field => (field.score === "fail" ? field.score : false)).filter(i => i)
      return [question, fails]
    })

    const cumulativeFailsArray = []
    const totalFails = tableData.reduce((a, [question, fails]) => {
      const b = (a += fails.length)
      cumulativeFailsArray.push(b)
      return b
    }, 0)

    const line = {
      y: cumulativeFailsArray.map(f => (f / totalFails) * 100),
      type: "scatter"
    }
    const bar = {
      y: tableData.map(([question, fails]) => fails.length),
      type: "bar"
    }

    const gData = [line, bar]
    Plotly.newPlot(this.chartRef.current, gData)

    const tData = tableData.map(([question, fails]) => [question, (fails / totalFails) * 100])

    this.setState({data: tData})
  }

  state = {project: null, data: []}

  columns = [
    {
      title: "Measure",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "% Improvement if Addressed",
      dataIndex: "improvement",
      key: "improvement"
    }
  ]

  chooseOption = e => this.setState({project: e.key})

  getProjects = async () => {
    const projects = {},
      names = {}

    console.log(this.props.auth)

    const pp = await db
      .collection("projects")
      .where("clientId", "==", this.props.auth.currentUser.uid)
      .get()

    pp.forEach(project => {
      names[project.intake.title] = true
      projects[project.title] = []
        .concat(projects[project.intake.title], Object.assign({}, project.data(), {id: project.id}))
        .filter(i => i)
    })

    this.setState({projects, names: Object.keys(names)})
  }

  render = () => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexFlow: "column",
          background: "white",
          margin: "0 1rem 0 0",
          padding: "2rem",
          border: "0.1rem solid lightgrey"
        }}
      >
        <h3>Failure of Priority Measures</h3>
        <div style={{padding: "1rem"}}>
          <TypeChooser menu={menu(this.state.names, this.chooseOption)} />
        </div>
        <div ref={this.chartRef}></div>
        <div style={{display: "flex", flexFlow: "column"}}>
          <h4>Most Impactful Failures</h4>
          <Table style={{width: "100%", flex: 1}} columns={this.columns} data={this.state.data.slice(0, 3)} />
        </div>
      </div>
    )
  }
}
