import React from "react"
import SystemLogger from "../SystemLogger"

import Auth from "../Auth"

const auth = new Auth()

export default class SystemNotifier extends React.Component {
  componentDidMount = () => {
    const authSubscription = auth.sunkaizenUserSubscribe(this.subscribeLogs)
    this.setState({authSubscription})
  }
  componentWillUnmount = () => {
    if (this.state.authSubscription) this.state.authSubscription()
    if (this.state.unsubscribe) this.state.unsubscribe()
  }

  state = {unsubscribe: undefined, logs: {}, subscribers: []}

  subscribeLogs = () => {
    auth.sunkaizenUserSubscribe(user => {
      if (this.state.unsubscribe) this.state.unsubscribe()
      if (user) {
        const unsubscribe = SystemLogger.subscribe(user.id)(message => {
          const {logs, subscribers} = this.state

          logs[message] = true

          const timeoutId = setTimeout(() => {
            // console.log("timeout reached", message)
            const {logs} = this.state
            delete logs[message]
            this.setState({logs})
          }, 5000)

          subscribers[message] = timeoutId
          this.setState({logs, subscribers})
        })

        this.setState({unsubscribe})
      }
    })
  }

  clear = message => {
    const {logs, subscribers} = this.state
    clearTimeout(subscribers[message])
    delete logs[message]
    this.setState({logs})
  }

  render = () => (
    <div
      style={{
        position: "fixed",
        left: "15%",
        zIndex: 10000,
        padding: "2rem 0 0 0",
        display: "flex",
        flexFlow: "column"
      }}
    >
      {Object.keys(this.state.logs).map(log => (
        <div
          style={{
            backgroundColor: "lightyellow",
            color: "black",
            padding: "1rem",
            margin: "0.1rem",
            borderRadius: "0.5rem",
            cursor: "pointer",
            fontSize: "2rem"
          }}
          onClick={() => this.clear(log)}
        >
          {log}
        </div>
      ))}
    </div>
  )
}
