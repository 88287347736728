import React from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {DragDropContext, Droppable} from "react-beautiful-dnd" //	, Draggable
import Auth from "../Auth"
import SystemLogger from "../SystemLogger"
import {setActive, setField, setResources} from "./action_creators"
import Sections, {Add} from "./Sections"

const auth = new Auth()

const mapStateToProps = state => ({
  form: state.form.base
})

const mapDispatchToProps = dispatch => ({
  active: ({id, type}) => dispatch(setActive({type, id})),
  setField: ({id, value, category}) => dispatch(setField({category, id, value})),
  setResources: values => dispatch(setResources({type: "base", values}))
})

class SectionsWrapper extends React.Component {
  static propTypes = {
    setField: PropTypes.func,
    active: PropTypes.func,
    form: PropTypes.object
  }

  componentDidMount = () => {
    this.logger = new SystemLogger(window.location.pathname, auth.currentUser.uid)
  }

  onDragEnd = result => {
    if (!result.destination) this.logger.log("You dropped an item outside the droppable area!", "please try again", 5)
    else {
      const {order} = this.props.form
      const [element] = order.splice(result.source.index, 1)
      order.splice(result.destination.index, 0, element)

      this.props.setResources({...this.props.form, order})
    }
  }

  onDragStart = target => this.props.active({id: target.draggableId, type: "section"})

  render = () => (
    <div>
      <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
        <Droppable droppableId="sectionsdroppable">
          {(provided, snapshot) => <Sections {...{provided, snapshot}} />}
        </Droppable>
      </DragDropContext>
      <hr />
      <Add />
    </div>
  )
}

const WrappedSectionsWrapper = connect(mapStateToProps, mapDispatchToProps)(SectionsWrapper)

export default WrappedSectionsWrapper
