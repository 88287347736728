import React from 'react'
import columns from './columns'
import onRow from './onRow'
import sorter from './sorter'
import rowSelection from './rowSelection'
// import or from './or'
import permissions from './permissions'
import terms from './terms'
import addUrl from './addUrl'
import rowClassName from './rowClassName'
import ButtonBar from './ButtonBar'

const config = {
  columns, 
  onRow, 
  sorter,
  rowSelection, 
  type: 'reports', 
  postProcess: [addUrl],
  alternate: ButtonBar,
  rowClassName,
  // or,
  permissions
  // terms
}

export default config