import React from "react"
import PropTypes from "prop-types"

import {connect} from "react-redux"
import {DragDropContext, Droppable} from "react-beautiful-dnd" //	, Draggable

import Auth from "../../Auth"
import SystemLogger from "../../SystemLogger"

import {Card} from "antd"

import {setActive, setField} from "../action_creators"

import Options, {Add} from "./Options"

const auth = new Auth()

const mapStateToProps = state => ({
  field: state.form.fields[state.active.field]
})

const mapDispatchToProps = dispatch => ({
  active: ({id, category}) => dispatch(setActive({category, id})),
  setField: ({id, value, category}) => dispatch(setField({category, id, value}))
})

class OptionsWrapper extends React.Component {
  static propTypes = {
    field: PropTypes.object,
    setField: PropTypes.func,
    active: PropTypes.func
  }

  componentDidMount = () => {
    this.logger = new SystemLogger(window.location.pathname, auth.currentUser.uid)
  }

  onDragEnd = result => {
    if (!result.destination) this.logger.log("You dropped an item outside the droppable area!", "please try again", 5)
    else {
      const {options} = this.props.field

      const [element] = options.splice(result.source.index, 1)
      options.splice(result.destination.index, 0, element)

      this.props.setField({
        id: this.props.field.id,
        value: Object.assign({}, this.props.field, {options}),
        category: "fields"
      })
    }
  }

  onDragStart = target => this.props.active({id: target.draggableId, category: "option"})

  render = () => (
    <Card>
      <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
        <Droppable droppableId="optionsDroppable">
          {(provided, snapshot) => <Options {...{provided, snapshot}} />}
        </Droppable>
      </DragDropContext>
      <hr />
      <Add />
    </Card>
  )
}

const WrappedOptionsWrapper = connect(mapStateToProps, mapDispatchToProps)(OptionsWrapper)

export default WrappedOptionsWrapper
