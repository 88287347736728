import React from "react"
import PropTypes from "prop-types"

import {IntakeFailure} from "./IntakeFailure"

/**
 * Displays a section along with the failures within it.
 */
export let FailuresBySection = ({sectionTitle, failures}) => {
  return (
    <div className="failures-by-section" data-test="failures-by-section-component">
      <div className="section-name" style={{fontWeight: `600`}}>
        {sectionTitle}
      </div>
      {failures.map(failure => (
        <IntakeFailure key={failure.id} {...{failure}} />
      ))}
    </div>
  )
}

FailuresBySection.propTypes = {
  sectionTitle: PropTypes.string,
  failures: PropTypes.arrayOf(PropTypes.object)
}
