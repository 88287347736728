/* this is the admin log viewer to see system logs */

import React from "react"
import PropTypes from "prop-types"

// import {Glyphicon} from "react-bootstrap"
import {DoubleLeftOutlined, LeftOutlined, RightOutlined, DoubleRightOutlined} from '@ant-design/icons'

import {db} from "../utilities"
import SystemLogger from "../SystemLogger"
import Searchbar from "../Searchbar"
// import FileHandler from '../FileHandling/FileHandler'

const localStyles = {
  glyph: {padding: "2rem", color: "grey", background: "white", cursor: "pointer"}
}

const queryOptions = {
  orderBy: (field = "created", dir) => {
    return dir ? {f: "orderBy", args: [field, dir]} : {f: "orderBy", args: [field]}
  },
  limit: (val = pageSize) => ({f: "limit", args: [val]}),
  where: uid => ({f: "where", args: ["creator", "==", uid]}),
  startAfter: docRef => ({f: "startAfter", args: [docRef]})
}

const pageSize = 10

export const NavBar = ({first, last, next, prev}) => (
  <div
    style={{
      alignSelf: "center",
      display: "flex",
      flexFlow: "row nowrap",
      padding: "1rem 5rem 1rem 5rem",
      justifyContent: "space-between"
    }}
  >
    <DoubleLeftOutlined onClick={first} />
    <LeftOutlined onClick={prev} />
    <RightOutlined onClick={next} />
    <DoubleRightOutlined onClick={last} />
  </div>
)

export default class LogViewer extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    auth: PropTypes.object,
    location: PropTypes.object
  }

  constructor(props) {
    super(props)

    const {location, auth} = this.props

    if (location.pathname && auth.currentUser.uid) {
      this.logger = new SystemLogger({path: location.pathname, uid: auth.currentUser.uid})
    } else {
      console.log(`the user is not logged in: ${auth.currentUser.uid} or there is no path: ${location.pathname}`)
      this.logger = new SystemLogger({})
    }

    this.setLogs = this.setLogs.bind(this)
    this.page = this.page.bind(this)
    this.ends = this.ends.bind(this)
    this.savePDF = this.savePDF.bind(this)

    this.navProps = {
      first: this.ends,
      prev: () => this.page("desc"),
      next: this.page,
      last: () => this.ends("last")
    }
  }

  state = {logs: [], loading: true, logsProperties: []}

  async componentDidMount() {
    let collection = ""
    let {auth} = this.props

    this.logsRef = React.createRef()

    if (auth.sunkaizenUser.admin) collection = "transactionLog"
    else collection = "projectTransactionLog"

    try {
      await this.setState({collection})
      this.page()
    } catch (e) {
      console.log(e)
    }
  }

  componentWillUnmount() {
    if (this.state.logWatch) this.state.logWatch()
  }

  setLogs(query) {
    if (this.state.logWatch) this.state.logWatch()

    const logWatch = query.onSnapshot(snapshot => {
      const logs = snapshot.docs
      const logsProperties = Object.keys(logs[0].data())
      logsProperties.sort((a, b) => a.created > b.created ? 1 : -1)

      this.setState({
        logs,
        loading: false,
        logsProperties
      })
    })

    this.setState({logWatch})
  }

  page(direction = null) {
    this.setState({loading: true})

    const {collection, logs} = this.state
    const {auth} = this.props

    let paginationMarker = null

    // desc = backwards counting
    if (logs.length > 0) {
      paginationMarker =
        direction === "desc" ? queryOptions.startAfter(logs[0]) : queryOptions.startAfter(logs[logs.length - 1])
    }

    let {where, orderBy, limit} = queryOptions

    const order = direction ? orderBy("created", direction) : orderBy()

    const options = auth.sunkaizenUser.admin
      ? [order, paginationMarker, limit(pageSize)].filter(i => i)
      : [where(auth.currentUser.uid), order, paginationMarker, limit(pageSize)].filter(i => i)

    const query = options.reduce((acc, cur) => {
      return acc[cur.f](...cur.args)
    }, db.collection(collection))
    this.setLogs(query)
  }

  async ends(side = null) {
    const {auth} = this.props
    const {collection} = this.state

    await this.setState({loading: true})

    let {where, orderBy, limit} = queryOptions

    const order = side === "last" ? orderBy("created", "desc") : orderBy()

    const options = auth.sunkaizenUser.admin
      ? [order, limit(pageSize)]
      : [where(auth.currentUser.uid), order, limit(pageSize)]

    const query = options.reduce((acc, cur) => acc[cur.f](...cur.args), db.collection(collection))
    this.setLogs(query)
  }

  async savePDF() {
    // const a = new FileHandler({ auth: this.props.auth })
    // const htmlPdf = await a.pdf({ data: this.logsRef.current, type: "text/html" })
    // const name = `logging_report_${new Date().toUTCString()}`
    // htmlPdf.save(name)
    console.log("disabled")
  }

  render() {
    let {state, props, navProps} = this //	ends, page,
    let {logs, logsProperties} = state //	loading,
    let {auth} = props

    return (
      <div style={{padding: "3rem", display: "flex", flexFlow: "column nowrap", alignItems: "space-evenly"}}>
        <h1 className="page-title" style={{flex: 1}}>
          {auth.sunkaizenUser && auth.sunkaizenUser.admin ? "System Logs" : "Project Logs"}
        </h1>
        <div style={{display: "flex", flexFlow: "row, nowrap", padding: "2rem", alignItems: "center"}}>
          <div style={{padding: "2rem", flex: 4}}>
            <Searchbar resourceProperties={logsProperties} />
          </div>
          <div
            style={{cursor: "pointer", color: "white", padding: "2rem", background: "rgba(34, 97, 145, 0.2)"}}
            onClick={this.savePDF}
          >
            Export logs to PDF
          </div>
        </div>
        <div style={{flex: 1}}>
          <NavBar {...navProps} />
        </div>
        <div ref={this.logsRef}>
          <div style={{flex: 1, fontWeight: "bold", fontSize: "125%", display: "flex", flexFlow: "row nowrap"}}>
            <div style={{padding: "0.3rem", flex: 1}}>Date</div>
            <div style={{padding: "0.3rem", flex: 4}}>Message</div>
          </div>
          <div style={{flex: "6 10 30rem", display: "flex", flexFlow: "column nowrap"}}>
            {logs.map((log, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  padding: "0.5rem",
                  background: "white",
                  borderRadius: "0.5rem"
                }}
              >
                <div style={{padding: "0.3rem", flex: 1}}>
                  {log
                    .data()
                    .created.toDate()
                    .toUTCString()}
                </div>
                <div style={{padding: "0.3rem", flex: 4}}>{log.data().message}</div>
              </div>
            ))}
          </div>
        </div>
        <div style={{flex: 1}}>
          <NavBar {...navProps} />
        </div>
      </div>
    )
  }
}
