import React from "react"
import _ from "lodash"

import {db} from "../utilities"
import {Form, Select} from "antd" //	Input, Button,
import {fieldNameConverter} from "../validators"

import {setField, makePermissions} from "./action_creators"
import {connect} from "react-redux"

const mapStateToProps = state => ({
  fields: state.fields,
  installers: state.installers
})
const mapDispatchToProps = dispatch => ({setField: field => value => dispatch(setField(field, value))})

const InstallerInformation = ({fields, setField, installers}) => (
  <div className="flex-column align-start padding-2 margin-bottom-1" style={{background: `#F4F6F8`}} >
    <h3>Installer Information</h3>
    <Form.Item style={{width: `100%`, margin: `0 0 1rem 0`, padding: `0 1rem 0 0`}}>
      <Select
        initialValue={fields.installer}
        value={fields.installer ? fields.installer.name : undefined}
        onChange={e => setField(`installer`)(installers[e] || {})}
        placeholder={fieldNameConverter[`installer`]}
        size="large"
        style={ {width: `100%`} }
        data-cy="installer"
      >
        {Object.values(installers).sort((a, b) => a.name > b.name ? 1 : -1).map(installer => (
          <Select.Option key={installer.id} value={installer.id}>
            {installer.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
    {fields[`installer`] && (
      <div style={{width: `100%`}}>
        <h4>Select NABCEP IDs</h4>
        <Select
          mode="multiple"
          style={{width: `100%`}}
          onChange={e => setField(`nabcep_ids`)(e)}
          value={fields.nabcep_ids}
          data-cy="nabcepIds"
        >
          {((fields.installer || {}).nabcep_ids || ``).split(`,`).sort().map((id, i) => {
            const trimmed = id.trim()
            if (!_.isEmpty(trimmed)) {
              return (
                <Select.Option key={i} value={trimmed} label={trimmed}>
                  {trimmed}
                </Select.Option>
              )
            }
          })}
        </Select>
      </div>
    )}
  </div>
)

const WrappedInstallerInformation = connect(mapStateToProps, mapDispatchToProps)(InstallerInformation)
export default WrappedInstallerInformation