import rootReducer from "./reducer"
import {createStore, applyMiddleware} from "redux"
import {createLogger} from "redux-logger"
import thunkMiddleware from "redux-thunk"

import {db, firebase} from "../utilities"

const loggerMiddleware = createLogger()

export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, loggerMiddleware))


export const del = async id => {
  const sections = await db
    .collection("forms")
    .doc(id)
    .collection("sections")
    .get()
  if (!sections.empty) {
    const pp = [].concat(
      sections.docs
        .map(section => {
          return section.data().order
            ? section.data().order.map(id =>
              section.ref
                .collection("fields")
                .doc(id)
                .delete()
            )
            : null
        })
        .filter(i => i)
    )
    pp.concat(sections.docs.map(section => section.ref.delete()))
    await Promise.all(pp)
  }
  await db
    .collection("forms")
    .doc(id)
    .delete()
}

// the duplicated sections are not getting added to the database
export const duplicate = async id => {
  try {
    const f = await db
      .collection("forms")
      .doc(id)
      .get()
    let form = {}
    if (f.exists) {
      form = Object.assign({}, f.data(), {id: f.id})
    } else {
      console.log("form does not exist")
      return
    }
    const s = await db
      .collection("forms")
      .doc(id)
      .collection("sections")
      .get()
    const sections = s.docs.map(section => ({...section.data(), id: section.id}))
    const newFormRef = db.collection("forms").doc()
    const newForm = {...form, id: newFormRef.id, title: `${form.title} (duplicate)`}
    const batch = db.batch()
    const pp = [].concat(
      sections.map(async section => {
        const newSectionRef = newFormRef.collection("sections").doc()
        const i = newForm.order.indexOf(section.id)
        newForm.order.splice(i, 1, newSectionRef.id)
        const newSection = Object.assign({}, section, {id: newSectionRef.id})
        // get a section's fields
        const ff = await db
          .collection("forms")
          .doc(id)
          .collection("sections")
          .doc(section.id)
          .collection("fields")
          .get()
        ff.forEach(async field => {
          const newFieldRef = newSectionRef.collection("fields").doc()
          const fObject = Object.assign({}, field.data(), {id: field.id})
          const i = newSection.order.indexOf(field.id)
          newSection.order.splice(i, 1, newFieldRef.id)
          batch.set(newFieldRef, {...fObject, id: newFieldRef.id})
        })
        batch.set(newSectionRef, newSection)
      })
    )
    batch.set(newFormRef, newForm)

    // copy rubrics over
    const rSnaps = await db.collection('rubrics').where('formId', '==', id).get()
    rSnaps.docs.forEach(rSnap => {
      const rubric = {...rSnap.data(), formId: newFormRef.id}
      batch.set(db.collection('rubrics').doc(), rubric)
    })

    try {
      await Promise.all(pp)
      console.log("mark 2")
      await batch.commit()
    } catch (e) {
      console.log("failed to commit duplicate form", e)
    }
  } catch (e) {
    console.log("could not get project from database", e)
  }
}