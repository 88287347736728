import React from "react"
// import PropTypes from 'prop-types'
import Auth from "../Auth"
const auth = new Auth()

// const boxMap = [
// 	"Created",
// 	"Scheduled",
// 	"Completed",
// 	"Canceled",
// 	"Passed",
// 	"Failed"
// ]

const boxPermissions = {
  admin: [`Canceled`, `Completed`, `Needs Corrections`, `In Progress`, `Review`, `Scheduled`, `To Be Scheduled`],
  "super-admin": [
    `Canceled`,
    `Completed`,
    `Needs Corrections`,
    `In Progress`,
    `Review`,
    `Scheduled`,
    `To Be Scheduled`
  ],
  client: [`Canceled`, `Completed`, `Scheduled`, `To Be Scheduled`],
  "field-user": [`Canceled`, `Completed`, `Needs Corrections`, `In Progress`, `Review`, `Scheduled`, `To Be Scheduled`],
  installer: [`Canceled`, `Completed`, `Needs Corrections`, `In Progress`, `Review`, `Scheduled`, `To Be Scheduled`],
  "quality-manager": [`Completed`, `Needs Corrections`, `Review`],
  scheduler: [`Canceled`, `Completed`, `Needs Corrections`, `In Progress`, `Review`, `Scheduled`, `To Be Scheduled`]
}

const ProjectBar = ({projects, type}) => {
  const projectCounts = {}
  projects.forEach(project => {
    projectCounts[project.status] = projectCounts[project.status] + 1 || 1
  })

  let passedFailed = {}
  if (auth.sunkaizenUser.type === 'client') {
    projects.forEach(p => {
      if (p.status === 'Completed') {
        if (p.intakeResult && p.intakeResult.rawData) {
          const {total, pass, percentage_failure} = p.intakeResult.rawData
          const r = (total > 0 ? Math.round((100 * pass) / total) : 0) > percentage_failure ? 'Passed' : 'Failed'
          passedFailed[r] = passedFailed[r] + 1 || 1
        }
        else {
          passedFailed['Unscored'] = passedFailed['Unscored'] + 1 || 1
        }
      }
      else passedFailed['Incomplete'] = passedFailed['Incomplete'] + 1 || 1
    })
  }

  const userProjects = [
    ...boxPermissions[auth.sunkaizenUser.type].map(type => [type, projectCounts[type]]),
    ...Object.keys(passedFailed).map(type => [type, passedFailed[type]])
  ]

  return (
    <div style={{display: `flex`, flexFlow: `row nowrap`, padding: `1rem 0 1rem 0`}}>
      {userProjects.map(([type, count], i) => (
        <div
          data-cy="projectStatus"
          key={i}
          style={{
            background: `white`,
            flex: 1,
            padding: `0.5rem`,
            border: `0.1rem solid lightgrey`,
            margin: `0 1rem 0 0`
          }}
        >
          <div style={{padding: `0.3rem`, fontWeight: `bold`, fontSize: `200%`}}>{count || 0}</div>
          <div style={{padding: `0.2rem 0.2rem 1rem 0.2rem`, fontSize: `110%`, fontWeight: `bold`}}>{type}</div>
        </div>
      ))}
    </div>
  )
}

export default ProjectBar
