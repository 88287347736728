import React, {useState} from "react"
import _ from "lodash"
import {Card, Button, Form, Select, Input} from "antd"

import DeleteButton from "../DeleteButton"

export function RubricSettings(props) {
  const {scoringSystems, newRubricName, rubrics, formId} = props

  const [newScoringSystemId, setNewScoringSystemId] = useState()

  return (
    <Card
      loading={!rubrics || _.isEmpty(scoringSystems)}
      title={<strong>Rubric Settings</strong>}
      style={{
        overflowY: `auto`,
        margin: `1.5rem 1.5rem 1.5rem 0`,
        textAlign: `left`,
        padding: `0 0.5rem`,
        position: `sticky`,
        top: `9.5rem`,
      }}
    >
      <Form>
        {rubrics &&
                  rubrics.map((rubric) => (
                    <Form.Item key={rubric.id} style={{marginTop: `0.5rem`}}>
                      <div
                        style={{
                          display: `flex`,
                          alignItems: `center`,
                        }}
                      >
                        <div
                          style={{
                            flex: 2,
                            textAlign: `left`,
                            lineHeight: `2rem`,
                            marginRight: `3rem`,
                            fontWeight: `bold`,
                          }}
                        >
                          {rubric.name}
                        </div>
                        <Select
                          style={{flex: 6}}
                          placeholder="Please select a Scoring System"
                          value={rubric.scoringSystemId || undefined}
                          onChange={(scoringSystemId) =>
                            props.setScoringSystemForRubric({
                              rubric,
                              scoringSystems,
                              scoringSystemId,
                            })
                          }
                        >
                          {scoringSystems.sort().map((scoringSystem) => (
                            <Select.Option key={scoringSystem.id} value={scoringSystem.id}>
                              {scoringSystem.name}
                            </Select.Option>
                          ))}
                        </Select>
                        <div
                          style={{
                            flex: 1,
                            marginTop: `0.1rem`,
                            textAlign: `right`,
                          }}
                        >
                          <DeleteButton
                            style={{height: `3.2rem`}}
                            icon="minus-circle"
                            size="large"
                            type="secondary"
                            confirmationDisplay={
                              <div>
                                <p>Are you sure you want to remove this Rubric?</p>
                                <p>
                                                  Removing it will discard{` `}
                                  <em>any scoring data</em>
                                  {` `}
                                                  you&aposve provided for it in this scoring system.
                                </p>
                              </div>
                            }
                            onConfirm={() =>
                              props.removeRubric({
                                rubricId: rubric.id,
                              })
                            }
                          />
                        </div>
                      </div>
                    </Form.Item>
                  ))}
        <div
          style={{
            marginTop: `2rem`,
            border: `1px solid rgba(0,0,0,0.13)`,
            width: `100%`,
            display: `block`,
          }}
        />
        <div
          style={{
            display: `flex`,
            marginTop: `1rem`,
            alignItems: `center`,
          }}
        >
          <div style={{flex: 4}}>
            <div>
              <Input
                value={newRubricName}
                onChange={(e) =>
                  props.setNewRubricName({
                    name: e.target.value,
                  })
                }
                placeholder="New rubric name"
                data-cy="rubricNameInput"
              />
              <Select
                style={{marginTop: `1rem`}}
                value={newScoringSystemId}
                placeholder="Scoring System for new Rubric"
                data-cy="scoringSystemSelect"
                onChange={(scoringSystemId) => setNewScoringSystemId(scoringSystemId)}
              >
                {scoringSystems.sort().map((scoringSystem) => (
                  <Select.Option key={scoringSystem.id} value={scoringSystem.id}>
                    {scoringSystem.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <Button
            style={{
              flex: 1,
              marginLeft: `1rem`,
              alignSelf: `flex-end`,
            }}
            onClick={() => {
              const success = props.addRubric({
                newRubricName,
                formId,
                scoringSystemId: newScoringSystemId,
              })
              if (success) {
                // Clear form.
                setNewScoringSystemId(undefined)
              }
            }}
            data-cy="addRubricBtn"
          >
                      Add Rubric
          </Button>
        </div>
      </Form>
    </Card>
  )
}
