import {db, dataFromSnapshot} from "../utilities"

export async function getProject(projectId) {
  return dataFromSnapshot(
    await db
      .collection(`projects`)
      .doc(projectId)
      .get()
  )
}
