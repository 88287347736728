//	user: {}, , unsuscribeFirebase: null
// if (!auth.loadingFirebase && !auth.currentUser) this.props.history.push("/login")
// else if (!auth.loadingSolarFIT && !auth.sunkaizenUser) this.props.history.push("/register")
// else if (!auth.loadingSolarFIT) this.setState({ solarFITuser: auth.sunkaizenUser })
// const unsubscribeFirebase = auth.subscribe(user => { this.setState({ user }) })
//	, unsubscribeFirebase
// if (this.state.unsubscribeFirebase) this.state.unsubscribeFirebase()

import React from "react"
import PropTypes from "prop-types"

import Loading from "./Loading"

const typeMap = type => user => {
  if (!type) return user
  return type.includes(user.type)
}

export default class CheckRegistration extends React.Component {
  static propTypes = {
    component: PropTypes.object,
    type: PropTypes.array,
    auth: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object
  }

  state = {solarFITuser: false, unsubscribeSolarFIT: null, loading: false}

  async componentDidMount() {
    const unsubscribeSolarFIT = this.props.auth.sunkaizenUserSubscribe(user => {
      if (!this.props.auth.loadingSolarFIT) {
        if (!user && this.props.auth.currentUser) {
          console.log("pushing to register")
          this.props.history.push("/register")
        } else if (!user) this.props.history.push("/login")

        this.setState({solarFITuser: user, loading: false})
      }
    })

    this.setState({unsubscribeSolarFIT})
  }

  componentWillUnmount() {
    if (this.state.unsubscribeSolarFIT) this.state.unsubscribeSolarFIT()
  }

  render() {
    let {Component, ...rest} = this.props
    const {solarFITuser, loading} = this.state

    return (
      <div style={{width: "100%", height: "100%"}}>
        {loading && <Loading />}
        {solarFITuser && (
          <div>
            {typeMap(this.props.type)(solarFITuser) ? (
              <Component {...rest} />
            ) : (
              <h1 className="page-header" style={{padding: "3rem"}}>
                You are not authorized to view this page
              </h1>
            )}
          </div>
        )}
      </div>
    )
  }
}
