import {firebase, db} from "./utilities"

export default class DevChecker {
  constructor() {
    this.devMap = null

    this.subscriptions = []
    this.unsubscribe = null

    this.subscribe()
  }

  subscribe = () => {
    this.unsubscribe = db
      .collection("meta")
      .doc("developers")
      .onSnapshot(snap => {
        const {IDs} = snap.data() || {}
        if (IDs) {
          this.devMap = IDs
          this.subscriptions.map(subscription => {
            subscription()
          })
        }
      })
  }

  destroy = () => {
    if (this.unsubscribe) this.unsubscribe()
  }

  check = async id => (this.devMap ? this.devMap[id] : this.waitForDB(id))

  waitForDB = id =>
    new Promise((resolve, reject) => {
      this.subscriptions.push(() => resolve(this.devMap(id)))
    })
}
