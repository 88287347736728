import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import {Modal, Input} from "antd" //  , Button
import {firebase, db} from "./utilities"

const EmailLogin = () => {
  const [email, setEmail] = useState(undefined)
  const [link, setLink] = useState(window.location.href)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    console.log(firebase.auth().isSignInWithEmailLink(link))
    console.log(link)

    if (firebase.auth().isSignInWithEmailLink(link)) {
      db.collection(`email_links`)
        .where(`link`, `==`, window.location.href)
        .get()
        .then(snap => {
          console.log(snap.size)
          if (snap.size > 0) {
            const {email, link} = snap.docs[0].data()
            console.log(`link: `, link)
            setLink(link)
            if (email) setEmail(email)
            else setShowModal(true)
          }
        })
    } else {
      console.log(`the URL provided is not a signin link`)
    }
  })

  useEffect(() => {
    if (email)
      firebase
        .auth()
        .signInWithEmailLink(email, link)
        .then(result => {
          console.log(`successfully signed in`)
        })
        .catch(e => console.log(`an error occurred trying to sign in with email link: `, e))
  })

  // console.log(window.location.href)
  // console.log(email, showModal)

  return (
    <div>
      <h1 className="page-header" style={{padding: `3rem`}}>
        You are now logged in
      </h1>
      <Modal
        title="Enter your Email Address"
        visible={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
      >
        <Input placeholder="Email Address" onChange={e => setEmail(e.target.value)} />
      </Modal>
    </div>
  )
}

EmailLogin.propTypes = {
  auth: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object
}

export default EmailLogin
