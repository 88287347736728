import {firebase, db, history} from "./utilities"
import DB from './DAL/DB'

export default class Auth {
  constructor() {
    // this.googleLogin = this.googleLogin.bind(this)
    // this.usernamePasswordLogin = this.usernamePasswordLogin.bind(this)
    // this.logout = this.logout.bind(this)
    // this.isAuthenticated = this.isAuthenticated.bind(this)

    this.currentUser = undefined
    this.sunkaizenUser = undefined

    // this.subscribe = this.subscribe.bind(this)
    // this.sunkaizenUserSubscribe = this.sunkaizenUserSubscribe.bind(this)

    // this.sunkaizenUserReturned = this.sunkaizenUserReturned.bind(this)
    // this.firebaseReturned = this.firebaseReturned.bind(this)

    this.loadingFirebase = true
    this.loadingSolarFIT = true

    this.subscribers = []
    this.dbsubscribers = []

    this.databasesubscription = null

    this.subscribe(this.setSunkaizenUser)
    firebase.auth().onAuthStateChanged(async user => {
      this.loadingSolarFIT = true
      this.firebaseReturned(user)
    })
  }

  subscribe = f => {
    if (this.currentUser) f(this.currentUser)
    const n = this.subscribers.push(f)
    return () => this.subscribers.splice(n - 1, 1)
  }

  // the solarFIT user subscribe function immediately calls the argument function on the current
  // 	this.solarfitUser, and then subscribes the function to updates
  sunkaizenUserSubscribe = f => {
    f(this.sunkaizenUser)
    const n = this.dbsubscribers.push(f)

    return () => this.dbsubscribers.splice(n - 1, 1)
  }

  sunkaizenUserReturned = user => {
    // if (this.loadingSolarFIT) {
    this.sunkaizenUser = user
    this.loadingSolarFIT = false
    this.dbsubscribers.forEach(f => f(user))
    // }
  }

  firebaseReturned = user => {
    this.currentUser = user
    this.subscribers.forEach(f => f(user))

    this.loadingFirebase = false
  }

  setSunkaizenUser = user => {
    if (user && user.uid) {
      this.databasesubscription = db
        .collection("users")
        .doc(user.uid)
        .onSnapshot(r => {
          this.sunkaizenUserReturned(r.data())
        })
    } else {
      this.sunkaizenUserReturned(null)
    }
  }

  register = async (email, password, code) => {
    try {
      await this.registerWithoutErrorCatching(email, password)
    } catch (e) {
      console.log(e.code, e.message)
    }
  }

  /**
   * The `register` method catches and logs any errors. Use this method
   * instead if you need errors to be thrown normally.
   */
  registerWithoutErrorCatching = (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password)
  }

  usernamePasswordLogin = async (email, password) => {
    await firebase.auth().signInWithEmailAndPassword(email, password)
  }

  googleLogin = async () => {
    let provider = new firebase.auth.GoogleAuthProvider()

    provider.addScope("https://www.googleapis.com/auth/firebase")
    provider.addScope("profile")

    await firebase.auth().signInWithPopup(provider)
  }

  isAuthenticated = () => {
    return !!firebase.auth().currentUser
  }

  logout = async () => {
    try {
      const locks = new DB.ProjectLocks()
      const {data, ok} = await locks.get(null, {filters: [['holder', '==', this.sunkaizenUser.id]]})

      for (let lock of data) { await locks.delete(lock.id) }

      await firebase.auth().signOut()

      this.loadingSolarFIT = true
      this.loadingFirebase = true

      this.sunkaizenUser = null
      this.currentUser = null

      if (this.databasesubscription) this.databasesubscription()

      history.push("/login")
    } catch (e) {
      console.log("unable to log out", e)
    }
  }

  deleteSelf = async () => {
    try {
      await firebase.auth().currentUser.delete()
    } catch (e) {
      console.log(`failed to delete user ${firebase.auth().currentUser.id}`, e)
    }
  }

  sendPasswordResetEmail = async email => {
    console.log("sending password reset email")
    try {
      await this.sendPasswordResetEmailWithoutErrorCatching(email)
    } catch (e) {
      console.log("failed to send password reset email", e)
      throw e
    }
  }

  /**
   * The `sendPasswordResetEmail` method catches and logs any errors. Use this
   * method instead if you need errors to be thrown normally.
   */
  sendPasswordResetEmailWithoutErrorCatching = email => {
    return firebase.auth().sendPasswordResetEmail(email)
  }

  getIdToken () { return firebase.auth().currentUser.getIdToken() }
}

export const auth = new Auth()
