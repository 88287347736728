import React, {useContext} from 'react'
import DateTimePicker from './DateTimePicker'
import FieldUsers from './FieldUsers'
import {ReducerContext} from './reducer'
import {ValidateScheduling} from './utilities'
import {Button} from 'antd'
import {finalize} from './utilities'

export default function LeftPanel ({setVisible}) {
  const {state, dispatch} = useContext(ReducerContext)

  const {time, date, duration, inspector, project} = state
  const scheduled = {time, date, duration, inspector, project}
  const validator = new ValidateScheduling()

  const finalizable = validator.validate(scheduled, false)

  return (
    <div>
      { project.ignoreAvailability && <div className="bold" >Ignoring inspector availability</div> }
      <div 
        style={{flex: 4, position: 'relative', height: '70rem'}}
        className="overflow-scroll padding-1 flex-column schedule-inspections-left-panel" 
      >
        <FieldUsers />
        { inspector.id && <DateTimePicker /> }
      </div>
      <div className="full-width padding-2" style={{visibility: finalizable ? 'visible' : 'hidden', height: finalizable ? 40 : 0, transition: 'height 1s'}} >
        <Button 
          onClick={async () => { 
            await finalize(scheduled, dispatch, state) 
            if (setVisible) setVisible(false)
          }} 
          style={{alignSelf: 'flex-end', height: 'inherit'}} type="primary" 
        >
                    Schedule Inspection
        </Button>
      </div>        
    </div>
  )    
}