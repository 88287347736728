import React from "react"
// import PropTypes from 'prop-types'

// import { connect } from 'react-redux'

import {Radio} from "antd"

const events = [`Pass`, `Fail`, `Missed`, `N/A`]

const Templated = () => (
  <Radio.Group style={{pointerEvents: `none`}}>
    {events.map((event, i) => (
      <Radio value={event} key={i}>
        {event}
      </Radio>
    ))}
  </Radio.Group>
)

export default Templated
