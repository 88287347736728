import React, {useContext, useReducer} from "react"
import PropTypes from "prop-types"

import {Form, Icon, Input} from "antd" //	, Select, Checkbox, DatePicker, Button
import {fieldNameConverter} from "../validators"

import {setField} from "./action_creators"
import {connect} from "react-redux"

const mapStateToProps = state => ({fields: state.fields})
const mapDispatchToProps = dispatch => ({setField: field => value => dispatch(setField(field, value))})

const SiteRepresentative = ({fields, setField}) => {
  const requiredRep = fields.sameRep

  return (
    <div
      style={{
        display: `flex`,
        flexFlow: `column nowrap`,
        alignItems: `flex-start`,
        background: `#F4F6F8`,
        padding: `0 2rem 2rem 2rem`,
        margin: `0 0 1rem 0`
      }}
    >
      <h4>Site Contact Information</h4>
      <div style={{width: `100%`, display: `flex`, flexFlow: `column nowrap`, alignItems: `flex-start`}}>
        {requiredRep && (
          <div style={{width: `100%`}}>
            <div style={{display: `flex`, flexFlow: `row nowrap`, alignItems: `center`, color: `red`}}>
              <Icon type="info-circle" />
              <div style={{padding: `0 0 0 0.5rem`}}>Required</div>
            </div>
            <Form.Item style={{margin: `0 0 1rem 0`, width: `100%`}}>
              <Input
                size="large"
                onChange={e => {
                  console.log(1)
                  setField(`siteRepName`)(e.target.value)
                }}
                value={fields.siteRepName}
                placeholder={fieldNameConverter[`siteRepName`]}
                autocomplete="new-password"
                data-cy="siteRepName"
              />
            </Form.Item>
          </div>
        )}
        <div style={{display: `flex`, flexFlow: `row nowrap`, justifyContent: `space-between`, width: `100%`}}>
          <div style={{flex: 10}}>
            <div style={{display: `flex`, flexFlow: `row nowrap`, alignItems: `center`, color: `red`}}>
              <Icon type="info-circle" />
              <div style={{padding: `0 0 0 0.5rem`}}>Required</div>
            </div>
            <Form.Item style={{margin: `0 0 1rem 0`, padding: `0 1rem 0 0`, flex: 1}}>
              <Input
                size="large"
                placeholder={fieldNameConverter[`siteRepEmail`]}
                onChange={e => setField(`siteRepEmail`)(e.target.value)}
                data-cy="siteRepEmail"
                value={fields.siteRepEmail}
                autocomplete="new-password"
              />
            </Form.Item>
          </div>
          <div style={{flex: 1}}></div>
          <div style={{flex: 10}}>
            <div style={{display: `flex`, flexFlow: `row nowrap`, alignItems: `center`, color: `red`}}>
              <Icon type="info-circle" />
              <div style={{padding: `0 0 0 0.5rem`}}>Required</div>
            </div>
            <Form.Item style={{margin: `0 0 1rem 0`, padding: `0 0 0 1rem`, flex: 1}}>
              <Input
                value={fields.siteRepPhone}
                onChange={e => setField(`siteRepPhone`)(e.target.value)}
                size="large"
                placeholder={fieldNameConverter[`siteRepPhone`]}
                data-cy="siteRepPhone"
                autocomplete="new-password"
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  )
}

const WrappedSiteRepresentative = connect(mapStateToProps, mapDispatchToProps)(SiteRepresentative)
export default WrappedSiteRepresentative
