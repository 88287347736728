import React from "react"
import {Input} from "antd"
import {CloseOutlined} from '@ant-design/icons'
import {setQueryParams, setTableControls} from "../action_creators"
import {connect} from "react-redux"
import {onChange} from "../onChange"

const sToP = store => ({
  fullTextQuery: store.query.fullTextQuery,
  searchbar: store.tableVars.tableStrategy.searchbar,
  tableControls: store.tableControls
})
const dToP = dispatch => ({
  setFullTextQuery: text => dispatch(setQueryParams({fullTextQuery: text})),
  resetFrom: () => dispatch(setQueryParams({from: 0})),
  resetTablePage: () => dispatch(setTableControls({pagination: {current: 1}}))
})

function Searchbar ({searchbar, style, fullTextQuery, setFullTextQuery, resetFrom, resetTablePage, tableControls}) {
  const a = <CloseOutlined onClick={() => setFullTextQuery('')} />
  return (
    <div style={{...style}}>
      {searchbar
        ?   <form
          className="full-width flex-row" style={{...style}}
          onSubmit={e => {
            e.preventDefault()
            resetTablePage()
            resetFrom()
            onChange(tableControls)
          }}
        >
          <Input
            className="filter-bar-searchbar"
            type="text"
            value={fullTextQuery}
            onChange={e => setFullTextQuery(e.target.value)}
            placeholder={`Search`}
            addonAfter={a}
          />
        </form>
        :   <div></div>
      }
    </div>
  )
}

const WrappedSearchbar = connect(sToP, dToP)(Searchbar)
export default WrappedSearchbar
