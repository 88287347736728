export default function filterIsSame (newFilter, oldFilter) {
  const newF = {...newFilter}
  const oldF = {...oldFilter}

  if (!newF.created) newF.created = {}
  if (!newF.updated) newF.updated = {}

  if (!oldF.created) oldF.created = {}
  if (!oldF.updated) oldF.updated = {}

  const newString = JSON.stringify(newF)
  const oldString = JSON.stringify(oldF)

  return newString === oldString
}