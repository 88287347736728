import React from "react"
import PropTypes from "prop-types"

import {Form, Card, Select} from "antd"
import {connect} from "react-redux"

import {setField} from "./action_creators"

import {componentDisplayNames} from "./FieldTypes"
import {PropertyBox} from "./PropertyBoxes"

const mapStateToProps = state => ({
  field: state.form.fields[state.active.field] || {},
  id: state.active.field,
  status: state.status.field,
  active: state.active.field
})

const mapDispatchToProps = dispatch => ({
  setField: ({id, value}) => dispatch(setField({category: "fields", id, value}))
})

class FieldDetail extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    active: PropTypes.string,
    field: PropTypes.object,
    status: PropTypes.bool,
    setField: PropTypes.func
  }

  render = () => (
    <div>
      {this.props.active && (
        <Card title="Field Properties" style={{overflowY: "auto", marginTop: "1.5rem", height: "100rem"}}>
          <Form layout="inline" onSubmit={() => console.log("submit handler")}>
            <Form.Item label="Type">
              <Select
                style={{width: "24rem", marginLeft: "2rem"}}
                value={this.props.field.type || null}
                onChange={e => this.props.setField({id: this.props.id, value: {...this.props.field, type: e}})}
              >
                {Object.entries(componentDisplayNames).sort().map(([display, name], i) => (
                  <Select.Option key={i} value={name}>
                    {display}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <hr />
          </Form>
          <PropertyBox type={this.props.field.type} id={this.props.field.id} />
        </Card>
      )}
    </div>
  )
}

const WrappedFieldDetail = connect(mapStateToProps, mapDispatchToProps)(FieldDetail)

export default WrappedFieldDetail
