import React, {useState, useRef} from "react"

import {withRouter} from "react-router"
import {Modal, Button} from "antd"
import {onChange} from '../onChange'
import {capitalizeFirst, singular} from '../../utilities'
import {setQueryParams} from "../action_creators"
import {connect} from "react-redux"
import {colors} from '../../config'

const sToP = state => ({
  createButton: state.tableVars.tableStrategy.create,
  bulk: state.tableVars.tableStrategy.bulk,
  tableControls: state.tableControls,
  type: state.tableVars.tableStrategy.type
})

const dToP = dispatch => ({
  setQueryParams: () => dispatch(setQueryParams({from: 0}))
})

function ResourceCreate ({createButton, bulk, type, setQueryParams, tableControls}) {
  const [show, setShow] = useState(false)
  // const [num, setNum] = useState('one')
  const [component, setComponent] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [done, setDone] = useState(null)
  const [selfFinalizer, setSelfFinalizer] = useState(null)
  const [number, setNumber] = useState(null)

  const child = useRef()

  async function onOk () {
    if (selfFinalizer) {
      setDone(true)
      setShow(false)
    }
    else await submit()
  }

  async function submit () {
    setDisabled(true)
    try {
      await child.current.finalize()
      setShow(false)
      setQueryParams()
      onChange(tableControls)
    }
    catch (e) {
      console.log('an error occurred', e)
    }
    finally {
      setDisabled(false)
    }
  }

  function create (n = 'one') {
    setNumber(n)
    setComponent(n === 'one' ? createButton : bulk)
    setDone(null)
    setShow(true)
  }

  const M = component

  // the colors.primary and colors.tertiary on the buttons is necessary to override the antd style
  return createButton
    ? (
      <div>
        <div className="flex-row align-center" style={{height: '5rem'}} >
          <Button 
            style={{flex: 1, height: '4rem', background: colors.primary, color: 'white', margin: '0 0.3rem 0 0.3rem'}} 
            onClick={() => create('one')}
          >
                        Add New {capitalizeFirst(number === 'one' ? singular[type] : singular[type])}
          </Button>
          { bulk
            ? <Button 
              style={{flex: 1, height: '4rem', background: colors.tertiary, color: 'white', margin: '0 0.3rem 0 0.3rem'}} 
              onClick={() => create('many')}
            >
                    Add Bulk {capitalizeFirst(type)}
            </Button>
            : <div style={{flex: 1}} ></div>
          }
        </div>
        <Modal
          title={<h2>Create New {capitalizeFirst(number === 'one' ? singular[type] : type)}</h2>}
          visible={show}
          onOk={onOk}
          okButtonProps={{disabled}}
          onCancel={() => setShow(false)}
          width="90%"
          maskStyle={{background: 'rgba(0, 0, 0, 0.3)'}}
        >
          {M && <M ref={child} setDone={setDone} done={done} close={!show} selfFinalizer={setSelfFinalizer} />}
        </Modal>
      </div>
    )
    : <div></div>
}

const WrappedResourceCreate = withRouter(connect(sToP, dToP)(ResourceCreate))
export default WrappedResourceCreate
