import {v4 as uuidv4} from "uuid"

import {CHANGE_TICKET_INFORMATION} from "../action_creators"

function makeTicket(number) {
  return {
    id: uuidv4(),
    title: `ticket title #${number}`,
    description: `ticket description #${number}`,
    assignedTo: `ticket assigned to #${number}`
  }
}

export const ticketsById = (state = {NEW_TICKET: {}}, action) => {
  switch (action.type) {
  case CHANGE_TICKET_INFORMATION: {
    const updatedTicket = {...state[action.id], [action.field]: action.value}
    return {...state, [action.id]: updatedTicket}
  }
  default:
    return state
  }
}
