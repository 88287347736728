import React from "react"
import _ from "lodash"
import {Card} from "antd"

import * as Model from "./model"
import {RubricQuestion} from "./RubricQuestion"
import {displayCount} from "../utilities"

export function RubricSection(props) {
  const {section} = props
  const questions = Model.getSectionQuestions(section)

  return (
    <Card
      title={
        <div style={{display: `flex`, justifyContent: `space-between`}}>
          <strong>{section.title}</strong>
          <span style={{color: `#8A8C8F`}}>
            {displayCount({
              count: questions.length,
              none: `0 questions`,
              singular: `question`
            })}
            {` `}
            available for scoring
          </span>
        </div>
      }
      style={{
        margin: `1.5rem`,
        textAlign: `left`
      }}
    >
      {_.isEmpty(questions) && <div>No scorable questions for this section.</div>}
      {questions.map(question => (
        <RubricQuestion {...props} key={question.id} question={question} style={{marginBottom: `1rem`}} />
      ))}
    </Card>
  )
}
