import React from "react"
import {Select, Icon, Tooltip} from "antd" //	Input,
import PhotoRequired from "./PhotoRequired"
import Followup from "./Followup"
import {connect} from "react-redux"
import {setField} from "../../action_creators"

const stop = (state, ownProps) => ({
  field: state.fields[ownProps.id],
  value: state.fields[ownProps.id].value
})
const dtop = dispatch => ({setField: ({id, value}) => dispatch(setField(id, value))})

export function MultiSelect ({setField, field, id, question, label, options = [], required, tipText, value, photoRequired}) {
  let {values = {}, followup = {}} = value || {}

  const ff = {}
  options.forEach(option => (ff[option.label] = {}))
  followup = {...ff, ...followup}

  function set (v) {
    const vv = {}
    v.forEach(value => (vv[value] = true))
    field.value = {values: {...vv}, followup}
    setField({id, value: field})
  }

  return (
    <div>
      {required && (
        <div className="bold flex-row" >
          <Tooltip title="Required">
            <Icon type="exclamation-circle" style={{fontSize: "2rem", padding: "0 1rem 0 0"}} />
          </Tooltip>
          <div>REQUIRED</div>
        </div>
      )}
      <div className="bold padding-bottom-1" >{question}</div>
      <div className="flex-column full-width align-start" >
        <div className="padding-right-1" style={{fontFamily: "Arial, serif", fontSize: "85%"}}>{label.toUpperCase()}</div>
        <div>{tipText && `(${tipText})`}</div>
        <div className="full-width" >
          <Select
            mode="multiple"
            style={{width: "100%"}}
            placeholder={tipText}
            onChange={set}
            value={Object.keys(values)}
          >
            {options.map((option, i) => (
              <Select.Option style={{padding: "0.5rem"}} key={i} value={option.label}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
          {options.map(option => (values[option.label] ? <Followup id={id} option={option} /> : <div></div>))}
        </div>
      </div>
      {photoRequired && (!field.images || Object.keys(field.images).length < 1) && <PhotoRequired />}
    </div>
  )
}

const WrappedMultiSelect = connect(stop, dtop)(MultiSelect)
export default WrappedMultiSelect
