import {auth} from "../../Auth"

export default function getResources (makeSubscription, readDB) {
  return function () {
    makeSubscription(
      {
        paths: [{collection: 'csvFilters'}], 
        options: {where: [{field: 'user', test: '==', value: auth.sunkaizenUser.id}]}
      }, 
      'filters'
    )

    makeSubscription(
      {
        paths: [{collection: 'users'}],
        options: {where: [{field: 'type', test: 'in', value: ['quality-manager', 'installer']}]}
      }, 
      'users'
    )


    const clientOps = {
      paths: [{collection: 'users'}],
      options: {where: [{field: 'type', test: '==', value: 'client'}]}
    }

    if (auth.sunkaizenUser.type === 'client') {
      clientOps.options.where.push({field: 'id', test: '==', value: auth.sunkaizenUser.id})
    }
    makeSubscription(clientOps, 'clients')

    makeSubscription({paths: [{collection: 'forms'}]}, 'forms')
    makeSubscription({paths: [{collection: 'subscriptions'}]}, 'subscriptions')
    
    readDB({paths: [{collection: 'meta', doc: 'project_statuses'}]}, 'projectStatuses')        
  }
}