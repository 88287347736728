/**
 * The Rubric Model includes all things related to manipulating the data structure of
 * Rubrics, from interacting with the database, to validation, to business logic.
 */
import _ from "lodash"
import produce from "immer"

import {db, dataFromSnapshot} from "../utilities"

/**
 * Returns a form document.
 */
export async function getForm({formId}) {
  const formQuerySnapshot = await db
    .collection(`forms`)
    .doc(formId)
    .get()
  return dataFromSnapshot(formQuerySnapshot)
}

async function _getSections({formId}) {
  const sectionQuerySnapshot = await db
    .collection(`forms`)
    .doc(formId)
    .collection(`sections`)
    .get()
  return dataFromSnapshot(sectionQuerySnapshot)
}

/**
 * Joins sections with their field subcollection data, returning a section
 * object containing an array of `fields`.
 */
function _addFieldDataToSections({formId, sections}) {
  const promises = []

  sections.forEach(section => {
    const ref = db
      .collection(`forms`)
      .doc(formId)
      .collection(`sections`)
      .doc(section.id)
      .collection(`fields`)
      .get()
      .then(fieldQuerySnapshot => {
        const fields = dataFromSnapshot(fieldQuerySnapshot)
        return {...section, fields}
      })
    promises.push(ref)
  })

  return Promise.all(promises)
}

/**
 * The form comes with an `order` that we can use to sort the sections
 */
function orderSectionsByFormOrder({form, sections}) {
  return _.sortBy(sections, section => _.findIndex(form.order, sectionId => section.id === sectionId))
}

// Sort the fields so that they match `section.order`, which is an array of the
// field ids in the order they should be displayed.
function orderFormFieldsWithinSection({section}) {
  return {
    ...section,
    fields: _.sortBy(section.fields, field => _.findIndex(section.order, fieldId => fieldId === field.id))
  }
}

/**
 * Returns all the form fields grouped by section, with fields in the same order
 * as their section.
 *
 * If a section didn't have fields associated with it, it is excluded.
 *
 * Takes a `form` document (which must include an `id` field).
 */
export async function getAllFormFieldsBySection({form}) {
  const formId = form.id

  const sections = await _getSections({formId})
  let sectionsWithFields = await _addFieldDataToSections({
    formId,
    sections
  })

  // Only keep sections with non-empty fields.
  sectionsWithFields = _.filter(sectionsWithFields, ({fields}) => !_.isEmpty(fields))

  // Order the sections according to form order.
  sectionsWithFields = orderSectionsByFormOrder({
    form,
    sections: sectionsWithFields
  })

  return sectionsWithFields.map(section => orderFormFieldsWithinSection({section}))
}

if (process.env.NODE_ENV === `development`) {
  window.getAllFormFieldsBySection = getAllFormFieldsBySection
}

export function makeRubric({id, formId, name, questions, scoringSystemId}) {
  return {
    id,
    formId: formId,
    name,
    questions: questions || {},
    scoringSystemId: scoringSystemId || null
  }
}

/**
 * The listener will be passed an array of rubrics.
 */
export function subscribeToRubrics(formId, listener) {
  return db
    .collection(`rubrics`)
    .where(`formId`, `==`, formId)
    .onSnapshot(querySnapshot => querySnapshot && querySnapshot.docs && listener(dataFromSnapshot(querySnapshot)))
}

export function deleteRubric({rubricId}) {
  return db
    .collection(`rubrics`)
    .doc(rubricId)
    .delete()
}

export function updateRubric({rubricId, update}) {
  return db
    .collection(`rubrics`)
    .doc(rubricId)
    .set(update, {merge: true})
}

/**
 * Skips over any rubrics which don't have a scoringSystemId defined.
 */
export function joinScoringSystemsToRubrics({scoringSystems, rubrics}) {
  const newRubrics = produce(rubrics, draft => {
    // eslint-disable-next-line
    for (let rubric of draft) {
      if (rubric.scoringSystemId) {
        rubric.scoringSystem = _.find(scoringSystems, x => x.id === rubric.scoringSystemId)
      }
    }
  })
  return newRubrics
}

export const allowedQuestionTypes = [`radio-button`, `dropdown`, `templated`]

/**
 * Not all section questions are used in the rubric. We filter out the ones we
 * want using this function. Furthermore, valid question types with no available
 * options are also excluded. See the `allowedQuestionTypes` to see which
 * question types this function returns, modify that value as needed.
 */
export function getSectionQuestions(section) {
  return _.filter(
    section.fields,
    field => !_.isEmpty(field.options) && _.find(allowedQuestionTypes, allowedType => allowedType === field.type)
  )
}

/**
 * Compares rubric question data with sections question data to determine if all
 * fields are present, and then looks for at least one Pass and one Fail. For
 * now, look at the implementation to see the different data it returns.
 */
export function validateRubricQuestions({rubrics, sections}) {
  console.log(`sections`, sections)
  console.log(`rubrics`, rubrics)
  const sectionQuestions = sections.map(section => getSectionQuestions(section)).reduce((a, c) => [...a, ...c], [])

  let invalidQuestions = {}

  // eslint-disable-next-line
  for (let rubric of rubrics) {
    const rubricQuestions = rubric.questions
    // console.log("rubricQuestions", rubricQuestions)
    // console.log("sectionQuestions", sectionQuestions)

    for (let questionId in rubricQuestions) {
      const rubricQuestion = rubricQuestions[questionId]

      // Empty criticalities should skip validation
      if (!rubricQuestion.criticalityCategory) {
        invalidQuestions[questionId] = {
          error: 'missing criticalityCategory',
          rubric,
          question: rubricQuestion
        }
        continue
      }

      const sectionQuestion = _.find(sectionQuestions, q => q.id === questionId)
      // console.log("sectionQuestion", sectionQuestion)
      // console.log("rubricQuestion", rubricQuestion)

      // Check if any fields are missing.
      if (_.get(sectionQuestion, `options`, []).length !== Object.values(_.get(rubricQuestion, `options`, [])).length) {
        const errorMessage = sectionQuestion.label
          ? `"${sectionQuestion.label}" for rubric "${rubric.name}" has blank fields`
          : `rubric "${rubric.name}" is missing a label`

        invalidQuestions[questionId] = {
          error: errorMessage,
          rubric,
          question: rubricQuestion
        }
      }
      // Check that there's at least one pass and fail.
      let passCount = 0
      let failCount = 0
      // eslint-disable-next-line
      for (let optionKey in rubricQuestion.options) {
        if (rubricQuestion.options[optionKey] === `Pass`) passCount += 1
        if (rubricQuestion.options[optionKey] === `Fail`) failCount += 1
      }
      if (passCount < 1 || failCount < 1) {
        let sectionQuestionName
        if (sectionQuestionName) {
          sectionQuestionName = `"${sectionQuestion.label}"`
        } else {
          if (sectionQuestion.question) {
            sectionQuestionName = `Question "${sectionQuestion.question}"`
          } else {
            sectionQuestionName = `One of your questions`
          }
        }
        invalidQuestions[questionId] = {
          error: `${sectionQuestionName} for rubric "${rubric.name}" must have at least one Pass and one Fail`,
          rubric,
          question: rubricQuestion
        }
      }
    }
  }

  return invalidQuestions
}

/**
 * Rubrics contain a (possibly `null`) `scoringSystemId`. We can use this, and a
 * collection of rubrics, to lookup the rubric associated with any scoring
 * system.
 *
 * Returns `null` if there was no match, and `undefined` if there was no
 * `scoringSystemId` on the rubric.
 */
export function getRubricScoringSystem({rubric, scoringSystems}) {
  const id = rubric.scoringSystemId
  if (id) {
    return _.find(scoringSystems, scoringSystem => scoringSystem.id === id) || null
  } else {
    return undefined
  }
}

/**
 * The Rubric model uses questions in a particular way. This function does a
 * basic transformation from a section question to the rubric format.
 */
export function convertQuestionToRubricFormat({question, criticalityCategory = null}) {
  const options = {}
  // eslint-disable-next-line
  for (let option of question.options) {
    options[option.label] = null
  }
  return [question.id, {criticalityCategory, options: options}]
}

/**
 * Returns a new Rubric object.
 */
export function setRubricQuestionOptionContributionType({rubric, question, optionLabel, optionContributionType}) {
  rubric = _.cloneDeep(rubric)
  const options = _.get(rubric, [`questions`, question.id, `options`], {})
  _.set(rubric, [`questions`, question.id, `options`], {
    ...options,
    [optionLabel]: optionContributionType
  })
  options[optionLabel] = optionContributionType
  return rubric
}
