import {image, selectedImage, shape} from './mapIcons'
import {contentString} from '../utilities'

export default function addProjects (state, pins, onClick) {
  const {project, projects, mapping: {map}} = state

  for (let i in pins) { pins[i].setMap(null) }

  const pp = {}
  Object.values(projects).forEach(({
    id, site: {siteRepEmail: email, siteRepName: contact, siteRepPhone: phone, geocode_results} = {}
  }) => {
    const {
      formatted_address: title, geometry: {location: position}
    } = geocode_results || {formatted_address: {}, geometry: {location: {}}}

    const marker = new window.google.maps.Marker({
      position, title, map, shape, icon: id === project.id ? selectedImage() : image()
    })
    const infoWindow = new window.google.maps.InfoWindow({
      content: contentString({id, name: title, contact, phone, email})
    })

    marker.addListener("click", () => { 
      onClick(projects[id]) 
      infoWindow.open(map, marker)
    })

    pp[id] = marker
  })

  return pp
}