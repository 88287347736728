import React, {useEffect, useContext} from "react"
import {ReducerContext} from "./reducer"
import {GenericSelector} from './GenericSelector'
import DB from '../DAL/DB'

const analytics = new DB.Analytics()

export default function Dropdowns () {
  const {state, dispatch} = useContext(ReducerContext)

  // get rubrics
  useEffect(function () {
    (async function () {
      if (!state.dropdown.form) return
      const {ok, data} = await analytics.get(null, {filters: [[`form_id`, `==`, state.dropdown.form]]})
      if (ok) {
        const rubrics = {}
        for (let resource of data) {rubrics[resource.id] = resource}
        dispatch({type: `rubrics`, value: rubrics})
      }
    })()
  }, [state.dropdown.form])

  // get aggregations
  useEffect(function () {
    // state.rubrics must exist for state.dropdown.rubric to exist
    if (state.dropdown.rubric) {
      const aggregations = state.rubrics[state.dropdown.rubric].aggregation_types
      dispatch({type: `aggregations`, value: aggregations})
    }
  }, [state.dropdown.rubric])

  // get data
  useEffect(function () {
    (async function () {
      if (!state.dropdown.rubric || !state.dropdown.aggregation) return
      const aggregations = new DB.Aggregations()
      const {ok, data: [aggregation]} = await aggregations.get(
        null,
        {filters : [[`rubric`, `==`, state.dropdown.rubric], [`aggregation_type`, `==`, state.dropdown.aggregation]]}
      )
      
      if (ok) dispatch({type: `data`, value: aggregation})
    })()        
  }, [state.dropdown.aggregation])

  // form, rubric, aggregation
  function setDropdown(type, value) {
    if (state[type] === value) return

    switch (type) {
    case `form`: return dispatch({type: `dropdown`, value: {form: value}})
    case `rubric`: return dispatch({type: `dropdown`, value: {form: state.dropdown.form, rubric: value}})
    case `aggregation`: return dispatch({
      type: `dropdown`, 
      value: { 
        form: state.dropdown.form, 
        rubric: state.dropdown.rubric, 
        aggregation: value
      }})
    default: throw new Error(`provided a type that is not allowed (${type})`)
    }
  }    

  return (
    <div className="flex-row selectors" >
      <div style={{flex: 1}} >
        <GenericSelector
          className="first"
          placeholder="Select Form"
          values={state.forms}
          valueTitle="title"
          selectedValue={state.dropdown.form}
          onChange={value => setDropdown(`form`, value)}
        />
      </div>
      {/* TODO: Get the name of the rubric to appear as the field title */}
      <div style={{flex: 1}} >
        <GenericSelector
          placeholder={state.rubrics ? `Select Rubric` : `Please Select a Form First`}
          values={Object.values(state.rubrics || {}).sort()}
          valueTitle="rubric_name"
          selectedValue={state.dropdown.rubric}
          onChange={value => setDropdown(`rubric`, value)}
        />
      </div>
      <div style={{flex: 1}} >
        <GenericSelector
          placeholder={state.aggregations ? `Select Aggregation Type` : `Please Select a Rubric First`}
          values={(state.aggregations || []).map(x => ({id: x}))}
          valueTitle="id"
          selectedValue={state.dropdown.aggregation}
          onChange={value => setDropdown(`aggregation`, value)}
        />
      </div>
    </div>
  )
}