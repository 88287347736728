export default function contentString ({id, name, contact, phone, email}) {
  return (
    `
            <div>
                <div>${id}</div>
                <div>${name}</div>
                <hr />
                <div><span>Site Contact: </span> ${contact}</div>
                <div><span>Site Phone: </span> ${phone}</div>
                <div><span>Site Email: </span> ${email}</div>
            </div>
        `
  )
}