import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
import {setPopup} from './action_creators'

const stop = state => ({
  x: (state.tableVars.popup || {}).x,
  y: (state.tableVars.popup || {}).y,
  record: (state.tableVars.popup || {}).record
})

const dtop = {setPopup}

export function ContextPopup ({setPopup, x, y, record}) {
  useEffect(function () {
    if (!x || !y) setPopup(null, null, null)
        
    document.addEventListener('click', function closePopup () {
      setPopup(null, null, null)
      document.removeEventListener('click', closePopup)
    })
  }, [x, y])
    
  return (
    <div>
      {x && y && 
                <div 
                  className="context-popup"
                  style={{left: `${x}px`, top: `${y}px`}} 
                  onClick={() => console.log('selected Testing')} 
                >
                    Context Menu {record.siteOwner}
                </div>            
      }
    </div>
  )
}

const WrappedContextPopup = connect(stop, dtop)(ContextPopup)
export default WrappedContextPopup