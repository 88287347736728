import React from "react"
// import PropTypes from 'prop-types'
import modules from "./Modules"
import {List} from "antd"
import {Link} from "react-router-dom"

const {ProjectsNearingDeadline, ReasonsForCancellation, ProjectAgeAtTimeOfCancellation} = modules

const Admin = ({projects}) => (
  <div style={{display: `flex`, flexFlow: `row nowrap`, width: `100%`}}>
    <div style={{flex: 1}}>
      <ProjectsNearingDeadline projects={projects} />
    </div>
    <div style={{flex: 1, display: `flex`, flexFlow: `column`, padding: `0.5rem`}}>
      <div style={{flex: 1}}>
        <ReasonsForCancellation projects={projects} />
      </div>
      <div style={{flex: 1}}>
        <ProjectAgeAtTimeOfCancellation projects={projects} />
      </div>
    </div>
  </div>
)

const FieldUser = ({projects}) => (
  <div style={{display: `flex`, flexFlow: `row nowrap`, width: `100%`}}>
    <ProjectsNearingDeadline projects={projects} />
    <div style={{display: `flex`, flexFlow: `column`, padding: `0.5rem`}}></div>
  </div>
)

const QualityManager = ({projects}) => (
  <div style={{display: `flex`, flexFlow: `row nowrap`, width: `100%`}}>
    <ProjectsNearingDeadline projects={projects} />
    <div style={{display: `flex`, flexFlow: `column`, padding: `0.5rem`}}></div>
  </div>
)

// const Scheduler = ({ projects }) => (
// 	<div style={ { display: "flex", flexFlow: "row nowrap" } } >
// 		<ProjectsNearingDeadline projects={ projects } />
// 		<div style={ { display: "flex", flexFlow: "column", padding: "0.5rem" } } >
// 			<ReasonsForCancellation projects={ projects } />
// 			<ProjectAgeAtTimeOfCancellation { projects } />
// 		</div>
// 	</div>
// )

const Scheduler = () => <div></div>

const {FailuresOfPriorityMeasures, InspectionQualityByRegion} = modules

const Client = ({auth, projects}) => (
  <div style={{display: `flex`, flexFlow: `row nowrap`, width: `100%`}}>
    <div style={{display: `flex`, flex: 1, width: `100%`, position: `relative`, margin: `0 1rem 0 0`}}>
      <div
        style={{
          zIndex: 5,
          background: `rgba(0, 0, 0, 0.5)`,
          position: `absolute`,
          flex: 1,
          height: `100%`,
          width: `100%`,
          alignItems: `center`,
          justifyContent: `center`,
          fontSize: 36,
          fontWeight: `bold`,
          color: `white`,
          display: `flex`
        }}
      >
        Coming Soon
      </div>
      <FailuresOfPriorityMeasures auth={auth} projects={projects} />
    </div>
    <div style={{display: `flex`, flex: 1, width: `100%`, position: `relative`, margin: `0 1rem 0 0`}}>
      <div
        style={{
          zIndex: 5,
          background: `rgba(0, 0, 0, 0.5)`,
          position: `absolute`,
          flex: 1,
          height: `100%`,
          width: `100%`,
          alignItems: `center`,
          justifyContent: `center`,
          fontSize: 36,
          fontWeight: `bold`,
          color: `white`,
          display: `flex`
        }}
      >
        Coming Soon
      </div>
      <InspectionQualityByRegion projects={projects} />
    </div>
  </div>
)

const Installer = ({auth, projects}) => (
  <div style={{display: `flex`, flexFlow: `column`, width: `100%`}}>
    <List
      header={
        <div>
          <h3>Your Projects</h3>
          <div style={{display: `flex`, flexFlow: `row nowrap`}}>
            <div style={{flex: 1, fontWeight: `bold`}}>Owner</div>
            <div style={{flex: 2, fontWeight: `bold`}}>Location</div>
            <div style={{flex: 1, fontWeight: `bold`}}>Client</div>
            <div style={{flex: 1, fontWeight: `bold`}}>Type</div>
            <div style={{flex: 1, fontWeight: `bold`}}>Created</div>
            <div style={{flex: 1, fontWeight: `bold`}}>Status</div>
          </div>
        </div>
      }
      bordered
      style={{padding: `0 0 2rem 0`}}
      size="small"
      dataSource={projects}
      renderItem={project => (
        <Link to={`/projects/${project.id}/manage`} style={{display: `flex`, flexFlow: `row nowrap`, padding: `1rem`}}>
          <div style={{flex: 1}}>{project.site.siteOwnerName || `No Owner Listed`}</div>
          <div style={{flex: 2}}>{project.site.siteAddress}</div>
          <div style={{flex: 1}}>{project.client.name}</div>
          <div style={{flex: 1}}>{project.intake.title}</div>
          <div style={{flex: 1}}>{project.created.toDate().toDateString()}</div>
          <div style={{flex: 1}}>{project.status}</div>
        </Link>
      )}
    />
    <div style={{display: `flex`, flexFlow: `column`}}>
      <div style={{display: `flex`, flex: 1, width: `100%`, position: `relative`, margin: `0 1rem 0 0`}}>
        <div
          style={{
            zIndex: 5,
            background: `rgba(0, 0, 0, 0.5)`,
            position: `absolute`,
            flex: 1,
            height: `100%`,
            width: `100%`,
            alignItems: `center`,
            justifyContent: `center`,
            fontSize: 36,
            fontWeight: `bold`,
            color: `white`,
            display: `flex`
          }}
        >
          Coming Soon
        </div>
        <FailuresOfPriorityMeasures auth={auth} projects={projects} />
      </div>
      <div style={{display: `flex`, flex: 1, width: `100%`, position: `relative`, margin: `0 1rem 0 0`}}>
        <div
          style={{
            zIndex: 5,
            background: `rgba(0, 0, 0, 0.5)`,
            position: `absolute`,
            flex: 1,
            height: `100%`,
            width: `100%`,
            alignItems: `center`,
            justifyContent: `center`,
            fontSize: 36,
            fontWeight: `bold`,
            color: `white`,
            display: `flex`
          }}
        >
          Coming Soon
        </div>
        <InspectionQualityByRegion projects={projects} />
      </div>
    </div>
  </div>
)

const moduleMap = {
  "super-admin": Admin,
  admin: Admin,
  "field-user": FieldUser,
  "quality-manager": QualityManager,
  scheduler: Scheduler,
  client: Client,
  installer: Installer
}

const VariableFields = ({auth, type = `admin`, projects}) => {
  const C = moduleMap[type]
  return <C auth={auth} projects={projects} />
}

export default VariableFields
