import moment from 'moment'


// get all availabilities that match the date supplied
export default function makeCurrentDayAvailability (availability, date, ignoreAvailability) {
  const currentDayAvailability = {}

  if (ignoreAvailability) {
    for (let h = 1; h < 25; h++) {
      for (let m = 0; m < 56; m += 5) {
        currentDayAvailability[`${h}:${m}`] = true
      }
    }
        
    return currentDayAvailability
  }

  for (let i in availability) {
    const {date: startDate, endDate} = availability[i]
    let [sd, ed] = [moment(startDate.toDate()), moment(endDate.toDate())]

    // does the availability event encompass the current day?
    if (sd.clone().startOf('day') <= date && ed.clone().endOf('day') >= date) {
      // if so, does it start/end on the current day?
      if (!sd.isSame(date, 'day')) sd = date.clone().startOf('day')
      if (!ed.isSame(date, 'day')) ed = date.clone().endOf('day')

      do {
        currentDayAvailability[sd.format('k:m')] = true
        sd.minutes(sd.minutes() + 5)
      } while (sd < ed)
    }
  }

  return currentDayAvailability
}