export function transformData (aggregationData) {
  const {questions, aggregation_type: aggregationType} = aggregationData

  const better = []
  const worse = []

  questions.map(question => {
    const questionWithoutResults = {...question}
    delete questionWithoutResults.results

    return question.results.map(result => ({
      ...result,
      question: questionWithoutResults
    }))
  })
    .flat()
    .filter(result => result.pvalue < 0.05)
    .forEach(result => {
      result.avg > result.question.global_avg 
        ? better.push(result) 
        : worse.push(result)
    })

  return {
    better: convertResultsToListData(better, aggregationType), 
    worse: convertResultsToListData(worse, aggregationType), 
    aggregationType
  }
}

function convertResultsToListData (results, type) {
  // group by the user who got the result
  const groupedResults = {}
  results.forEach(result => groupedResults[result[type]] = groupedResults[result[type]] 
    ? [...groupedResults[result[type]], result]
    : [result])

  // generate the final object holding the needed data
  const listData = []
  Object.entries(groupedResults).forEach(([name, results]) => listData.push({
    [type]: name,
    listOfMeasures: results.map(result => result.question.question).join(', '),
    numMeasures: results.length
  }))

  return listData
}