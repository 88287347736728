import React from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {Checkbox} from "antd"

import {setIntakeFailureSelection} from "../action_creators"

const mapStateToProps = null

const mapDispatchToProps = {setIntakeFailureSelection}

export let IntakeFailure = ({failure, setIntakeFailureSelection}) => {
  const onChange = event => setIntakeFailureSelection({id: failure.id, selected: event.target.checked})

  return (
    <div className="intake-failure" key={failure.id} data-test="intake-failure-component">
      <Checkbox checked={failure.selected} onChange={onChange}></Checkbox>
      <div>{failure.label}</div>
      <div>{failure.question}</div>
      <div>{failure.answer}</div>
    </div>
  )
}

IntakeFailure = connect(mapStateToProps, mapDispatchToProps)(IntakeFailure)

IntakeFailure.propTypes = {
  failure: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    question: PropTypes.string,
    answer: PropTypes.string,
    selected: PropTypes.bool
  }),
  setIntakeFailureSelection: PropTypes.func
}
