
const getLocation = inspector => {
  if (inspector.geocode_results) return inspector.geocode_results.geometry
  else if (inspector.geometry) return inspector.geometry
  else return null
}

// placeholder for filterInspectors method that sets state filteredInspectors = all inspectors within teh current map's bounds
// 	this has to fire whenever the bounds change on a map
export default function filterInspectors (map, inspectors) {
  const bounds = map.getBounds()

  const filteredInspectors = {}
  Object.entries(inspectors).forEach(([id, inspector]) => {
    const geometry = getLocation(inspector)
    if (geometry && bounds && bounds.contains(geometry.location)) {
      filteredInspectors[id] = inspector
    }
  })

  return filteredInspectors
}