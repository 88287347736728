import React from "react"
// import PropTypes from 'prop-types'

import Auth from "../Auth"
import GeocodeWrapper from "../GeocodeWrapper"
import Papa from "papaparse"
import {db, firebase} from "../utilities" //	capitalizeFirst,
// import SystemLogger from '../SystemLogger'
import getStaticMapThumbnail from "./getStaticMapThumbnail"
import {resourceType, validateResource} from "../validators"
import {saveAs} from "file-saver"
import retry from "async-retry"

const auth = new Auth()
const wrappedGeocode = new GeocodeWrapper()

// export const applyAnswers = answers => project => {
// 	project.intake.order.forEach(id => {
// 		const sectionTitle = project.intake.sections[id].title
// 		if (answers[sectionTitle]) {
// 			project.intake.sections[id].fields.forEach(field => {
// 				if (answers[sectionTitle][field.question]) { field.tipText = answers[sectionTitle][field.question] }
// 				else if (!field.tipText) field.tipText = field.tipText = null
// 			})
// 		}
// 	})
//
// 	return project
// }

// this is a Promise wrapper for FileReader so that we can use Promises.all on an array of files for clarity
export const readUploadedText = (file) => {
  if (!file) return Promise.resolve(null)

  let fr = new FileReader()

  return new Promise((resolve, reject) => {
    fr.onload = (e) => {
      resolve(fr.result)
    }
    fr.onerror = (e) => reject(console.log(`some kinda error converting file to text: `, e))
    fr.readAsText(file)
  }).then((file) => makeCSV(file))
}

export const validateHeaders = (header, template) =>
  header.every((h, i) => h.toLowerCase().trim() === template[i].toLowerCase().trim())
// export const validateAnswerHeaders = header => header.every((h, i) => h.toLowerCase().trim() === ["section_title", "field_question", "tiptext"][i])

// export const makeCSV = textfiles => [].concat(...textfiles.map(file => Papa.parse(file).data ))
export const makeCSV = (textfile) =>
  new Promise((resolve, reject) => {
    Papa.parse(textfile, {
      error: (e) => reject(e),
      complete: (f) => resolve(f.data),
    })
  })

//	[address, site_owner, site_contact, phone, email, installer, field_user, ...row]
export const addTipText = (project, row, order) => {
  //	[address, site_owner, site_contact, phone, email, installer, field_user, ...order]
  let {tipText} = row
  tipText = tipText.slice(7)

  const fieldMaps = {}
  Object.values(project.intake.sections).forEach((section) => (fieldMaps[section.id] = section.fields.map((f) => f.id)))

  order.forEach(({sectionId, fieldId}, i) => {
    const value = tipText[i]

    if (value) {
      const section = project.intake.sections[sectionId]
      const fIndex = fieldMaps[sectionId].indexOf(fieldId)

      if (fIndex > -1) {
        project.intake.sections[sectionId].fields[fIndex].tipText = value
      }
    }
  })

  return project
}

export async function geocodeSites(file) {
  const results = []
  for (let row of file) {
    const [address, site_owner, site_contact, phone, email, system_installer, field_user] = row
    try {
      const geocode_results = await retry(
        async () => {
          return await wrappedGeocode.geocode(address)
        },
        {retries: 10}
      )
      await new Promise((resolve) => setTimeout(resolve, 500))
      results.push({
        geocode_results,
        address,
        site_owner,
        site_contact,
        phone,
        email,
        system_installer,
        field_user,
      })
    } catch (e) {
      // todo: display error on screen
      console.log(`${address} failed to geocode`)
      console.log(e)
    }
  }

  return results
}

// file.map(async ([address, site_owner, site_contact, phone, email, system_installer, field_user]) => ({
//   geocode_results: await retry(async () => { return await wrappedGeocode.geocode(address) }, {retries: 10}),
//   address,
//   site_owner,
//   site_contact,
//   phone,
//   email,
//   system_installer,
//   field_user
// }))

export const validateInstaller = async (si) => {
  if (!si) {
    throw new Error(`No Installer was assigned to this project`)
  } else {
    try {
      const sis = await db.collection(`installers`).where(`name`, `==`, si).get()
      return sis.exists ? {...sis.data(), id: sis.id} : {}
    } catch (e) {
      console.log(`error validating field user provided`, e)
      throw new Error(`The provided Installer does not exist ${si}`)
    }
  }
}

export const validateFieldUser = async (fu) => {
  if (!fu) {
    throw new Error(`No Field User was assigned to this project`)
    // this.logger.error(, null, 3)
    // return null
  } else {
    try {
      const fus = await db.collection(`users`).where(`name`, `==`, fu).where(`type`, `==`, `field-user`).get()
      return fus.empty ? {} : {...fus.docs[0].data(), id: fus.docs[0].id}
    } catch (e) {
      console.log(`error validating field user provided`, e)
      throw new Error(`The provided Field User does not exist ${fu}`)
    }
  }
}

export const makeResources =
  ({
    geocode_results = ``,
    site_contact: siteRepName,
    site_owner: siteOwnerName,
    system_installer: installer_id,
    field_user: field_user_id,
    address: siteAddress,
    phone: siteRepPhone,
    email: siteRepEmail,
  }) =>
  (siteRef) =>
  async (projRef) => {
    let dummyAddress = null,
      latitude = null,
      longitude = null

    if (geocode_results.length < 1) {
      console.log(`no geocode results, no project`)
      // this needs to be tested; it was causing an error on proejct creation when one of the projects had a blank address
      return [{}, {}]
    }

    let site = {
      geocode_results: JSON.parse(JSON.stringify(geocode_results)),
      siteOwnerName,
      siteAddress,
      siteRepName,
      siteRepEmail,
      siteRepPhone,
      id: siteRef.id,
    }

    try {
      if (dummyAddress) site.dummyAddress = dummyAddress
      if (latitude && longitude) {
        try {
          site.geocode_results = JSON.parse(
            JSON.stringify(
              await retry(
                async () => {
                  const res = wrappedGeocode.geocode({lat: latitude, lng: longitude}, `location`)
                  if (!res.address_components) throw new Error("failed to geocode")
                  return res
                },
                {retries: 10}
              )
            )
          )
          site.siteAddress = site.geocode_results.formatted_address
        } catch (e) {
          console.log(`error geocoding project address in bulkUtilities`, e)
        }
      }

      site = await getStaticMapThumbnail(site)
      site.id = siteRef.id
      site.errors = validateResource(site)(resourceType[`site`])

      let inspector = null,
        installer = null

      if (field_user_id) {
        const fuSnap = await db.collection(`users`).doc(field_user_id).get()
        inspector = {...fuSnap.data(), id: fuSnap.id}
      }

      if (installer_id) {
        const installerSnap = await db.collection(`users`).doc(installer_id).get()
        installer = {...installerSnap.data(), id: installerSnap.id}
      }

      const project = {
        // organizations_index: {[auth.currentUser.uid]: true},
        id: projRef.id,
        site,
        status: `To Be Scheduled`,
        created: firebase.firestore.Timestamp.now(),
        updated: firebase.firestore.Timestamp.now(),
      }

      if (installer) project.installer = installer
      if (inspector) project.inspection = {inspector}

      return [site, project]
    } catch (e) {
      console.log(`failed to generate bulk project in bulkUtilities`, e)
    }
  }

export const makeBatches = (dbOps) => {
  const batchJobs = []
  for (let i = 0; i < dbOps.length; i = i + 400) {
    const b = dbOps.slice(i, i + 400)
    const batch = db.batch()

    b.forEach(({ref, data}) => {
      batch.set(ref, data)
    })
    batchJobs.push(batch)
  }
  return batchJobs
}

export const prompt = () => (
  <div style={{fontSize: `75%`, width: `100%`}}>
    <p>Drag and drop project information here.</p>
    <p>Project information is a CSV file with the following format:</p>
    <table style={{width: `100%`}}>
      <tbody>
        <tr>
          <th>Address</th>
          <th>Site_Owner</th>
          <th>Site_Contact</th>
          <th>Phone</th>
          <th>Email</th>
          <th>System_Installer</th>
          <th>Field_User</th>
        </tr>
        <tr>
          <td>123 StreetName, City, State, Zip</td>
          <td>Bob Johnson</td>
          <td>Person Personson</td>
          <td>123 456 - 7890</td>
          <td>ppersonson@email.com</td>
          <td>SunRun</td>
          <td>Jason Trager</td>
        </tr>
      </tbody>
    </table>
    <p style={{padding: `2rem 0 0 0`}}>Please include all the headers on your CSV, or project creation will fail</p>
  </div>
)

export const makeCSVTemplate = ({type, template}) => {
  const t = new Blob([Papa.unparse({fields: template})])

  return (
    <div style={{display: `flex`, flexFlow: `row wrap`}}>
      <div>Click</div>
      <div
        onClick={() => saveAs(t, `${type.title} template for bulk projects.csv`)}
        style={{color: `blue`, padding: `0 0.3rem 0 0.3rem`}}
      >
        here
      </div>
      <div style={{padding: `0 0.3rem 0 0.3rem`}}>to download a CSV template;</div>
      <div>fill it out, and then upload it by dragging it into this box</div>
    </div>
  )
}

export const SiteInformation = ({sites = []}) => (
  <div style={{borderRadius: `0.5rem`, padding: `2rem`, margin: `2rem 0 0 0`, backgroundColor: `rgb(244, 246, 248)`}}>
    <h3>Uploaded Projects</h3>
    <div
      style={{backgroundColor: `white`, border: `0.1rem solid lightgrey`, padding: `1.5rem`, borderRadius: `0.5rem`}}
    >
      {sites.map(
        ({geocode_results, site_owner, site_contact, address, phone, email, system_installer, field_user}, i) => (
          <div key={i}>
            <div style={{padding: `0 0 1rem 0`}}>#{i + 1}</div>
            <div style={{padding: `0 1rem 0 0`, fontWeight: `bold`}}>Geocoded Address</div>
            <div>{geocode_results && geocode_results.formatted_address}</div>
            <div style={{padding: `0 1rem 0 0`, fontWeight: `bold`}}>Your Address</div>
            <div>{address}</div>
            <div
              style={{display: `flex`, flexFlow: `row wrap`, justifyContent: `space-between`, padding: `2rem 0 2rem 0`}}
            >
              <div style={{display: `flex`, flexFlow: `row nowrap`}}>
                <div style={{fontWeight: `bold`, padding: `0 1rem 0 0`}}>Site Owner</div>
                <div>{site_owner}</div>
              </div>
              <div style={{display: `flex`, flexFlow: `row nowrap`}}>
                <div style={{fontWeight: `bold`, padding: `0 1rem 0 0`}}>Site Contact</div>
                <div>{site_contact}</div>
              </div>
              <div style={{display: `flex`, flexFlow: `row nowrap`}}>
                <div style={{fontWeight: `bold`, padding: `0 1rem 0 0`}}>Phone</div>
                <div>{phone}</div>
              </div>
              <div style={{display: `flex`, flexFlow: `row nowrap`}}>
                <div style={{fontWeight: `bold`, padding: `0 1rem 0 0`}}>Email</div>
                <div>{email}</div>
              </div>
            </div>
            <div
              style={{display: `flex`, flexFlow: `row wrap`, justifyContent: `space-between`, padding: `2rem 0 2rem 0`}}
            >
              <div style={{display: `flex`, flexFlow: `row nowrap`}}>
                <div style={{fontWeight: `bold`, padding: `0 1rem 0 0`}}>System Installer</div>
                <div>{system_installer}</div>
              </div>
              <div style={{display: `flex`, flexFlow: `row nowrap`}}>
                <div style={{fontWeight: `bold`, padding: `0 1rem 0 0`}}>Field User</div>
                <div>{field_user}</div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  </div>
)
