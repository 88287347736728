import {firebase} from "../../utilities"

export default function base (state, dispatch, {saveToDB, store}) {
  const statuses = state.files.map(file => store(file)).filter(i => i)

  //Follows this design https://www.fullstackfirebase.com/firebase-storage/notes
  return new Promise(function (resolve, reject) {
    let done = 0
    const l = state.files.length

    if (!statuses.length) resolve()

    for (let status of statuses) {
      status.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

          dispatch({
            type: 'status', 
            name: status.snapshot.ref.name, 
            value: {progress, size: snapshot.totalBytes}
          })
        },
        error => reject(error),
        async () => {
          const {bucket, fullPath, name} = status.snapshot.ref
          const {totalBytes: size} = status.snapshot
    
          const s = {...state.statuses}
          delete s[name]
          dispatch({type: 'statuses', value: s})
    
          await saveToDB({
            bucket,
            fullPath,
            url: await status.snapshot.ref.getDownloadURL(),
            name,
            size,
            created: firebase.firestore.Timestamp.now()
          })

          done++
          if (done === l) resolve()
        }
      )
    }
  })
}