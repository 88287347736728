import React, {useState, useEffect} from "react"
import {Draggable} from "react-beautiful-dnd"
import {connect} from "react-redux"

import {storage} from '../../../utilities'
import {deletePhoto, setField, setProject} from "../../action_creators"
import {Popover, Button, Modal} from "antd"

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: "0.2rem",
  margin: "0 0 2rem 0",

  // styles needed for draggables
  ...draggableStyle
})

const stop = (state, ownProps) => ({
  project: state.project,
  field: state.fields[ownProps.fieldId],
  images: (state.fields[ownProps.fieldId] || {}).images,
  status: state.project.status,
  reviewMode: state.meta.reviewMode
})

const dtop = (dispatch, ownProps) => ({
  deletePhoto: ({name, id}) => dispatch(deletePhoto(name, id)),
  setImageURLs: field => dispatch(setField(ownProps.fieldId, field)),
  setProject: project => dispatch(setProject(project))
})

export function PhotoContainer ({fieldId, provided, ...props}) {
  const {project, field = {}, images, status, reviewMode} = props
  const {deletePhoto, setImageURLs, setProject} = props

  const [visible, setVisible] = useState(false)
  const [currentImage, setCurrentImage] = useState({url: null})

  useEffect(function () { (async function () { await updateImageURLs() })() }, [images])

  async function updateImageURLs () {
    if (field.images) {
      const {images} = field
      const promises = []
      for (let i in images) {
        if (!images[i].url) {
          const pp = storage.ref(images[i].fullPath).getDownloadURL()
            .then(url => {
              field.images[i].url = url
              if (!project.photos) project.photos = {}
              project.photos[i].url = url
            })
          promises.push(pp)
        }
      }
  
      await Promise.all(promises).then(() => {
        setImageURLs(field)
        setProject(project)
      })
    }
  }

  function popover (image, id) {
    const del = () => deletePhoto({name: image.name, id})
    const fullsize = async () => { 
      setCurrentImage(image)
      setVisible(true)
    }
  
    return (
      <div className="flex-row padding-1" >
        <Button onClick={del}>Delete</Button>
        <Button onClick={fullsize}>Expand</Button>
      </div>
    )      
  }

  const reviewModeActive = status === "Review" && !reviewMode
  return (
    <div className="flex-column" >
      {images && Object.keys(images).length > 0 && (
        <h4 style={{padding: "2.5rem 0 0 0"}}>Attached Photos</h4>
      )}
      <div className="flex-row" ref={provided.innerRef}>
        <div style={{flex: 1}} className={reviewModeActive ? 'flex-column' : 'flex-wrap'} >
          {
            Object.values(images || {}).map((image, i) => (
              <Draggable key={image.name} draggableId={`${field.id}prefix${image.name}`} index={i}>
                {(provided, snapshot) => (
                  <div style={reviewModeActive ? {flex: 1} : {width: "12.5%"}}>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <Popover content={popover(image, fieldId)} title="Action">
                        <img 
                          className="full-width" 
                          style={{border: '1px solid lightgrey'}} 
                          src={image.url} 
                          alt="intake question" 
                        />
                      </Popover>
                    </div>
                  </div>
                )}
              </Draggable>
            ))
          }
        </div>
      </div>
      <Modal
        title="Expanded Image"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <img alt="user uploaded" style={{width: "100%", height: "100%"}} src={currentImage.url} />
      </Modal>
    </div>
  )
}

const WrappedPhotoContainer = connect(stop, dtop)(PhotoContainer)
export default WrappedPhotoContainer