export default function getCalendar (makeSubscription, id) {
  if (!id) return
  return makeSubscription(
    {
      paths: [{collection: `calendars`}],
      options: {
        where: [{field: `data.inspection.inspector.id`, test: `==`, value: id || ``}],
        order: 'created'
      }
    }, 
    `calendar`
  )
}