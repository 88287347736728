import React from "react"
import _ from "lodash"
import {Card, message} from "antd"

import * as Model from "./model"
import {RubricSettings} from "./RubricSettings"
import {RubricSection} from "./RubricSection"
import {TitleBar} from "./TitleBar"
import {updateArray, db, dataFromSnapshot} from "../utilities"

import "./RubricPage.scss"

export default class RubricPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formId: props.match.params.formId,
      rubrics: undefined,
      scoringSystems: [],
      sections: []
    }

    const setState = this.setState.bind(this)

    this.unsubscribeFromRubricsPromise = fetchData(setState, {
      formId: this.state.formId
    })
    this.callbacks = _.merge(
      ..._.map(
        {
          setNewRubricName,
          addRubric,
          removeRubric,
          setScoringSystemForRubric,
          setRubricCriticalityForQuestion,
          setRubricQuestionOptionContributionType,
          saveRubrics
        },
        (func, name) => ({[name]: params => func(setState, params)}) // Pass in setState so that the callers don't have to initialize it themselves.
      )
    )
  }

  componentWillUnmount() {
    this.unsubscribeFromRubricsPromise.then(unsubscribe => unsubscribe())
  }

  render() {
    return (
      <div className="rubric-page">
        <TitleBar {...this.props} {...this.state} {...this.callbacks} />
        <div style={{display: `flex`, flexDirection: `row`}}>
          <div style={{flex: 8}}>
            {_.isEmpty(this.state.sections) && (
              <Card
                loading={true}
                title={<strong>Loading Sections...</strong>}
                style={{textAlign: `left`, margin: `1.5rem`}}
              />
            )}
            {this.state.sections.map(section => (
              <RubricSection key={section.id} section={section} {...this.state} {...this.callbacks} />
            ))}
          </div>
          <div style={{flex: 4}}>
            <RubricSettings {...this.state} {...this.callbacks} />
          </div>
        </div>
      </div>
    )
  }
}

function setNewRubricName(setState, {name}) {
  setState({newRubricName: name})
}

/** Returns `false` if there were validation errors, `true` otherwise. */
function addRubric(setState, {formId, newRubricName, scoringSystemId}) {
  if (_.isEmpty(newRubricName)) {
    message.error(`Rubric must have a name`)
    return false
  }
  if (_.isEmpty(scoringSystemId)) {
    message.error(`Rubric must have a scoring system`)
    return false
  }

  const rubricRef = db.collection(`rubrics`).doc()
  const newRubric = Model.makeRubric({
    id: rubricRef.id,
    formId: formId,
    name: newRubricName,
    scoringSystemId
  })

  setState(state => ({
    newRubricName: ``,
    rubrics: [...state.rubrics, newRubric]
  }))

  rubricRef.set(newRubric)

  return true
}

function removeRubric(setState, {rubricId}) {
  Model.deleteRubric({rubricId})
  setState(state => ({
    rubrics: state.rubrics.filter(rubric => rubric.id !== rubricId)
  }))
}

/**
 * Returns the unsubscribe function for the rubrics.
 */
async function fetchData(setState, {formId}) {
  const scoringSystemsPromise = db
    .collection(`scoring_systems`)
    .get()
    .then(dataFromSnapshot)
  const form = await Model.getForm({formId})
  const sectionsPromise = Model.getAllFormFieldsBySection({form})

  const [scoringSystems, sections] = await Promise.all([scoringSystemsPromise, sectionsPromise])

  setState({scoringSystems, form, sections})

  // Subscribe to rubrics and merge in the latest values every update.
  const unsubscribeFromRubrics = Model.subscribeToRubrics(formId, rubrics => {
    setState(state => ({
      rubrics: Model.joinScoringSystemsToRubrics({
        rubrics: rubrics,
        scoringSystems: state.scoringSystems
      })
    }))
  })

  return unsubscribeFromRubrics
}

function setScoringSystemForRubric(setState, {rubric, scoringSystemId, scoringSystems}) {
  const scoringSystem = _.find(scoringSystems, scoringSystem => scoringSystem.id === scoringSystemId)

  const newRubric = {...rubric, scoringSystem, scoringSystemId}

  setState(state => ({
    rubrics: updateArray(state.rubrics, newRubric)
  }))

  Model.updateRubric({rubricId: rubric.id, update: {scoringSystemId}})
}

function setRubricCriticalityForQuestion(setState, {rubric, question, criticalityCategory}) {
  rubric = _.cloneDeep(rubric)

  if (!criticalityCategory) {
    console.log('criticalityCategory missing')
  }

  rubric.questions[question.id] = {
    ...rubric.questions[question.id],
    criticalityCategory
  }

  setState(state => ({rubrics: updateArray(state.rubrics, rubric)}))
}

function setRubricQuestionOptionContributionType(setState, {rubric, question, optionLabel, optionContributionType}) {
  setState(state => ({
    rubrics: updateArray(
      state.rubrics,
      Model.setRubricQuestionOptionContributionType({
        rubric,
        question,
        optionLabel,
        optionContributionType
      })
    )
  }))
}

let dismissErrorMessage = () => {}

async function saveRubrics(_setState, {rubrics, sections}) {
  dismissErrorMessage() // Dismiss whatever the last error message was, if any

  const invalidQuestions = Model.validateRubricQuestions({
    rubrics,
    sections
  })

  if (_.isEmpty(invalidQuestions)) {
    const stopLoading = message.loading(`Saving...`)

    let promises = []
    // eslint-disable-next-line
    for (let rubric of rubrics) {
      const {id, formId, name, questions, scoringSystemId} = rubric
      promises.push(
        Model.updateRubric({
          rubricId: id,
          update: {id, formId, name, questions, scoringSystemId}
        })
      )
    }

    await Promise.all(promises)
    stopLoading()
    message.success(`Rubrics saved!`)
  } else {
    // eslint-disable-next-line
    for (let questionId in invalidQuestions) {
      const {error} = invalidQuestions[questionId] // , question, rubric

      dismissErrorMessage = message.error(error, 15)
    }
  }
}
