import {combineReducers} from "redux"

const tableVars = (state = {tableType: '', tableStrategy: {}}, action) => {
  switch (action.type) {
  case 'tableType': return {...state, tableType: action.tableType}
  case 'tabVar': return {...state, tabVar: action.tabVar}
  case 'queryBuilder': return {...state, queryBuilder: action.queryBuilder}
  case 'tableStrategy': return {...state, tableStrategy: action.tableStrategy}
  case 'tabs': return {...state, tabs: action.tabs}
  default: return state
  }
}

// this reducer is for tracking elastic queries
const query = (
  state = {size: 10, from: 0, total: 0, status: `All`, query_strings: [], filters: [], fullTextQuery: ``},
  action
) => {
  switch (action.type) {
  case `size`:
    return {...state, size: action.value}
  case `from`:
    return {...state, from: action.value}
  case `total`:
    return {...state, total: action.value}
  case `status`:
    return {...state, status: action.value}
  case `queryStrings`:
    return {...state, query_strings: action.value}
  case `filters`:
    return {...state, filters: action.value}
  case `fullTextQuery`:
    return {...state, fullTextQuery: action.value}
  case `updateQuery`:
    return {...state, ...action.query}
  default:
    return state
  }
}

const tableControls = (state = {lock: 0, loading: true, pagination: {current: 1}, filters: {}, sorter: {order: null, field: null}}, action) => {
  switch (action.type) {
  case `sorter`:
    return {...state, sorter: action.sorter}
  case `updateTableControls`:
    return {...state, ...action.tableControls}
  case 'loading': return {...state, loading: action.bool}
  case 'lock': return {...state, lock: action.lock}
  default:
    return state
  }
}

const resources = (state = {}, action) => {
  switch (action.type) {
  case `resources`:
    return action.resources || state
  case `prune`:
    const resources = {...state}
    delete resources[action.id]
    return resources
  default:
    return state
  }
}

const tabs = (state = [], action) => {
  switch (action.type) {
  case 'tabs': return action.tabs || state
  default: return state
  }
}

const rootReducer = combineReducers({
  query,
  tableControls,
  resources,
  tabs,
  tableVars
})

export default rootReducer
