import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"

import {Spin} from "antd"

// import Loading from './Loading'

import {capitalizeFirst} from "./utilities"

const styles = {
  textlabel: {
    fontWeight: "bold",
    paddingRight: "1rem",
    flex: "0 1 auto",
    fontSize: "125%"
  },
  textfield: {
    flex: 4,
    padding: "1rem",
    borderRadius: "1rem"
  }
}

const componentForm = {
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  country: "long_name",
  postal_code: "short_name"
}

const blankForm = {
  street_number: "",
  route: "",
  locality: "",
  administrative_area_level_1: "",
  country: "",
  postal_code: ""
}

const addressFields = ["street_number", "route", "locality", "administrative_area_level_1", "postal_code"]
const addressNameMap = {
  street_number: "Street Number",
  route: "Street Name",
  locality: "City",
  administrative_area_level_1: "State",
  postal_code: "Zip"
}

export default class AddressForm extends React.Component {
  static propTypes = {
    address: PropTypes.object,
    currentUser: PropTypes.object,
    updateAddress: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.initAutocomplete = this.initAutocomplete.bind(this)
    this.fillAddress = this.fillAddress.bind(this)
    this.usePosition = this.usePosition.bind(this)
    this.geolocate = this.geolocate.bind(this)
    this.change = this.change.bind(this)
    this.loaded = this.loaded.bind(this)
    this.setLoaded = this.setLoaded.bind(this)

    this.autocompleteRef = React.createRef()
  }

  state = {
    autocomplete: {},
    street_number: "",
    route: "",
    locality: "",
    administrative_area_level_1: "",
    postal_code: "",
    address: "",
    loaded: false
  }

  async componentDidMount() {
    await this.loaded()
    this.setState({...this.props.address})
  }

  async componentWillUnmount() {
    window.clearInterval(this.lastInterval)
  }

  async setLoaded(e) {
    if (e.key === "googleMapsLoaded") {
      if (e.newValue === "true") {
        console.log("google maps is loaded")
        this.setState({loaded: true})
        await this.initAutocomplete()
      }
    }
  }

  async loaded() {
    if (window.google && window.google.maps) {
      console.log("google maps is already loaded")
      window.removeEventListener("storage", this.setLoaded)
      await this.setState({loaded: true})
      await this.initAutocomplete()
    } else {
      console.log("google maps is not yet loaded, listening")
      window.addEventListener("storage", this.setLoaded)
    }
  }

  // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
  async initAutocomplete() {
    window.addEventListener("bounds_changed", e => {
      console.log(e)
      this.state.autocomplete.getBounds()
    })

    // this.autocomplete is a ref
    // console.log(this.autocompleteRef.current)

    let autocomplete = new window.google.maps.places.Autocomplete(this.autocompleteRef.current, {types: ["geocode"]})
    autocomplete.addListener("place_changed", this.fillAddress)
    autocomplete.addListener("place_changed", () => console.log("address changed"))

    // Force autocomplete field to have the value set by the caller. Google maps
    // integration forces it "off". The 100ms interval isn't great, but this
    // will be cleaned up the moment this component unmounts; as such, it's
    // the least fiddly way of ensuring this invariant holds.
    this.autocompleteRef.current.autocomplete = this.props.autocomplete
    window.clearInterval(this.lastInterval)
    this.lastInterval = setInterval(() => {
      this.autocompleteRef.current.autocomplete = this.props.autocomplete
    }, 100)

    await this.setState({autocomplete})
  }

  async fillAddress() {
    console.log("address in address bar changed")

    let place = await this.state.autocomplete.getPlace()
    await this.setState({...blankForm})

    let {location} = place.geometry
    let addressComponents = place.address_components

    let address = {}

    let geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({location}, (results, status) => {
      addressComponents.forEach((addressComponent, i) => {
        if (addressComponents[i][componentForm[addressComponent.types[0]]]) {
          address[addressComponent.types[0]] = addressComponents[i][componentForm[addressComponent.types[0]]]
        }
      })

      let bounds = results[0].geometry.bounds
        ? results[0].geometry.bounds.toJSON()
        : results[0].geometry.viewport.toJSON()
      address["geometry"] = {bounds, location: results[0].geometry.location.toJSON()}
      // address["location"] = { lat: lat(), lng: lng() }
      this.setState({...address, address: place.formatted_address})

      this.props.updateAddress({...address, address: place.formatted_address})
      return true
    })
  }

  async usePosition(position) {
    console.log("got position from geolocating:", position)

    let {latitude, longitude, accuracy} = position.coords

    let geolocation = {lat: latitude, lng: longitude}
    let circle = new window.google.maps.Circle({center: geolocation, radius: accuracy})

    this.state.autocomplete.setBounds(circle.getBounds())
  }

  async geolocate() {
    // Only trigger for fieldUsers.
    if (_.get(this.props.currentUser, "type") === "field-user") {
      window.navigator.geolocation.getCurrentPosition(this.usePosition, error =>
        console.log("error geolocating: ", error)
      )
    }
  }

  change(e) {
    this.setState({[e.target.attributes.name.value]: e.target.value})
    this.props.updateAddress(this.state)
  }

  render() {
    return (
      <div
        style={{
          paddingBottom: "0.5em",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          alignContent: "center"
        }}
      >
        {!this.state.loaded ? (
          <Spin size="large" />
        ) : (
          <div
            style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", padding: "0 0 0.5em 0"}}
          >
            <span style={styles.textlabel}>Enter your Address</span>
            <input
              autoComplete={this.props.autocomplete}
              style={styles.textfield}
              name="address"
              // id="autocomplete"
              ref={this.autocompleteRef}
              placeholder={this.state.address ? this.state.address : "Enter your address"}
              onFocus={this.geolocate}
              type="text"
            />
          </div>
        )}
        {addressFields.map((f, i) => (
          <div
            key={i}
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "0 0 0.5em 2em",
              width: "100%",
              alignItems: "center"
            }}
          >
            {/* <span style={ styles.textlabel } >{ addressNameMap[f] }</span> */}
            <input
              hidden={true}
              disabled={true} // These fields are 100% dependent upon the address selected, and not user input.
              type="text"
              name={addressNameMap[f]}
              placeholder={capitalizeFirst(addressNameMap[f])}
              value={this.state[f]}
              onChange={this.change}
              style={styles.textfield}
            />
          </div>
        ))}
      </div>
    )
  }
}
