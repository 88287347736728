import {INITIALIZE_TICKET_MODAL, SET_INTAKE_FAILURE_SELECTION} from "../action_creators"

export const intakeFailuresById = (state = {}, action) => {
  switch (action.type) {
  case INITIALIZE_TICKET_MODAL:
    return action.intakeFailuresById
  case SET_INTAKE_FAILURE_SELECTION:
    return {...state, [action.id]: {...state[action.id], selected: action.selected}}
  default:
    return state
  }
}
