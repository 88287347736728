import React, {useEffect} from "react"
import PropTypes from "prop-types"
import IgnoreAvailability from './IgnoreAvailability'
import {db} from "../utilities"
import {fieldNameConverter} from "../validators"

import {Form, Select} from "antd"

import {setField, makePermissions} from "./action_creators"
import {connect} from "react-redux"

const mapStateToProps = state => ({
  fields: state.fields,
  fus: state.permissions.fus,
  change: state.permissionsCategories.fus
})
const mapDispatchToProps = dispatch => ({
  setField: field => value => dispatch(setField(field, value)),
  makePermissions: () => dispatch(makePermissions(`field-user`))
})

const InspectionInformation = ({makePermissions, change, fields = {}, setField, fus}) => {
  useEffect(() => {
    makePermissions()
  }, [change])

  const {inspector} = fields
  const set = id => setField(`inspector`)(fus[id])

  return (
    <div
      style={{
        display: `flex`,
        flexFlow: `column nowrap`,
        alignItems: `flex-start`,
        background: `#F4F6F8`,
        padding: `2rem`,
        margin: `0 0 1rem 0`
      }}
    >
      <h3>Inspector Information</h3>
      <div style={{width: `100%`, display: `flex`, flexFlow: `column`}}>
        {/* {fields.projectType && fields.projectType.noSchedule && (
          <h3 style={{fontWeight: `bold`, padding: `1rem`}}>
            Do not schedule an intake for the the selected project type
          </h3>
        )} */}

        <IgnoreAvailability change={setField} ignoreAvailability={fields.ignoreAvailability} />
        <Select
          disabled={fields.projectType && fields.projectType.noSchedule}
          onChange={e => set(e)}
          value={inspector ? inspector.name : null}
          size="large"
          style={ {width: `100%`} }
          placeholder={ fieldNameConverter[`inspector`] }
          data-cy="inspector"
        >
          {Object.values(fus).sort((a, b) => a.name > b.name ? 1 : -1).map(user => (
            <Select.Option key={user.id} value={user.id}>
              {user.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  )
}

InspectionInformation.propTypes = {
  fields: PropTypes.object,
  setField: PropTypes.func,
  fus: PropTypes.object
}

const WrappedInspectionInformation = connect(mapStateToProps, mapDispatchToProps)(InspectionInformation)
export default WrappedInspectionInformation
