import React from "react"
import _ from "lodash"
import {Card, List, Select} from "antd"

import * as Model from "./model"
import {uuid} from "../utilities"

const rubricColumnMargin = `0 1rem`
const maxSectionWidthForRubric = `30rem`

export function RubricQuestion({style, ...props}) {
  const {rubrics, question, scoringSystems} = props

  return (
    <Card
      title={<CardTitle question={question} />}
      loading={!rubrics || !question || !question.options}
      headStyle={{backgroundColor: `#F0F2F4`}}
      style={style}
      bodyStyle={{padding: 0}}
      data-cy="rubricOptions"
    >
      <List
        bordered
        style={{border: `none`}}
        header={
          <div style={{display: `flex`, position: `relative`}}>
            <TitleRow {...props} rubrics={rubrics} scoringSystems={scoringSystems} question={question} />
          </div>
        }
        data-cy="scoringSystemOpt"
      >
        {question.options &&
                    question.options.map((option) => (
                      <List.Item key={option.id || uuid()} style={{display: `flex`}}>
                        <OptionRow
                          {...props}
                          option={option}
                          rubrics={rubrics}
                          question={question}
                        />
                      </List.Item>
                    ))}
      </List>
    </Card>
  )
}

function RowLayout(props) {
  const {left, right} = props

  return (
    <>
      <div style={{flex: 3}}>{left}</div>
      <div style={{flex: 1}} />
      <div style={{flex: 8, display: `flex`, textAlign: `center`}}>{right}</div>
    </>
  )
}

function CardTitle(props) {
  const {question} = props

  return (
    <div>
      <strong>{question.label || `Untitled`}</strong>
      <div style={{fontSize: `1.35rem`}}>{question.question}</div>
    </div>
  )
}

function TitleRow(props) {
  const {rubrics, scoringSystems, question} = props

  if (_.isEmpty(rubrics)) {
    return (
      <RowLayout
        left={<div style={{fontStyle: `italic`, marginTop: `0.5rem`}}>Select Criticality</div>}
        right={<div style={{fontWeight: `bold`, marginTop: `0.5rem`}}>Need to add at least one Rubric</div>}
      />
    )
  }

  return (
    <RowLayout
      left={
        <div
          style={{
            position: `absolute`,
            bottom: `0.5rem`,
            fontStyle: `italic`
          }}
        >
          Select Criticality
        </div>
      }
      right={rubrics.map(rubric => {
        const maybeScoringSystem = Model.getRubricScoringSystem({
          rubric,
          scoringSystems
        })

        return (
          <div
            key={rubric.id}
            style={{
              flex: 1,
              maxWidth: maxSectionWidthForRubric,
              margin: rubricColumnMargin
            }}
          >
            <strong style={{textAlign: `center`}}>{rubric.name}</strong>
            <Select
              placeholder="Select Criticality"
              style={{display: `block`}}
              value={_.get(rubric, [`questions`, question.id, `criticalityCategory`], null)}
              onChange={e =>
                props.setRubricCriticalityForQuestion({
                  rubric,
                  question,
                  criticalityCategory: e
                })
              }
              data-cy="rubricCriticality"
            >
              <Select.Option key={`ignore`} value={null}>
                <span style={{fontStyle: `italic`}}>ignore this question</span>
              </Select.Option>
              {Object.keys(_.get(maybeScoringSystem, `criticality_map`, {})).sort().map(criticalityCategory => (
                <Select.Option key={criticalityCategory} value={criticalityCategory} data-cy="criticalityCategory">
                  {criticalityCategory}
                </Select.Option>
              ))}
            </Select>
          </div>
        )
      })}
    />
  )
}

function OptionRow(props) {
  const {rubrics, option, question} = props

  return (
    <RowLayout
      left={option.label || `Untitled`}
      right={rubrics.map((rubric) => (
        <div
          key={rubric.id}
          style={{
            flex: 1,
            maxWidth: maxSectionWidthForRubric,
            margin: rubricColumnMargin,
          }}
        >
          <Select
            // Disable input for this rubric row if
            data-cy="rubricQuestion"
            disabled={!hasCriticalityCategoryBeenSelected(rubric, question)}
            onChange={(e) =>
              props.setRubricQuestionOptionContributionType({
                rubric,
                question,
                optionLabel: option.label,
                optionContributionType: e,
              })
            }
            value={_.get(rubric, [`questions`, question.id, `options`, option.label])}
            style={{display: `block`}}
          >
            <Select.Option key="Pass" value={`Pass`} data-cy="pass">
                            Pass
            </Select.Option>
            <Select.Option key="Fail" value={`Fail`} data-cy="fail">
                            Fail
            </Select.Option>
            <Select.Option key="Ignore" value={`Ignore`} data-cy="ignore">
                            Ignore
            </Select.Option>
          </Select>
        </div>
      ))}
    />
  )
}

const hasCriticalityCategoryBeenSelected = (rubric, question) =>
  Boolean(rubric.questions && rubric.questions[question.id] && rubric.questions[question.id].criticalityCategory)
