import React from "react"
import {Icon} from "antd"

export default function PhotoRequired () {
  return (
    <div 
      className="margin-top-2 padding-top-1"
      style={{textAlign: `center`, border: `0.1rem dashed #C4CDD5`, background: `#FBFBFB`, width: `8rem`, height: `8rem`}}
    >
      <Icon type="picture" style={{fontSize: `4rem`}} />
      <div className="bold" >Required</div>
    </div>
  )
}