import React from 'react'
import {Icon, Popconfirm} from "antd"
import {auth} from '../Auth'

export default function SiteNote ({logger, i, notes, deleteSiteNote, changeSiteNote, author, authorId, created, note, setNotes, contactAttempted = false}) {
  return (
    <div key={i} className="flex-column padding-0-5" >
      <div className="flex-row justify-space-between align-center" >
        <div className="bold padding-right-1" >{created.toDate().toDateString()}</div>
        {auth.currentUser.uid === authorId && (
          <Popconfirm onConfirm={() => deleteSiteNote(i)} title="Are you sure you wish to delete this note?">
            <Icon type="delete" />
          </Popconfirm>
        )}
      </div>
      <div className="flex-column" style={{padding: `0 0 0 0.5rem`}}>
        <div className="padding-right-1 italic" >{author || `unnamed`}:</div>
        {auth.currentUser.uid === authorId ? (
          <textarea
            style={{border: `none`}}
            value={note}
            rows="3"
            columns="800"
            onChange={e => {
              const nn = [...notes]
              nn[i] = {author, authorId, created, note: e.target.value, contactAttempted}
              setNotes(nn)
            }}
            onBlur={changeSiteNote}
          />
        ) : (
          <p>{note}</p>
        )}
        <div className="padding-top-1 flex-row" >
          <div className="padding-right-1" >Attempted Contact</div>
          <input
            disabled={auth.currentUser.uid !== authorId}
            type="checkbox"
            onChange={async e => {
              const nn = [...notes]
              nn[i] = {author, authorId, created, note, contactAttempted: e.target.checked}
              setNotes(nn)
              await changeSiteNote()
              if (e.target.checked) logger.event(`${author} attempted to contact the site`)
            }}
            checked={contactAttempted}
          />
        </div>
      </div>
    </div>
  )
}