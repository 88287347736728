import React from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {Draggable} from "react-beautiful-dnd" //	DragDropContext, Droppable,
import {Button} from "antd" //	Form, Popconfirm, Card, Divider, Input, Icon, Row, Col, Select, Affix, Tabs
import {setActive, setField} from "../action_creators"
import Option from "./Option"

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: "0.2rem",
  margin: "0 0 2rem 0",

  // styles needed for draggables
  ...draggableStyle
})

const mapStateToProps = state => ({
  activeOption: state.active.option,
  field: state.form.fields[state.active.field] || null,
  sectionId: state.active.section,
  formId: state.form.base.id
})

const mapDispatchToProps = dispatch => ({
  del: field => index => {
    const {options} = field
    options.splice(index, 1)
    dispatch(setField({category: "fields", id: field.id, value: {...field, options}}))
  },
  active: index => dispatch(setActive({type: "option", index}))
})

class Options extends React.Component {
  static propTypes = {
    field: PropTypes.object,
    provided: PropTypes.object,
    snapshot: PropTypes.object,
    del: PropTypes.func,
    active: PropTypes.func
  }

  render() {
    const {field = {options: []}, activeOption, provided, del} = this.props //	active, snapshot,
    // const duplicates = new Set(field.options.map(option => option.label)).size === field.options.length
    const duplicates = {}
    if (!field.options) field.options = []
    field.options.forEach(
      option => (duplicates[option.label] = duplicates[option.label] ? duplicates[option.label] + 1 : 1)
    )

    return (
      <div ref={provided.innerRef}>
        {!Object.values(duplicates).every(v => v < 2) && (
          <div
            style={{
              fontWeight: "bold",
              fontSize: 20,
              background: "red",
              color: "white",
              padding: "1rem",
              margin: "0 0 1rem 0",
              borderRadius: "0.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            There are duplicate options
          </div>
        )}
        {field.options.map((o, i) => (
          <Draggable key={i} draggableId={i.toString()} index={i}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
              >
                <Option errors={duplicates[o.label] > 1} key={i} index={i} del={del(field)} selected={activeOption} />
              </div>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </div>
    )
  }
}

const WrappedOptions = connect(mapStateToProps, mapDispatchToProps)(Options)
export default WrappedOptions

const NakedAdd = ({add}) => (
  <Button onClick={add} className="thick" type="primary" size="large" style={{marginLeft: "1rem"}}>
    Add Option
  </Button>
)

const buttonProps = state => ({
  field: state.form.fields[state.active.field],
  formId: state.form.base.id,
  sectionId: state.active.section
})
const buttonDispatch = (dispatch, ownProps) => ({
  addResource: (field, formId, sectionId) => {
    const options = [].concat(field.options, {label: "", requireFollowup: false}).filter(i => i)
    dispatch(setField({category: "fields", id: field.id, value: Object.assign({}, field, {options})}))
  }
})

const buttonMergeProps = (pState, pDispatch) => ({
  add: () => pDispatch.addResource(pState.field, pState.formId, pState.sectionId)
})

NakedAdd.propTypes = {add: PropTypes.func}

const Add = connect(buttonProps, buttonDispatch, buttonMergeProps)(NakedAdd)

export {Add}
