import React, {useReducer} from 'react'

function reducer (state, action) {
  switch (action.type) {
  case 'forms': return {...state, forms: action.value}
  case 'rubrics': return {...state, rubrics: action.value}   
  case 'aggregations': return {...state, aggregations: action.value}
  case 'data': return {...state, data: action.value}
  case 'final': return {...state, final: action.value}        
  case 'dropdown': return {...state, dropdown: action.value}
  default: return state
  }
}

const initialState = {final: {}, dropdown: {}}

export const ReducerContext = React.createContext(null)

export const wrap = C => props => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <ReducerContext.Provider value={{state, dispatch}}>
      <C {...props} />
    </ReducerContext.Provider>
  )
}