import {firebase} from '../../utilities'

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = "0" + month
  if (day.length < 2) day = "0" + day

  return [year, month, day].join("-")
}

export const headerMap = project => ({
  'Project ID': project.id,
  'Site Owner Name': project.site.siteOwnerName,
  'Site Address': project.site.geocode_results.formatted_address,
  'Site State': (function () {
    if (project.site.geocde_results) {
      return project.site
        .geocode_results
        .address_components
        .filter(c => c.types
          .includes('administrative_area_level_1'))[0]
        .short_name
    }
    return null
  })(),
  'Site Rep Email': project.site.siteRepEmail,
  'Site Rep Phone': project.site.siteRepPhone,
  'Site Rep Name': project.site.siteRepName,
  'Client Name': project.client.name,
  'Client ID': project.client.id,
  'Project Type/Form': project.intake.title,
  'Project Rubric': (project.rubric || {}).name,
  'Status': project.status,
  'Installer Name': (project.installer || {}).name,
  'Installer ID': (project.installer || {}).id,
  'Installer Email': (project.installer || {}).email,
  'Inspector Name': (function () {
    if (!project.inspection) return ""
    if (!project.inspection.inspector) return ""
    return project.inspection.inspector.name
  })(),
  'Inspector Email': (function () {
    if (!project.inspection) return ""
    if (!project.inspection.inspector) return ""
    return project.inspection.inspector.email
  })(),
  'Reviewer Name': (project.reviewer || {}).name,
  'Reviewer Email': (project.reviewer || {}).email,
  'Site Notes': (project.siteNotes && Array.isArray(project.siteNotes)) 
    ? project.siteNotes.map(n => {
      if (n.created) {
        const t = new firebase.firestore.Timestamp(n.created._seconds, n.created._nanoseconds)
        n.created = t.toDate().toString()
      }
      return JSON.stringify(n)
    }) 
    : null,
  'Created Date': (function () {
    if (!project.created || !project.created.toDate) return ""
    return formatDate(project.created.toDate())
  })(),
  'Inspection Date': (function () {
    if (!project.inspection) return ""
    if (!project.inspection.date) return ""
    return formatDate(project.inspection.date.toDate())
  })(),
  'Started Date': (function () {
    if (!project.startedAt || !project.startedAt.toDate) return ""
    return formatDate(project.startedAt.toDate())
  })(),
  'Completed/Last': (function () {
    if (!project.updated || !project.updated.toDate) return ""
    return formatDate(project.updated.toDate())
  })(),
  'Updated Date': (function () {
    if (!project.updated || !project.updated.toDate) return ""
    return formatDate(project.updated.toDate())
  })(),
  'Reason for cancelling': (project.cancellations && Array.isArray(project.cancellations)) 
    ? project.cancellations.map(r => {
      if (r.canceledAt) {
        const t = new firebase.firestore.Timestamp(r.canceledAt._seconds, r.canceledAt._nanoseconds)
        r.canceledAt = formatDate(t.toDate())
      }

      return JSON.stringify(r)
    }) 
    : null,
})

export const headers = [
  'Project ID',
  'Site Owner Name',
  'Site Address',
  'Site State',
  'Site Rep Email',
  'Site Rep Phone',
  'Site Rep Name',
  'Client Name',
  'Client ID',
  'Project Type/Form',
  'Project Rubric',
  'Status',
  'Installer Name',
  'Installer ID',
  'Installer Email',
  'Inspector Name' ,
  'Inspector Email',
  'Reviewer Name',
  'Reviewer Email',
  'Site Notes',
  'Created Date',
  'Inspection Date',
  'Started Date',
  'Completed/Last',
  'Updated Date',
  'Reason for cancelling'
]