import React from "react"
import PropTypes from "prop-types"
import {db} from "../utilities"

import Auth from "../Auth"

import ProjectType from "./ProjectType"
import ClientInformation from "./ClientInformation"
import InstallerInformation from "./InstallerInformation"
import InspectionInformation from "./InspectionInformation"
import SiteOwnerInformation from "./SiteOwnerInformation"
import SiteRepresentative from "./SiteRepresentative"

import SystemLogger from "../SystemLogger"
import {projectModify, projectsOne, mapDBToFields} from "./utilities"
import {resourceType, fieldNameConverter, validateResource, siteValidators} from "../validators"
// import { wrap } from './context'

import {
  populatePermissions,
  setInstallers,
  setInitialFields,
  setProject,
  blankFields,
  makePermissions
} from "./action_creators"
import {connect} from "react-redux"

const mapDispatchToProps = dispatch => ({
  populatePermissions: type => permissions => dispatch(populatePermissions(type, permissions)),
  setInstallers: installers => dispatch(setInstallers(installers)),
  setFields: fields => dispatch(setInitialFields(fields)),
  setProject: project => dispatch(setProject(project)),
  blankFields: () => dispatch(blankFields()),
  makePermissions: type => dispatch(makePermissions(type))
})

const mapStateToProps = state => ({
  fields: state.fields,
  project: state.projectBase,
  forms: Object.keys(state.permissionsCategories.formsPopulate || {}).length,
  clients: Object.keys(state.permissionsCategories.clientsPopulate || {}).length,
  fus: Object.keys(state.permissionsCategories.fusPopulate || {}).length
})

const auth = new Auth()

const validators = {...resourceType["project"], ...resourceType["site"]}

class ProjectCreator extends React.Component {
  static propTypes = {id: PropTypes.string}

  componentDidMount = () => {
    this.logger = new SystemLogger({
      uid: auth.sunkaizenUser.id,
      path: `/projects/${this.props.id ? this.props.id : null}`
    })

    this.getUsers()
    this.getForms()

    if (this.props.id) {
      const subscription = db
        .collection("projects")
        .doc(this.props.id)
        .onSnapshot(async projSnap => {
          const project = {
            ...projSnap.data(),
            id: projSnap.id
          }

          const fields = mapDBToFields(project)
          this.props.setFields(fields)
          this.props.setProject(project)
        })
      this.setState({subscriptions: [].concat(this.state.subscriptions, subscription)})
    }
  }

  async componentDidUpdate (pprops) {
    if (!pprops.done && this.props.done) {
      if (this.props.setDone) this.props.setDone(false)
      await this.finalize()      
    }
  }

  componentWillUnmount = () => {
    // if (this.state.subscriptions.length > 0) this.state.subscriptions.map(subscription => subscription())
    this.props.blankFields()
  }

  state = {fus: {}, clients: {}, forms: {}}

  getUsers = () => {
    const subscription = db.collection("users")
      .where("type", "in", ['field-user', 'client', 'installer'])
      .onSnapshot(snapshot => {
        const fus = {},
          clients = {},
          installers = {}
        snapshot.docs.forEach(doc => {
          const user = doc.data()
          switch (user.type) {
          case "field-user":
            fus[doc.id] = user
            break
          case "client":
            clients[doc.id] = user
            break
          case "installer":
            installers[doc.id] = user
          default:
            break
            // console.log("not a field-user or a client: ", user.type)
          }
        })
        // console.log(fus, clients)
        this.props.populatePermissions("clients")(clients)
        this.props.populatePermissions("fus")(fus)
        this.props.setInstallers(installers)
      })

    this.setState({subscriptions: [].concat(this.state.subscriptions, subscription)})
  }

  getForms = () => {
    const subscription = db
      .collection("forms")
      .where("active", "==", true)
      .onSnapshot(snapshot => {
        const forms = {}
        snapshot.docs.forEach(doc => (forms[doc.id] = {...doc.data(), id: doc.id}))
        this.props.populatePermissions("forms")(forms)
      })
    this.setState({subscriptions: [].concat(this.state.subscriptions, subscription)})
  }

  finalize = async () => {
    try {
      const validationTargets = {...siteValidators}
      const errors = validateResource(this.props.fields)(validationTargets)
      const fatalErrors = []
      
      for (let error of errors) {
        const [[key, value]] = Object.entries(error)
        if (value.length > 0) {
          fatalErrors.push([key, value])
        }
      }

      for (let [k, v] of fatalErrors) {
        this.logger.log(`Error validating ${k}: ${JSON.stringify(v)}`)
      }

      if (fatalErrors.length > 0) throw new Error(`Validation error: project not created`)

      if (this.props.id) {
        await projectModify(this.props.project, this.props.fields, auth.currentUser.uid)
        this.logger.categoryId = this.props.project.id
        this.logger.log(`Project with address ${this.props.project.site.siteAddress} was successfully updated`)
      } else {
        console.log(this.props.fields)
        const id = await projectsOne(this.props.fields, auth.currentUser.uid)
        this.props.blankFields()
        this.logger.categoryId = id
        this.logger.log("Project successfully created")
      }
    } catch (e) {
      this.logger.category = null
      this.logger.categoryId = null
      if (e.message === "ADDRESS_FAILED_GEOCODING") {
        console.log(e)
        this.logger.error(
          "Could not geocode the address you provided; please provide a valid address or choose a point on the map"
        )
      } 
      else this.logger.error(`Project creation failed; contact your system administrator if this issue persists`)
      console.log("flash an error message so that the user sees their project was not created", e)
      throw e
    }
  }

  render = () => {
    const errors = this.props.errors

    return (
      <div style={{padding: "2rem", background: "white"}}>
        {Object.values(errors || {}).filter(error => error.length > 0).length > 0 && (
          <div
            style={{
              zIndex: 10,
              background: "red",
              position: "sticky",
              top: "1rem",
              display: "flex",
              flexFlow: "column"
            }}
          >
            {Object.entries(errors || {})
              .filter(([field, errors]) => errors.length > 0)
              .map(([field, errors]) => (
                <div
                  style={{
                    color: "white",
                    padding: "1rem",
                    display: "flex",
                    flexFlow: "row nowrap",
                    alignItems: "flex-start"
                  }}
                >
                  <div style={{padding: "0 1rem 0 0"}}>{fieldNameConverter[field]}</div>
                  <div>
                    {errors.map(error => (
                      <div>{error}</div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        )}
        <ProjectType />
        <SiteOwnerInformation />
        <SiteRepresentative />
        <ClientInformation />
        <InstallerInformation />
        <InspectionInformation />
      </div>
    )
  }
}

const WrappedProjectCreator = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(ProjectCreator)
export default WrappedProjectCreator
