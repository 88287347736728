import DB from '../../DAL/DB'
import {auth} from '../../Auth'
import CalendarEntry from '../CalendarEntry'

const calendars = new DB.Calendars()

function _makeDataMap (dataList) {
  const dataMap = {}
  for (let d of dataList) { dataMap[d.id] = new CalendarEntry({data: d}) }
  return dataMap
}

export function subscribeAvailability (dispatch) {
  return function () {
    const options = {filters: []}
    if (!['client', 'admin', 'super-admin', 'scheduler'].includes(auth.sunkaizenUser.type)) {
      options.filters.push(['data.id', '==', auth.sunkaizenUser.id])
    }
    else options.filters.push(['type', '==', 'Availability'])

    return calendars.subscribe(null, options, ({ok, data}) => {
      for (let a of data) {
        a.color = 'blue'
      }
      if (ok) dispatch({type: 'availability', value: _makeDataMap(data)})
    })        
  }
}

export function subscribeEvents (dispatch) {
  return function () {
    const options = {filters: []}
    if (!['client', 'admin', 'super-admin', 'scheduler'].includes(auth.sunkaizenUser.type)) {
      options.filters.push(['data.inspection.inspector.id', '==', auth.sunkaizenUser.id])
    }
    else options.filters.push(['type', '==', 'Intake'])

    return calendars.subscribe(null, options, ({ok, data}) => {
      if (ok) dispatch({type: 'events', value: _makeDataMap(data)})
    })        
  }
}