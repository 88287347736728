import columns from './columns'
import onRow from './onRow'
import permissions from './permissions'
import sorter from './sorter'
import rowSelection from './rowSelection'
import CreateForm from './CreateForm'
import {addRubrics} from './utilities'

import {organizationFilter} from '../utilities'

const config = {
  columns, 
  onRow, 
  permissions, 
  sorter,
  rowSelection, 
  type: 'forms', 
  searchbar: true,
  alternate: CreateForm,
  postProcess: [addRubrics],
  preProcess: [organizationFilter]
}

export default config