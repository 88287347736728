import {capitalizeFirst} from '../utilities'

export function generateColumns({aggregationType, betterOrWorse}) {
  const betterOrWorseText = betterOrWorse === `better` ? `Better` : `Worse`
  
  return [
    {title: aggregationType ? capitalizeFirst(aggregationType) : `Aggregation Type`, dataIndex: aggregationType},
    {
      title: `Number of Statistically ${betterOrWorseText} Measures`,
      dataIndex: `numMeasures`,
      align: `center`
    },
    {
      title: `List of Statistically ${betterOrWorseText} Measures`,
      dataIndex: `listOfMeasures`,
      align: `center`
    }
  ]
}