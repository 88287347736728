import React from "react"
import {Checkbox} from "antd"

import {connect} from "react-redux"

const checkboxStateToProps = (state, ownProps) => ({option: state.form.fields[ownProps.id].options[ownProps.i] || {}})
const NakedCheckboxItem = ({i, option}) => <div key={i}>{option && <Checkbox key={i}>{option.label}</Checkbox>}</div>
const CheckboxItem = connect(checkboxStateToProps)(NakedCheckboxItem)

const mapStateToProps = (state, ownProps) => ({field: state.form.fields[ownProps.id] || {}})
const NakedCheckboxes = ({field, id}) => (
  <div>{field.options && field.options.map((option, i) => <CheckboxItem id={id} i={i} />)}</div>
)
const Checkboxes = connect(mapStateToProps)(NakedCheckboxes)

export default Checkboxes
