import React from "react"
import {connect} from "react-redux"
import PhotoRequired from './PhotoRequired'

const stop = (state, op) => ({images: state.fields[op.id].images})

function RequireFollowUpPhoto ({images}) {
  return <div>{ !Object.keys(images || {}).length && <PhotoRequired /> }</div>
}

const WrappedRequireFollowUpPhoto = connect(stop)(RequireFollowUpPhoto)
export default WrappedRequireFollowUpPhoto