import React, {useContext} from "react"
import {ReducerContext} from './reducer'
import {Input, Spin, Icon, Select} from "antd"

export default function CalendarFilter({loading}) {
  const {state, dispatch} = useContext(ReducerContext)
  const {filterType, filterText} = state
  const iconStyles = {color: `#D8D8D8`, marginLeft: -4, cursor: filterType ? `text` : `not-allowed`}

  return (
    <Input
      id={`the-id-of-the-calendar-filter-component`}
      prefix={ loading 
        ? <Spin size="small" style={{...iconStyles, marginTop: 4}} />
        : (
      // See comment on `filterTextEmpty` for more information.
          <Icon
            style={{
              ...iconStyles,
              ...(!filterText ? {} : {color: `red`, fontSize: `1.6rem`, cursor: `pointer`}),
              marginTop: -1
            }}
            title={!filterText ? undefined : `Clear Search`}
            type={!filterText ? `search` : `close`}
            onClick={() => {
              const elem = document.getElementById(`the-id-of-the-calendar-filter-component`)
              if (!filterText) elem.focus()
              else dispatch({type: 'filterText', value: ``})
            }}
          />
        )
      }
      value={filterText}
      onChange={e => { dispatch({type: 'filterText', value: e.target.value}) }}
      placeholder={filterType ? `Start typing to search...` : `Please select a filter`}
      disabled={!filterType}
      loading={loading}
      size="large"
      style={{padding: `2rem 2rem 0 2rem`}}
      addonBefore={(
        <Select 
          value={filterType}
          onChange={e => dispatch({type: 'filterType', value: e}) } 
          placeholder="Filter by..." 
          style={{width: `20rem`}}
        >
          <Select.Option value="User">Filter by User</Select.Option>
          <Select.Option value="Client">Filter by Client</Select.Option>
          <Select.Option value="ProjectType">Filter by Project Type</Select.Option>
        </Select>        
      )}
    />
  )
}
