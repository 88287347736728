import React from "react"
import PropTypes from "prop-types"
import {Select} from "antd"

const Multiselect = ({select, options, selected}) => (
  <Select className="full-width" mode="multiple" defaultValue={selected} onChange={select}>
    {options.sort((a, b) => a.name > b.name).map(option => (
      <Select.Option key={option.id}>{option.name}</Select.Option>
    ))}
  </Select>
)

Multiselect.propTypes = {
  select: PropTypes.func,
  options: PropTypes.array,
  selected: PropTypes.string
}

export default Multiselect
