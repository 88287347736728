import React from "react"
import PropTypes from "prop-types"

import {Menu, Icon, Dropdown, Popover} from "antd"
import {Link} from "react-router-dom"

import NotificationsMenu from "./Logging/NotificationsMenu"

// this is just to get the ::placeholder pseudo-selector for the search box
import "./Header.css"

import {storage} from "./utilities"

const styles = {
  link: {
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: "125%",
    paddingTop: "0.3em",
    width: "90%",
    color: "white"
  },
  linkContainer: {
    paddingTop: "0.6em",
    paddingBottom: "0.5em",
    width: "90%"
  },
  header: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background: "#234091"
  },
  divider: {
    paddingTop: "3em"
  },
  form: {
    backgroundColor: "rgba(255,255,255,0.2)",
    width: "80%",
    display: "flex",
    borderRadius: "0.2em",
    height: "60%"
  },
  search: {
    backgroundColor: "transparent",
    color: "white",
    border: "none",
    alignSelf: "center",
    justifySelf: "flex-start",
    padding: "0.5em",
    borderRadius: "0.2em",
    height: "60%"
  },
  authPhoto: {
    alignSelf: "center",
    height: "inherit",
    borderRadius: "45%"
  },
  icon: {
    color: "#919EAB",
    alignSelf: "center",
    padding: "1rem",
    fontSize: "200%"
  },
  logoutDropdown: {
    color: "white",
    display: "flex",
    alignItems: "center",
    padding: "2rem"
  }
}

// <Dropdown placement="bottomRight" overlay={ Overlay } ><div><Icon type="ellipsis" /> More</div></Dropdown>

const Overlay = (logout, currentUser) => (
  <Menu>
    {currentUser && (
      <Menu.Item>
        <Link to="/settings/account">Account Info</Link>
      </Menu.Item>
    )}
    <Menu.Item>
      <div onClick={logout} data-cy="logout">
        <Icon type="logout" style={{padding: "0 1rem 0 0"}} />
        Logout
      </div>
    </Menu.Item>
  </Menu>
)

export class UserInfo extends React.Component {
  static propTypes = {
    auth: PropTypes.object,
    user: PropTypes.object
  }

  componentDidMount = async () => {
    if (this.props.user) {
      if (this.props.user.avatar && this.props.user.avatar.fullPath) {
        let avatar = await storage.ref(this.props.user.avatar.fullPath).getDownloadURL()
        if (!avatar) avatar = "https://picsum.photos/100/100"
        this.setState({avatar})
      } else this.setState({avatar: "https://picsum.photos/100/100"})
    }
  }

  componentDidUpdate = async pprops => {
    if (!pprops.user || this.props.user) return

    if (
      (!pprops.user.avatar && this.props.user && this.props.user.avatar) ||
      (this.props.user && this.props.user.avatar && pprops.user.avatar.fullPath !== this.props.user.avatar.fullPath)
    ) {
      let avatar = await storage.ref(this.props.user.avatar.fullPath).getDownloadURL()
      // console.log(avatar)
      if (!avatar) avatar = "https://picsum.photos/100/100"
      this.setState({avatar})
    } else if (!this.props.user.avatar && pprops.user.avatar) this.setState({avatar: "https://picsum.photos/100/100"})
  }

  state = {avatar: null}

  render = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        fontSize: "75%",
        alignItems: "center",
        justifyContent: "flex-end"
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          padding: "0.5rem",
          justifyContent: "flex-end"
        }}
      >
        {this.state.avatar && <img src={this.state.avatar} style={styles.authPhoto} alt="user" />}
      </div>
      <div
        style={{
          color: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div style={{fontWeight: "bold"}}>{this.props.user ? this.props.user.name : ""}</div>
        <div>{this.props.user ? this.props.user.email : "no email on file"}</div>
      </div>
      <Dropdown placement="bottomRight" overlay={Overlay(this.props.auth.logout, this.props.auth.currentUser)}>
        <Icon style={styles.logoutDropdown} type="caret-down" data-cy="logoutIcon"/>
      </Dropdown>
    </div>
  )
}

export default class Header extends React.Component {
  static propTypes = {
    auth: PropTypes.object
  }

  componentDidMount = () =>
    this.props.auth.sunkaizenUserSubscribe(user => {
      this.setState({user})
      this.forceUpdate()
    })

  state = {user: null}

  submit = e => {
    e.preventDefault()
    console.log("this is the searchbar result: ", e.target)
  }

  render() {
    let {auth} = this.props
    return (
      <div style={styles.header}>
        <div style={{display: "flex", flex: 80}}>
          {/*
						<div
							style={{
								flex: 30,
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							<form onSubmit={this.submit} style={styles.form}>
								<Icon style={styles.icon} type="search" />
								<input
									className="header-search"
									style={styles.search}
									type="text"
									placeholder="Search Coming Soon"
								/>
								<input type="submit" style={{ display: "none" }} />
							</form>
						</div>

						<div style={{ flex: 5 }}></div>
					*/}
          <div
            style={{
              flex: 20,
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            {/* this needs a popover that handles login/logout */}
            <span>
              {auth.sunkaizenUser ? (
                <UserInfo user={this.state.user} auth={auth} />
              ) : (
                <Link to="/login">
                  <Icon type="login" /> Sign In
                </Link>
              )}
            </span>
          </div>
        </div>
      </div>
    )
  }
}
