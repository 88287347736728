import {store} from "../utilities"
import tablePostProcessor from './tablePostProcessor'
import {unlock} from './locking'

export default async function postProcess (queryResult, lock) {
  const state = store.getState()
  const {postProcess = []} = state.tableVars.tableStrategy || {}
  let pp

  if (postProcess.length) { pp = postProcess.reduce((a, f) => a.then(f), Promise.resolve(queryResult)) }
  await pp

  if (unlock(lock)) tablePostProcessor(queryResult)
  else { throw new Error('lock is expired') }
}