// need to set this up so that clients can only use form types they have permissions for

import React from "react"
import PropTypes from "prop-types"

// import { Form, Icon, Input, Button, Select, Checkbox, DatePicker } from 'antd'
import {Form, Select, Icon} from "antd"
import {db} from "../utilities"
import {fieldNameConverter} from "../validators"
import {Required} from "./utilities"

import {setField, makePermissions} from "./action_creators"
import {connect} from "react-redux"

const mapStateToProps = state => {
  return ({
    fields: state.fields,
    id: state.projectId,
    update: state.permissionsCategories.formsPopulate,
    formU: state.permissions.forms.length,
    forms: state.permissions.forms
  })
}
const mapDispatchToProps = dispatch => ({
  setField: field => value => dispatch(setField(field, value)),
  makePermissions: () => dispatch(makePermissions("form"))
})

class ProjectType extends React.Component {
  static propTypes = {
    fields: PropTypes.object,
    setField: PropTypes.func,
    forms: PropTypes.object,
    id: PropTypes.string
  }

  state = {unsubscribe: [], rubrics: {}}

  componentDidMount = () => {
    if (this.props.fields.projectType) this.rubricGetter()
  }

  componentDidUpdate = pprops => {
    if (this.props.fields.projectType) {
      const {id} = this.props.fields.projectType || {}
      const {id: pId} = pprops.fields.projectType || {}

      if (id && id !== pId && this.props.fields.projectType) {
        this.rubricGetter()
      }
    }

    if (this.props.update !== pprops.update) this.props.makePermissions()
  }

  componentWillUnmount = () => {
    if (this.state.unsubscribe) this.state.unsubscribe.map(u => u())
  }

  rubricGetter = () => {
    const unsubscribe = db
      .collection("rubrics")
      .where("formId", "==", this.props.fields.projectType.id)
      .onSnapshot(snapshot => {
        if (!snapshot.empty) {
          const rubrics = {}
          snapshot.docs.forEach(r => {
            rubrics[r.od] = {...r.data(), id: r.id}
          })
          this.setState({rubrics})
        } else {
          console.log("there are no rubrics for this form")
        }
      })

    this.setState({unsubscribe: [].concat(this.state.unsubscribe, unsubscribe)})
  }

  render = () => {
    const {fields, setField, id} = this.props
    const {projectType = {}, rubric} = fields

    return (
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "flex-start",
          background: "#F4F6F8",
          padding: "2rem",
          margin: "0 0 1rem 0"
        }}
      >
        <h3>Project Type</h3>
        <Required />
        {id && (
          <div style={{padding: "0.5rem", fontWeight: "bold"}}>
            Warning: Changing the Project Type will reset the Intake; progress cannot be recovered
          </div>
        )}
        <Form.Item style={{width: "100%", margin: "0 0 1rem 0", padding: "0 1rem 0 0"}}>
          <Select
            placeholder={fieldNameConverter["type"]}
            value={projectType.title}
            onChange={e => {
              if (projectType.id !== this.props.forms[e].id) setField('rubric')(null)
              setField("projectType")(this.props.forms[e])
            }}
            size="large"
            style={{width: "100%"}}
            data-cy="projectType"
          >
            {Object.values(this.props.forms || {}).sort((a, b) => a.title > b.title ? 1 : -1).map((f, i) => (
              <Select.Option key={i} value={f.id}>
                {f.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <h3>Project Scoring Rubric</h3>
        <Form.Item style={{width: "100%", margin: "0 0 1rem 0", padding: "0 1rem 0 0"}}>
          <Select
            placeholder="Rubrics for this form"
            value={(rubric || {}).name}
            onChange={e => setField("rubric")(e)}
            size="large"
            style={{width: "100%"}}
            data-cy="projectRubric"
          >
            {Object.values(this.state.rubrics).sort((a, b) => a.name > b.name ? 1 : -1).map((r, i) => (
              <Select.Option key={i} value={r}>
                {r.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    )
  }
}

const WrappedProjectType = connect(mapStateToProps, mapDispatchToProps)(ProjectType)
export default WrappedProjectType
