import React, {useState, useEffect} from "react"
import {Menu, Icon} from "antd"
import PropTypes from "prop-types"
import {Link} from "react-router-dom"
import Auth from "./Auth"

const auth = new Auth()

const selectedMap = {
  "/": `home`,
  "/projects": `projects`,
  "/users": `users`,
  "/reports": `reports`,
  "/calendar": `calendar`,
  "/analytics": `data`,
  "/logs": `logs`,
  "/forms": `forms`,
  "/schedule": `schedule`,
  "/scoringSystems": `scoring`
}

const menuItems = {
  home: (
    <Menu.Item key="home">
      <Link to="/">
        <Icon type="home" /> My Home
      </Link>
    </Menu.Item>
  ),
  projects: (
    <Menu.Item key="projects">
      <Link to="/projects">
        <Icon type="check-square" /> Projects
      </Link>
    </Menu.Item>
  ),
  users: (
    <Menu.Item key="users">
      <Link to="/users">
        <Icon type="user" /> Users
      </Link>
    </Menu.Item>
  ),
  reports: (
    <Menu.Item key="reports">
      <Link to="/reports">
        <Icon type="line-chart" /> Reports
      </Link>
    </Menu.Item>
  ),
  calendar: (
    <Menu.Item key="calendar">
      <Link to="/calendar">
        <Icon type="calendar" /> Calendar
      </Link>
    </Menu.Item>
  ),
  data: (
    <Menu.Item key="data">
      <Link to="/analytics">
        <Icon type="bar-chart" /> Data
      </Link>
    </Menu.Item>
  ),
  // "logs": <Menu.Item key="logs" ><Link to="/logs"><Icon type="table" /> Application Logs</Link></Menu.Item>,
  forms: (
    <Menu.Item key="forms">
      <Link to="/forms">
        <Icon type="bars" /> Forms
      </Link>
    </Menu.Item>
  ),
  schedule: (
    <Menu.Item key="schedule">
      <Link to="/schedule">
        <Icon type="schedule" /> Scheduling
      </Link>
    </Menu.Item>
  ),
  scoring: (
    <Menu.Item key="scoring">
      <Link to="/scoringSystems">
        <Icon type="audit" /> Scoring Systems
      </Link>
    </Menu.Item>
  )
}

const userMap = {
  "super-admin": [`home`, `projects`, `users`, `reports`, `calendar`, `data`, `logs`, `forms`, `schedule`, `scoring`],
  admin: [`home`, `projects`, `users`, `reports`, `calendar`, `data`, `logs`, `forms`, `schedule`, `scoring`],
  client: [`home`, `projects`, `reports`, 'data'],
  "field-user": [`home`, `projects`, `calendar`],
  installer: [`home`, `projects`, `calendar`, `data`],
  "quality-manager": [`home`, `projects`],
  scheduler: [`home`, `projects`, `calendar`, `schedule`]
}

const Sidebar = ({location}) => {
  const [user, setUser] = useState({})
  useEffect(() => {
    const unsubscribe = auth.sunkaizenUserSubscribe(skUser => {
      if (skUser) setUser(skUser)
      else if (user) setUser({})
    })

    return unsubscribe
  }, [user.id])

  return (
    <div style={{background: `#FBFBFB`, height: `100%`}}>
      <h3
        style={{
          alignItems: `center`,
          justifyContent: `center`,
          display: `flex`,
          flex: `15 1 10%`,
          background: `#FBFBFB`,
          margin: 0,
          height: `7.5rem`
        }}
      >
        FIT-QM&trade;
      </h3>
      <Menu
        selectedKeys={[selectedMap[location.pathname]]}
        mode="inline"
        style={{width: `100%`, height: `100%`, textAlign: `left`, padding: `0 0 0 1rem`}}
      >
        {user.type && userMap[user.type].map(item => menuItems[item])}
      </Menu>
    </div>
  )
}

Sidebar.propTypes = {auth: PropTypes.object}

export default Sidebar
