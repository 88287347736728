import {store} from "../utilities"
import {setQueryParams, setTableControls, lockQueue} from '../action_creators'
import makeQueryString from './makeQueryString'
import filterIsSame from './filterIsSame'
import update from './update'

export default function onChange (pagination = {current: 1}, filters = {}, sorter = {}) {
  store.dispatch(lockQueue(true))
  const state = store.getState()
  const ff = {}

  Object.entries(filters).forEach(([k, v]) => { if (v.length > 0) ff[k] = v })

  let queryParams = {}

  if (filterIsSame(ff, state.tableControls.filters)) { queryParams.from = (pagination.current - 1) * state.query.size || 0 } 
  else { queryParams = makeQueryString(filters) }

  store.dispatch(setQueryParams(queryParams))
  store.dispatch(setTableControls({pagination, filters: ff, sorter}))    

  update()
}