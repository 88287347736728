import React from 'react'
import {Select} from "antd"
import _ from "lodash"

export function GenericSelector({values = [], selectedValue, valueTitle, placeholder, onChange, className}) {
  return (
    <Select
      className={`selector ` + className || ``}
      value={selectedValue}
      placeholder={placeholder}
      onChange={onChange}
    >
      {
        values.map(value => (
          <Select.Option value={value.id} key={value.id}>
            {_.isFunction(valueTitle) ? valueTitle(value) : value[valueTitle]}
          </Select.Option>
        ))
      }
    </Select>
  )
}