import React from 'react'
import {connect} from "react-redux"
import {setField} from "../action_creators"
import PropTypes from "prop-types"
import {Form, Input, Checkbox} from "antd"

const mapStateToProps = (state, ownProps) => ({
  field: state.form.fields[ownProps.id] || {}
})

const mapDispatchToProps = dispatch => ({
  setField: ({id, value}) => dispatch(setField({category: "fields", id, value})),
  add: field => {
    const options = [
      {label: "Pass", requireFollowup: false},
      {label: "Fail", requireFollowup: false},
      {label: "Missed", requireFollowup: false},
      {label: "N/A", requireFollowup: false}
    ]
    dispatch(setField({category: "fields", id: field.id, value: {...field, options}}))
  },
  delete: () => { console.log('this should remove the options from the store') }
})

class TemplatedBase extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    setField: PropTypes.func,
    field: PropTypes.object,
    add: PropTypes.func,
    delete: PropTypes.func
  }

  supportsRequireUploadingPhotos() {
    return true
  }

  componentDidMount = () => {
    this.props.add(this.props.field)
  }

  render = () => (
    <div>
      <Form.Item label="Label" style={{margin: 0}}>
        <Input
          style={{backgroundColor: "rgba(248, 248, 248, 1)", marginBottom: "2rem"}}
          size="large"
          onChange={e =>
            this.props.setField({
              id: this.props.id,
              value: {...this.props.field, label: e.target.value}
            })
          }
          value={this.props.field.label}
        />
      </Form.Item>
      <Form.Item label="Question" style={{margin: 0}}>
        <Input
          style={{backgroundColor: "rgba(248, 248, 248, 1)", marginBottom: "2rem"}}
          size="large"
          onChange={e =>
            this.props.setField({
              id: this.props.id,
              value: {...this.props.field, question: e.target.value}
            })
          }
          value={this.props.field.question}
        />
      </Form.Item>
      <Form.Item label="Tip text" style={{margin: 0}}>
        <Input
          style={{backgroundColor: "rgba(248, 248, 248, 1)", marginBottom: "2rem"}}
          size="large"
          onChange={e =>
            this.props.setField({
              id: this.props.id,
              value: {...this.props.field, tipText: e.target.value}
            })
          }
          value={this.props.field.tipText}
        />
      </Form.Item>
      <Form.Item style={{margin: 0}}>
        <Checkbox
          onChange={e =>
            this.props.setField({id: this.props.id, value: {...this.props.field, required: e.target.checked}})
          }
          value={this.props.field.required}
          checked={this.props.field.required}
        >
          Required
        </Checkbox>
      </Form.Item>
      {this.supportsRequireUploadingPhotos() && (
        <Form.Item style={{margin: 0}}>
          <Checkbox
            onChange={e =>
              this.props.setField({
                id: this.props.id,
                value: {...this.props.field, photoRequired: e.target.checked}
              })
            }
            value={this.props.field.photoRequired}
            checked={this.props.field.photoRequired}
          >
            Require Uploading Photos
          </Checkbox>
        </Form.Item>
      )}
      <Form.Item style={{margin: 0}}>
        <Checkbox
          onChange={e =>
            this.props.setField({
              id: this.props.id,
              value: {...this.props.field, requiredForFieldIntake: e.target.checked}
            })
          }
          value={this.props.field.requiredForFieldIntake}
          checked={this.props.field.requiredForFieldIntake}
        >
          Required for Field Intake
        </Checkbox>
      </Form.Item>
    </div>
  )  
}

const WrappedTemplated = connect(mapStateToProps, mapDispatchToProps)(TemplatedBase)

export default WrappedTemplated