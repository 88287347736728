import React, {useEffect, useContext} from 'react'
import {ReducerContext, wrap} from './reducer'
import {pushData, loadProjectSchedule, projectDatesToMoment} from './utilities'
import DB from '../DAL/DB'
import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'
import './ScheduleInspections.css'

const projects = new DB.Projects()

export function TopLevelState ({match, setVisible}) {
  const {state, dispatch} = useContext(ReducerContext)
  const push = pushData({state, dispatch})
  const {params: {id} = {}} = match

  // if the user is accessing the scheduler from projectdetail, load the selected project
  useEffect(function () {
    (async function () {            
      if (id) {
        const {ok, data: [project]} = await projects.get(id)
        if (ok) {
          const schedule = loadProjectSchedule(project)
          push(schedule)
          dispatch({type: 'projects', value: {[project.id]: project}})
        }
      }
    })()
  }, [])

  // whenever a new project is selected, load it into state so the component can display its assignment traits
  useEffect(function () {
    if (!state.project.id) return
    const schedule = loadProjectSchedule(state.project)

    for (let k in schedule) { if (!schedule[k]) delete schedule[k] }

    push({date: state.date, time: state.time, ...schedule})
  }, [state.project.id])

  return (
    <div className="flex-row" >
      <LeftPanel setVisible={setVisible} />
      <div style={{flex: 10}} >
        <RightPanel posthooks={[projectDatesToMoment]} single={id} />
      </div>
    </div>
  )
}

const WrappedTopLevelState = wrap(TopLevelState)
export default WrappedTopLevelState