import Papa from "papaparse"
import flattenProject from "./flattenProject"
import {headers} from "./headerOrder"
import {commentHeaders} from "./commentHeaderOrder"
import flattenIntakeComment from "./flattenIntakeComments"

export default function createCSV(projects) {
  const pp = [headers]

  for (let project of projects) {
    const p = flattenProject(project)
    pp.push(p)
  }

  return Papa.unparse(pp)
}

const getProjectIntakeFields = (project) => {
  const intakeFields = []
  const sections = Object.values(project.intake.sections)

  sections.forEach((section) => {
    section.fields.forEach((field) => {
      if (Array.isArray(field.options)) {
        field.options.forEach((option) => {
          if (option.requireFollowupComments) {
            const {label} = option
            if (field.value?.followup[label]?.requireFollowUpComment) {
              intakeFields.push({
                section: section.title,
                question: field.question,
                answer: label,
                comment: field.value.followup[label].requireFollowUpComment,
              })
            }
          }
        })
      }
    })
  })

  return intakeFields
}

export function createCommentCSV(projects) {
  const pp = [commentHeaders]

  const rows = []
  for (let project of projects) {
    const intake_fields = getProjectIntakeFields(project)
    intake_fields.forEach((intake_field) => {
      const p = flattenIntakeComment(project, intake_field)
      pp.push(p)
    })
  }

  return Papa.unparse(pp)
}
