import React from "react"
import {Icon} from "antd"

export default class PhotoRequired extends React.Component {
  render() {
    return (
      <div
        style={{
          marginTop: 20,
          paddingTop: 10,
          textAlign: `center`,
          border: `1px dashed #C4CDD5`,
          background: `#FBFBFB`,
          width: 80,
          height: 80
        }}
      >
        <Icon type="picture" style={{fontSize: 42}} />
        <div>
          <b>Add</b>
        </div>
      </div>
    )
  }
}
