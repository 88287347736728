import _ from "lodash"
import React from "react"
import PropTypes from "prop-types"

import {
  buildQuery,
  pathMustNotEndInADocumentMsg,
  createFunctionsFromPathsMapping,
  setTerminalDocumentForPaths
} from "./common"

/**
 * The update HOC provides a component with update functionality after providing
 * a `mapUpdateFunctionsToPaths` property. See the documentation for CreateHOC
 * to understand more, as the architecture is very similar between components.
 *
 * The major difference between them is that the update functions take both a
 * string id as a first argument, and an object describing which fields to
 * update. There is also a special "dot syntax" that can be used to describe
 * nested updates, which is elaborated upon below.
 *
 * ## Nested Data
 *
 * To update nested data, *you do not need to copy and merge in JavaScript*.
 * Instead, firebase supports dot syntax with updates. So for data like:
 *
 *      {a: {b: "John", c: "Food"}}
 *
 * you do not have to pull the full data, and do
 *
 *      updateFn(id, {a: {...data.a, b: "Green"}})
 *
 * but rather you can write something like
 *
 *      updateFn(id, {"a.b": "Green"})
 */
export const update = () => Component => {
  function UpdateHOC({childRef, mapUpdateFunctionsToPaths = {}, ...props}) {
    const updateFunctions = createFunctionsFromPathsMapping({
      mapFunctionsToPaths: mapUpdateFunctionsToPaths,
      makeFunction: prepareDocumentUpdate
    })

    return <Component ref={childRef} {...props} updateFunctions={updateFunctions} />
  }

  UpdateHOC.propTypes = {
    childRef: PropTypes.object,
    mapUpdateFunctionsToPaths: PropTypes.object
  }

  return React.forwardRef(function ForwardRefUpdateHOC(props, ref) {
    return <UpdateHOC {...props} childRef={ref} />
  })
}

/**
 * Returns an update function that takes a string id and an object and updates
 * the corresponding document from the (sub)collection at the path specified.
 *
 * See CreateHOC or DestroyHOC for more implementation examples and
 * documentation on similar patterns.
 */
export function prepareDocumentUpdate(path) {
  const lastPathObject = _.last(path)
  if (lastPathObject.doc) {
    throw new Error(pathMustNotEndInADocumentMsg(`update`, `update`, lastPathObject))
  }

  const updateDocument = (id, updatesObject) => {
    if (!_.isString(id)) {
      throw new Error(`You must provide a string id to update a document in firebase.`)
    }

    const pathToDocument = setTerminalDocumentForPaths(path, id)
    const query = buildQuery({paths: pathToDocument})
    return query.update(updatesObject)
  }

  return updateDocument
}
