import {fieldHasErrors} from './fieldHasErrors'

export function checkRequired (intake) {
  let errors = {}

  Object.values(intake.sections).forEach(section => {
    for (let i in section.fields) {
      const field = section.fields[i]
      if (fieldHasErrors(field)) {
        // console.log(field, section.id, section.title)
        errors[field.id] = true
      }
    }
  })
  
  // console.log(`errors found for project: `, errors)
  return errors
}