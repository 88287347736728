import _ from "lodash"
import React from "react"
import PropTypes from "prop-types"

import {buildQuery, createFunctionsFromPathsMapping} from "./common"

/**
 * See `src/HOC/Firebase/common.js` for documentation on our path syntax, and
 * what format is expected for document creation.
 *
 * `mapCreationFunctionsToPaths` should be an object that maps a creation
 * function name (as key) to a collection in our data store via our path syntax.
 *
 * ## Injected Properties
 *
 * ### Creation Functions
 *
 * The HOC injects the `creationFunctions` property containing keys of the names
 * given by the `mapCreationFunctionsToPaths` object, to values of the functions
 * that will create objects at the given paths.
 */
export const create = () => (Component) => {
  function CreateHOC({childRef, mapCreationFunctionsToPaths, ...props}) {
    const creationFunctions = createFunctionsFromPathsMapping({
      mapFunctionsToPaths: mapCreationFunctionsToPaths,
      makeFunction: (path) => (document) => {
        const {id, createDocument} = prepareDocumentCreation(path)
        return {createDocumentPromise: createDocument(document), id}
      },
    })

    return <Component ref={childRef} {...props} pendingResults={() => {}} creationFunctions={creationFunctions} />
  }

  CreateHOC.propTypes = {
    childRef: PropTypes.object,
    mapCreationFunctionsToPaths: PropTypes.object,
  }

  return React.forwardRef(function ForwardRefCreateHOC(props, ref) {
    return <CreateHOC {...props} childRef={ref} documentsPendingCreation={{}} />
  })
}

/**
 * Takes a `paths` array and returns an object containing a
 * `createDocument(document)` function for creating a document at that path,
 * alongside the `id` of the document that *may* be created (the function never
 * needs to be invoked).
 *
 * When `createDocument` is invoked, it returns a promise that will resolve once
 * the data has been written to the backend.
 *
 * This two stage process allows us to examine the autogenerated id immediately,
 * whether or not the object ever gets created, simplifying UI code that uses
 * object placeholders, or prepares for loading.
 *
 * The `paths` array must resolve to a collection. Here are some examples of
 * valid paths:
 *
 *      [{collection: `projects`}] // Same as db.collection(`projects`)
 *
 *      [{collection: `projects`, doc: `2hf78e742hdy4i7es4y`}, {collection: `admins`}]
 *      // Same as db.collection(`projects`).doc(`2hf78e742hdy4i7es4y`).collection(`admins`)
 *
 * This path however, would error out:
 *
 *      [{collection: `projects`, doc: `2hf78e742hdy4i7es4y`}]
 *      // Same as db.collection(`projects`).doc(`2hf78e742hdy4i7es4y`)
 */
export function prepareDocumentCreation(paths) {
  const lastPathObject = _.last(paths)
  if (lastPathObject.doc) {
    throw new Error(
      `The paths you provided would create a document either at the location of another document, or with the id given to 'doc' (but this is not needed as ids are autogenerated by default). You need to specify a terminal collection instead. Here's the failing path object: ${JSON.stringify(
        lastPathObject
      )}`
    )
  }

  const query = buildQuery({paths})
  const doc = query.doc()
  const createDocument = (document) => doc.set(document)

  return {id: doc.id, createDocument}
}
