import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from "@fullcalendar/list"

export default function fullCalendarProps (args) {
  const {showIntakes, setShowIntakes} = args
  const {userType, displayedEvents, dispatch, eventClick} = args

  return {
    defaultView: "dayGridMonth",
    views: {listWeekMonth: {type: "listWeek", duration: {months: 1}, buttonText: "list"}},
    header: {
      left: "prev,next today",
      center: "title",
      right: "showIntakes dayGridMonth,timeGridWeek,listWeekMonth"
    },
    customButtons: {
      showIntakes: {
        text: showIntakes ? "Hide Intakes" : "Show Intakes",
        click: () => setShowIntakes(!showIntakes)
      }
    },
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
    rerenderDelay: 10,
    unselectAuto: false,
    editable: userType === 'field-user',
    weekends: true,
    events: displayedEvents,
    selectable: userType === 'field-user',
    select: value => dispatch({type: 'selected', value}),
    eventClick: eventClick
  }
}