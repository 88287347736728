export default async function get (url, body) {
  const res = await (
    await fetch(url, {
      method: `POST`,
      mode: `cors`,
      headers: {"Content-Type": `application/json`},
      body: JSON.stringify(body)
    })
  ).json()

  return res
}