import React, {useState, useEffect} from "react"
import {saveAs} from "file-saver"
import {auth} from "../../Auth"
import {Spin} from "antd"
import {FUNCTIONS_URL} from '../../config'
import {loggerEffect} from '../../effects'
import Branding from './Branding'
import Content from './Content'
import {makeBody} from './makeBody'

let URL = `${FUNCTIONS_URL}/utilities/new_report`
// Uncomment this if you are running a local shell that you want to query
// URL = "http://localhost:5000/sunkaizen-9f140/us-central1/utilities/new_report"

export default function PDFReportGenerator ({id, title, formId, site, done}) {
  const [options, setOptions] = useState({})
  const [loading, setLoading] = useState(false)
  const [logger, setLogger] = useState(null)

  useEffect(loggerEffect(setLogger), [])

  useEffect(function () {
    const {reportConfiguration} = auth.sunkaizenUser
    if (reportConfiguration) {
      if (reportConfiguration.default) { setOptions(reportConfiguration.default) }
      if (reportConfiguration[formId]) { setOptions(reportConfiguration[formId]) } 
    }
  })

  useEffect(function () { 
    (async function () { if (done) await create() })()
  }, [done])

  function setField (fieldname, value) { setOptions({...options, [fieldname]: value}) }
  async function create () {
    setLoading(true)

    const oo = makeBody(options)

    try {
      const report = await (
        await fetch(URL, {
          mode: "cors",
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify({options: oo, id})
        })
      ).blob()

      saveAs(report, `Custom ${site.siteOwnerName} ${title} Report.pdf`)      
    }
    catch (e) { 
      console.log(e) 
      logger.error(`failed to create PDF report: ${e.message}`)
    }
    finally { setLoading(false) }
  }

  return (
    <div>
      <div className="flex-row justify-center" >{loading && <Spin size="large" />}</div>
      <div>Choose options for generating a custom PDF report</div>
      <Branding {...{setField, options}} />
      <Content {...{setField, options}} />
    </div>    
  )
}