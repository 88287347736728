import React, {useState} from "react"
import {ScheduleInspectionModal} from '../ScheduleInspections'
import {Modal, Button} from "antd"
import CancelProjectModal from "./CancelProjectModal"

export default function Scheduled ({project}) {
  const [showCancel, setShowCancel] = useState(false)
  const [schedule, setSchedule] = useState(false)

  return (
    <div>
      <div className="flex-row align-space-between full-width" style={{flex: 1}}>
        <ScheduleInspectionModal icon="calendar" id={project.id} style={{flex: 1}} >Reschedule</ScheduleInspectionModal>
        {project.status === "Scheduled" && (
          <Button className="margin-left-1" style={{flex: 1}} onClick={() => setShowCancel(true)}>
                        Cancel Inspection
          </Button>
        )}
      </div>
      <Modal
        width="95%"
        title="Cancel Intake"
        visible={showCancel}
        onOk={() => setShowCancel(false)}
        onCancel={() => setShowCancel(false)}
        footer={null}
      >
        <CancelProjectModal type="intake" id={project.id} />
      </Modal>
    </div>
  )
}