import React from "react"

import SingleInputPropertyBoxBase from './SingleInputPropertyBoxBase'
import OptionPropertyBoxBase from './OptionPropertyBoxBase'
import TemplatedBase from './TemplatedBase'

const componentNameMapping = {
  'single-input': SingleInputPropertyBoxBase,
  'text-area': SingleInputPropertyBoxBase,
  'radio-button': OptionPropertyBoxBase,
  'dropdown': OptionPropertyBoxBase,
  'checkboxes': OptionPropertyBoxBase,
  'multiselect': OptionPropertyBoxBase,
  'date': SingleInputPropertyBoxBase,
  'time': SingleInputPropertyBoxBase,
  'templated': TemplatedBase
}

const components = []

const PropertyBox = ({type, id}) => {
  const C = componentNameMapping[type] || SingleInputPropertyBoxBase
  return <C id={id} />
}

function componentByName(name) {
  const c = componentNameMapping[name]
  return c ? c : SingleInputPropertyBoxBase
}

export {PropertyBox, componentByName} // , components, 
