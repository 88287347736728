import React from "react"
// import PropTypes from 'prop-types'
import {Icon} from "antd"
import {Link} from "react-router-dom"

import {db} from "../utilities"
// import Auth from '../Auth'

// const auth = new Auth()

export default class Notifications extends React.Component {
  static propTypes = {}

  componentDidMount = () => {
    this.getNotifications()
  }
  componentWillUnmount = () => {
    if (this.state.unsubscribe) this.state.unsubscribe()
  }

  state = {notifications: []}

  getNotifications = () => {
    const unsubscribe = this.makeQuery()
      .orderBy("created", "desc")
      .limit(10)
      .onSnapshot(notificationsSnapshot => {
        const notifications = notificationsSnapshot.docs
          .filter(notification => !notification.seen)
          .map(notification => ({...notification.data(), id: notification.id}))

        const notificationDates = {}
        notifications.forEach(notification => {
          notificationDates[notification.created] = notification
        })
        this.setState({notifications: Object.values(notificationDates)})
        // this.setState({ notifications })
      })

    this.setState({unsubscribe})
  }

  markRead = e => id => {
    e.stopPropagation()
    e.preventDefault()
    db.collection("projectTransactionLog")
      .doc(id)
      .set({seen: true}, {merge: true})
  }

  makeQuery = () => {
    const {sunkaizenUser} = this.props.auth
    // const { filters } = this.state

    // console.log(sunkaizenUser)
    const filterArray = [
      sunkaizenUser.type === "admin" || sunkaizenUser.type === "super-admin"
        ? null
        : ["recipient", "==", sunkaizenUser.id],
      ["seen", "==", false]
    ].filter(i => i)

    return filterArray.reduce((a, c) => a.where(...c), db.collection("projectTransactionLog"))
  }

  render = () => (
    <div style={{width: "100%", display: "flex", flexFlow: "column", margin: "0 0 3rem 0"}} data-cy="projectNotifications">
      {this.state.notifications.slice(0, 3).map((notification, i) => (
        <Link
          to={`/projects/${notification.categoryId}/manage`}
          key={i}
          style={{
            color: "black",
            textDecoration: "none",
            borderRadius: "0.5rem",
            backgroundColor: "lightyellow",
            margin: "0 0 0.5rem 0",
            padding: "0.5rem 1rem 0.5rem 1rem",
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            justifyContent: "space-between",
            border: "0.1rem solid lightgrey"
          }}
        >
          <div style={{padding: "0.5rem 1rem 0.5rem 1rem"}}>
            {`Project (${(notification.site || {}).siteOwnerName || "No Owner Listed"}, ${
              (notification.site || {}).siteAddress
            }) ${notification.message}`}
          </div>
          <Icon
            style={{zIndex: 10, padding: "1rem"}}
            type="close"
            onClickCapture={e => this.markRead(e)(notification.id)}
          />
        </Link>
      ))}
      {this.props.auth.currentUser && this.state.notifications.length - 3 > 0 && (
        <Link
          style={{padding: "1.5rem 0 0 0", display: "flex", alignSelf: "flex-start"}}
          to={`/projectNotifications/${this.props.auth.currentUser.uid}`}
        >
          + more notifications
        </Link>
      )}
    </div>
  )
}
