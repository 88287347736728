import React from "react"
import {connect} from "react-redux"
import {Button} from "antd"
import {Sticky} from "react-sticky"
import {history} from "../history"
import {saveIntake} from './utilities'
import {setSave} from './action_creators'

const stp = s => ({disabled: s.meta.saveButton})
const dtp = {setSave}

export function TabBar ({project, highlightErrors, props, Default, disabled, setSave}) {
  async function saver (route) {
    setSave(true)
    try {
      await saveIntake()
      if (route) {
        setSave(false)
        history.push(route)
      }
      if (!route) {
        setTimeout(() => setSave(false), 10000)
      }
    }
    catch (e) {
      highlightErrors(e)
      console.log(`some errors occurred that prevent submission`, e)
    }
  }

  return (
    <Sticky>{({style}) => (
      <div className="flex-row background-white justify-space-between" style={{...style, zIndex: 10}} >
        <Default {...props} style={{width: '75%'}} />
        <div className="flex-row" style={{flex: 1}} >
          <Button disabled={disabled} style={{margin: `1rem 1rem 1rem 0`}} type="default" onClick={() => saver()}>
                        Save
          </Button>
          <Button 
            disabled={disabled} 
            style={{margin: `1rem 0 1rem 0`}} 
            type="primary" 
            onClick={() => saver(`/projects/${project.id}/manage`)} 
          >
                        Save and Exit
          </Button>
        </div>
      </div>
    )}</Sticky>    
  )
}

const WrappedTabBar = connect(stp, dtp)(TabBar)
export default WrappedTabBar