import React from "react"
import PropTypes from "prop-types"
import {Button, Icon} from "antd"
import AutocompleteField from "../AutocompleteField"
import {db, storage, history, emailRx} from "../utilities"
import Auth from "../Auth"
import SystemLogger from "../SystemLogger"
import GeocodeWrapper from "../GeocodeWrapper"

import Dropdown from "./Dropdown"
import Field from "./Field"
import PermissionsBlock from "./PermissionsBlock"
import AvatarPane from "./AvatarPane"
import "./DetailedUserView.css"

import {capitalizationMap} from "./utilities"

export const wrappedGeocode = new GeocodeWrapper()

const auth = new Auth()

export default class DetailedUserView extends React.Component {
  static propTypes = {id: PropTypes.string}

  avatar = "https://picsum.photos/300/300/?random"

  componentDidMount = async () => {
    this.logger = new SystemLogger({
      uid: auth.currentUser.uid,
      path: `/users/${this.props.id}`
    })

    const unsubscribe = db
      .collection("users")
      .doc(this.props.id)
      .onSnapshot(async userSnap => {
        const user = {...userSnap.data(), id: userSnap.id}

        let avatar = null
        if (user.avatar && user.avatar.fullPath) avatar = await storage.ref(user.avatar.fullPath).getDownloadURL()
        await this.setState({user, avatar: avatar ? avatar : this.avatar})
      })

    const userTypes = await db
      .collection("meta")
      .doc("roles")
      .get()

    this.setState({unsubscribe, userTypes: Object.keys(userTypes.data())})
  }

  componentWillUnmount = () => {
    if (this.state.unsubscribe) this.state.unsubscribe()
  }

  state = {
    user: {},
    unsubscribe: null,
    edit: false,
    show: false,
    userTypes: [],
    avatar: "https://picsum.photos/300/300/?random"
  }

  change = type => v => {
    this.setState({user: {...this.state.user, [type]: v}})
  }

  changeAddress = async address => {
    console.log('changeAddress', address)
    const {user} = this.state

    if (!address || !Array.isArray(address)) {
      const filteredAddress = {
        street_number: null,
        route: null,
        locality: null,
        administrative_area_level_1: null,
        postal_code: null
      }
      this.setState({user: {...user, ...filteredAddress, address, geocode_results: null}})
      return
    }

    //Create lookup for what "types" of address info we want to store onto a user's record
    const types = {
      street_number: true,
      route: true,
      locality: true,
      administrative_area_level_1: true,
      postal_code: true
    }

    //Filter down just to the address components we want from the lookup
    const addressComponents = address[0].address_components.filter(c => types[c.types[0]])

    const filteredAddress = {
      street_number: addressComponents.filter(c => c.types.includes("street_number"))[0].short_name,
      route: addressComponents.filter(c => c.types.includes("route"))[0].short_name,
      locality: addressComponents.filter(c => c.types.includes("locality"))[0].short_name,
      administrative_area_level_1: addressComponents.filter(c => c.types
        .includes("administrative_area_level_1"))[0].short_name,
      postal_code: addressComponents.filter(c => c.types.includes("postal_code"))[0].short_name
    }

    this.setState({user: {...user, ...filteredAddress, address: address[0].formatted_address, geocode_results: address[0]}})
  }

  toggleEdit = async () => {
    // Untoggle edit and save to the DB if data is valid.
    if (this.state.edit) {

      // console.log(this.state.user)

      // Simple email validation.
      if (this.state.user.email && emailRx.test(this.state.user.email)) {
        await db
          .collection("users")
          .doc(this.state.user.id)
          .set(this.state.user)
      } else {
        const systemLogger = new SystemLogger({
          uid: auth.currentUser.uid,
          path: `/users/${this.props.id}`
        })
        systemLogger.error(`"${this.state.user.email}" is not a valid email address`)
        return
      }
    }

    this.setState({edit: !this.state.edit})
  }

  deleteAvatar = async () => {
    const fixedUser = {...this.state.user}
    delete fixedUser.avatar
    db.collection("users")
      .doc(this.state.user.id)
      .set(fixedUser)
  }

  render = () => {
    const {change, changeAddress, addPhoto, deleteAvatar, toggleEdit} = this
    const {edit, user, userTypes, avatar, show} = this.state
    const permissionsProps = {edit, change}

    return (
      <div>
        <div className="header-container flex-row flex-align-center">
          <h1 className="page-header">{user.name}</h1>
          {["admin", "super-admin"].includes(auth.sunkaizenUser.type) && (
            <Button size="large" onClick={() => history.push("/users")} type="primary">
              Done
            </Button>
          )}
        </div>
        <div className="flex-row">
          <div className="user-information-pane">
            <div className="user-information-pane-title flex-row flex-align-center">
              <h3>User Information</h3>
              <div className="user-information-pane-edit-button flex-row flex-align-center" onClick={toggleEdit}>
                <Icon type={edit ? "save" : "edit"} />
                <div style={{padding: "0 0 0 1rem"}}>{edit ? "Save" : "Edit"}</div>
              </div>
            </div>
            <div className="flex-row flex-align-center">
              <Field edit={edit} user={user} style={{flex: 12}} type="name" change={change("name")} />
              <div style={{flex: 1}}></div>
              <Dropdown
                options={userTypes}
                edit={
                  auth &&
                  auth.sunkaizenUser &&
                  !['super-admin', 'client'].includes(user.type) &&
                  (auth.sunkaizenUser.type === "admin" || auth.sunkaizenUser.type === "super-admin") &&
                  edit
                }
                user={user}
                style={{flex: 12}}
                type="type"
                select={this.change("type")}
              />
            </div>
            <div className="flex-row flex-align-center">
              <Field edit={edit} user={user} style={{flex: 12}} type="email" change={this.change("email")} />
              <div style={{flex: 1}}></div>
              <Field edit={edit} user={user} style={{flex: 12}} type="phone" change={this.change("phone")} />
            </div>
            <div className="flex-row flex-align-center full-width">
              <div className="flex-column address-wrapper">
                <div className="address-title">{capitalizationMap["address"]}</div>
                <AutocompleteField
                  full
                  edit={edit}
                  style={{flex: 1, border: "none"}}
                  submit={changeAddress}
                  old_address={user.address}
                />
              </div>
            </div>
            {user.type === "installer" && (
              <Field edit={edit} user={user} style={{flex: 12}} type="nabcep_ids" change={change("nabcep_ids")} />
            )}

            <div style={{padding: "1rem"}}></div>
            <PermissionsBlock {...{permissionsProps, user}} />
          </div>
          <AvatarPane {...{avatar, change, deleteAvatar, edit, user}} />
        </div>
      </div>
    )
  }
}


{/*
              <div style={ { display: "flex", flexFlow: "row nowrap", alignItems: "center" } } >
                <Field edit={ edit } user={ user } style={ { flex: 12 } } type="city" change={ this.change("city") } />
                <div style={ { flex: 1 } } ></div>
                <Field edit={ edit } user={ user } style={ { flex: 5 } } type="state" change={ this.change("state") } />
                <div style={ { flex: 1 } } ></div>
                <Field edit={ edit } user={ user } style={ { flex: 6 } } type="zip" change={ this.change("zip") } />
              </div>
            */}