import React from "react"
import PropTypes from "prop-types"
import {db} from "../utilities"
import Filters from "./Filters"
import ProjectBar from "./ProjectBar"
import Auth from '../Auth'

const auth = new Auth()

class ProjectStatusBar extends React.Component {
  static propTypes = {
    auth: PropTypes.object,
    projects: PropTypes.array,
    filters: PropTypes.object,
    setFilters: PropTypes.func,
    clearFilters: PropTypes.func
  }

  componentDidMount = () => {
    const unsubscribe = db
      .collection("users")
      .where("type", "==", "client")
      .onSnapshot(clientSnap => {
        const cc = clientSnap.docs.map(client => ({
          ...client.data(),
          id: client.id
        }))
        const clients = {}
        cc.forEach(c => clients[c.id] = c)

        if (auth.sunkaizenUser.type === 'client') {
          this.setState({clients: [clients[auth.sunkaizenUser.id]]})
        }
        else if (auth.sunkaizenUser.clientsAll || ['super-admin', 'admin'].includes(auth.sunkaizenUser.type)) {
          this.setState({clients: cc})
        }
        else {
          this.setState({clients: Object.keys(auth.sunkaizenUser.clients || {}).map(cId => clients[cId])})
        }
      })

    this.setState({unsubscribe})
  }

  componentWillUnmount = () => {
    if (this.state.unsubscribe) this.state.unsubscribe()
  }

  state = {clients: []}

  render = () => {
    const intakeTypes = Array.from(new Set(this.props.projects.map(project => project.status))).filter(i => i)

    return (
      <div style={{display: "flex", flexFlow: "column", width: "100%"}}>
        <Filters
          auth={this.props.auth}
          filters={this.props.filters}
          clearFilters={this.props.clearFilters}
          setFilters={this.props.setFilters}
          type={
            this.props.auth.sunkaizenUser && this.props.auth.sunkaizenUser.type
              ? this.props.auth.sunkaizenUser.type
              : undefined
          }
          clientTypes={this.state.clients.sort((a, b) => a.name > b.name).map((client = {}) => {
            if (client.name) return client.name
            else console.log('something is wrong with clients: ', this.state.clients)
          }).filter(i => i)}
          intakeTypes={intakeTypes}
        />
        <ProjectBar
          projects={this.props.projects}
          type={
            this.props.auth.sunkaizenUser && this.props.auth.sunkaizenUser.type
              ? this.props.auth.sunkaizenUser.type
              : undefined
          }
        />
      </div>
    )
  }
}

export default ProjectStatusBar
