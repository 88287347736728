import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"

import Auth from "./Auth"
import AddressForm, {addressFields} from "./AddressForm"
import SystemLogger from "./SystemLogger"
import UserInformation from './UserBuilder/UserInformation'
import {firebase, db, capitalizeFirst} from "./utilities"
import {usersOne} from './UserBuilder/utilities'

const auth = new Auth()

const styles = {
  button: {
    padding: "0.3em",
    cursor: "pointer",
    width: "10rem",
    margin: "0.3em",
    backgroundColor: "cornflowerblue",
    border: "none",
    color: "white",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold"
  }
}

export default class Register extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    auth: PropTypes.object
  }

  state = {
    resource: {pending: true, type: 'client'},
    unsubscribeFirebase: null
  }

  componentDidMount = async () => {
    const {auth} = this.props

    const unsubscribeFirebase = auth.sunkaizenUserSubscribe(sunkaizenUser => {
      if (!sunkaizenUser) return 
      else {
        unsubscribeFirebase()
        this.props.history.push('/')
      }
    })

  }

  componentWillUnmount = () => {
    if (this.state.unsubscribeFirebase) this.state.unsubscribeFirebase()
  }

  change = field => async value => {
    await this.setState({resource: {...this.state.resource, [field]: value}})
  }

  submit = async () => {
    try {
      const fields = {
        ...this.state.resource,
        id: auth.currentUser.uid,
        pending: true
      }

      const logger = new SystemLogger({uid: null})
      await usersOne(fields, auth.currentUser.uid, logger)

      logger.log("Registration complete! Logging in...")
    } 
    catch (e) { console.log("error setting body to pending collection: ", e) }
  }

  render = () => (
    <div style={{width: "100%"}}>
      <div style={{padding: "3rem"}}>
        <h1>Register</h1>
        <h3>This login is not associated with an account on FIT-QM; please create one</h3>
        <div style={{display: "flex", justifyContent: "center", paddingTop: "2rem"}}>
          <UserInformation 
            noheader 
            notype 
            style={{width: '80%'}} 
            fields={this.state.resource} 
            setField={this.change} 
            autocomplete="new-password"
          />
        </div>
        <div style={styles.button} onClick={this.submit}>
          Submit
        </div>
      </div>
    </div>
  )
}