import React from "react"
import PropTypes from "prop-types"

import {typeFilters} from "./FilterProps"
import {db, capitalizeFirst} from "../utilities"
import {FULL_TEXT_SEARCH_URL} from "../config"

import Auth from "../Auth"
const auth = new Auth()

const localStyles = {
  filterField: selected => ({
    background: selected ? "white" : "lightgrey",
    borderTop: selected ? "0.3rem solid #234091" : "0.3rem solid lightgrey",
    padding: "0.5rem 2rem 0.75rem 2rem",
    margin: "0 0.3rem 0 0",
    fontSize: "115%",
    cursor: "pointer"
  })
}

export default class TabFilters extends React.Component {
  static propTypes = {
    selected: PropTypes.string,
    select: PropTypes.func,
    loading: PropTypes.bool
  }

  componentDidMount = async () => {
    const authUnsubscribe = auth.sunkaizenUserSubscribe(user => this.setState({user}))

    const body = {type: "users", sortField: "type.sortable"}
    const res = await (
      await fetch(`${FULL_TEXT_SEARCH_URL}/counts`, {
        method: "POST",
        mode: "cors",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body)
      })
    ).json()

    const counts = res.aggregations ? res.aggregations.aggCounts.buckets : []
    counts.push({key: "All", doc_count: counts.reduce((a, c) => a + c.doc_count, 0)})

    this.setState({counts, authUnsubscribe})

  }

  componentWillUnmount = () => {
    if (this.state.authUnsubscribe) this.state.authUnsubscribe()
  }

  state = {counts: [], user: undefined}

  render = () => (
    <div style={{display: "flex", position: 'relative', alignItems: 'center', justifyContent: 'center', margin: "0.1rem 0 0.2rem 0"}}>
      {this.props.loading && 
        <div style={{position: 'absolute', flex: 1, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.1)'}} ></div>
      }
      {auth.sunkaizenUser &&
        this.state.counts.map(({key, doc_count}) => (
          <div
            key={key}
            style={localStyles.filterField(key === this.props.selected)}
            onClick={() => {
              this.props.select(key)
              // this.props.setTableControls({
              //   ...this.props.tableControls, 
              //   pagination: {
              //     ...this.props.tableControls.pagination, 
              //     current: 1
              //   }})
            }}
          >
            {`${capitalizeFirst(key)} (${doc_count})`}
          </div>
        ))}
    </div>
  )  
}
