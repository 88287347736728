import moment from 'moment'
import durationToMoment from '../utilities/durationToMoment'

const minutesArray = []
for (let i = 0; i < 60; i += 5) minutesArray.push(i)

const allHours = []
for (let i = 0; i < 24; i++) {allHours.push(i)}

export default function appointmentFilter (availability, appointments, date) {
  for (let appointment of appointments) {
    let {end: e, date: s} = appointment
    const [start, end] = [moment(s.toDate()), moment(e.toDate())]

    if (!date.isSame(start, 'day')) continue

    start.minutes(start.minutes() - start.minutes() % 5)

    do {
      delete availability[start.format('k:m')]
      start.minutes(start.minutes() + 5)
    } while (start < end)
  }

  const hoursMap = {}
  const minutesMap = {}
  for (let i = 1; i < 25; i++) { minutesMap[i] = [] }

  for (let t in availability) { 
    const [hour, minute] = t.split(':')
    hoursMap[parseInt(hour)] = true 
    minutesMap[hour][parseInt(minute)] = true
  }

  const hours = allHours.filter(h => !hoursMap[h])
  const f = h => minutesArray.filter(m => {
    if (!minutesMap[h]) return false
    return !minutesMap[h][m]
  })

  return [hours, f]
}