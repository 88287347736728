import React from "react"
import PropTypes from "prop-types"

import Chart from "../../Chart"

class ReasonsForCancellation extends React.Component {
  static propTypes = {
    projects: PropTypes.array
  }

  render = () => {
    const values = [],
      labels = []
    const canceled = this.props.projects.filter(project => project.status === "Canceled")
    const reasons = [].concat(...canceled.map(project => project.cancellations.map(cancel => cancel.reason)))

    const t = {}
    reasons.map(reason => (t[reason] = t[reason] ? t[reason] + 1 : 1))
    Object.entries(t).forEach(([reason, count]) => {
      labels.push(reason)
      values.push(count)
    })

    const options = [{values, labels, type: "pie"}]
    const layout = {showlegend: false, height: 300, width: 300, margin: {l: 20, r: 20, t: 20, b: 20}}

    return (
      <div style={{background: "white", padding: "2rem", border: "0.1rem solid lightgrey"}}>
        <h3>Reasons for Cancellation</h3>
        <Chart options={options} layout={layout} />
      </div>
    )
  }
}

export default ReasonsForCancellation
