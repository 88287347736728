import {useEffect, useState} from 'react'
import SystemLogger from "./SystemLogger"
import {auth} from './Auth'
import DB, {dbResources} from './DAL/DB'
import DevChecker from "./DevChecker"


export function useLoggerEffect (path) {
  const [logger, setLogger] = useState(null)

  useEffect(function () {
    const unsubscribe = auth.sunkaizenUserSubscribe(user => {
      if (user) {
        const l = new SystemLogger({path, uid: user.id})
        setLogger(l)
      }
    })
    return unsubscribe
  }, [auth.sunkaizenUser.id])

  return logger
}

export const loggerEffect = (callback, path) => () => {
  // should call callback with a fake logger implementation so that there are no race conditions
  //  when google auth is slow
  auth.sunkaizenUserSubscribe(user => {
    if (user) {
      const logger = new SystemLogger({path, uid: user.id})
      callback(logger)
    }
  })    
}

export const checkLogin = (isLoggedIn, isNotLoggedIn) => () => {
  if (typeof isLoggedIn !== 'function' && typeof isNotLoggedIn !== 'function') {
    throw new Error('at least one arg must be a function')
  }

  return auth.sunkaizenUserSubscribe(user => {
    if (!user) {
      if (isNotLoggedIn) isNotLoggedIn()
      else return
    }
    else if (isLoggedIn) isLoggedIn(user)
  })
}

export const simpleGet = (collection, id, callback) => () => {
  const resource = new dbResources[collection]
  if (![id, collection, callback].every(i => i)) return
  (async function () { 
    const {ok, data} = await resource.get(id, null) 
    console.log(ok, data)
    if (ok) callback(data[0])
  })()
}

export const getMany = (collection, callback) => () => {
  if (![collection, callback].every(i => i)) return
  const resource = new dbResources[collection]
  (async function () { 
    const {ok, data} = await resource.get()
    if (ok) callback(data)
  })()
}


export function getLock (id) {
  const locks = new DB.ProjectLocks()
  return useEffect(function () {
    (async function () {
      await locks.update(id, null, {holder: auth.sunkaizenUser.id})
    })()

    return async () => { 
      await locks.delete(id)
    }
  }, [])
}

export function checkLock (id, setLock) {
  const locks = new DB.ProjectLocks()
  return useEffect(function () {
    (async function () {
      const {data: [lock], ok} = await locks.get(id)

      if (!ok) {
        setLock(false)
        return
      }
      if (!lock.holder) {
        setLock(false)
        return
      }
      if (lock.holder === auth.sunkaizenUser.id) {
        setLock(false)
        return
      }

      setLock(true)
    })()
  })
}

const devChecker = new DevChecker()
export function checkDev (setDev) {
  useEffect(function () {
    (async function () {
      const dev = await devChecker.check(auth.sunkaizenUser.id)
      setDev(dev)
    })()
  }, [])
}