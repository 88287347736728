import React from "react"
// import PropTypes from 'prop-types'

import {wrappedGeocode} from "../UserList/utilities"
import {db, firebase} from "../utilities"
import UserInformation from "./UserInformation"
import Permissions from "./Permissions"
import Auth from "../Auth"
import {validateField, validateResource, fieldNameConverter} from "../validators"
import {validators, usersOne} from './utilities'
import SystemLogger from "../SystemLogger"

const auth = new Auth()


class SingleUserBuilder extends React.Component {
  // static propTypes = {}

  componentDidMount = () => {
    this.logger = new SystemLogger({
      uid: auth.sunkaizenUser.uid,
      path: `/users/${auth.sunkaizenUser.id}`
    })

    auth.sunkaizenUserSubscribe(user => {
      this.logger = new SystemLogger({
        uid: auth.sunkaizenUser.id,
        path: `/users/${auth.sunkaizenUser.id}`
      })      
    })
  }

  state = {fields: {clients: {}, qualifications: {}, regions: {}}, user: {errors: {}}}

  setField = field => value => {
    const {fields, user} = this.state
    if (field !== "geocode_results") {
      const errors = validateField({[field]: value})(validators[field])
      // const [errors] = validateResource({[field]: value})({[field]: validators[field]})
      user.errors = {...user.errors, ...errors}
    }

    this.setState({fields: {...fields, [field]: value}, user})
  }

  finalize = async finalize => {
    if (!auth.currentUser.uid) {
      this.logger.error(`You are not logged in; only a logged in admin user can create other users`)
      console.log("not logged in")
      return
    }
    if (finalize) {
      await usersOne(this.state.fields, auth.currentUser.uid, this.logger)
    }
    this.setState({fields: {}})
  }

  render() {
    return (
      <div style={{padding: "2rem", background: "white"}}>
        {Object.values(this.state.user.errors).filter(error => error.length > 0).length > 0 && (
          <div
            style={{
              zIndex: 10,
              background: "red",
              position: "sticky",
              top: "1rem",
              display: "flex",
              flexFlow: "column"
            }}
          >
            {Object.entries(this.state.user.errors)
              .filter(([field, errors]) => errors.length > 0)
              .map(([field, errors]) => (
                <div
                  style={{
                    color: "white",
                    padding: "1rem",
                    display: "flex",
                    flexFlow: "row nowrap",
                    alignItems: "flex-start"
                  }}
                >
                  <div style={{padding: "0 1rem 0 0"}}>{fieldNameConverter[field]}</div>
                  <div>
                    {errors.map(error => (
                      <div key={`${field}_${error}`}>{error}</div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        )}
        <UserInformation fields={this.state.fields} setField={this.setField} />
        <Permissions fields={this.state.fields} setField={this.setField} />
      </div>
    )
  }
}

export default SingleUserBuilder
