import React from "react"
import PropTypes from "prop-types"

// import { db, capitalizeFirst, singular } from '../utilities'
import SingleUserBuilder from "../UserBuilder/SingleUserBuilder"
import BulkUserBuilder from "../UserBuilder/BulkUserBuilder"
import {withRouter} from "react-router"
import {Modal, Button} from "antd" // Input, Icon, , DatePicker
// import Auth from '../Auth'
// const auth = new Auth()

class ResourceCreate extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    style: PropTypes.object
  }

  state = {
    show: false,
    activeFilters: {},
    num: "one",
    component: null
  }

  componentDidMount = () => {
    this.child = React.createRef()
  }

  submit = async (finalize = true) => {
    try {
      await this.child.current.finalize(finalize)
      this.setState({show: false})
      this.props.get()
      return
    } catch (e) {
      console.log(`an error occurred`, e)
    }
  }

  show = show => this.setState({show})

  create = (num = "one") => {
    this.setState({
      show: true,
      num,
      component: num === "one" ? SingleUserBuilder : BulkUserBuilder
    })
  }

  render = () => {
    const {component: M} = this.state

    return (
      <div style={this.props.style}>
        <div style={{display: "flex", flexFlow: "row nowrap"}}>
          <Button
            style={{flex: 1, height: "40px", background: "purple", color: "white", margin: "0 0.3rem 0 0.3rem"}}
            onClick={() => this.create("one")}
          >{`Add New User`}</Button>
          <Button
            style={{flex: 1, height: "40px", background: "#6DBE44", color: "white", margin: "0 0.3rem 0 0.3rem"}}
            onClick={() => this.create("many")}
          >{`Add Bulk Users`}</Button>
        </div>
        <Modal
          title={<h2>{`Create New Users`}</h2>}
          visible={this.state.show}
          onOk={() => this.submit()}
          onCancel={() => this.submit(false)}
          width="80%"
          maskStyle={{background: "rgba(0, 0, 0, 0.3)"}}
        >
          {M && <M ref={this.child} />}
        </Modal>
      </div>
    )
  }
}

const WrappedResourceCreate = withRouter(ResourceCreate)
export default WrappedResourceCreate
