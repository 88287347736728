import React from "react"
// import PropTypes from 'prop-types'

import {Link} from "react-router-dom"
import {Table, Tooltip} from "antd"

import {history} from "../../utilities"

const ProjectsNearingDeadline = ({projects = []}) => {
  const columns = [
    {
      title: `Site Owner`,
      dataIndex: `site.siteOwnerName`,
      key: `siteOwnerName`
    },
    {
      title: `Status`,
      dataIndex: `status`,
      key: `status`
    },
    {
      title: `Created`,
      dataIndex: `created`,
      key: `created`,
      render: t => {
        const u = t ? t.toDate() : null
        return <div>{u ? u.toDateString() : null}</div>
      }
    }
  ]

  projects.sort((a, b) => {
    if (a.created === b.created) return 0
    if (a.created > b.created) return 1
    else return -1
  })

  const fProjects = projects.filter(project => project.status !== `Completed` && project.status !== `Canceled`)

  const onRow = (record, rowIndex) => ({
    style: {cursor: `pointer`},
    onClick: event => {
      history.push(`/projects/${record.id}/manage`)
    }
  })

  return (
    <div
      style={{
        border: `0.1rem solid lightgrey`,
        padding: `1rem`,
        background: `white`,
        flex: 1,
        display: `flex`,
        flexFlow: `column`,
        alignItems: `center`,
        justifyContent: `center`,
        margin: `0.5rem 1rem 0 0`
      }}
    >
      <Tooltip title="The project deadline is 6 months after it is created" >
        <h3 style={{padding: `1rem 0 0 0`}}>Projects Nearing Deadline</h3>
      </Tooltip>
      <Table
        style={{width: `100%`, flex: 1}}
        columns={columns}
        dataSource={fProjects.slice(0, 4)}
        onRow={onRow}
        rowKey={x => x.id}
      />
    </div>
  )
}

export default ProjectsNearingDeadline
