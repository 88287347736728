import _ from "lodash"

export const sectionsFromProject = project => project.intake.sections

export function intakeFailuresById(project) {
  const sections = sectionsFromProject(project)
  return groupIntakeFailuresById(intakeFailuresFromSections(sections))
}

/**
 * Sections contain fields within them that are the results for every intake
 * form question. The data comes from our database grouped by section, but for
 * UI we want the data to be stored flat and by id. So we loop through each
 * section, grab the data we want out of each result (from the `field`), and
 * flatten all of those results together.
 */
function intakeFailuresFromSections(sections) {
  return _.flatMap(sections, section => {
    return _.map(section.fields, field => {
      const {id, label, question, value} = field

      return {sectionId: section.id, sectionTitle: section.title, id, label, question, answer: value}
    })
  })
}

function groupIntakeFailuresById(intakeFailures) {
  return _.reduce(intakeFailures, (acc, failure) => ({...acc, [failure.id]: failure}), {})
}
