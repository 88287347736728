import {db, firebase} from '../utilities'
import BaseModel from './BaseModel'
import Projects from './Projects'
import Forms from './Forms'
import FormSection from './FormSection'
import FormField from './FormField'
import Users from './Users'
import Calendars from './Calendars'
import Reports from './Reports'
import ProjectSnapshots from './ProjectSnapshots'
import Rubrics from './Rubrics'
import ScoringSystems from './ScoringSystems'
import Sites from './Sites'
import Subscriptions from './Subscriptions'
import ProjectLogs from './ProjectLogs'
import Logs from './Logs'
import Tickets from './Tickets'
import TicketComments from './TicketComments'
import Meta from './Meta'
import EmailLinks from './EmailLinks'
import Authorizations from './Authorizations'
import Organizations from './Organizations'
import Analytics from './Analytics'
import Aggregations from './Aggregations'
import OauthClient from './OauthClient'
import CSVFilters from './CSVFilters'
import TempProjects from './TempProjects'
import ProjectLocks from './ProjectLocks'

class Batch {
    operations: Promise<any>[]

    constructor () {
        this.operations = []
    }

    add (operation: Promise<any>) {
        this.operations.push(operation)
    }

    async commit () {
        try {
            const results = await Promise.all(this.operations)
            return results
        }
        catch (e) {
            console.log('batch commit failed: ', e)
            throw e
        }
    }
}

class FirebaseBatch {
    batch: firebase.firestore.WriteBatch
    constructor () { this.batch  = db.batch() }

    update (baseQuery: BaseModel, id: string, data: firebase.firestore.DocumentData, merge: true) {
        const ref = baseQuery.baseQuery.doc(id)
        this.batch.set(ref, data, {merge})
    }

    delete (baseQuery: BaseModel, id: string) {
        const ref = baseQuery.baseQuery.doc(id)
        this.batch.delete(ref)
    }

    async commit () {
        this.batch.commit()
    }
}

export default {
    Batch,
    FirebaseBatch,
    Analytics,
    Aggregations,
    Meta,
    Projects,
    Forms,
    FormSection,
    FormField,
    Users,
    Calendars,
    Reports,
    ProjectSnapshots,
    Rubrics,
    ScoringSystems,
    Sites,
    Subscriptions,
    ProjectLogs,
    Logs,
    Tickets,
    TicketComments,
    EmailLinks,
    Authorizations,
    Organizations,
    OauthClient,
    CSVFilters,
    TempProjects,
    ProjectLocks
}

export const dbResources = {
    batch: Batch,
    analytics: Analytics,
    aggregations: Aggregations,
    projects: Projects,
    meta: Meta,
    forms: Forms,
    formSection: FormSection,
    formField: FormField,
    users: Users,
    calendars: Calendars,
    reports: Reports,
    snapshots: ProjectSnapshots,
    rubrics: Rubrics,
    scoring_systems: ScoringSystems,
    sites: Sites,
    subscriptions: Subscriptions,
    projectTransactionLog: ProjectLogs,
    transactionLog: Logs,
    tickets: Tickets,
    ticketComments: TicketComments,
    emailLinks: EmailLinks,
    authorizations: Authorizations,
    organizations: Organizations,
    oauth2_client: OauthClient,
    csvFilters: CSVFilters,
    tempProjects: TempProjects,
    project_locks: ProjectLocks
}
