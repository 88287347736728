import {dbResources} from '../../DAL/DB'
import {auth} from '../../Auth'
import SystemLogger from '../../SystemLogger'

/*
    DBQuery:
        [
            {
                paths: [PathFragment: {collection: string, doc: string?}], 
                options: {
                    where: [WhereClause: {field: string, test: string, value: string}]?,
                    order: string?
                }
            }, 
            name: string
        ]
*/ 

export function convertPaths (paths) {
  let R
  let collection
  let doc
  let resource
  let section
  let field

  switch (paths.length) {
  case 1:
    collection = paths[0].collection
    doc = paths[0].doc
    R = dbResources[collection]
    resource = new R()
    return {resource, doc}
  case 2:
    collection = paths[0].collection
    doc = paths[0].doc

    if (collection === `forms`) {
      section = paths[1].doc
      R = dbResources[`formSection`]
      resource = new R(doc)
      return {resource, doc: section}
    }
    else if (collection === `tickets`) {
      R = dbResources[`ticketComments`]
      const comment = paths[1].doc
      resource = new R(doc)
      return {resource, doc: comment}
    }
  case 3:
    collection = paths[0].collection
    doc = paths[0].doc
    section = paths[1].doc
    field = paths[2].doc
    R = dbResources[`formField`]
    resource = new R(doc, section)
    return {resource, doc: field}
  }
}



export class SystemLog {
  constructor () {
    this.queue = []
    this.logger = null
    auth.sunkaizenUserSubscribe(user => { 
      if (user) {
        this.logger = new SystemLogger({uid: user.id}) 
        this.flushQueue()
      }
    })
  }

  flushQueue () {
    this.queue.forEach(message => this.logger.log(message))
    this.queue = []
  }

  notify (message) {
    if (this.logger) this.logger.log(message)
    else this.queue.push(message)
  }
}