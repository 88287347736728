import React, {useState} from "react"
// import PropTypes from 'prop-types'

import {db, history} from "./utilities" //  capitalizeFirst, singular,

import {Table} from "antd"

// name, email, phone, formatted_address
const columns = [
  {title: `ID`, dataIndex: `id`, key: `id`},
  {title: `Name`, dataIndex: `name`, key: `name`},
  {title: `Email`, dataIndex: `email`, key: `email`},
  {title: `Phone`, dataIndex: `phone`, key: `phone`},
  {title: `Address`, dataIndex: `geocode_results.formatted_address`, key: `geocode_results.formatted_address`}
]

const FieldUserDirectory = () => {
  const [fus, setFus] = useState([])
  db.collection(`users`)
    .where(`type`, `==`, `field-user`)
    .onSnapshot(userSnap => {
      if (!userSnap.empty) {
        const users = userSnap.docs.map(user => ({...user.data(), id: user.id}))
        setFus(users)
      }
    })

  return (
    <Table
      {...{
        columns,
        dataSource: fus,
        onRow: (record, rowIndex) => ({onClick: e => history.push(`/users/${record.id}/manage`)}),
        style: {padding: `1rem`, backgroundColor: `white`, margin: `3rem`}
      }}
    />
  )
}

export default FieldUserDirectory
