import React, {useContext, useState, useEffect} from "react"
import {ReducerContext, wrap} from '../reducer'
import CalendarAvailabilityModal from '../CalendarAvailabilityModal'
import CalendarEvent from "../CalendarEvent"
import CalendarFilter from "../CalendarFilter"
// https://fullcalendar.io/docs/react
import FullCalendar from "@fullcalendar/react"
import "@fullcalendar/core/main.css"
import "@fullcalendar/daygrid/main.css"
import "@fullcalendar/timegrid/main.css"
import "../Calendar.css"
import {filterEvents} from '../utilities'
import {auth} from "../../Auth"
import {subscribeAvailability, subscribeEvents} from './effects'
import fullCalendarProps from "./fullCalendarProps"

export function Calendar () {
  const {state, dispatch} = useContext(ReducerContext)

  const eventsMap = {...state.events, ...state.availability}
  const allEvents = Object.values(eventsMap)

  const [showIntakes, setShowIntakes] = useState(false)
  const [show, setShow] = useState(false)
  const [displayedEvents, setDisplayedEvents] = useState([])

  useEffect(subscribeAvailability(dispatch), [])
  useEffect(subscribeEvents(dispatch), [])
  useEffect(function () {
    setDisplayedEvents(updateDisplayedEvents())
  }, [state.filterText, state.filterType, showIntakes, allEvents.length])
  useEffect(function () {
    if (state.filterType === 'Client' && state.filterText) setShowIntakes(true) 
  }, [state.filterType === 'Client' && state.filterText])

  function eventClick (e) {
    e.jsEvent.preventDefault()
    dispatch({type: 'selected', value: e.event.id})
    setShow(true)
  }

  function updateDisplayedEvents () {
    return filterEvents({
      showIntakes,
      events: allEvents,
      filter: state.filterType,
      searchTerm: state.filterText
    })
  }

  return (
    <div className="calendar calendar-fu">
      <CalendarAvailabilityModal />
      <CalendarFilter />
      <div className="demo-app-calendar padding-2" id="mycalendartest" >
        <CalendarEvent event={eventsMap[state.selected]} visible={show} toggle={() => setShow(!show)} />
        <div id="calendar-modal"></div>
        <FullCalendar 
          {...fullCalendarProps({
            showIntakes, setShowIntakes, 
            userType: auth.sunkaizenUser.type, displayedEvents, dispatch, eventClick
          })}
        />
      </div>
    </div>
  )
}

const WrappedCalendar = wrap(Calendar)
export default WrappedCalendar