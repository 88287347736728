import JSZip from "jszip"
import {saveAs} from "file-saver"
import DB from '../../../DAL/DB'
import {auth} from '../../../Auth'

const reports = new DB.Reports()

async function changeStatus (id, status) {
  await reports.update(id, null, {[`permissions.${auth.sunkaizenUser.id}`]: status})
}


export default async function createZip (items) {
  const zip = new JSZip()
  
  const pp = []
  for (let i in items) {
    const item = items[i]

    const promise = changeStatus(item.id, 'Read')
      .then(() => fetch(item.url))
      .then(r => r.blob())
      .then(b => [b, item.name])      

    pp.push(promise)
  }

  try {
    const docs = await Promise.all(pp)

    for (let [file, name] of docs) { zip.file(name, file, {binary: true}) }

    try {
      const zipfile = await zip.generateAsync({type: "blob"})
      saveAs(zipfile, `Reports ${new Date().toString()}`)
    }
    catch (e) {
      console.log(`systemNotifier: could not create reports zip file, please try again later`)
      console.log('error: ', e)
      throw e
    }
  }
  catch (e) { 
    console.log(`systemNotifier: failed to download all reports requestedj:  ${e.message}`) 
    throw e
  }
}