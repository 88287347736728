import {db} from "../utilities"

export default class CalendarEntry { 
  constructor ({date, endDate, duration, type, data}) {
    this.dataIsValid = this.dataIsValid.bind(this)
    this.build = this.build.bind(this)
    this.commit = this.commit.bind(this)
      
    if (data && this.dataIsValid(data)) {
      Object.assign(this, data)
      Object.assign(this, {start: this.start.toDate(), end: this.end.toDate()})
      return
    }

    if (type && !this.validType(type)) {
      throw new Error(`provided an invalid type: '${type}'; if a type is provided, it must be one of ${this.validTypes}`) 
    }

    Object.assign(this, {type, date, endDate, duration, start: date.toDate(), end: endDate.toDate()})
  }
  
  validType (type) { return ['Availability', 'Intake'].includes(type) }
  
  dataIsValid ({date, endDate, data, type}) {  
    if ([date, endDate, data].every(i => i) && this.validType(type)) return true  
    return false
  }
  
  build ({user}) {
    if (!this.type) throw new Error('to use this method, you must provide a type at instantiation')
    this.validateAvailabilityArgs(user)
    this.data = user
      
    this.title = this.type + " - " + this.data.name
  }
    
  validateAvailabilityArgs ({id, name, email}) { if (id, name, email) return true }
  
  async commit () {
    this.batch = db.batch()
    if (!this.validType(this.type)) {
      throw new Error(`object has an invalid type: ${this.type}; valid types are: ${this.validTypes}`)
    }
      
    const calRef = db.collection('calendars').doc()
    this.id = calRef.id
        
    const {date, endDate, type, data, start, end, title, id} = this
    this.batch.set(calRef, {date, endDate, type, data, start, end, title, id})   
      
    return await this.batch.commit()
  }
}