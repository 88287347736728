import React from "react"
import PropTypes from "prop-types"
import {Draggable} from "react-beautiful-dnd" //	DragDropContext, Droppable,
import {connect} from "react-redux"
import {Button} from "antd" //	Form, Popconfirm, Card, Divider, Input, Icon, Row, Col, Select
import {addResource, setResources, deleteResource, setActive, statusUpdate, setField, addSection} from "./action_creators"
import {db} from "../utilities" //	, firebase
import SectionItem from "./SectionItem"

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: "0.2rem",
  margin: "0 0 0.2rem 0",

  // styles needed for draggables
  ...draggableStyle
})

const mapStateToProps = state => ({
  all: state.form,
  form: state.form.base,
  order: ((state.form.base || {}).order || 0),
  sections: state.form.sections,
  section: state.active.section,
  status: state.status.section
})

const mapDispatchToProps = dispatch => ({
  setResources: values => dispatch(setResources({type: "sections", values})),
  del: form => async id => { dispatch(deleteResource({id, category: "sections"})) },
  active: id => { dispatch(setActive({type: "section", id})) },
  statusUpdate: bool => dispatch(statusUpdate({category: "section", bool}))
  // setField: ({ id, value }) => dispatch(setField({ category: "sections", id, value }))
})

class Sections extends React.Component {
  static propTypes = {
    form: PropTypes.object,
    sections: PropTypes.object,
    section: PropTypes.string,
    status: PropTypes.bool,
    add: PropTypes.func,
    setResources: PropTypes.func,
    del: PropTypes.func,
    active: PropTypes.func,
    statusUpdate: PropTypes.func,
    provided: PropTypes.object,
    snapshot: PropTypes.object
  }

  state = {editing: false, subscriptions: []}

  toggle = async editing => this.setState({editing})

  render() {
    const {form, order} = this.props //	sections, section, status,

    // const { editing } = this.state
    // const { toggle, save } = this

    return (
      <div ref={this.props.provided.innerRef}>
        {order.length > 0 ? (
          <div>
            {order.map((id, index) => (
              <Draggable key={id} draggableId={id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <SectionItem del={this.props.del(this.props.form)} id={id} />
                  </div>
                )}
              </Draggable>
            ))}
            {this.props.provided.placeholder}
          </div>
        ) : (
          <div>Click the Add Section button to begin</div>
        )}
      </div>
    )
  }
}

const WrappedSections = connect(mapStateToProps, mapDispatchToProps)(Sections)
export default WrappedSections

const buttonProps = state => ({
  form: state.form.base
})
const buttonDispatch = (dispatch, ownProps) => ({
  active: id => dispatch(setActive({type: "section", id})),
  addResource: async form => { dispatch(addSection()) }
})

const buttonMergeProps = (pState, pDispatch) => ({
  add: async () => await pDispatch.addResource(pState.form)
})

const NakedAdd = ({add}) => <Button onClick={add}>Add Section</Button>

NakedAdd.propTypes = {add: PropTypes.func}

const Add = connect(buttonProps, buttonDispatch, buttonMergeProps)(NakedAdd)

export {Add}
