// https://www.figma.com/proto/GpIdNcZTC8F4mGBNfC6f1D/Home?node-id=3%3A7&redirected=1&scaling=min-zoom
import React from "react"
import PropTypes from "prop-types"
import {Button, Dropdown, Icon, Menu} from "antd"
import projectDownloader from './projectDownloader'
import DevChecker from "../DevChecker"
import ResetElastic from './ResetElastic'
import ProjectStatusBar from "./ProjectStatusBar"
import VariableFields from "./VariableFields"
import Notifications from "./Notifications"
import {auth} from "../Auth"

const devChecker = new DevChecker()

const menu = chooseOption => (
  <Menu onClick={chooseOption}>
    <Menu.Item key="client">
      <Icon type="user" />
      Client
    </Menu.Item>
    <Menu.Item key="admin">
      <Icon type="user" />
      Admin
    </Menu.Item>
    <Menu.Item key="field-user">
      <Icon type="user" />
      Field User
    </Menu.Item>
    <Menu.Item key="quality-manager">
      <Icon type="user" />
      Quality Manager
    </Menu.Item>
    <Menu.Item key="scheduler">
      <Icon type="user" />
      Scheduler
    </Menu.Item>
    <Menu.Item key="installer">
      <Icon type="user" />
      Installer
    </Menu.Item>
  </Menu>
)

const TypeChooser = ({menu}) => (
  <Dropdown overlay={menu}>
    <Button>
      Select a User Type to Spoof
      <Icon type="down" />
    </Button>
  </Dropdown>
)

export default class Dashboard extends React.Component {
  static propTypes = {auth: PropTypes.object}

  componentDidMount = async () => {
    const unsubscribe = auth.sunkaizenUserSubscribe(async user => {
      if (user) {
        this.get()
        let dev = false
        if (['admin', 'super-admin'].includes(user.type)) { dev = await devChecker.check(user.id) }
        
        this.setState({dev, type: user.type})
      }
    })

    this.setState({unsubscribe})
  }

  componentDidUpdate (pprops, pstate) {
    if (this.state.barFilters !== pstate.barFilters) {
      this.get()
    }
  }

  componentWillUnmount = () => { if (this.state.unsubscribe) this.state.unsubscribe() }

  state = {loading: true, projects: {}, filters: [], barFilters: {}}

  get = async (f = null) => {
    this.setState({loading: true})
    const projects = await projectDownloader(f ? f : this.state)

    const projectMap = {}
    for (let project of projects) { projectMap[project.id] = project }
    this.setState({loading: false, projects: projectMap})
  }

  chooseOption = e => this.setState({type: e.key})

  clearFilters = () => {
    this.setState({barFilters: {}})
  }

  setFilters = async (name, value) => {
    const barFilters = {...this.state.barFilters}
    barFilters[name] = value

    this.setState({barFilters})
  }

  render = () => (
    <div
      style={{
        alignItems: "flex-start",
        display: "flex",
        flexFlow: "column",
        padding: "4rem",
        background: "rgb(244, 246, 248)"
      }}
    >
      {/* user type spoofing on dashboards */}
      {this.state.dev && (
        <div style={{padding: "3rem 0 3rem 0"}}>
          <TypeChooser menu={menu(this.chooseOption)} />
          <ResetElastic />
        </div>
      )}
      <h1 className="page-header">Projects Overview</h1>
      <Notifications auth={this.props.auth} />
      <ProjectStatusBar
        auth={this.props.auth}
        filters={this.state.barFilters}
        projects={Object.values(this.state.projects)}
        clearFilters={this.clearFilters}
        setFilters={this.setFilters}
      />
      {this.state.type && (
        <VariableFields auth={auth} type={this.state.type} projects={Object.values(this.state.projects)} />
      )}
    </div>
  )
}
