import React from "react"
import {Select} from "antd"

import {connect} from "react-redux"

const dropdownStateToProps = (state, ownProps) => ({option: state.form.fields[ownProps.id].options[ownProps.i] || {}})
const NakedDropdownItem = ({id, i, option}) => (
  <Select.Option value={option.label.toLowerCase()}>{option.label}</Select.Option>
)
const DropdownItem = connect(dropdownStateToProps)(NakedDropdownItem)

const mapStateToProps = (state, ownProps) => ({options: state.form.fields[ownProps.id].options || []})
const NakedMultiselect = ({options, id}) => (
  <Select style={{width: `20rem`}} defaultValue={options[0] ? options[0].label.toLowerCase() : `default`}>
    <Select.Option value="default">Multiselect</Select.Option>
    {options.sort().map((option, i) => (
      <DropdownItem key={i} id={id} i={i} />
    ))}
  </Select>
)
const Multiselect = connect(mapStateToProps)(NakedMultiselect)

export default Multiselect
