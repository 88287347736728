import React from "react"
import PropTypes from "prop-types"

import {Spin} from "antd"

// import DetailedView from './DetailedView'
import {DetailedUserView} from "./DetailedUserView"
// import DetailedProjectView from "./DetailedProjectView/DetailedProjectView"

import {db} from "./utilities"

const componentMap = {
  // projects: DetailedProjectView,
  users: DetailedUserView
}

export default class DetailedStateHolder extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    auth: PropTypes.object
  }

  state = {id: "", resource: null, loading: true, collection: ""}

  async componentDidMount() {
    // I'm going to handle the permission check here with database rules
    // 	if the user does not have permission to access this project, the database call
    // 	will throw an error
    this.getResource()
  }
  componentWillUnmount() {
    if (this.state.unsubscribe) this.state.unsubscribe()
    this.setState({resource: null})
  }

  getResource = () => {
    // let { type } = this.props
    let {collection, id} = this.props.match.params

    this.setState({loading: true})

    const unsubscribe = db
      .collection(collection)
      .doc(id)
      .onSnapshot(r => {
        let resource = {...r.data(), id: r.id}
        this.setState({id, resource, collection, loading: false})
      })

    this.setState({unsubscribe})
  }

  del = async e => {
    let {id, collection} = this.state

    try {
      await db
        .collection(collection)
        .doc(id)
        .delete()
      console.log(`successfully deleted ${id}`)

      this.setState({resource: {}})
    } catch (e) {
      console.log(`failed to delete ${id}`)
    }
  }

  render() {
    let {getResource, del, props} = this
    let {collection} = props.match.params

    const Component = componentMap[collection]

    return this.state.resource ? (
      <Component {...{...this.props, ...this.state, getResource, del}} />
    ) : (
      <div style={{padding: "3rem"}}>
        <Spin size="large" />
      </div>
    )
  }
}