import React, {useState, useEffect, useContext} from 'react'
import {elastic} from '../HOC/Elastic/ElasticHOC'
import {ReducerContext} from './reducer'
import {SearchOutlined} from '@ant-design/icons'
import SchedulingMap from './SchedulingMap'
import Projects from './Projects'
import {Button, Input} from 'antd'
import {ENVIRONMENT} from '../config'
import DB from '../DAL/DB'

const meta = new DB.Meta()

export function RightPanel ({single, queryTypes, search, resultsElastic}) {
  const {state, dispatch} = useContext(ReducerContext)

  const [mapToggle, setMapToggle] = useState(true)
  const [term, setTerm] = useState('')

  const q = new queryTypes.project({
    query: term, 
    sort: [{[`created._seconds`]: 'desc'}],
    filters: [{terms: {[`status.sortable`]: ['To Be Scheduled', 'Scheduled']}}],
    size: ENVIRONMENT === 'development' ? 20 : null
  })    

  useEffect(function () {
    if (single) return

    search(q)
  }, [term, state.updateProjects])

  useEffect(function () {
    if (single) return

    const projectsMap = {}
    for (let p of resultsElastic || []) { if (p) projectsMap[p.id] = p }

    dispatch({type: 'projects', value: projectsMap})
  }, [resultsElastic])

  // refresh whenever the database reports an elastic update
  useEffect(function () {
    return meta.subscribe('elasticUpdates', null, function ({ok, data: [elasticUpdates]}) {
      if (ok) { search(q) }
    })
  }, [])

  return (
    <div>
      <div className="flex-row padding-1" >
        <Button onClick={() => setMapToggle(!mapToggle)} > {mapToggle ? 'Project List' : 'Map'}</Button>
        <div className="padding-1" ></div>
        {!single && 
                    <Input 
                      onPressEnter={e => setTerm(e.target.value)} 
                      prefix={<SearchOutlined />}
                      placeholder="Search"
                    />
        }
      </div>
      {/* the map has to stay rendered or it resets the center point to whatever the default is */}
      <div style={mapToggle ? {visibility: 'visible'} : {visibility: 'hidden', height: 0}} >
        <SchedulingMap />
      </div>
      {!mapToggle && <Projects single={single} />}
    </div>
  )
}

const WrappedRightPanel = elastic(RightPanel)
export default WrappedRightPanel