import ProjectsNearingDeadline from "./ProjectsNearingDeadline"
import ReasonsForCancellation from "./ReasonsForCancellation"
import FailuresOfPriorityMeasures from "./FailuresOfPriorityMeasures"
import InspectionQualityByRegion from "./InspectionQualityByRegion"
import ProjectAgeAtTimeOfCancellation from "./ProjectAgeAtTimeOfCancellation"

const modules = {
  ProjectsNearingDeadline,
  ReasonsForCancellation,
  FailuresOfPriorityMeasures,
  InspectionQualityByRegion,
  ProjectAgeAtTimeOfCancellation
}

export default modules
