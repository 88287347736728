import React from "react"
import {Form, Input, Checkbox} from "antd"

import {connect} from "react-redux"
import {setField} from "../action_creators"

const mapStateToProps = (state, ownProps) => ({
  field: state.form.fields[ownProps.id] || {}
})

const mapDispatchToProps = dispatch => ({
  setField: ({id, value}) => dispatch(setField({category: "fields", id, value}))
})

const SingleInputPropertyBoxBase = ({id, setField, field}) => {
  return (
    <div>
      <Form.Item label="Label" style={{margin: 0}}>
        <Input
          style={{backgroundColor: "rgba(248, 248, 248, 1)", marginBottom: "2rem"}}
          size="large"
          onChange={e =>
            setField({
              id: id,
              value: {...field, label: e.target.value}
            })
          }
          value={field.label}
        />
      </Form.Item>
      <Form.Item label="Question" style={{margin: 0}}>
        <Input
          style={{backgroundColor: "rgba(248, 248, 248, 1)", marginBottom: "2rem"}}
          size="large"
          onChange={e =>
            setField({
              id: id,
              value: {...field, question: e.target.value}
            })
          }
          value={field.question}
        />
      </Form.Item>
      <Form.Item label="Tip text" style={{margin: 0}}>
        <Input
          style={{backgroundColor: "rgba(248, 248, 248, 1)", marginBottom: "2rem"}}
          size="large"
          onChange={e =>
            setField({
              id: id,
              value: {...field, tipText: e.target.value}
            })
          }
          value={field.tipText}
        />
      </Form.Item>
      <Form.Item style={{margin: 0}}>
        <Checkbox
          onChange={e =>
            setField({id: id, value: {...field, required: e.target.checked}})
          }
          value={field.required}
          checked={field.required}
        >
          Required
        </Checkbox>
      </Form.Item>
      <Form.Item style={{margin: 0}}>
        <Checkbox
          onChange={e =>
            setField({
              id: id,
              value: {...field, photoRequired: e.target.checked}
            })
          }
          value={field.photoRequired}
          checked={field.photoRequired}
        >
          Require Uploading Photos
        </Checkbox>
      </Form.Item>
      <Form.Item style={{margin: 0}}>
        <Checkbox
          onChange={e =>
            setField({
              id: id,
              value: {...field, requiredForFieldIntake: e.target.checked}
            })
          }
          value={field.requiredForFieldIntake}
          checked={field.requiredForFieldIntake}
        >
          Required for Field Intake
        </Checkbox>
      </Form.Item>
    </div>
  )
}

const WrappedSingleInputPropertyBoxBase = connect(mapStateToProps, mapDispatchToProps)(SingleInputPropertyBoxBase)
export default WrappedSingleInputPropertyBoxBase
