// This is a basic form to collect user information

import React from "react"
import PropTypes from "prop-types"

import {Icon, Select, Input} from "antd" //	Form,
import {db, capitalizeFirst, Required} from "../utilities"
import "./UserInformation.css"
import AutocompleteField from "../AutocompleteField"


const UglyField = ({className, style, children}) => (
  <div className={`ugly-field${className ? `` + className : ``}`} style={style} >
    {children.props.field && (
      <div style={{color: `grey`, fontSize: `65%`, textAlign: `left`, padding: `0 0 0 0.2rem`}}>
        {children.props.placeholder}
      </div>
    )}
    {children}
  </div>
)

const TextField = ({type = `text`, set, field, placeholder}) => (
  <Input
    type={type}
    value={field}
    onChange={e => set(e.target.value)}
    style={{border: `none`}}
    size="large"
    placeholder={placeholder}
    autocomplete="new-password"
  />
)

const SelectField = ({set, field, placeholder, options}) => (
  <Select
    onChange={e => set(e)}
    value={field}
    size="large"
    placeholder={placeholder}
    style={{border: `none`, width: `100%`}}
  >
    {options.sort().map((t, i) => (
      <Select.Option key={i} style={{border: `none`}} value={t}>
        {capitalizeFirst(t)}
      </Select.Option>
    ))}
  </Select>
)

export default class UserInformation extends React.Component {
  static propTypes = {
    fields: PropTypes.object,
    setFields: PropTypes.func,
    style: PropTypes.object,
    noheader: PropTypes.bool,
    notype: PropTypes.bool
  }

  //Create lookup for what "types" of address info we want to store onto a user's record
  static types = {
    street_number: true,
    route: true,
    locality: true,
    administrative_area_level_1: true,
    postal_code: true
  }

  state = {types: [], unsubscribe: null}

  componentDidMount = () => {
    const unsubscribe = db
      .collection(`meta`)
      .doc(`roles`)
      .onSnapshot(docSnap => {
        if (docSnap.exists) {
          const types = Object.keys(docSnap.data())
          this.setState({types})
        }
      })

    this.setState({unsubscribe})
  }

  changeAddress = async address => {
    if (!address) return
    if (!address[0].address_components) return

    const filteredAddress = {}
    address[0].address_components.forEach(c => {
      if (c.types.some(t => UserInformation.types[t])) {
        filteredAddress[c.types[0]] = c.short_name
      }
    })
    for (let k in filteredAddress) this.props.setField(k)(filteredAddress[k])
    this.props.setField(`address`)(address[0].formatted_address)
    this.props.setField(`geocode_results`)(address[0])
  }

  componentWillUnmount = () => {
    if (this.state.unsubscribe) this.state.unsubscribe()
  }

  render = () => (
    <div className="outer-container user-information" style={this.props.style} >
      { !this.props.noheader && <h3>User Information</h3>}
      <div className="flex-wrap-row" style={{width: `100%`, margin: `0 0 1rem 0.5rem`}}>
        <div className="flex-column" style={{flex: 20}}>
          <Required />
          <UglyField style={{flex: 20}}>
            <TextField field={this.props.fields.name} set={this.props.setField(`name`)} placeholder="Full Name" />
          </UglyField>
        </div>
        { !this.props.notype && <div style={{flex: 1}}></div> }
        { !this.props.notype &&
          <div className="flex-column" style={{flex: 20}}>
            <Required />
            <UglyField>
              <SelectField
                {...{
                  set: this.props.setField(`type`),
                  field: this.props.fields.type,
                  placeholder: `Select User Type`,
                  options: this.state.types
                }}
              />
            </UglyField>
          </div>
        }
      </div>

      <div className="flex-wrap-row" style={{width: `100%`, margin: `0 0 1rem 0.5rem`}}>
        <div className="flex-column" style={{flex: 20}}>
          <Required />
          <UglyField>
            <TextField
              field={this.props.fields.email}
              type="email"
              set={this.props.setField(`email`)}
              placeholder="Email"
            />
          </UglyField>
        </div>
        <div style={{flex: 1}}></div>
        <div className="flex-column" style={{flex: 20, justifyContent: `flex-end`}}>
          <UglyField>
            <TextField field={this.props.fields.phone} set={this.props.setField(`phone`)} placeholder="Phone" />
          </UglyField>
        </div>
      </div>

      <div className="flex-wrap-row" style={{width: `100%`, margin: `0 0 1rem 0.5rem`}}>
        <UglyField style={{flex: 20}}>
          <AutocompleteField
            full
            edit
            autocomplete={this.props.autocomplete}
            style={{flex: 1, border: `none`, padding: `1rem`}}
            submit={this.changeAddress}
            old_address={this.props.fields.address}
            placeholder="Address"
            data-cy="address"
          />
        </UglyField>
      </div>
      {this.props.fields.type === `client` && (
        <UglyField className="flex-wrap-row" style={{width: `100%`, margin: `0 0 1rem 0.5rem`}}>
          <TextField
            field={this.props.fields.company}
            set={this.props.setField(`company`)}
            placeholder="Company Name"
          />
        </UglyField>
      )}

      {/*
					THIS IS TEMPORARY;
					it will need to be replaced by a component that more cleanly handles multiple Ids
					and validates them against the NABCEP database
				*/}
      {this.props.fields.type === `installer` && (
        <UglyField className="flex-wrap-row" style={{width: `100%`, margin: `0 0 1rem 0.5rem`}}>
          <TextField
            field={this.props.fields.nabcep_ids}
            set={this.props.setField(`nabcep_ids`)}
            placeholder="NABCEP Ids, Comma Separated"
          />
        </UglyField>
      )}
    </div>
  )
}


// <input style={ { padding: "0.2rem", border: "none" } } value={ value } placeholder={ placeholder } />
// { !!value && <div style={ { color: "grey", fontSize: "65%", textAlign: "left", padding: "0 0 0 0.2rem"  } } >{ placeholder }</div> }
// {/*<TextField field={ this.props.fields.address } set={ this.props.setField("address") } placeholder="Address" />*/}
//         {/* 
//             <div style={ { flex: 1 } } ></div>
//             <UglyField style={ { flex: 20 } } >
//               <TextField field={ this.props.fields.address2 } set={ this.props.setField("address2") } placeholder="Address Line 2" />
//             </UglyField>
//           */}

//       {
//           <div style={ { display: "flex", flexFlow: "row wrap", width: "100%", margin: "0 0 1rem 0.5rem" } } >
//             <UglyField style={ { flex: 20 } } >
//               <TextField field={ this.props.fields.city } set={ this.props.setField("city") } placeholder="City" />
//             </UglyField>
//             <div style={ { flex: 1 } } ></div>
//             <UglyField style={ { flex: 7 } } >
//               <TextField field={ this.props.fields.state } set={ this.props.setField("state") } placeholder="State" />
//             </UglyField>
//             <div style={ { border: "none", flex: 1 } } ></div>
//             <UglyField style={ { flex: 12 } } >
//               <TextField field={ this.props.fields.zip } set={ this.props.setField("zip") } placeholder="Zip Code" />
//             </UglyField>
//           </div>
//         }