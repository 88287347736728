import React, {useState, useEffect} from "react"
import {Select, Icon} from "antd"
import {auth} from "../../Auth"
import SavedFilters from './SavedFilters'
import {setTableControls} from "../action_creators"
import {connect} from "react-redux"
import {onChange} from "../onChange"

const sToP = state => ({savedFilters: state.tableVars.tableStrategy.savedFilters})
const dToP = dispatch => ({setTableControls: f => dispatch(setTableControls(f))})

function ChooseSavedFilter ({setTableControls, style, savedFilters}) {
  const [filters, setFilters] = useState([])
  const [show, setShow] = useState(false)
  const [val, setVal] = useState(undefined)

  useEffect(function () {
    const unsubscribe = auth.sunkaizenUserSubscribe(user => {
      if (user) {
        setFilters(user.projectFilters || [])
      }
    })

    return unsubscribe
  }, [])

  function loadFilter (i) {
    if (i === 'create') {
      setShow(true)
      setVal(undefined)
      return
    }

    setVal(i)
    const filter = filters[i]
    setTableControls({filters: filter.filter})
    onChange()
  }

  return (
    <div className="flex-row" style={savedFilters ? style : {width: 0}}>
      { savedFilters
        ? <div className="flex-row" style={{flex: 1}} >
          <Select
            onChange={e => loadFilter(e)}
            value={val}
            style={{backgroundColor: "white", width: '100%', marginRight: '1rem'}}
            suffixIcon={<Icon type="search" />}
            size="large"
            placeholder="Saved Filters"
          >
            {filters.map((filter, key) => 
              <Select.Option key={key} value={key}>
                {filter.name}
              </Select.Option>
            )}
            <Select.Option 
              key="create" 
              value="create" 
              style={{borderTop: '1px solid lightgrey', fontWeight: 'bold'}} 
            >
                Add New Filter
            </Select.Option>
          </Select>
          <SavedFilters show={show} setShow={setShow} />
        </div>        
        : <div></div>
      }
    </div>    
  )
}

const WrappedChooseSavedFilter = connect(sToP, dToP)(ChooseSavedFilter)
export default WrappedChooseSavedFilter
