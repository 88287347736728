import React from "react"
import {connect} from "react-redux"

const stop = store => ({tableStrategy: store.tableVars.tableStrategy})

function Alternate ({tableStrategy}) {
  const {alternate: M, create} = tableStrategy
  return (<div>{ !create && M && <M /> }</div>)
}

const Wrapped = connect(stop)(Alternate)
export default Wrapped