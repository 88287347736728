import {firebase, storage, db} from "../utilities"
import SystemLogger from "../SystemLogger"
const Timestamp = firebase.firestore.Timestamp

// Append any newly uploaded file metadata to the "files" array on the associated document for files
const makeFileType = ({addToDoc, docSnap, fileMetadata, collection}) => {
  addToDoc.files = [].concat(docSnap.data().files, fileMetadata).filter(i => i) // Omit undefined
  // Keep a second copy of doc.files on doc.intake.files if this is a project
  if (collection == `projects`) {
    let intakeFiles = docSnap.data().intake.files
    intakeFiles = [].concat(intakeFiles, fileMetadata).filter(i => i)
    addToDoc.intake = {...docSnap.data().intake, files: intakeFiles}
  }
}

const makePhotoType = ({addToDoc, docSnap, fileMetadata, collection, sectionId, fieldIndex}) => {
  //Unlike files, photos are stored as associative arrays.
  let docPhotos = docSnap.data().photos || {}
  let photoKey = `${fileMetadata[0].created.seconds}_${fileMetadata[0].name}`
  docPhotos[photoKey] = fileMetadata[0]
  addToDoc.photos = docPhotos

  //If this photo corresponds to a single intake field, add a reference to it to the field on the project's intake
  if (collection === `projects`) {
    let docIntake = docSnap.data().intake
    let intakePhotos = docIntake.photos || {}
    intakePhotos[photoKey] = fileMetadata[0]
    docIntake.photos = intakePhotos

    if (sectionId && fieldIndex) {
      let fieldPhotos = docSnap.data().intake.sections[sectionId].field[fieldIndex].photos || {}
      fieldPhotos[photoKey] = fileMetadata[0]
      docIntake.section[sectionId].field[fieldIndex].photos = fieldPhotos
    }
    addToDoc.intake = docIntake
  }
}

//Add the report top level to the "reports" array.
const makeReportType = ({addToDoc, docSnap}) => {
  addToDoc.reports = docSnap.data().reports || null
}

// If this thumbnail belongs to a project, put it on the site object
//  Add the thumbnail to the site (note that we overwrite any existing thumbnail
// Otherwise at present there is no clear definition for what it means to be
//  a thumbnail on another type of document, so just stick it in misc
const makeThumbnailType = ({addToDoc, docSnap, fileMetadata, collection}) => {
  if (collection == `projects`) addToDoc.site = {...docSnap.data().site, thumbnail: fileMetadata[0]}
  else addToDoc.misc_files = [].concat(docSnap.data().misc_files, fileMetadata)
}

// Usually used for Users.  A document can only have a single avatar
const makeAvatarType = ({addToDoc, fileMetadata, collection}) => {
  if (collection === `users`) addToDoc.avatar = fileMetadata[0]
}

// Intended for client-type users.  A document can only have a single logo
const makeLogoType = ({addToDoc, fileMetadata}) => {
  addToDoc.logo = fileMetadata[0]
}

// If type == "misc" add the file to the misc_files array on that document
const makeMiscType = ({addToDoc, docSnap, fileMetadata}) => {
  addToDoc.misc_files = [].concat(docSnap.data().misc_files, fileMetadata).filter(i => i)
}

/** Takes the uploaded files and puts them in the storage bucket
 * 	as well as writes a reference to the file on the associated file in the correct place
 *  All files must be associated with a specific document in the database.
 *  @param files The array of bytestreams that represent the literal files being uploaded
 *  @param collection A string containing the collection of the associated db resource
 *  @param id The Id of the document these files are associated with
 *  @param type A string containing the type of file that this is.  Photos and files of other types are stored in
 *              different locations, and loaded differently for projects.
 *  @param updateStatus A callback function that should be run as the status updates.  Usually this is simply a setState() call for the
 *              fileupload component.
 *  @param sectionId (optional) The ID of the section on an intake form that this file is associated with
 *  @param fieldIndex (optional) The index of the field that this file is associated with.
 *  @param auth (optional) A copy of the Auth object. Required for Logo-type submissions.  Used to log errors
 *  @param submit (optional) A callback function that will be passed the path determined by type collection and ID.  If a submit function is provided
 *                all logic that updates database resources with file references will be skipped.  If possible, do not use this feature.
 * Note: While File references are written on the intake object on projects, those files may not
 *  actually have anything to do with Intakes.
 *  They are "Project files" that can carry data about anything to do with a project
 *  Not necessarily just an intake
 */

export const submitFiles = async ({
  files,
  collection,
  id,
  type,
  updateStatus,
  sectionId = null,
  fieldIndex = null,
  auth,
  submit,
  done = () => null
}) => {
  //Only allow PNGs for logos
  if (type === `logo`) {
    if (!files[0]) return
    if (files[0].type !== `image/png`) {
      console.log(`not a PNG`)
      const logger = new SystemLogger({uid: id, path: `/users/${id}`})

      const u = auth.sunkaizenUserSubscribe(u => {
        logger.event(`tried to upload a non-PNG logo, logo type is ${files[0].type}; canceling`, ``, 5, {
          [u.id]: u.type
        })

        u()
      })

      return
    }
  }

  //Throw an error if the path is ill defined
  if( !collection || !id || !type){
    throw new Error(`Collection, id, and type must be defined to submit a file.
     Collection : ${collection}, id : ${id}, type : ${type}`)
  }

  const filePath = `${collection}/${id}/${type}s`

  //Use the supplied submit function if one was provided
  // console.log("supplied subitter", submit)
  if (submit) {
    console.log(`calling supplied submit function`)
    submit(files, filePath)
    return
  }

  // console.log("Using default submit logic")
  //Upload the file to the storage
  const statuses = files.map(file => {
    const fileRef = storage.ref().child(`${filePath}/${file.name}`)
    return fileRef.put(file)
  })

  //Get a reference to the DB object
  const docSnap = await db
    .collection(collection)
    .doc(id)
    .get()

  //Follows this design https://www.fullstackfirebase.com/firebase-storage/notes
  statuses.forEach(status => {
    status.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        const statuses = {
          [status.snapshot.ref.name]: {
            progress,
            size: snapshot.totalBytes
          }
        }
        // Update the status to the callback provided if they gave us one.
        updateStatus && updateStatus({statuses})
      },
      error => {
        console.log(`Something went wrong uploading files to this project`, error)
      },
      async () => {
        let fileMetadata = []

        const {bucket, fullPath, name} = status.snapshot.ref
        const {totalBytes: size} = status.snapshot
        const url = await status.snapshot.ref.getDownloadURL()

        //Add object containing file metadata to "files" array
        fileMetadata.push({
          bucket,
          fullPath,
          url,
          name,
          size,
          created: Timestamp.now(),
          file_type: type
        })

        //Update status again now that we know we're fully uploaded.
        let currentStatus = {}
        currentStatus[name] = {
          size: size,
          progress: 100
        }
        if (updateStatus) {
          updateStatus({
            statuses: {
              ...currentStatus
            }
          })
        }

        let addToDoc = {}

        switch (type) {
        case `file`:
          makeFileType({addToDoc, docSnap, fileMetadata, collection})
          break
        case `photo`:
          makePhotoType({addToDoc, docSnap, fileMetadata, collection, sectionId, fieldIndex})
          break
        case `report`:
          makeReportType({addToDoc, docSnap})
          break
        case `thumbnail`:
          makeThumbnailType({addToDoc, docSnap, fileMetadata, collection})
          break
        case `avatar`:
          makeAvatarType({addToDoc, fileMetadata, collection})
          break
        case `logo`:
          makeLogoType({addToDoc, fileMetadata})
          break
        case `misc`:
          makeMiscType({addToDoc, docSnap, fileMetadata})
          break
        default:
          break
        }

        await db
          .collection(collection)
          .doc(id)
          .set(addToDoc, {merge: true})

        done()
      }
    )
  })
}

// //The reference onto the corresponding file is written in a different place depending on the "type specified"
// if (type == "file") {
//     //Append any newly uploaded file metadata to the "files" array on the associated document for files
//     addToDoc.files = []
//         .concat(docSnap.data().files, fileMetadata)
//         .filter(i => i) //Omit undefined

//     //Keep a second copy of doc.files on doc.intake.files if this is a project
//     if (collection == "projects") {
//         let intakeFiles = docSnap.data().intake.files

//         intakeFiles = []
//             .concat(intakeFiles, fileMetadata)
//             .filter(i => i)

//         addToDoc.intake = {
//             ...docSnap.data().intake,
//             files: intakeFiles
//         }
//     }
// } else if (type == "photo") {
//     //Unlike files, photos are stored as associative arrays.
//     let docPhotos = docSnap.data().photos || {}

//     let photoKey = `${fileMetadata[0].created.seconds}_${fileMetadata[0].name}`

//     docPhotos[photoKey] = fileMetadata[0]
//     addToDoc.photos = docPhotos

//     //If this photo corresponds to a single intake field, add a reference to it to the field on the project's intake
//     if (collection === "projects") {
//         let docIntake = docSnap.data().intake
//         let intakePhotos = docIntake.photos || {}
//         intakePhotos[photoKey] = fileMetadata[0]
//         docIntake.photos = intakePhotos

//         if (sectionId && fieldIndex) {
//             let fieldPhotos =
//                 docSnap.data().intake.sections[sectionId].field[
//                     fieldIndex
//                 ].photos || {}

//             fieldPhotos[photoKey] = fileMetadata[0]

//             docIntake.section[sectionId].field[
//                 fieldIndex
//             ].photos = fieldPhotos
//         }
//         addToDoc.intake = docIntake
//     }
// } else if (type == "report") {
//     //Add the report top level to the "reports" array.
//     addToDoc.reports = docSnap.data().reports || []
// } else if (type == "thumbnail") {
//     //If this thumbnail belongs to a project, put it on the site object
//     if (collection == "projects") {
//         //Add the thumbnail to the site (note that we overwrite any existing thumbnail
//         addToDoc.site = {
//             ...docSnap.data().site,
//             thumbnail: fileMetadata[0]
//         }
//     } else {
//         //Otherwise at present there is no clear definition for what it means to be
//         //a thumbnail on another type of document, so just stick it in misc
//         let misc_files = docSnap.data().misc_files
//         misc_files = [].concat(misc_files, fileMetadata)
//         addToDoc.misc_files = misc_files
//     }
// } else if (type == "avatar") {
//     //Usually used for Users.  A document can only have a single avatar
//     addToDoc.avatar = fileMetadata[0]
// } else if (type == "logo") {
//     //Intended for client-type users.  A document can only have a single logo
//     addToDoc.logo = fileMetadata[0]
// } else if (type == "misc") {
//     //If type == "misc" add the file to the misc_files array on that document
//     let misc_files = docSnap.data().misc_files
//     misc_files = [].concat(misc_files, fileMetadata)
//     addToDoc.misc_files = misc_files
// }
