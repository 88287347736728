import React from "react"
import PropTypes from "prop-types"
import {Router, Route, Switch, Redirect} from "react-router-dom"
import {history} from "./utilities"
import Auth from "./Auth"
import CheckRegistration from "./CheckRegistration"
import Header from "./Header"
import Sidebar from "./Sidebar"
import AccountCreationPending from "./AccountCreationPending"
import Login from "./Login"
import Register from "./Register"
import ScheduleInspections from "./ScheduleInspections"
import SystemSettings from "./SystemSettings"
import ProjectList from "./ProjectList/TopLevelState"
import UserList from "./UserList/TopLevelState"
import RubricPage from "./Rubrics/RubricPage"
import LogViewer from "./Logging/LogViewer"
import FormsList from "./FormsList"
import FormEdit from "./Forms/Provider"
import DetailedStateHolder from "./DetailedStateHolder"
import {DetailedProjectView} from "./DetailedProjectView"
import Dashboard from "./Dashboards/DashboardLanding"
import ProjectNotifications from "./ProjectNotifications"
import ScoringSystemsPage from "./ScoringSystems/ScoringSystemsPage"
import PdfTest from "./PdfTest"
import Testing from "./Testing"
import AutocompleteField from "./AutocompleteField"
import EmailLogin from "./EmailLogin"
import DoIntake from "./DoIntake/Provider"
import SystemNotifier from "./Logging/SystemNotifier"
import Calendar from "./Calendar/Calendar"
import Reports from "./Reports"
import FieldUserDirectory from "./FieldUserDirectory"
import InstallerDirectory from "./InstallerDirectory"
import NewLogin from "./NewLogin"
import AnalyticsPage from "./Analytics/TopLevelState"
import TicketModal from "./Ticketing/TicketModal"
import ProjectPhotoDuplicates from './Ops/ProjectPhotoDuplicates'
import CalendarIntakesByUser from './CalendarIntakesByUser'
import ProjectViewer from './ProjectViewer'

import TestHOC from "./HOC/TestHOC"

import './styles.css'

const styles = {
  sidebar: {
    flex: `15 1 10%`
    // display: "inline-block"
  },
  content: {
    flex: 80,
    display: `flex`,
    alignContent: `flex-start`,
    flexFlow: `column`
  },
  container: {flex: 1}
}

const auth = new Auth()

export const Landing = () => (
  <div>
    <h1>Click the Sign In button on the left sidebar to get started</h1>
  </div>
)

const Authorized = ({component: Component, ...rest}) => (
  <Route {...rest} render={props => <CheckRegistration {...{...rest, Component, auth, ...props}} />} />
)

class RedirectToUserManage extends React.Component {
  static propTypes = {auth: PropTypes.object}

  componentDidMount = () => {
    this.props.auth.subscribe(user => this.setState({user}))
  }
  state = {user: null}

  render = () => {
    return this.state.user ? <Redirect to={`/users/${this.state.user.uid}/manage`} /> : <div>Loading</div>
  }
}

export default class App extends React.Component {
  async componentDidMount() {
    auth.subscribe(user => this.setState({user}))
  }

  state = {user: null}

  render() {
    const {user} = this.state

    return (
      <Router history={history}>
        <div className="app" style={{display: `flex`, flexFlow: `row nowrap`}}>
          <SystemNotifier />
          <div style={styles.sidebar}>
            <Route component={Sidebar} />
          </div>
          <div style={{display: `flex`, backgroundColor: `#F4F6F8`, width: `80%`, flexFlow: `column`}}>
            <div style={{height: `7.5rem`}}>
              <Header auth={auth} />
            </div>
            <div style={styles.content}>
              <div style={{height: `91.5%`}}>
                <Switch>
                  <Route exact path="/new_login/:email/:code" component={NewLogin} />
                  <Route exact path="/landing" component={Landing} />
                  <Route exact path="/login" render={routeProps => <Login auth={auth} {...routeProps} />} />
                  <Route exact path="/pending" render={routeProps => <AccountCreationPending {...routeProps} />} />
                  <Route exact path="/register" render={routeProps => <Register {...routeProps} auth={auth} />} />
                  <Authorized
                    exact
                    path="/analytics"
                    type={[`admin`, `super-admin`, `client`]}
                    component={AnalyticsPage}
                  />
                  <Authorized
                    exact
                    path="/schedule"
                    type={[`admin`, `super-admin`, `scheduler`]}
                    component={ScheduleInspections}
                  />
                  {/* <Authorized exact path="/logs" type={ ['admin', 'super-admin'] } component={ LogViewer } /> */}
                  <Authorized exact path="/forms" type={[`admin`, `super-admin`]} component={FormsList} />
                  <Authorized
                    exact
                    path="/forms/:formId/rubrics"
                    type={[`admin`, `super-admin`]}
                    component={RubricPage}
                  />
                  <Authorized
                    exact
                    path="/scoringSystems"
                    type={[`admin`, `super-admin`]}
                    component={ScoringSystemsPage}
                  />
                  <Authorized exact path="/tickets" component={TicketModal} />
                  <Authorized exact path="/projects" component={ProjectList} />
                  <Authorized exact path="/users" type={[`admin`, `super-admin`]} component={UserList} />
                  <Authorized exact path="/calendar" component={Calendar} />
                  <Authorized 
                    exact path="/reports" 
                    component={Reports} 
                    type={['admin', 'super-admin', 'client']}
                  />
                  {/* <Authorized exact path="/data" component={Data} /> */}
                </Switch>

                <Authorized exact path="/" component={Dashboard} />

                <Switch>
                  <Authorized
                    exact
                    path="/settings/system"
                    type={[`admin`, `super-admin`]}
                    component={SystemSettings}
                  />
                </Switch>

                <Authorized exact path="/forms/:id" type={[`admin`, `super-admin`]} component={FormEdit} />
                <Authorized exact path="/projectNotifications/:id" component={ProjectNotifications} />
                <Switch>
                  <Authorized exact path="/projects/:id/manage" component={DetailedProjectView} />
                  <Authorized exact path="/:collection/:id/manage" component={DetailedStateHolder} />
                </Switch>
                <Authorized
                  exact
                  path="/intake/:id"
                  type={[`admin`, `super-admin`, `client`, `field-user`, `quality-manager`]}
                  component={DoIntake}
                />
                <Authorized
                  exact
                  path="/report/:id"
                  type={[`admin`, `super-admin`, `client`]}
                  component={() => <h1 style={{padding: `3rem`}}>Reports Placeholder</h1>}
                />

                <Route exact path="/settings/account" render={() => <RedirectToUserManage auth={auth} />} />
                <Route exact path="/emailLogin" render={() => <EmailLogin auth={auth} />} />

                <Authorized exact path="/testing" component={Testing} />
                <Authorized exact path="/testhoc" component={TestHOC} />

                <Authorized exact path="/pdftest" component={PdfTest} />
                <Authorized exact path="/autocompletetest" component={AutocompleteField} />
                <Authorized
                  exact
                  path="/field_users"
                  type={[`admin`, `super-admin`, `client`]}
                  component={FieldUserDirectory}
                />
                <Authorized
                  exact
                  path="/installers"
                  type={[`admin`, `super-admin`, `client`]}
                  component={InstallerDirectory}
                />
                <Authorized exact path="/ops/duplicate_photos" type={['admin', 'super-admin']} component={ProjectPhotoDuplicates} />
                <Authorized exact path="/ops/intakes_by_user" types={['admin', 'super-admin']} component={CalendarIntakesByUser} />
                <Authorized exact path="/project_viewer" types={['admin', 'super-admin']}  component={ProjectViewer} />
              </div>
            </div>
          </div>
        </div>
      </Router>
    )
  }
}

//	<Authorized exact path="/csvtest" component={ CreateCSVTest } />
// {/* try to render anything else, and if nothing matches, render a type list */}
// {/* <Authorized exact path="/:route" component={ TypeChooser } /> */}
// {/* <Route exact path="/settings/account" render={ () => <RedirectToUserManage auth={ auth } /> } /> */}
// {/* <Authorized exact path="/settings/:route" component={ TypeChooser } /> */}
// {/*<Authorized exact path="/pdftest" component={ PdfTest } />*/}
// {/*<Authorized exact path="/testing" component={ Testing } />*/}
// {/*<Authorized exact path="/charttest" component={ Chart } /> */}
