import {baseStore} from './utilities'
import DB from '../../DAL/DB'
import {files} from 'jszip'

const projects = new DB.Projects()

export default function file (parentID) {
  async function saveToDB (file) { 
    const {data: [p], ok} = await projects.get(parentID)
    if (!ok) throw new Error(`project provided to file uploader ${parentID} doesn't exist in DB`)

    if (!p.intake.files) p.intake.files = []
    p.intake.files.push(file)
    await projects.update(parentID, null, p)
  }
  const store = baseStore(`projects/${parentID}/files/`)

  return {store, saveToDB}
}