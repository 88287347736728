import React, {useRef, useEffect, useContext, useState} from 'react'
import {googleMaps} from '../../HOC/GoogleMapsHOC'
import {ReducerContext} from '../reducer'
import {auth} from '../../Auth'

export function SchedulingMapWrapper ({loaded, children}) {
  const {state, dispatch} = useContext(ReducerContext)
  const {project} = state
  const mapRef = useRef(null)

  useEffect(function () {
    if (!loaded || !mapRef) return

    // have this code check the state to see if there's a project in there already; if so, set the
    // initial location of the map to that location

    let location

    if (project.id) { location = project.site.geocode_results.geometry.location }
    else if (auth.sunkaizenUser.geocode_results) location = auth.sunkaizenUser.geocode_results.geometry.location
    else location = {lat: 39.8333333, lng: 98.585522 * -1}

    // const {geocode_results = {geometry: {}}} = auth.sunkaizenUser
    // const {location: l} = geocode_results.geometry

    // // Lebanon, Kansas: 39.8333333,−98.585522
    // const userLocation = l || {lat: 39.8333333, lng: 98.585522 * -1}
    // const {site = {geocode_results: {geometry: {location: userLocation}}}} = project || {}

    const map = new window.google.maps.Map(mapRef.current, {
      center: location,
      zoom: 4,
      styles: [{featureType: 'poi', stylers: [{visibility: 'off'}]}]
    })

    dispatch({type: 'setMap', value: map})
  }, [loaded, mapRef.current, project])

  return (
    <div className="full-width" >
      {loaded && children}
      <div style={{height: window.innerHeight * 0.82}} ref={mapRef} >Map</div>
    </div>
  )
}

const WrappedSchedulingMapWrapper = googleMaps()(SchedulingMapWrapper)
export default WrappedSchedulingMapWrapper