import React, {useState, useEffect} from "react"
import Chart from './Chart'

function insertDelimiterAt(text, delimAt = 35, delimiter=`<br>` ){

  let textWords = text.split(` `)

  let charsSinceLastDelim = 0
  let index=0
  while (index < textWords.length){
    let word = textWords[index]
    charsSinceLastDelim += word.length

    if( charsSinceLastDelim >= delimAt){
      charsSinceLastDelim = 0
      textWords.splice(index, 0, delimiter)
    }
    
    index++
  }

  return textWords.join(` `)

}

export default function ParetoChart ({data = {}, layout = {height: 600, width: 1200}}) {
  const entries = Object.entries(data)

  entries.sort((a, b) => {
    if (a[1] === b[1]) return 0
    else if (a[1] < b[1]) return 1
    else return -1
  })

  let x = []
  let y = []

  for (let [xVal, yVal] of entries) {
    x.push(xVal)
    y.push(yVal)
  }

    
  //Make the labels look good
  x = x.map(label => insertDelimiterAt(label))
    
  const total = y.reduce((a, c) => a + c, 0)
  let intermediate = 0
  let percentages = y.map(v => {
    intermediate = intermediate + v
    return intermediate/total * 100
  })

  //Only take the top n entries
  const n = 5
  x = x.slice(0,n)
  y = y.slice(0,n)
  percentages = percentages.slice(0,n)

  const graphData = [
    {
      x,
      y,
      yaxis: `y2`,
      type: `bar`
    },
    {
      x,
      y: percentages,
      type: `scatter`
    }
  ]

  const graphLayout = {
    grid: {
      rows: 2,
      columns: 1,
      pattern: `coupled`
    },
    yaxis: {
      title: `Percentage of Failures`,
      side: `right`
    },
    yaxis2: {
      title: `Number of Failures` 
    },
    xaxis:{
      tickangle : 0
    },
    showlegend: false,
    ...layout          
  }

  return <Chart options={graphData} layout={graphLayout} />
}
