import {firebase} from "../../../utilities"
import {auth} from '../../../Auth'
import DB from '../../../DAL/DB'
import {cancelIntake} from './cancelIntake'
import {cancelProject} from './cancelProject'

const projects = new DB.Projects()
const calendars = new DB.Calendars()

export async function saveCancellation (project, selected, required, type, logger) {
  const cancellation = {canceledAt: firebase.firestore.Timestamp.now(), ...required, reason: selected}
  const projectCancellation = type !== "intake"
  
  if (projectCancellation) cancelProject(project, cancellation)
  else cancelIntake(project, cancellation, selected, projectCancellation)
  
  try {
    await projects.update(project.id, {noMerge: true}, project)
    const {ok, data: [calendarEntry]} = await calendars.get(project.id, null)
    if (ok) await calendars.delete(calendarEntry.id)
  } 
  catch (e) { 
    const logMessage = projectCancellation ? 'a project' : 'an intake'
    if (projectCancellation) {
      logger.event(`${auth.currentUser.displayName} canceled ${logMessage}`, null, 3)
      console.log(`failed to cancel ${logMessage}`, e) 
    }
    else {
      logger.event(`${auth.currentUser.displayName} canceled ${logMessage}`, null, 3)
      console.log(`failed to cancel ${logMessage}`, e) 
    }
  }
}