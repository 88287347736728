export default class GeocodeWrapper {
  constructor() {
    this.mapsInitializing = this.mapsInitializing.bind(this)
    this._geocode = this._geocode.bind(this)

    this.geocoder = null
  }

  mapsInitializing() {
    return new Promise((resolve, reject) => {
      const setLoaded = e => {
        if (e.key === "googleMapsLoaded") {
          if (e.newValue === "true") {
            this.geocoder = new window.google.maps.Geocoder()
            resolve()
          }
        }
      }

      if (window.google && window.google.maps) {
        window.removeEventListener("storage", setLoaded)
        this.geocoder = new window.google.maps.Geocoder()
        resolve("geocoder exists")
      } else {
        window.addEventListener("storage", setLoaded)
      }
    })
  }

  callback = (resolve, reject) => (results, status) => {
    const [best, ...rest] = results || []
    switch (status) {
    case "OK":
      resolve(best)
      break
    case "ZERO_RESULTS":
      reject("no results")
      break
    case "OVER_QUERY_LIMIT":
      reject("you've used up all your geocodes for the day")
      break
    case "REQUEST_DENIED":
      reject("your request was denied for some reason")
      break
    case "INVALID_REQUEST":
      reject(`the request was invalid, probably because you did not provide an address`)
      break
    case "UNKNOWN_ERROR":
      reject(`an unknown error occurred that prevented geocoding your address`)
      break
    case "ERROR":
      reject("there was a network error processing your request; it may work if you try again")
      break
    default:
      reject("an unknown error occurred; google returned no status on this geocode request")
    }
  }

  geocodeType = {
    address: address => callback => this.geocoder.geocode({address}, callback),
    location: location => callback => this.geocoder.geocode({location}, callback)
  }

  _geocode(value, type = "address") {
    // console.log(type, value)
    const f = this.geocodeType[type](value)
    return new Promise((resolve, reject) => {
      f(this.callback(resolve, reject))
    })
  }

  // https://developers.google.com/maps/documentation/javascript/geocoding
  geocode(value, type = "address") {
    return this.mapsInitializing()
      .then(() => this._geocode(value, type))
      .catch(e => {
        console.log(e)
        return {}
        // throw new Error("ADDRESS_FAILED_GEOCODING")
      })
  }
}
