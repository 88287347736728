import React from "react"
// import PropTypes from 'prop-types'

import {Dropdown} from "antd" //	Menu, Icon,
import Auth from "../Auth"
import {Link} from "react-router-dom"
import {db} from "../utilities"

const auth = new Auth()

const Logs = ({logs}) => (
  <div style={{background: "white", padding: "1rem", border: "0.1rem solid lightgrey", borderRadius: "0.5rem"}}>
    {logs.map(l => (
      <div style={{padding: "0.3rem"}}>
        <Link to={`/${l.category}/${l.category.id}`}>{l.message}</Link>
      </div>
    ))}
  </div>
)

export default class NotificationsMenu extends React.Component {
  componentDidMount = () => {
    auth.subscribe(user => {
      if (user) {
        const unsubscribe = db
          .collection("projectTransactionLog")
          .where(`recipient`, "==", user.uid)
          .where("seen", "==", false)
          .onSnapshot(logSnap => {
            const logs = logSnap.docs.map(l => ({...l.data(), id: l.id}))
            logs.sort((a, b) => (a.created.toMillis() > b.created.toMillis() ? 1 : -1))

            this.setState({logs})
          })

        this.setState({unsubscribe})
      } else if (this.state.unsubscribe) this.state.unsubscribe()
    })
  }

  componentWillUnmount = () => {
    if (this.state.unsubscribe) this.state.unsubscribe()
  }

  state = {logs: []}

  render = () => (
    <Dropdown overlay={<Logs logs={this.state.logs} />}>
      <div style={{height: "100%", position: "relative"}}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "2rem",
            height: "2rem",
            borderRadius: "50%",
            background: "darkgrey",
            color: "white",
            position: "absolute",
            top: "0",
            left: "-0.5rem"
          }}
        >
          {this.state.logs.map(l => !l.seen).length}
        </div>
        {this.props.children}
      </div>
    </Dropdown>
  )
}
