import React, {useReducer} from 'react'
import FileUploader from './FileUploader'

const initialState = {pattern: {}, statuses: {}, uploading: false, hovering: false, files: [], photos: {}}
function reducer (state, action) {
  switch (action.type) {
  case 'pattern': return {...state, pattern: action.value}
  case 'status': return {...state, statuses: {...state.statuses, [action.name]: action.value}}
  case 'statuses': return {...state, statuses: action.value}
  case 'uploading': return {...state, uploading: action.value}
  case 'hovering': return {...state, hovering: action.value}
  case 'files': return {...state, files: action.value}
  case 'photos': return {...state, photos: {...state.photos, ...action.value}}
  case 'clear': return {pattern: {}, statuses: {}, uploading: false, hovering: false, files: [], photos: {}}
  default: return state
  }
}

export const ReducerContext = React.createContext(null)

export default function (props) {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <ReducerContext.Provider value={{state, dispatch}}>
      <FileUploader {...props} />
    </ReducerContext.Provider>
  )
}