import React from "react"
import PropTypes from "prop-types"
import {Modal, Input, Button} from "antd"

import SystemLogger from "./SystemLogger"

const googleSignInBadge = "./google.png"

const styles = {
  button: {
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: "125%",
    color: "white",
    backgroundColor: "cornflowerblue",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    padding: "1rem",
    margin: "0.5rem",
    border: "none"
  },
  label: {
    fontSize: "125%",
    fontWeight: "bold",
    paddingRight: "0.4em",
    flex: 1
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "85%",
    height: "40%"
  },
  modal: {
    padding: "6rem 3rem 3rem 3rem",
    width: "50%",
    margin: "1em",
    border: "0.1em solid darkgrey",
    boxShadow: "0.1em 0.1em 0.1em 0.1em darkgrey",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "1rem"
  },
  link: {
    textDecoration: "none",
    color: "cornflowerblue",
    cursor: "pointer"
  },
  display: bool => ({display: bool ? "flex" : "none"}),
  input: {flex: 1, margin: "1rem", padding: "0.5rem", borderRadius: "1rem"}
}

export default class Login extends React.Component {
  static propTypes = {
    auth: PropTypes.object,
    history: PropTypes.object
  }

  // constructor (props) {
  // 	super(props)

  // 	this.unPwSubmit = this.unPwSubmit.bind(this)
  // 	this.googleLogin = this.googleLogin.bind(this)
  // 	this.register = this.register.bind(this)
  // 	this.submitRegister = this.submitRegister.bind(this)
  // }

  state = {show: false, toggle: false, resetEmail: null}

  unPwSubmit = async e => {
    e.preventDefault()
    let {email, password} = e.target
    // console.log(username.value, password.value)
    try {
      await this.props.auth.usernamePasswordLogin(email.value, password.value)
      this.props.history.push("/")
    } catch (e) {
      const logger = new SystemLogger({})
      logger.error(e.message)
    }
  }

  register = e => {
    console.log("showing registration overlay")
    this.setState({show: true})
  }

  submitRegister = async e => {
    e.preventDefault()
    let {email, password} = e.target

    try {
      await this.props.auth.registerWithoutErrorCatching(email.value, password.value)
      this.setState({show: false})
      this.props.history.push("/register")
    } catch (e) {
      console.log(e)
      const logger = new SystemLogger({})

      if (e.code === "auth/email-already-in-use") {
        logger.log(
          "This account already exists; try logging-in instead of registering. You can reset your password if you've forgotten it."
        )
      } else {
        logger.error(e.message)
      }
    }
  }

  googleLogin = async () => {
    try {
      await this.props.auth.googleLogin()
      this.props.history.push("/")
    } catch (e) {
      console.log("error signing in", e)
    }
  }

  resetPassword = async () => {
    try {
      await this.props.auth.sendPasswordResetEmailWithoutErrorCatching(this.state.resetEmail)
      this.toggle()
    } catch (e) {
      const logger = new SystemLogger({})
      logger.error(e.message)
    }
  }

  toggle = () => this.setState({toggle: !this.state.toggle})

  render() {
    return (
      <div 
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "5rem",
        }}
      >
        <form
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignContent: "center",
            padding: "0.5rem",
          }}
          onSubmit={this.unPwSubmit}
        >
          <div
            style={{display: "flex", flexFlow: "row nowrap", alignItems: "center", justifyContent: "center"}}
          >
            <div style={styles.label}>Email:</div>
            <input style={styles.input} name="email" type="text" data-cy="email" />
          </div>
          <div
            style={{display: "flex", flexFlow: "row nowrap", alignItems: "center", justifyContent: "center"}}
          >
            <div style={styles.label}>Password:</div>
            <input style={styles.input} name="password" type="password" data-cy="password" />
          </div>
          <div onClick={this.toggle} style={styles.link}>
                      Forgot Password?
          </div>
          <button style={styles.button}>Sign In</button>
        </form>
        <div style={{padding: "0.3em"}}>
          <span style={{paddingRight: "0.2em"}}>Don&apos;t have an account?</span>
          <span style={styles.link} onClick={this.register} data-testid="register">
                      Register
          </span>
        </div>
        <div onClick={this.googleLogin}>
          <img alt="Google Login" src={googleSignInBadge} />
        </div>
        <div style={{...styles.overlay, ...styles.display(this.state.show)}}>
          <form onSubmit={this.submitRegister} style={styles.modal} data-testid="register-form">
            <div style={{display: "flex", flexFlow: "column nowrap"}}>
              <div style={{display: "flex", flexFlow: "row nowrap", alignItems: "center"}}>
                <div style={styles.label}>Email:</div>
                <Input
                  style={{...styles.input, backgroundColor: "whitesmoke"}}
                  name="email"
                  type="text"
                  data-testid="register-email"
                />
              </div>
              <div style={{display: "flex", flexFlow: "row nowrap", alignItems: "center"}}>
                <div style={styles.label}>Password:</div>
                <Input
                  style={{...styles.input, backgroundColor: "whitesmoke"}}
                  name="password"
                  type="password"
                  data-testid="register-password"
                />
              </div>
              <div style={{display: "flex", flexFlow: "row nowrap", justifyContent: "space-evenly"}}>
                <button style={styles.button} type="submit" data-testid="register-submit">
                                  Register
                </button>
                <button style={styles.button} type="button" onClick={(e) => this.setState({show: false})}>
                                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
        <Modal
          title="Reset Password"
          visible={this.state.toggle}
          onCancel={this.toggle}
          okText="Send Password Reset Link"
          onOk={this.resetPassword}
        >
          <Input
            style={{marginBottom: "1rem"}}
            placeholder="Enter your email address"
            onChange={(e) => this.setState({resetEmail: e.target.value})}
          />
        </Modal>
      </div>
    )
  }
}
