/*
    go to utilities -> headerOrder to change what fields are in the CSV
*/

import React, {useState, useEffect, useContext} from "react"
import {saveAs} from "file-saver"
import {read, subscribe, compose} from "../HOC/Firebase"
import {List, Progress, Button} from "antd"
import {ReducerContext, wrap} from "./reducer"
import {Header, DateItem, DropdownItem} from "."
import {getProjects, createCSV, getResources, organizeResources, makeFilterManipulationFunctions} from "./utilities"
import {createCommentCSV} from "./utilities/createCSV"

const itemMap = (type) => (type === "completed" || type === "started" ? DateItem : DropdownItem)

export function CreateCSVReport({done, setDone, makeSubscription, subscribeResources, readDB, readResults}) {
  const {state, dispatch} = useContext(ReducerContext)
  const [loading, setLoading] = useState(false)
  const [fields, setFields] = useState([])
  const [percent, setPercent] = useState(0)

  useEffect(function () {
    const f = ["client", "type", "status", "started", "completed", "quality-manager", "installer"].map(
      (name) => state.filterTypes[name]
    )
    setFields(f)
  }, [])

  useEffect(
    function () {
      ;(async function () {
        if (!done) {
          setLoading(false)
          return
        }
        const projects = await getProjects({...state.filterTypes}, setPercent)
        const csvFile = createCSV(projects, state.includeIntakeData)
        const blob = new Blob([csvFile], {type: "text/csv"})
        saveAs(blob, "report.csv")
        setPercent(0)
        dispatch({type: "clearState"})
        setDone(false)
      })()
    },
    [done]
  )

  useEffect(getResources(makeSubscription, readDB), [])
  useEffect(organizeResources(subscribeResources, readResults, state, dispatch), [subscribeResources, readResults])

  const {setFilter, deleteInstance, addInstance} = makeFilterManipulationFunctions(state, dispatch)

  const createIntakeCommentReport = async () => {
    console.info("createIntakeCommentReport - ")
    const projects = await getProjects({...state.filterTypes}, setPercent)
    const csvFile = createCommentCSV(projects)
    const blob = new Blob([csvFile], {type: "text/csv"})
    saveAs(blob, "report.csv")
    setPercent(0)
    dispatch({type: "clearState"})
    setDone(false)
  }

  return (
    <div>
      <List
        loading={loading}
        itemLayout="vertical"
        size="small"
        dataSource={fields}
        header={<Header />}
        renderItem={(item) => {
          const C = itemMap(item.type)
          return <C {...{item, setFilter, deleteInstance, addInstance}} />
        }}
      />
      {done && <Progress percent={percent} size="small" />}
      {/** Generate intake comment report */}
      <div className="p2">
        <Button onClick={createIntakeCommentReport}>Create Intake Comment Report</Button>
      </div>
    </div>
  )
}

const WrappedCreateCSVReport = compose(wrap, read(), subscribe())(CreateCSVReport)
export default WrappedCreateCSVReport
