import React from "react"
import {Form, Input, Checkbox} from "antd"
import OptionsWrapper from "./OptionsWrapper"
import {connect} from "react-redux"
import {setField} from "../action_creators"

const mapStateToProps = (state, ownProps) => ({
  field: state.form.fields[ownProps.id] || {}
})

const mapDispatchToProps = dispatch => ({
  setField: ({id, value}) => dispatch(setField({category: `fields`, id, value}))
})

const OptionPropertyBoxBase = ({id, setField, field, name, display}) => (
  <div style={{overflow: `scroll`}}>
    <Form.Item style={{margin: 0}} label="Label">
      <Input
        style={{marginBottom: `2rem`}}
        size="large"
        onChange={e => setField({id, value: Object.assign({}, field, {label: e.target.value})})}
        value={field.label}
      />
    </Form.Item>
    <Form.Item style={{margin: 0}} label="Question">
      <Input
        style={{marginBottom: `2rem`}}
        size="large"
        onChange={e => setField({id, value: Object.assign({}, field, {question: e.target.value})})}
        value={field.question}
      />
    </Form.Item>
    <Form.Item style={{margin: 0}} label="Tip text">
      <Input
        style={{marginBottom: `2rem`}}
        size="large"
        onChange={e => setField({id, value: Object.assign({}, field, {tipText: e.target.value})})}
        value={field.tipText}
      />
    </Form.Item>
    <Form.Item style={{margin: 0}}>
      <Checkbox
        onChange={e => setField({id, value: Object.assign({}, field, {required: e.target.checked})})}
        value={field.required}
        checked={field.required}
      >
        Required
      </Checkbox>
    </Form.Item>
    <Form.Item style={{margin: 0}}>
      <Checkbox
        onChange={e => setField({id, value: Object.assign({}, field, {photoRequired: e.target.checked})})}
        value={field.photoRequired}
        checked={field.photoRequired}
      >
        Require Uploading Photos
      </Checkbox>
    </Form.Item>
    <Form.Item style={{margin: 0}}>
      <Checkbox
        onChange={e => setField({id, value: Object.assign({}, field, {requiredForFieldIntake: e.target.checked})})}
        value={field.requiredForFieldIntake}
        checked={field.requiredForFieldIntake}
      >
        Required for Field Intake
      </Checkbox>
    </Form.Item>
    <b>Options</b>
    <div style={{marginBottom: `4rem`}}>
      <OptionsWrapper />
    </div>
  </div>
)

const WrappedOptionPropertyBoxBase = connect(mapStateToProps, mapDispatchToProps)(OptionPropertyBoxBase)
export default WrappedOptionPropertyBoxBase
