function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = "0" + month
  if (day.length < 2) day = "0" + day

  return [year, month, day].join("-")
}

/**section: section.title,
              question: field.question,
              answer: label,
              comment: field.value.requireFollowup[label].requireFollowUpComment, */

export const commentHeaderMap = (project, intake_field) => ({
  "Project ID": project.id,
  "Site Owner Name": project.site.siteOwnerName,
  "Site Address": project.site.geocode_results.formatted_address,
  "Site Rep Email": project.site.siteRepEmail,
  "Site Rep Phone": project.site.siteRepPhone,

  "Installer Name": (project.installer || {}).name,
  Completed: (function () {
    if (!project.updated || !project.updated.toDate) return ""
    return formatDate(project.updated.toDate())
  })(),

  Section: intake_field.section,
  Question: intake_field.question,
  Comment: intake_field.comment.trim(),
  // Only if the pass and the total exists and the valid percentage
  "Overall Score":
    (project.intakeResult?.rawData && project.intakeResult.rawData.pass / project.intakeResult.rawData.total >= 0
      ? Math.round((project.intakeResult.rawData.pass / project.intakeResult.rawData.total) * 100)
      : "0") + "%",
  "PASS/FAIL":
    project.intakeResult?.rawData && project.intakeResult.rawData.pass / project.intakeResult.rawData.total >= 0
      ? (project.intakeResult.rawData.pass / project.intakeResult.rawData.total) * 100 >
        project.intakeResult.rawData.percentage_failure
        ? "PASS"
        : "FAIL"
      : "FAIL",
})

export const commentHeaders = [
  "Project ID",
  "Site Owner Name",
  "Site Address",
  "Site Rep Email",
  "Site Rep Phone",
  "Installer Name",
  "Completed",
  "Section",
  "Question",
  "Comment",
  "Overall Score",
  "PASS/FAIL",
]
