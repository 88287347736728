import {store} from "../utilities"
import {FULL_TEXT_SEARCH_URL} from "../../config"
import {setTabs} from '../action_creators'
import tabsPostProcessor from './tabsPostProcessor'
import get from './get'

export default async function queryTabs () {
  const state = store.getState()
  const {permissions = () => []} = state.tableVars.tableStrategy || {}
  const p = permissions() || []

  const url = `${FULL_TEXT_SEARCH_URL}/counts`
  const res = await get(url, {
    type: state.tableVars.tableStrategy.type, 
    sortField: `${state.tableVars.tableStrategy.tabs}.sortable`, 
    permissions: p
  })

  const counts = tabsPostProcessor(res)
  store.dispatch(setTabs(counts))    
}