import {firebase} from "../utilities"
import {auth} from "../Auth"
import {FULL_TEXT_SEARCH_URL} from "../config"

// const {size = 200, from = 0, query_strings, filters, barFilters} = query
export default async function projectDownloader (state) {
  // download 200 projects
  const query = makeQuery(state)
  const res = await get(query).then(r => r.json())

  // check total number of projects in elastic
  const {value: t} = res.hits.total

  // for loop starting at 200, incrementing by 200 projectes each time, to get the 'from' number
  //  for subsequent queries, then fire them off and store each promise in an array
  const promises = []
  for (let i = 201; i < t; i += 200) { promises.push(get({...query, from: i}).then(r => r.json())) }

    
  // Promise.all the array, and concat the results into total array
  const total = [res, ...(await Promise.all(promises)).flat()]

  // transform total array into plain projects
  return total
    .map(d => d.hits.hits) // array of arrays, each holding 200ish results
    .flat() // make them into a single array
    .map(d => d._source) // elastic returns the projects with a bunch of unimportant metadata
    .map(d => { 
      if (!d.created) {
        console.log(d)
        // throw new Error(`missing created: ${d.id}`)
      }
      d.created = new firebase.firestore.Timestamp(d.created._seconds, d.created._nanoseconds) 
      return d
    }) // fix timestamp
}

// change this to use a function that first strips a ton of content out of the projects
async function get (q) {

  console.log(q)

  const body = JSON.stringify(makeQuery(q))
  
  const res = await fetch(`${FULL_TEXT_SEARCH_URL}/full_search`, {
    method: `POST`,
    mode: `cors`,
    headers: {"Content-Type": `application/json`},
    body
  })

  return res
}

const filterConverter = {
  clients: clientsArray => clientsArray ? {terms: {[`client.id`]: clientsArray}} : {},
  intakes: status => status ? {term: {"status.sortable": {value: status}}} : {},
  timespan: timespan => timespan ? {range: {'created._seconds': {gte: timespan.unix()}}} : {},
  "inspection.inspector.id": id => [["inspection.inspector.id", "==", id]],
  "client.id": id => [["client.id", "==", id]]
}

function userCategory (user) {
  let filter
  const {id, qualifications, qualificationsAll, clients, clientsAll} = user
  let {type} = user

  // if (type === 'field-user') type = 'inspector'
  // else if (type === 'quality-manager')

  switch (type) {
  case 'field-user': 
    // type = 'inspector'
    filter = {match: {[`inspection.inspector.id`]: auth.sunkaizenUser.id}}
    break
  case 'quality-manager':
    if (!qualificationsAll) filter = {terms: {['intake.id']: Object.keys(qualifications)}}
    if (!clientsAll) filter = {terms: {['client.id']: Object.keys(clients)}}
    break
  case 'scheduler': 
    filter = {match: {[`user_index.${id}`]: type || `client`}}
    break            
  case 'admin':
  case 'super-admin':
    break
  default: console.log('strange user type: ', type)
  }

  return filter
}

function makeQuery (query) {
  const {size = 200, from = 0, query_strings = [], filters, barFilters = {}} = query
  let ff = Object.entries(barFilters).map(([name, value]) => filterConverter[name](value))

  const userFilter = userCategory(auth.sunkaizenUser)
  ff.push(userFilter)

  return {
    type: `projects`,
    size,
    from,
    query_strings,
    filters: ff.filter(i => i),
    brief: true
  }
}  