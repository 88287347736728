import convertElastic from "./convertElastic"
import {applyFilter} from "."
import {FULL_TEXT_SEARCH_URL} from "../../config"
import retry from "async-retry"
import {auth} from "../../Auth"

export default async function getProjects(filters, setPercent) {
  const appliedFilters = applyFilter(filters)
  console.info("appliedFilters - ", appliedFilters)
  // Get matched project counts:
  const countUrl = `${FULL_TEXT_SEARCH_URL}/query_count`
  const c = await get(countUrl, {
    query: "",
    type: "projects",
    ...appliedFilters,
  })
  console.info("Count - ", c)
  const total = c.count

  const SIZE_LIMIT = 30 // do not set over 30 as Firebase functions is limiting it.
  let search_after = ""
  let projects = []
  let count = 0
  const url = `${FULL_TEXT_SEARCH_URL}/full_search`

  /** 
   to sort projects:
    
  {{ES_URL}}/main/_mapping

  {
    "properties": {
      "id": { 
        "type":     "text",
        "fielddata": true
      }
    }
  }
  */

  const body = {
    from: 0,
    size: SIZE_LIMIT,
    query: "",
    type: "projects",
    sort: [{id: "asc"}],
    search_after,
    ...appliedFilters,
  }
  if (auth.sunkaizenUser.type === "client")
    body.filters.push({match: {[`user_index.${auth.sunkaizenUser.id}`]: "client"}})

  do {
    const r = await retry(
      async (bail) => {
        return await get(url, {...body, search_after})
      },
      {retries: 10}
    )
    count += SIZE_LIMIT
    setPercent(Math.round((count / total) * 100))
    projects = projects.concat(convertElastic(r.hits.hits))

    // extract last id
    if (r.hits.hits.length > 0) {
      search_after = r.hits.hits[r.hits.hits.length - 1].sort
    } else {
      search_after = ""
    }

    console.info("search_after:", search_after)
  } while (search_after)

  return projects
}

function extractor(elasticResponse) {
  return elasticResponse.hits.hits
}

async function get(url, body) {
  return await (
    await fetch(url, {
      method: `POST`,
      mode: `cors`,
      headers: {"Content-Type": `application/json`},
      body: JSON.stringify(body),
    })
  ).json()
}
