import React from "react"
import PropTypes from "prop-types"

const Loading = props => (
  <div
    {...props}
    className={`loading-text` + (props.className ? ` ` + props.className : ``)}
    style={{padding: `2rem`, ...(props.style || {})}}
  >
    Loading...
  </div>
)
export default Loading

Loading.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
}
