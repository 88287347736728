import React from 'react'
import makeCalendarMap from './makeCalendarMap'
import moment from 'moment'

const dateCellRender = calendar => value => {
  const calendarMap = makeCalendarMap(calendar)
  const d = value.format('MM/DD')
  const events = calendarMap[d] || []

  return (
    <div className="flex-row justify-center" >
      <div className="flex-row" >
        {
          events.map((event, i) => {
            switch (i) {
            case 0:
            case 1:
            case 2: return <div key={i}>&bull;</div>
            case 3: return <div>+</div>
            default: return
            }
          }).filter(i => i)
        }
      </div>
    </div>
  )
}

export default dateCellRender