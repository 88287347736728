import React from "react"
import PropTypes from "prop-types"
import {Button, Row, Col} from "antd" //	Input,
import DeleteButton from "../DeleteButton"
import {FieldType} from "./FieldTypes"

const FormItem = ({disabled = false, id = "", type, activate, del, duplicate, selected}) => (
  <Row
    className={selected === id ? "formBuilderItemSelected" : ""}
    style={{padding: "2rem", marginTop: "2rem"}}
    gutter={20}
  >
    <Col span={20} onClick={() => activate(id)}>
      <FieldType type={type} id={id} />
    </Col>
    <Col span={2}>
      <Button disabled={disabled} title="Duplicate" onClick={() => duplicate(id)} icon="copy" size="large" />
    </Col>
    <Col span={2}>
      <DeleteButton
        confirmationDisplay="Delete this field?"
        title="Delete"
        type="secondary"
        onConfirm={() => del(id)}
        icon="delete"
        size="large"
      />
    </Col>
  </Row>
)

FormItem.propTypes = {
  form: PropTypes.object,
  id: PropTypes.string,
  setResource: PropTypes.func,
  selected: PropTypes.string,
  del: PropTypes.func
}

export default FormItem
