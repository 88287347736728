import {db, firebase} from "../utilities"
import DB from '../DAL/DB'
import {resourceType} from "../validators"

export const validators = resourceType["user"]

const users = new DB.Users()

export const usersOne = async (ff, uid, logger) => {
  if (!logger) logger = console.log

  const {data: [user]} = await users.get(null, {filters: [['email', '==', ff.email]]})

  if (user) {
    logger.error(`A user with the email address (${ff.email}) already exists. User creation failed`)
    return
  }

  // validation
  let errors = []
  for (let field of ['email', 'type', 'name']) {

    console.log(ff[field])

    if (!ff[field]) {
      logger.error(`missing ${field} field, user cannot be created`)
      errors.push(field)
    }
  }
  if (errors.length) throw new Error(`field(s) ${JSON.stringify(errors)} failed validation`)

  if (type === 'field-user' && !address) {
    logger.event('No address provided; this field user will not appear on the scheduling map')
    throw new Error(`Failed validation because user type is field user and address does not exist`)
  }
  //

  const userRef = ff.id ? db.collection("users").doc(ff.id) : db.collection("users").doc()
  const {address, type = 'client', pending = false} = ff


  const timestamp = firebase.firestore.Timestamp.now()

  try {
    await userRef.set({
      ...ff,
      id: userRef.id,
      type,
      updated: timestamp,
      created: timestamp,
      pending
    })
    logger.event(`Successfully ${ff.id ? 'modified user' : 'created new user'} ${ff.email}`)
  } catch (e) {
    logger.error('An error occurred trying to save this user; user was not successfully created')
    throw e
  }
}