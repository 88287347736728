import React from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {Collapse, Card, Row, Col} from "antd" //	, Popconfirm,  Divider, Icon, Button, Select, Affix, Tabs
// https://github.com/atlassian/react-beautiful-dnd
import {setResources, clearForm, setActive, save} from "./action_creators" //, setField
import {db, firebase} from "../utilities"
import SystemLogger from "../SystemLogger"
import Header from "./Header"
import FieldsWrapper from "./FieldsWrapper"
import SectionsWrapper from "./SectionsWrapper"
import FormSettings from "./FormSettings"
import FieldDetail from "./FieldDetail"


const createNewForm = async logger => {
  const docRef = db.collection("forms").doc()
  try {
    await docRef.set({id: docRef.id, title: "Untitled Form", created: firebase.firestore.Timestamp.now()})
    
  } catch (e) {
    logger.log("failed to create a new form", "n/a", 3)
    console.log("failed to create a new form")
  }
}

const mapStateToProps = state => ({
  all: state.form,
  form: state.form.base,
  sections: state.form.sections,
  activeSection: state.active.section,
  activeField: state.active.field,
  lock: state.meta.lock
})

const mapDispatchToProps = dispatch => ({
  setResources: (type, values) => dispatch(setResources({type, values})),
  setActive: (type, id) => dispatch(setActive({type, id})),
  clearForm: () => dispatch(clearForm()),
  save: () => save()
})

class FormEdit extends React.Component {
  static propTypes = {
    auth: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    setResources: PropTypes.func,
    all: PropTypes.object,
    form: PropTypes.object,
    sections: PropTypes.object
  }

  componentDidMount = () => {
    this.logger = new SystemLogger(this.props.location.pathname, this.props.auth.currentUser.uid)

    // this is here to save the form if the user, for some reason, decides to refresh the page
    window.onbeforeunload = () => { this.props.save() }

    this.getForm()
  }

  componentWillUnmount = () => {
    if (this.state.subscriptions.length > 0) this.state.subscriptions.map(s => s())
    this.props.save()
    this.props.clearForm()
  }

  state = {subscribers: [], activeSidebar: "form", loading: true}

  getForm = async () => {
    if (this.props.match.params.id === "new") { 
      const docRef = await createNewForm(this.logger)
      this.props.history.replace(`/forms/${docRef.id}`)
    }

    const projectSubscription = db
      .collection("forms")
      .doc(this.props.match.params.id)
      .onSnapshot(docSnap => {
        if (!docSnap.empty) {
          const base = {order: [], ...docSnap.data(), id: docSnap.id}
          this.props.setResources("base", base)

          if (
            !this.props.activeSection &&
            base.order[0]
          ) this.props.setActive("section", base.order[0] || "")

          this.getSections()
          this.setState({loading: false})
        } 
        else { this.logger.log("this form doesn't exist; you shouldn't have been able to get here", "n/a", 3) }
      })

    this.setState({subscriptions: [].concat(this.state.subsciptions, projectSubscription).filter(i => i)})
  }

  getSections = () => {
    const sectionsSubscription = db
      .collection("forms")
      .doc(this.props.form.id)
      .collection("sections")
      .onSnapshot(snapshot => {
        const sections = {}
        snapshot.forEach(doc => (sections[doc.id] = {...doc.data(), id: doc.id}))

        this.props.setResources("sections", sections)

        if (this.props.activeSection && !this.props.activeField) {
          if (this.props.sections[this.props.activeSection] && this.props.sections[this.props.activeSection].order) {
            this.props.setActive("field", this.props.sections[this.props.activeSection].order[0])
          }
        }
      })

    this.setState({subscriptions: [].concat(this.state.subsciptions, sectionsSubscription).filter(i => i)})
  }

  setSidebar = activeSidebar => this.setState({activeSidebar})

  render = () => (
    <div className="pageContainer">
      <Header id={this.props.form.id} published={this.props.form.active} save={this.props.save} />
      <div>
        {this.props.form.active ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgba(0, 0, 255, 0.4)",
              color: "white",
              display: "flex",
              alignItems: "center",
              padding: "5rem",
              justifyContent: "center",
              fontWeight: "bold",
              fontSize: "300%"
            }}
          >
            <div
              style={{
                position: "sticky",
                top: "10rem",
                padding: "5rem",
                backgroundImage: "radial-gradient(black 10%, transparent 65%, transparent 25%)"
              }}
            >
              Unpublish this form to enable editing
            </div>
          </div>
        ) : (
          <div style={{padding: "2rem", display: "flex", flexFlow: "row nowrap"}}>
            <div style={{flex: 1}}>
              <FieldsWrapper />
            </div>
            <div
              style={{
                flex: 1,
                padding: 0,
                position: "sticky",
                top: 0,
                right: "3rem",
                marginLeft: "1rem",
                height: "75rem",
                zIndex: 30,
                overflow: "scroll"
              }}
            >
              <Collapse style={{padding: "0.3rem"}} defaultActiveKey={["1"]}>
                <Collapse.Panel header="Details" key={"1"}>
                  <Card
                    tabList={[
                      {
                        key: "form",
                        tab: "Form Settings"
                      },
                      {
                        key: "sections",
                        tab: "Sections"
                      }
                    ]}
                    activeTabKey={this.state.activeSidebar}
                    onTabChange={key => this.setSidebar(key)}
                  >
                    {this.state.activeSidebar === "sections" ? <SectionsWrapper save={this.props.save} /> : <FormSettings />}
                  </Card>
                </Collapse.Panel>
                <FieldDetail />
              </Collapse>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const WrappedFormEdit = connect(mapStateToProps, mapDispatchToProps)(FormEdit)

export default WrappedFormEdit


// save = async () => {
//   const {base, sections, fields} = this.props.all

//   if (!base.created) base.created = firebase.firestore.Timestamp.now()
//   base.updated = firebase.firestore.Timestamp.now()
//   if (!base.createdBy) {
//     const {id, name} = this.props.auth.sunkaizenUser
//     base.createdBy = {id, name}
//   }

//   let formRef
//   if (base.id) formRef = db.collection("forms").doc(base.id)
//   else formRef = db.collection("forms").doc()
//   const sectionsRef = formRef.collection("sections")

//   const batch = db.batch()

//   if (base.order) {
//     Object.values(sections).forEach(section => {
//       if (!base.order.includes(section)) batch.delete(sectionsRef.doc(section.id))
//     })
//     base.order.forEach((id, i) => {
//       const section = sections[id]

//       if (section && section.id) {
//         const sectionRef = sectionsRef.doc(section.id)

//         if (section.order) {
//           section.order.forEach((id, i) => {
//             let field = fields[id] || {}
//             const fieldRef = sectionRef.collection("fields").doc(id)
//             if (field.type) {
//               if (field.options) field = {...field, options: field.options.filter(o => !!o.label)}
//               batch.set(fieldRef, field)
//             } else {
//               batch.delete(fieldRef)
//             }
//           })
//           batch.set(sectionRef, section)
//         } else {
//           batch.delete(sectionRef)
//         }
//       } else {
//         console.log("section does not exist", id)
//         base.order.splice(i, 1)
//       }
//     })
//   }

//   batch.set(formRef, base, {merge: true})

//   try {
//     await batch.commit()
//   } catch (e) {
//     console.log("something went wrong committing batch: ", e)
//   }
// }