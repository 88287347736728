import {store} from "../utilities"

export default function makeQuery (tabs = null) {
  const state = store.getState()
  const {tabs: tabName, permissions = () => [], sorter = () => []} = state.tableVars.tableStrategy || {}
  const p = permissions() || []

  const {sorter: sortValues} = state.tableControls
  const sort = sorter(sortValues) || []

  let ff = []
  let filters = []

  const {size, from, status, queryStrings: query_strings = [], filters: filtersSaved, fullTextQuery: query} = state.query

  // deep merge here?
  if (tabName && status !== `All`) ff.push({term: {[`${tabName}.sortable`]: {value: status}}})
  if (!tabs) { filters = [...filtersSaved, ...p, ...ff].filter(i => i) }
    
  const qq = {
    type: (state.tableVars.tableStrategy || {}).type,
    query,
    size,
    from,
    sort,
    query_strings,
    filters
  }

  if (!qq.type) throw new Error('table has no type')

  return qq
}