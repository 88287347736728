import React, {useEffect} from "react"
import PropTypes from "prop-types"

import {db} from "../utilities"
import {Form, Select} from "antd"
import {fieldNameConverter} from "../validators"
import {Required} from "./utilities"

import {setField, makePermissions} from "./action_creators"
import {connect} from "react-redux"

const mapStateToProps = state => ({
  fields: state.fields,
  clients: state.permissions.clients,
  change: state.permissionsCategories.clients
})
const mapDispatchToProps = dispatch => ({
  setField: field => value => dispatch(setField(field, value)),
  makePermissions: () => dispatch(makePermissions(`client`))
})

const ClientInformation = ({fields, setField, makePermissions, change, clients}) => {
  useEffect(() => {
    makePermissions()
  }, [change])

  return (
    <div
      style={{
        display: `flex`,
        flexFlow: `column nowrap`,
        alignItems: `flex-start`,
        background: `#F4F6F8`,
        padding: `2rem`,
        margin: `0 0 1rem 0`
      }}
    >
      <h3>Client Information</h3>
      <Required />
      <Form.Item style={{width: `100%`, margin: `0 0 1rem 0`, padding: `0 1rem 0 0`}}>
        <Select
          initialValue={fields.client ? fields.client.name : null}
          onChange={e => {
            const {id, name, email, qualifications} = clients[e]
            setField(`client`)({id, name, email, qualifications: qualifications || {}})
          }}
          size="large"
          value={ fields.client ? fields.client.name : null }
          style={ {width: `100%`} }
          placeholder={ fieldNameConverter[`client`] }
          data-cy="clientInfo"
        >
          {Object.values(clients || {}).sort((a, b) => a.name > b.name ? 1 : -1).map((client, i) => (
            <Select.Option key={i} value={client.id}>
              {client.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  )
}

ClientInformation.propTypes = {
  fields: PropTypes.object,
  setFields: PropTypes.func,
  clients: PropTypes.object
}

const WrappedClientInformation = connect(mapStateToProps, mapDispatchToProps)(ClientInformation)
export default WrappedClientInformation
