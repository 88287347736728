import React from "react"
// import PropTypes from 'prop-types'
import {connect} from "react-redux"

import {Button, Row, Col} from "antd" //	, Popconfirm, Card, Divider, Input, Icon, Select, Affix, Tabs

import {db, firebase, history} from "../utilities"

import {setField} from "./action_creators"

const mapDispatchToProps = dispatch => ({
  setField: value => dispatch(setField({category: `base`, id: `updated`, value}))
})

const Header = ({setField, id, save, saveAndClose, published}) => {
  const publish = async active => {
    const update = {active}
    if (active) {
      setField(firebase.firestore.Timestamp.now())
      await save()
    }

    db.collection(`forms`)
      .doc(id)
      .set(update, {merge: true})
  }

  saveAndClose = async () => {
    await save()
    history.push(`/forms`)
  }

  return (
    <Row>
      <Col span={15}>
        <h1 className="page-title pageTitle">Form Builder</h1>
      </Col>
      <Col span={4}>
        <Button
          ghost={false}
          className="thick"
          type={published ? `default` : `primary`}
          size="large"
          onClick={() => publish(published ? false : true)}
        >
          {published ? `Unpublish` : `Publish`}
        </Button>
      </Col>
      <Col span={1}></Col>
      <Col span={4}>
        <Button ghost={false} className="thick" type="primary" size="large" onClick={saveAndClose}>
          Done
        </Button>
      </Col>
    </Row>
  )
}

const ConnectedHeader = connect(null, mapDispatchToProps)(Header)

export default ConnectedHeader
