import DB from '../../DAL/DB'

const projects = new DB.Projects()
const tempProjects = new DB.TempProjects()

export default async function checkFormIntegrity (project) {
  let modified = false
  for (let section in project.intake.sections) {
    if (!project.intake.order.includes(section)) {
      if (!modified) { 
        await tempProjects.update(project.id, null, project) 
      }
      modified = true

      delete project.intake.sections[section]
    }
  }

  if (modified) { 
    await projects.update(project.id, null, project) 
  }

  return project
}