import React, {useState} from "react"
import {Icon, Button, Modal} from "antd"
import {connect} from "react-redux"
import FileUploader, {patterns} from '../../NewFileUploader'
import {setField, photoViewer} from "../action_creators"
import {DropTarget} from './DropTarget'
import {sortImages} from './sortImages'
import "./PhotoPool.css"

const helperMessage = () => (
  <div className="flex-row align-center" >
    <Icon type="arrow-up" />
    <div className="padding-left-1" >Drop images here to attach them to this project</div>
  </div>
)

const sToP = state => ({
  fields: state.fields,
  project: state.project,
})

const dToP = dispatch => ({
  setField: (id, value) => dispatch(setField(id, value)),
  open: () => { dispatch(photoViewer(true)) }
})


export function PhotoPool ({project, fields, open, setField}) {
  const [visible, setVisible] = useState(false)
  const [currentImage, setCurrentImage] = useState({})

  async function submit (files) {
    setField(project.id, {images: {...fields[project.id].images, ...files}})
  }

  function fullsize (ci) { 
    setVisible(true)
    setCurrentImage(ci)
  }

  const sortedImages = Object.values((fields[project.id] || {}).images || {}).sort(sortImages)

  return (
    <div className="photo-pool">
      <FileUploader
        parentID={project.id}
        pattern={patterns.photo}
        helperMessage={helperMessage}
        submit={submit}
      />
      <hr />
      <div className="horizontal-divider" />
      <p className="flex-row justify-center" >
                Drag images from here to any of the corresponding inspection questions.
      </p>
      <Button className="button" size="large" onClick={open}>Manage Files</Button>
      <DropTarget {...{project, fields, sortedImages, fullsize}} />
      <Modal
        className="modal"
        title="Expanded Image"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <img alt="user uploaded" className="full-height full-width" src={currentImage.url} />
      </Modal>
    </div>
  )
}

const WrappedPhotoPool = connect(sToP, dToP)(PhotoPool)
export default WrappedPhotoPool