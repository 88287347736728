import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'antd'
import CreateCSVReports from './TopLevelState'

export default function CSVReports ({style}) {
  const [visible, setVisible] = useState(false)
  const [done, setDone] = useState(false)

  useEffect(function () { setVisible(done) }, [done])

  return (
    <div style={style} >
      <Button 
        disabled={done} 
        onClick={() => setVisible(true)}
        style={{height: '100%', width: '100%'}}
      >
                Create CSV Report
      </Button>
      <Modal
        visible={visible}
        onOk={() => setDone(true)}
        confirmLoading={done}
        onCancel={() => setVisible(false)}
        title={<h3>{"Create CSV Report"}</h3>}
        width="80rem"
        okText="Create"
      >
        <CreateCSVReports done={done} setDone={setDone} />
      </Modal>
    </div>
  )
}