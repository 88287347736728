import React, {useEffect, useState} from "react" // , useMemo
import _ from "lodash"
import produce from "immer"
import {Radio, Button, InputNumber, Icon} from "antd"

import Table from "../CompactTable/Table"
import {useModal, ModalInput} from "../Modal"
import {
  onInputChange,
  uuid,
  db
  // useStateSynchronizedWithInput
} from "../utilities"

export default function ScoringSystemsList() {
  // `data` is all of the scoring systems.
  const [data, setData] = useState()
  // `modalData` will be whatever the selected scoring system is, or a blank
  // buffer for creating a new scoring system.
  const [modalData, setModalData] = useState({CRUDFriendly: true})
  const [modalTitle, setModalTitle] = useState()
  // If "New" it's the Creation modal, if a number it's the View modal,
  // displaying the data at the index of the number.
  const [modalOperation, setModalOperation] = useState()

  useEffect(() => {
    const unsubscribe = subscribeToScoringSystemData({setData})
    return unsubscribe
  }, [])

  useEffect(() => console.log(`data`, data, `modalData`, modalData))

  const {showModal, hideModal, modal} = useModal({
    props: {
      title: modalTitle,
      onOk: () => {
        if (modalOperation.type === `New`) {
          // Transfrom the CRUD-friendly criticality map back into the
          // backend format.
          const newScoringSystem = makeModalDataBackendFriendly(modalData)

          // Validations

          if (_.isEmpty(newScoringSystem.name)) {
            return alert(`Scoring system must have a name`)
          }

          const emptyNames = Object.keys(newScoringSystem.criticality_map).filter(x => _.isEmpty(x))
          if (emptyNames.length !== 0) {
            return alert(`Each value must have a name`)
          }

          // Save to DB.

          const ref = db.collection(`scoring_systems`).doc()
          ref.set({...newScoringSystem, id: ref.id})
        }

        setModalData({})
        hideModal()
      },
      onCancel: () => setModalData({}),
      style: {maxWidth: `80rem`}
    },
    content: (
      <ModalContent
        modalData={makeModalDataCRUDFriendly(modalData)}
        setModalData={setModalData}
        modalOperation={modalOperation}
      />
    )
  })

  function openModal(operation) {
    setModalOperation(operation)

    if (operation.type === `New`) {
      setModalTitle(`Create New Scoring System`)
      setModalData({})
    } else if (operation.type === `View`) {
      const modalData = _.find(data, x => x.id === operation.id)
      setModalTitle(`Viewing \`${modalData.name}\``)
      setModalData(modalData)
    }
    showModal()
  }

  return (
    <>
      {modal}
      <Table
        buttonText="Add New Scoring System"
        data-cy="addNew"
        onButtonClick={() => openModal({type: `New`})}
        tableProps={{
          dataSource: data,
          rowKey: x => x.id,
          columns: [
            {title: `Name`, dataIndex: `name`},
            {
              title: `View`,
              dataIndex: `id`,
              render: text => (
                <Icon style={{fontSize: `2.6rem`}} type="eye" onClick={() => openModal({type: `View`, id: text})} />
              )
            }
          ]
        }}
      />
    </>
  )
}

function makeModalDataCRUDFriendly(modalData) {
  if (modalData.CRUDFriendly) {
    // Don't reprocess converted data.
    return modalData
  }

  return produce(modalData, draft => {
    const newCriticalityMap = {}

    // eslint-disable-next-line
    for (let name in draft.criticality_map) {
      const score = draft.criticality_map[name]
      const id = uuid()

      newCriticalityMap[id] = {name, score, id}
    }

    draft.criticality_map = newCriticalityMap
    draft.CRUDFriendly = true
  })
}

function makeModalDataBackendFriendly(crudModalData) {
  if (!crudModalData.CRUDFriendly) {
    // Don't process data that's already backend friendly.
    return crudModalData
  }

  return produce(crudModalData, draft => {
    const criticalityMap = {}

    Object.values(draft.criticality_map).forEach(value => {
      const {name, score} = value
      criticalityMap[name] = score
    })

    draft.criticality_map = criticalityMap
    delete draft.CRUDFriendly
  })
}

function subscribeToScoringSystemData({setData}) {
  return db.collection(`scoring_systems`).onSnapshot(snapshot => {
    const data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
    setData(data)
  })
}

function ModalContent({modalData, setModalData, modalOperation}) {
  const change = onInputChange(modalData, setModalData)

  // If not creating data, the modal is view-only.
  const disabled = modalOperation.type !== `New`

  return (
    <div>
      <form>
        <label>Name</label>
        <ModalInput
          value={modalData.name}
          disabled={disabled}
          style={{marginBottom: `2rem`}}
          onChange={change((value, draft) => {
            draft.name = value
          })}
        />
        <label>Percent overall required to pass (Optional)</label>
        <ModalInput
          disabled={disabled}
          style={{marginBottom: `4em`, width: `100%`}}
          placeholder="Any percent will pass"
          value={modalData.percentage_failure ? String(modalData.percentage_failure) : ``}
          min={0}
          max={100}
          formatter={value => value && `${value}%`}
          parser={value => value.replace(`%`, ``)}
          componentType={InputNumber}
          onChange={change(``, (value, draft) => {
            draft.percentage_failure = value
          })}
        />
        <div>
          <RowSpacing
            left={<span style={{fontWeight: `bold`}}>Value</span>}
            right={<span style={{fontWeight: `bold`}}>Number of Failures Permitted</span>}
          />

          {_.map(modalData.criticality_map, ({name, score}, id) => (
            <ValueRow disabled={disabled} key={id} change={change} id={id} name={name} score={score} />
          ))}
        </div>
        <Button
          disabled={disabled || Object.keys(modalData.criticality_map).length >= 10}
          data-cy="addValueBtn"
          title={modalData.criticality_map >= 10 ? `Can only have 10 values per scoring system` : ``}
          size="large"
          style={{marginTop: `3rem`}}
          onClick={change((_value, draft) => {
            const id = uuid()
            draft.criticality_map[id] = {
              id,
              name: ``,
              score: null
            }
          })}
        >
          Add Value
        </Button>
      </form>
    </div>
  )
}

const RowSpacing = ({left, right, style}) => (
  <div style={{display: `flex`, marginBottom: `1rem`, ...style}}>
    <div style={{flex: 4, paddingRight: `3rem`}}>{left}</div>
    <div style={{flex: 7}}>{right}</div>
  </div>
)

const ValueRow = ({id, name, score, change, disabled}) => (
  <RowSpacing
    left={
      <ModalInput
        disabled={disabled}
        value={name}
        data-cy="criticalityName"
        onChange={change((newName, draft) => {
          draft.criticality_map[id].name = newName
        })}
      />
    }
    right={
      <div
        data-cy="criticalityRow"
        style={{
          display: `flex`,
          justifyContent: `space-between`,
          alignItems: `center`,
        }}
      >
        <Radio.Group
          disabled={disabled}
          value={score === null ? `Unlimited` : `Limit`}
          onChange={change((value, draft) => {
            if (value === `Unlimited`) {
              draft.criticality_map[id].score = null
            } else if (value === `Limit`) {
              draft.criticality_map[id].score = 0
            }
          })}
        >
          <Radio value="Unlimited" style={{marginRight: `3rem`}} data-cy="unlimitedFailuresOpt">
                        Any number
          </Radio>
          <Radio value="Limit" data-cy="limitedFailuresOpt">
                        No more than
          </Radio>
        </Radio.Group>
        <ModalInput
          onBlur={(e) => {
            // Ensure that the field defaults to 0 if left empty.
            if (score === `AwaitingInput`) {
              change((_value, draft) => {
                draft.criticality_map[id].score = 0
              })(e)
            }
          }}
          data-cy="failurePermitInput"
          disabled={disabled || score === null}
          style={{maxWidth: `5rem`, fontSize: `1.4rem`}}
          value={_.isFinite(score) ? score : ``}
          onChange={change((value, draft) => {
            const newScore = parseFloat(value)
            draft.criticality_map[id].score = _.isFinite(newScore) ? newScore : `AwaitingInput`
          })}
        />
        <Button
          disabled={disabled}
          title="Remove Value"
          icon="minus-circle"
          size="large"
          style={{width: `4rem`}}
          onClick={change(``, (_, draft) => {
            delete draft.criticality_map[id]
          })}
        />
      </div>
    }
  />
)
