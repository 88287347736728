import React from "react"
// import PropTypes from 'prop-types'

import {capitalizeFirst} from "../utilities" //	db, , singular
// import moment from 'moment'

import {Input, Icon, Button, DatePicker} from "antd"

/* these functions return objects that, when spread over a column in a table defintion, add filtering to that column */

export const textSorter = (a, b) => {
  if (a && b) {
    if (a.toLowerCase() === b.toLowerCase()) return 0
    else return a.toLowerCase() > b.toLowerCase() ? 1 : -1
  } else if (!a) return -1
  else if (!b) return 1
  else return 0
}

export const numberSorter = (a, b) => {
  if (a && b) {
    if (a === b) return 0
    else return a > b ? 1 : -1
  } else if (!a) return -1
  else if (!b) return 1
  else return 0
}

export const textFilter = (activeFilters) => (category) => ({
  filteredValue: activeFilters.filters ? activeFilters.filters[category] : [],
  filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
    <div style={{border: `0.1rem solid lightgrey`, borderRadius: `0.5rem`, padding: `2rem`, backgroundColor: `white`}}>
      <Input
        placeholder={`Search ${capitalizeFirst(category)}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={confirm}
      />
      <div style={{display: `flex`, flexFlow: `row nowrap`, margin: `1rem 0 0 0`}}>
        <Button icon="search" size="small" onClick={confirm}>
          Search
        </Button>
        <Button size="small" onClick={clearFilters}>
          Reset
        </Button>
      </div>
    </div>
  ),
  filterIcon: (filtered) => <Icon type="search" />,
  render: (text) => <div>{text}</div>,
})

export const dateFilter = (activeFilters) => (category) => ({
  filteredValue: activeFilters.filters || [],
  filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
    if (selectedKeys[2] === `submitted`) {
      setSelectedKeys([])
    }
    return (
      <div
        style={{
          border: `0.1rem solid lightgrey`,
          borderRadius: `0.5rem`,
          padding: `2rem`,
          backgroundColor: `white`,
          display: `flex`,
          flexFlow: `column`,
        }}
      >
        <DatePicker
          placeholder="From"
          value={selectedKeys[0]}
          onChange={(e) => {
            const v = Array.from(selectedKeys)
            v[0] = e ? e.set({hour: 0, minute: 0, second: 0, millisecond: 0}) : undefined
            setSelectedKeys(v)
          }}
        />
        <DatePicker
          placeholder="To"
          value={selectedKeys[1]}
          onChange={(e) => {
            const v = Array.from(selectedKeys)
            v[1] = e ? e.set({hour: 0, minute: 0, second: 0, millisecond: 0}).date(e.date() + 1) : undefined
            setSelectedKeys(v)
          }}
        />
        <div style={{display: `flex`, flexFlow: `row nowrap`, margin: `1rem 0 0 0`}}>
          <Button
            icon="search"
            size="small"
            onClick={(e) => {
              confirm()
              selectedKeys[2] = `submitted`
              setSelectedKeys(selectedKeys)
            }}
          >
            Search
          </Button>
          <Button size="small" onClick={clearFilters}>
            Reset
          </Button>
        </div>
      </div>
    )
  },
})
