import {auth} from "../../../Auth"

export default function permissions () {
  if (auth && auth.sunkaizenUser) {
    let permissions
    if (['client', 'admin', 'super-admin'].includes(auth.sunkaizenUser.type)) {
      permissions = [{match: {'organization.id': auth.sunkaizenUser.organization.id}}]
    }
        
    return permissions
  }
  else return
}