import React from "react"
import PropTypes from "prop-types"
import './QueryVisualFormatter.css'
import {capitalizeFirst} from "../../utilities"


const directions = {
  asc: 'Ascending',
  desc: 'Descending'
}

const makeReadableSortName = sortable => {
  console.log(sortable)
  const [[field, direction]] = Object.entries(sortable)

  console.log(field, direction)
  const tokens = field.split('.')
  const [term, ...rest] = tokens.filter(() => ['intake', 'sortable'])

  if (rest.length > 0) console.log(rest)
  return `${capitalizeFirst(term)} (${directions[direction]})`
}

const QueryVisualFormatter = ({filter: f}) => {
  const {query, sort} = f

  // console.log(query_strings)
  // console.log(filters)

  return (
    <div>
      <h3>This is the filter you are saving</h3>
      { query.length > 0 && 
				<div className="container" >
				    <div className="title" >Text Search</div>
				    <div className="field" >{query}</div>
				</div>
      }
      { sort.length > 0 &&
				<div className="container" >
				    <div className="title" >Sort By</div>
				    {sort.map(sorter => <div className="field" >{makeReadableSortName(sorter)}</div>)}
				</div>
      }
    </div>
  )
}

QueryVisualFormatter.propTypes = {
  query: PropTypes.object
}

export default QueryVisualFormatter