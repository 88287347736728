import React, {forwardRef} from "react"

import {store} from "./utilities"
import {Provider} from "react-redux"
import ProjectCreator from "./ProjectCreator"

const EntryPoint = forwardRef((props, ref) => (
  <Provider store={store}>
    <ProjectCreator ref={ref} {...props} />
  </Provider>
))

export default EntryPoint
