import React, {useState, useEffect} from 'react'
import {Button} from 'antd'
import {db} from '../../../utilities'
import {history} from '../../../history'
import SystemLogger from "../../../SystemLogger"
import {auth} from '../../../Auth'
import {colors} from '../../../config'

export default function AddNewForm () {
  const [loading, setLoading] = useState(false)
  const [logger, setLogger] = useState(null)

  useEffect(function () {
    auth.sunkaizenUserSubscribe(user => {
      if (!user) return
      setLogger(new SystemLogger({path: '/forms', uid: auth.sunkaizenUser.uid}))
    })
  }, [])

  async function addNewForm () {
    setLoading(true)

    const docRef = db.collection('forms').doc()
    const form = {title: 'Untitled Form', id: docRef.id}

    try {
      await docRef.set(form)
      history.push(`/forms/${docRef.id}`)
    }
    catch (e) {
      console.log(e)
      logger.categoryId = docRef.id
      logger.error(`failed to create a new form`, null, 3)
    }
    finally {setLoading(false)}
  }

  return (
    <Button 
      style={{flex: 1, height: '4rem', background: colors.primary, color: 'white', margin: '0 0.3rem 0 0.3rem'}} 
      disabled={loading} 
      onClick={addNewForm} 
    >
            Add New Form
    </Button>
  )
}