import React, {useState} from "react"
import {Button, Modal} from "antd"
import CancelProjectModal from "./CancelProjectModal"

import ProjectCreator from "../ProjectBuilder/Provider"
import {auth} from "../Auth"

export default function Header ({id, status}) {
  // const childRef = useRef(null)
  const [edit, setEdit] = useState(false)
  const [cancel, setCancel] = useState(false)
  const [done, setDone] = useState(null)

  async function submit () {
    try {
      // await childRef.current.finalize()
      setDone(true)
      setEdit(false)
    }
    catch (e) { console.log('could not submit edited project', e) }
  }

  function showEdit (show) {
    // if (childRef.current) childRef.current.componentDidMount()
    setEdit(show)
    setDone(null)
  }

  return (
    <div>
      <div className="flex-row padding-right-2 padding-bottom-1 justify-space-between align-center">
        <h1 className="page-header">Project Details</h1>
        { status === 'Request' &&
                    <div>
                      <Button size="large" onClick={() => console.log('change status to To Be Scheduled with accepting organization as responsible organization')} ></Button>
                      <Button size="large" onClick={setCancel(true)} >Reject Project</Button>
                    </div>
        }
        { status !== 'Request' && auth.sunkaizenUser && ['client', 'admin', 'super-admin'].includes(auth.sunkaizenUser.type) && 
            <Button size="large" onClick={() => setEdit(true)}>Edit</Button>
        }
      </div>
      <Modal
        title={<h2>{`Edit Project`}</h2>}
        visible={edit}
        onOk={submit}
        onCancel={() => {
          // childRef.current.props.blankFields()
          showEdit(false)
        }}
        width="90%"
        maskStyle={{background: "rgba(0, 0, 0, 0.3)"}}
      >
        <ProjectCreator id={id} setDone={setDone} done={done} />
      </Modal>
      <Modal
        title={
          <div>
            <div>Reject Project</div>
            <div style={{fontSize: '1rem', fontWeight: 'normal', color: 'grey'}} >Warning: Rejecting this project will cancel it</div>
          </div>
        }
        visible={cancel}
        okText="Reject Project"
        onOk={() => setCancel(false)}
        onCancel={() => setCancel(false)}
        footer={null}
      >
        <CancelProjectModal id={id} done={() => setCancel(false)} />
      </Modal>
    </div>
  )
}