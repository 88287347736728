import {baseStore} from './utilities'
import DB from '../../DAL/DB'

const projects = new DB.Projects()

export default function thumbnail (parentID) {
  async function saveToDB (metadata) {
    const {data: [project], ok} = await projects.get(parentID)
    if (!ok) throw new Error('unable to get project in file uploader thumbnail pattern')

    project.site.thumbnail = metadata
    await projects.update(parentID, null, project)
  }    
  const store = baseStore(`projects/${parentID}/thumbnails/`)

  return {store, saveToDB}
}