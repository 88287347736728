import React from "react"
// import PropTypes from 'prop-types'

import Papa from "papaparse"
import {db, firebase} from "../utilities"

import SystemLogger from "../SystemLogger"
import Permissions from "./Permissions"
import GeocodeWrapper from "../GeocodeWrapper"
import FileUploader from "../FileHandling/FileUploader"
import Auth from "../Auth"

const auth = new Auth()
const wrappedGeocode = new GeocodeWrapper()

// this is a Promise wrapper for FileReader so that we can use Promises.all on an array of files for clarity
const readUploadedText = file => {
  let fr = new FileReader()

  return new Promise((resolve, reject) => {
    fr.onload = e => {
      resolve(fr.result)
    }
    fr.onerror = e => reject(console.log("some kinda error converting file to text: ", e))
    fr.readAsText(file)
  }).then(file => makeCSV(file))
}

const validateHeaders = header =>
  header.every((h, i) => {
    const b = h.toLowerCase().trim() === ["name", "email", "address", "city", "state", "zip", "type", "nabcep_ids"][i]
    return b
  })

const makeCSV = textfile =>
  new Promise((resolve, reject) => {
    Papa.parse(textfile, {
      error: e => reject(e),
      complete: f => resolve(f.data)
    })
  })

const validUserTypes = {'client': true, 'field-user': true, 'installer': true, 'quality-manager': true, 'scheduler': true}
function validateType (userArray) {
  for (let i in userArray) {
    userArray[i].type = userArray[i].type.toLowerCase()
    if (!validUserTypes[userArray[i].type]) userArray[i].errors = {
      ...(userArray[i].errors || {}), 
      type: `Invalid type supplied (${userArray[i].type})`
    }
  }
}

const geocodeAddress = csvArray => {
  return csvArray.map(async ([name, email, address, city, state, zip, type, nabcep_ids]) => {
    const a = `${address} ${city}, ${state} ${zip}`

    return {
      geocode_results: JSON.parse(JSON.stringify(await wrappedGeocode.geocode(a))),
      name,
      email,
      address,
      city,
      state,
      zip,
      type,
      nabcep_ids
    }
  }).filter(i => i)
}

const types = {
  street_number: true,
  route: true,
  locality: true,
  administrative_area_level_1: true,
  postal_code: true
}

const makeUser = ({
  geocode_results,
  name,
  email,
  address,
  city,
  state,
  zip,
  type,
  nabcep_ids = null
}) => permissions => async userRef => {
  const {
    clients = {},
    clientsAll = null,
    qualifications = {},
    qualificationsAll = null,
    regions = {},
    regionsAll = null
  } = permissions || {}
  let dummyAddress = null,
    latitude = null,
    longitude = null

  const filteredAddress = {}

  if (!geocode_results.address_components) {
    if (type === 'field-user') {
      throw new Error(`${name} does not have an address; field users must have addresses`)
    }
  }
  else {
    geocode_results.address_components.forEach(c => {
      if (c.types.some(t => types[t])) { filteredAddress[c.types[0]] = c.short_name }
    })    
  }

  if (type === "admin" || type === "super-admin") {
    throw new Error(`invalid user type: ${type} on user: ${name} with email: ${email}`)
  }
  
  let user = {
    clients,
    clientsAll,
    qualifications,
    qualificationsAll,
    regions,
    regionsAll,
    geocode_results,
    name,
    email,
    address,
    type,
    nabcep_ids,
    id: userRef.id,
    created: firebase.firestore.Timestamp.now(),
    updated: firebase.firestore.Timestamp.now(),
    ...filteredAddress
  }

  if (dummyAddress) user.dummyAddress = dummyAddress
  if (latitude && longitude) {
    user.geocode_results = JSON.parse(
      JSON.stringify(await wrappedGeocode.geocode({lat: latitude, lng: longitude}, "location"))
    )
    user.formatted_address = user.geocode_results.formatted_address
  }

  // console.log(user.geocode_results)

  return user
}

const makeBatches = dbOps => {
  const batchJobs = []
  for (let i = 0; i < dbOps.length; i = i + 400) {
    const b = dbOps.slice(i, i + 400)
    const batch = db.batch()

    b.forEach(({ref, data}) => {
      batch.set(ref, data)
    })
    batchJobs.push(batch)
  }
  return batchJobs
}

const message = () => (
  <div style={{fontSize: "75%", width: "100%"}}>
    <p>Drag and drop a CSV of user information here.</p>
    <p>User information has the following format:</p>
    <table style={{width: "100%"}}>
      <tbody>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Address</th>
          <th>City</th>
          <th>State</th>
          <th>Zip</th>
          <th>Type</th>
          <th>NABCEP_IDs</th>
        </tr>
        <tr>
          <td>Bob John</td>
          <td>bob@john.com</td>
          <td>123 Street Lane Suite 3</td>
          <td>Pleasanton</td>
          <td>CA</td>
          <td>12345</td>
          <td>client</td>
          <td>4123x24uijsdlf1238</td>
        </tr>
      </tbody>
    </table>
    <p style={{padding: "2rem 0 0 0"}}>
      Please include all the headers on your CSV, even if you do not provide any values for them, or user creation will
      fail
    </p>
  </div>
)

const UserInformation = ({users = []}) => (
  <div style={{borderRadius: "0.5rem", padding: "2rem", margin: "2rem 0 0 0", backgroundColor: "rgb(244, 246, 248)"}}>
    <h3>Uploaded Users</h3>
    <div
      style={{backgroundColor: "white", border: "0.1rem solid lightgrey", padding: "1.5rem", borderRadius: "0.5rem"}}
    >
      {users.map(({errors, geocode_results, name, email, address, city, state, zip, type, nabcep_ids}, i) => (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} key={i}>
          { errors && errors.type &&
            <div style={{fontSize: '3rem', position: 'absolute', padding: '2rem', background: 'rgba(0, 0, 0, 0.3)'}} >{errors.type}</div>
          }
          <div style={{flex: 1}} >
            <div style={{padding: "0 0 1rem 0"}}>#{i + 1}</div>
            <div style={{padding: "0 1rem 0 0", fontWeight: "bold"}}>Geocoded Address</div>
            <div>{geocode_results.formatted_address || '(Failed to Geocode this address)'}</div>
            <div style={{padding: "0 1rem 0 0", fontWeight: "bold"}}>Your Address</div>
            <div>{`${address} ${city}, ${state} ${zip}`}</div>
            <div
              style={{display: "flex", flexFlow: "row nowrap", justifyContent: "space-between", padding: "2rem 0 2rem 0"}}
            >
              <div style={{display: "flex", flexFlow: "row nowrap"}}>
                <div style={{fontWeight: "bold", padding: "0 1rem 0 0"}}>Name</div>
                <div>{name}</div>
              </div>
              <div style={{display: "flex", flexFlow: "row nowrap"}}>
                <div style={{fontWeight: "bold", padding: "0 1rem 0 0"}}>Email</div>
                <div>{email}</div>
              </div>
              <div>
                <div style={{fontWeight: "bold", padding: "0 1rem 0 0"}}>Type</div>
                <div>{type}</div>
              </div>
              {nabcep_ids && nabcep_ids.length > 0 && (
                <div style={{display: "flex", flexFlow: "row nowrap"}}>
                  <div style={{fontWeight: "bold", padding: "0 1rem 0 0"}}>NABCEP IDs</div>
                  <div>{nabcep_ids}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)

class BulkUserImport extends React.Component {
  state = {users: [], fields: {clients: {}, qualifications: {}, regions: {}}}

  async componentDidMount() {
    this.logger = new SystemLogger({uid: auth.currentUser.uid})
    await this.loaded()
  }

  setField = field => value => this.setState({fields: {...this.state.fields, [field]: value}})

  upload = async files => {
    console.log(1)

    let promises = files.map(f => readUploadedText(f))
    let textfiles = await Promise.all(promises)

    textfiles.map(async file => {
      let header = file.shift()

      file = file.filter(f => f.some(l => l.length > 0))

      try {
        if (!validateHeaders(header)) {
          console.log("there's something wrong with the header; canceling", header)
          return
        }
        const userPromises = geocodeAddress(file)
        const newUsers = await Promise.all(userPromises)
        validateType(newUsers)

        const users = [].concat(this.state.users, newUsers).filter(i => i)

        console.log(users)

        this.setState({users})
      } catch (e) {
        console.log("could not geocode all the sites", e)
      }
    })
  }

  async setLoaded(e) {
    if (e.key === "googleMapsLoaded") {
      if (e.newValue === "true") {
        console.log("google maps is loaded")
        this.setState({loaded: true})
        this.geocoder = new window.google.maps.Geocoder()
      }
    }
  }

  async loaded() {
    if (window.google && window.google.maps) {
      // console.log("google maps is already loaded")
      window.removeEventListener("storage", this.setLoaded)
      await this.setState({loaded: true})
      this.geocoder = new window.google.maps.Geocoder()
    } else {
      console.log("google maps is not yet loaded, listening")
      window.addEventListener("storage", this.setLoaded)
    }
  }

  finalize = async (finalize = true) => {
    if (finalize) {
      const {users} = this.state //	, clients, regions, qualifications
      // const { uid } = auth.currentUser

      // the issue here is that only the user is a promise; the ref is not
      // 	so the promise resolves immediately while the users are still working
      let dbOps = users.filter(u => (!u.errors || !u.errors.type)).map(uu => {
        const userRef = db.collection("users").doc()
        try {
          const user = makeUser(uu)(this.state.fields)(userRef)
          return {ref: userRef, data: user}
        } catch (e) {
          this.logger.error(e.message)
        }
      })

      const uuu = dbOps.map(({data}) => data)
      const uu = await Promise.all(uuu)

      dbOps.forEach((op, i) => (dbOps[i].data = uu[i]))
      const batchJobs = makeBatches(dbOps)

      try {
        const results = await Promise.all(batchJobs.map(batch => batch.commit()))

        if (results.every(r => !r)) {
          this.logger.event(
            "successfully created bulk users",
            `inserted ${dbOps.length} resources into the database`,
            3
          )
        } else {
          throw new Error("failed to commit all the db objects")
        }
      } catch (e) {
        this.logger.error("failed to commit all users to the database", JSON.stringify(e, null, 2), 5)
        console.log(e)
      }
    }
    this.setState({fields: {}, users: []})
  }

  render = () => (
    <div style={{height: "100%"}}>
      <Permissions fields={this.state.fields} setField={this.setField} />
      <FileUploader submit={this.upload} helperMessage={message} />
      {this.state.users.length > 0 && <UserInformation users={this.state.users} />}
    </div>
  )
}

export default BulkUserImport
