import {store} from "./utilities"
import {db, firebase} from "../utilities"
import Auth from '../Auth'

const auth = new Auth()

export const setLock = bool => ({type: 'LOCK', bool})
export const setResources = ({type, values}) => ({type: 'SET_RESOURCES', name: type, value: values})

// these two actions are the same, and one should be removed
export const setField = ({category, id, value}) => ({type: 'SET_FIELD', category, id, value})
export const addResource = ({id, body, category}) => ({type: `ADD_RESOURCE`, category, id, body})

export const mergeField = ({category, id, key, value}) => ({type: 'MERGE_FIELD', category, id, key, value})
export const statusUpdate = ({category, bool}) => ({type: 'STATUS_UPDATE', category, bool})
export const clearForm = () => ({type: 'CLEAR_FORM'})

export const deleteResource = ({category, id}) => async dispatch => {
  const state = store.getState()
  const pId = state.form.base.id
  const batch = db.batch()

  save()

  if (category === `fields`) {
    const {order} = {...state.form.sections[state.active.section]}
    order.splice(order.indexOf(id), 1)

    const sRef = db
      .collection(`forms`)
      .doc(pId)
      .collection(`sections`)
      .doc(state.active.section)
    const fRef = sRef.collection(`fields`).doc(id)

    batch.set(sRef, {order}, {merge: true})
    batch.delete(fRef)
  } else if (category === `sections`) {
    const {order} = {...state.form.base}
    if (order.includes(id)) order.splice(order.indexOf(id), 1)
    else throw new Error(`the order array does not have the requested ID in it`)

    const pRef = db.collection(`forms`).doc(pId)
    const sRef = pRef.collection(`sections`).doc(id)

    batch.set(pRef, {order}, {merge: true})
    batch.delete(sRef)
  }

  await batch.commit()
  return {type: 'DELETE_RESOURCE', category, id}
}

export const setActive = ({type, id}) => async dispatch => {
  if (type === 'field') { 
    dispatch({type: 'SET_ACTIVE', name: type, value: id})
  }
  else if (type === `section`) { 
    await save() 
    dispatch({type: 'SET_ACTIVE', name: type, value: id})
  }
  else { throw new Error(`setActive received an invalid type: (${type})`) }
}

export const save = async () => {
  const state = store.getState()
  const {base, sections} = state.form
  const batch = db.batch()

  if (!base || base.order.length < 1) return

  let formRef
  if (base.id) formRef = db.collection("forms").doc(base.id)
  else throw new Error('form is missing an ID')
  const sectionsRef = formRef.collection("sections")

  stampMetadata(base)
  
  base.order.forEach((id, i) => {
    const section = sections[id]

    if (section && section.id) { saveFields(section, sectionsRef, batch) } 
    else { 
      console.log('section for ID does not exist', section)
      // throw new Error("section for ID does not exist", id) 
    }
  })

  batch.set(formRef, base, {merge: true})
  try { await batch.commit() } 
  catch (e) { throw new Error("something went wrong committing batch: ", e) }
}

const saveFields = (section = {}, sectionsRef, batch) => {
  const state = store.getState()
  const {fields = {}} = state.form

  console.log('section in saveFields: ', section)

  const sectionRef = sectionsRef.doc(section.id)

  section.order.forEach((id, i) => {
    let field = fields[id]
    const fieldRef = sectionRef.collection("fields").doc(id)
    if (field && field.type) {
      if (field.options) field = {...field, options: field.options.filter(o => !!o.label)}
      batch.set(fieldRef, field)
    } 
    else { console.log('field for ID does not exist', id) }
  })
  batch.set(sectionRef, section)
}

const stampMetadata = base => {
  if (!base.created) base.created = firebase.firestore.Timestamp.now()
  base.updated = firebase.firestore.Timestamp.now()
  if (!base.createdBy) {
    const {id, name} = auth.sunkaizenUser
    base.createdBy = {id, name}
  }

}

export const addSection = () => async dispatch => {
  const state = store.getState()
  const {form: {base: {id, order}}} = state
  const sectionRef = db.collection('forms').doc(id).collection('sections').doc()
  console.log(sectionRef.id)
  dispatch(setActive({
    type: 'field',
    id: null
  }))
  dispatch(setField({
    type: 'MERGE_FIELD', 
    category: 'base', 
    id: 'order', 
    value: [...order, sectionRef.id]
  }))
  dispatch(setField({
    type: 'MERGE_FIELD', 
    category: 'sections', 
    id: sectionRef.id, 
    value: {id: sectionRef.id, title: "Untitled Section", order: []}
  }))
  dispatch(setActive({type: "section", id: sectionRef.id}))
}
